import ArchiveIcon from '@mui/icons-material/Archive'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { downloadShareableInventoryCsv } from 'core/api/inventory/downloadShareableInventoryCSV'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import { useAdminListBuilder } from 'hooks/useAdminListBuilder'
import { useInventory } from 'hooks/inventory/useInventory'
import { useUsers } from 'hooks/useUsers'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InventoryList } from '@otw/models/inventory'
import { Vehicle } from '@otw/models/inventory'
import { PrivateUserInfoType } from '@otw/models'
import SoldDialog from '../Dialogs/SoldDialog'
import VehicleSaleSelectionDialog from '../Dialogs/VehicleSaleSelectionDialog'

interface AddToListDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (listId: string | 'new', listName?: string, description?: string, associatedUserIds?: string[]) => void
  lists: InventoryList[]
  loading: boolean
  users: PrivateUserInfoType[]
}

const AddToListDialog: React.FC<AddToListDialogProps> = ({ open, onClose, onSubmit, lists, loading, users }) => {
  const [selectedList, setSelectedList] = useState<string>('new')
  const [newListName, setNewListName] = useState('')
  const [newListDescription, setNewListDescription] = useState('')
  const [selectedUsers, setSelectedUsers] = useState<PrivateUserInfoType[]>([])

  useEffect(() => {
    if (open) {
      setSelectedList('new')
      setNewListName('')
      setNewListDescription('')
      setSelectedUsers([])
    }
  }, [open])

  useEffect(() => {
    if (selectedList !== 'new' && lists) {
      const currentList = lists.find(list => list.id === selectedList)
      const userIds = currentList?.associatedUserIds || []
      const associatedUsers = users.filter(user => userIds.includes(user.id))
      setSelectedUsers(associatedUsers)
    }
  }, [selectedList, lists, users])

  const handleSubmit = () => {
    if (selectedList === 'new' && !newListName) return
    onSubmit(
      selectedList,
      newListName,
      newListDescription,
      selectedUsers.map(user => user.id),
    )
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Add to List</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Select List</InputLabel>
            <Select value={selectedList} onChange={e => setSelectedList(e.target.value)} label='Select List'>
              <MenuItem value='new'>Create New List</MenuItem>
              {lists.map(list => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedList === 'new' && (
            <>
              <TextField
                label='List Name'
                value={newListName}
                onChange={e => setNewListName(e.target.value)}
                fullWidth
                required
              />
              <TextField
                label='Description'
                value={newListDescription}
                onChange={e => setNewListDescription(e.target.value)}
                fullWidth
                multiline
                rows={3}
              />
            </>
          )}

          <Autocomplete
            multiple
            options={users}
            value={selectedUsers}
            onChange={(_, newValue) => setSelectedUsers(newValue)}
            getOptionLabel={option =>
              `${option.email || ''}${option.company_name ? ` (${option.company_name})` : ''}`
            }
            renderInput={params => <TextField {...params} label='Share with Users' placeholder='Search users...' />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip label={option.email} {...getTagProps({ index })} key={option.id} />)
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Stack>
                  <Typography>{option.email || 'No email'}</Typography>
                  {option.company_name && (
                    <Typography variant='caption' color='text.secondary'>
                      {option.company_name}
                    </Typography>
                  )}
                </Stack>
              </li>
            )}
            filterOptions={(options, { inputValue }) => {
              const searchTerms = inputValue.toLowerCase().split(' ')
              return options.filter(option =>
                searchTerms.every(
                  term =>
                    (option.email?.toLowerCase() || '').includes(term) ||
                    (option.company_name?.toLowerCase() || '').includes(term) ||
                    (option.first_name?.toLowerCase() || '').includes(term) ||
                    (option.last_name?.toLowerCase() || '').includes(term),
                ),
              )
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={(selectedList === 'new' && !newListName) || loading}
          variant='contained'
        >
          {loading ? 'Adding...' : 'Add to List'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const LoadingDialog = ({ open, message }: { open: boolean; message: string }) => (
  <Dialog open={open} PaperProps={{ sx: { backgroundColor: 'background.paper', padding: 2 } }}>
    <DialogContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <CircularProgress size={24} />
        <Typography>{message}</Typography>
      </Box>
    </DialogContent>
  </Dialog>
)

const DeleteConfirmationDialog = ({
  open,
  vehicles,
  onConfirm,
  onCancel,
}: {
  open: boolean
  vehicles: Vehicle[]
  onConfirm: () => void
  onCancel: () => void
}) => (
  <Dialog open={open} onClose={onCancel} maxWidth='sm' fullWidth>
    <DialogTitle sx={{ color: 'error.main' }}>Confirm Delete</DialogTitle>
    <DialogContent>
      <Typography gutterBottom>Are you sure you want to delete the following vehicles?</Typography>
      <List>
        {vehicles.map(vehicle => (
          <ListItem key={vehicle.id} divider>
            <ListItemText
              primary={`${vehicle.stock_number} - ${vehicle.make} ${vehicle.model}`}
              secondary={`Added: ${vehicle.date_added ? new Date(vehicle.date_added).toLocaleDateString() : 'N/A'}`}
            />
          </ListItem>
        ))}
      </List>
      <Typography color='error' sx={{ mt: 2 }}>
        This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm} color='error' variant='contained'>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

const ActionsPanel = React.memo(
  ({ selectedRows, clearAllFilters }: { selectedRows: Vehicle[]; clearAllFilters: () => void }) => {
    const { userInfo } = useAuth()
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('')
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const theme = useTheme()
    const location = useLocation()
    const inventoryType = location.pathname.includes('/shield-inventory-v2')
      ? 'shield_inventory'
      : 'master_inventory'
    const { deleteDocument, updateDocument } = useInventory(inventoryType, undefined, {
      dontShowUpdates: true,
    })
    const { showSnackbar } = useSnackbar()
    const [showAddToList, setShowAddToList] = useState(false)
    const {
      lists,
      loading: listsLoading,
      createList,
      addVehiclesToList,
      updateListAssociations,
    } = useAdminListBuilder()
    const { users } = useUsers()

    // Add states for the dialogs
    const [vehicleSelectionOpen, setVehicleSelectionOpen] = useState(false)
    const [soldPopupOpen, setSoldPopupOpen] = useState(false)
    const [selectedVehiclesToSell, setSelectedVehiclesToSell] = useState<Vehicle[]>([])
    const [remainingVehicles, setRemainingVehicles] = useState<Vehicle[]>([])
    
    // Add state for pending updates
    const [pendingUpdates, setPendingUpdates] = useState<Array<{id: string, data: any}>>([])

    const handleDownloadCsv = useCallback(async () => {
      try {
        setLoadingMessage('Downloading CSV...')
        setLoading(true)
        const selectedIds = selectedRows.map(row => row.id)
        if (!userInfo?.role) {
          showSnackbar('Unknown error occurred..please contact admin@optictruckworks.com', 'error')
          return
        }
        await downloadShareableInventoryCsv(inventoryType, selectedIds, userInfo?.role)
      } finally {
        setLoading(false)
      }
    }, [selectedRows, userInfo?.role, inventoryType, showSnackbar])

    const handleDownloadPdf = useCallback(async () => {
      try {
        setLoadingMessage('Downloading PDF...')
        setLoading(true)
        const selectedIds = selectedRows.map(row => row.id)
        await downloadShareableInventoryPdf(inventoryType, selectedIds)
      } finally {
        setLoading(false)
      }
    }, [selectedRows, inventoryType])

    const handleDelete = useCallback(async () => {
      try {
        setLoadingMessage('Deleting...')
        setLoading(true)
        await Promise.all(selectedRows.map(row => deleteDocument(row.id)))
      } finally {
        setLoading(false)
        setShowDeleteConfirm(false)
      }
    }, [selectedRows, deleteDocument])

    const buttonStyles = {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
      },
      '&:disabled': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(69, 85, 115, 0.3)' : 'rgba(69, 85, 115, 0.12)',
        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(69, 85, 115, 0.26)',
      },
    }

    const deleteButtonStyles = {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.main : theme.palette.error.dark,
      },
      '&:disabled': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(244, 67, 54, 0.3)' : 'rgba(244, 67, 54, 0.12)',
        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(244, 67, 54, 0.26)',
      },
    }

    // Start the process
    const handleMarkSold = useCallback(() => {
      // Initialize with all selected vehicles
      setRemainingVehicles([...selectedRows])
      setVehicleSelectionOpen(true)
      setSoldPopupOpen(false)
      // Clear any pending updates
      setPendingUpdates([])
    }, [selectedRows])

    // Handle vehicle selection confirmation
    const handleVehicleSelectionConfirm = useCallback((selectedVehicles: Vehicle[]) => {
      // Only proceed if vehicles were selected
      if (selectedVehicles.length > 0) {
        setSelectedVehiclesToSell(selectedVehicles)
        setSoldPopupOpen(true)
      } else {
        // No vehicles selected, close the dialog
        setVehicleSelectionOpen(false)
      }
    }, [])

    // Add new handler for sold dialog submit
    const handleSoldSubmit = useCallback(
      async (soldToUser: { id: string; name: string }, soldByUser: { id: string; name: string }) => {
        try {
          // Don't set loading state yet - we're just queueing updates
          
          const currentDate = new Date().toISOString()
          
          // Ensure sold_to_user and sold_by_user are valid objects
          if (!soldToUser?.id || !soldToUser?.name) {
            showSnackbar('Error: Invalid sold to user data', 'error')
            console.error('Invalid soldToUser data:', soldToUser)
            return
          }
          
          if (!soldByUser?.id || !soldByUser?.name) {
            showSnackbar('Error: Invalid sold by user data', 'error')
            console.error('Invalid soldByUser data:', soldByUser)
            return
          }
          
          // Create the update data object explicitly
          const updateData = {
            status: 'Sold',
            sold_to_user: {
              id: soldToUser.id,
              name: soldToUser.name,
            },
            sold_by_user: {
              id: soldByUser.id,
              name: soldByUser.name,
            },
            sold_date: currentDate,
            show_on_website: false,
          }
          
          // Add updates to the pending list instead of executing them immediately
          const updates = selectedVehiclesToSell.map(row => ({
            id: row.id,
            data: updateData,
          }))
          
          setPendingUpdates(prev => [...prev, ...updates])
          
          // Update remaining vehicles by removing the ones we just processed
          const selectedIds = new Set(selectedVehiclesToSell.map(v => v.id))
          const updatedRemainingVehicles = remainingVehicles.filter(v => !selectedIds.has(v.id))
          
          // Reset selected vehicles for the next batch
          setSelectedVehiclesToSell([])
          
          // If there are no more vehicles to process, execute all pending updates
          if (updatedRemainingVehicles.length === 0) {
            // Show loading while we process all the updates
            setLoadingMessage('Marking vehicles as sold...')
            setLoading(true)
            
            try {
              // Execute all pending updates at once
              await Promise.all(
                pendingUpdates.concat(updates).map(update => 
                  updateDocument({
                    id: update.id,
                    data: update.data,
                  })
                )
              )
              
              showSnackbar(`Successfully marked all vehicles as sold`, 'success')
              
              // Close all dialogs
              setVehicleSelectionOpen(false)
              setSoldPopupOpen(false)
              
              // Clear pending updates
              setPendingUpdates([])
            } catch (error) {
              showSnackbar('Failed to mark vehicles as sold', 'error')
              console.error('Error marking vehicles as sold:', error)
            } finally {
              setLoading(false)
            }
          } else {
            // Go back to selection step with remaining vehicles
            setRemainingVehicles(updatedRemainingVehicles)
            setSoldPopupOpen(false)
          }
        } catch (error) {
          showSnackbar('Failed to prepare updates', 'error')
          console.error('Error preparing updates:', error)
        }
      },
      [selectedVehiclesToSell, remainingVehicles, pendingUpdates, updateDocument, showSnackbar]
    )

    console.log('remainingVehicles', remainingVehicles)

    const handleAddToList = useCallback(
      async (listId: string | 'new', listName?: string, description?: string, associatedUserIds?: string[]) => {
        try {
          setLoadingMessage('Adding to list...')
          setLoading(true)

          const selectedIds = selectedRows.map(row => row.id)

          if (listId === 'new' && listName) {
            await createList({
              name: listName,
              description: description || '',
              initialInventoryIds: selectedIds,
              associatedUserIds: associatedUserIds || [],
            })
          } else if (listId !== 'new') {
            // Add to existing list
            await addVehiclesToList({
              listId,
              vehicleIds: selectedIds,
            })
            // Update user associations if provided
            if (associatedUserIds) {
              await updateListAssociations({
                listId,
                userIds: associatedUserIds,
              })
            }
          }

          showSnackbar('Vehicles added to list successfully', 'success')
          setShowAddToList(false)
        } catch (error) {
          console.error('Error adding to list:', error)
          showSnackbar('Failed to add vehicles to list', 'error')
        } finally {
          setLoading(false)
        }
      },
      [selectedRows, createList, addVehiclesToList, updateListAssociations, showSnackbar],
    )

    return (
      <>
        <LoadingDialog open={loading} message={loadingMessage} />
        <DeleteConfirmationDialog
          open={showDeleteConfirm}
          vehicles={selectedRows}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirm(false)}
        />
        <AddToListDialog
          open={showAddToList}
          onClose={() => setShowAddToList(false)}
          onSubmit={handleAddToList}
          lists={lists || []}
          loading={loading || listsLoading}
          users={users}
        />
        {/* Vehicle Selection Dialog - only shown when not on the sold step */}
        {vehicleSelectionOpen && !soldPopupOpen && (
          <VehicleSaleSelectionDialog
            open={true}
            onClose={() => {
              setVehicleSelectionOpen(false)
              setRemainingVehicles([]) // Reset if closed manually
            }}
            vehicles={remainingVehicles}
            onConfirm={handleVehicleSelectionConfirm}
          />
        )}

        {/* SoldDialog - only shown when on the sold step */}
        {soldPopupOpen && (
          <SoldDialog
            open={true}
            onClose={() => {
              setSoldPopupOpen(false)
              // Don't close the selection dialog, just go back to it
              setSelectedVehiclesToSell([])
            }}
            onSubmit={handleSoldSubmit}
            itemName={
              selectedVehiclesToSell.length === 1
                ? selectedVehiclesToSell[0].stock_number
                : `${selectedVehiclesToSell.length} vehicles`
            }
          />
        )}
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography variant='h6' color='text.primary'>
            Bulk Actions
          </Typography>
          <Divider />
          <Button
            startIcon={<DeleteIcon />}
            variant='contained'
            onClick={() => setShowDeleteConfirm(true)}
            disabled={!selectedRows.length || loading}
            sx={deleteButtonStyles}
          >
            Delete Selected
          </Button>
          <Button
            startIcon={<FileDownloadIcon />}
            variant='contained'
            onClick={handleDownloadCsv}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Download CSV
          </Button>
          <Button
            startIcon={<PictureAsPdfIcon />}
            variant='contained'
            onClick={handleDownloadPdf}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Download PDF
          </Button>
          <Button
            startIcon={<ArchiveIcon />}
            variant='contained'
            onClick={handleMarkSold}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Mark Sold
          </Button>
          <Button startIcon={<FilterAltOffIcon />} variant='text' onClick={clearAllFilters} sx={buttonStyles}>
            Clear All Filters
          </Button>
          <Button
            startIcon={<PlaylistAddIcon />}
            variant='contained'
            onClick={() => setShowAddToList(true)}
            disabled={!selectedRows.length || loading}
            sx={buttonStyles}
          >
            Add to List
          </Button>
        </Stack>
      </>
    )
  },
)

ActionsPanel.displayName = 'ActionsPanel'

export { ActionsPanel }
