import { GridApi, ColumnPinnedType, RowSelectionOptions, FirstDataRenderedEvent, GridReadyEvent, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnValueChangedEvent, ColumnVisibleEvent, ColumnRowGroupChangedEvent, ColumnEverythingChangedEvent, ColumnPivotChangedEvent } from 'ag-grid-enterprise'
import { MenuModule, ColumnsToolPanelModule, FiltersToolPanelModule, StatusBarModule, SideBarModule, RowGroupingModule, SetFilterModule, MultiFilterModule, RangeSelectionModule, RichSelectModule, GridChartsModule, ExcelExportModule, ClipboardModule, ServerSideRowModelModule, MasterDetailModule, SparklinesModule } from 'ag-grid-enterprise'
import { formatCurrency } from './getInventoryColumns'
import SoldPageStatusBar from './SoldPageStatusBar'
import CustomHeaderComponent from './CustomHeaderComponent'

export const getGridConfig = ({
  columnDefs,
  rows,
  isLoading,
  defaultColDef,
  masterDetail,
  onCellClicked,
  handleCellValueChanged,
  sideBar,
  rowHeight,
  fontSize,
//   quickFilterText,
  onGridReady,
  onSelectionChanged,
  setActiveToolPanel,
  onFirstDataRendered,
// events
  onColumnMoved,
  onColumnVisible,
  onColumnResized,
  onColumnPinned,
  onColumnValueChanged,
  onColumnPivotChanged,
  onColumnRowGroupChanged,
  isSellPage,
  onToggleSoldPage,
  handleCellValueChange,
  gridContext,
}: {
  columnDefs: any[]
  rows: any[]
  isLoading: boolean
  defaultColDef: any
  masterDetail: any
  onCellClicked: (params: any) => void
  handleCellValueChanged: (params: any) => void
  sideBar: any
  rowHeight: number
  fontSize: string
  onGridReady: (params: GridReadyEvent) => void
  onSelectionChanged: () => void
  setActiveToolPanel: (panel: string) => void
  onFirstDataRendered: (params: FirstDataRenderedEvent) => void
  onColumnMoved: (params: ColumnMovedEvent) => void
  onColumnVisible: (params: ColumnVisibleEvent) => void
  onColumnResized: (params: ColumnResizedEvent) => void
  onColumnPinned: (params: ColumnPinnedEvent) => void
  onColumnValueChanged: (params: ColumnValueChangedEvent) => void
  onColumnPivotChanged: (params: ColumnPivotChangedEvent) => void
  onColumnRowGroupChanged: (params: ColumnRowGroupChangedEvent) => void
  isSellPage: boolean;
  onToggleSoldPage: (checked: boolean) => void;
  handleCellValueChange?: (vehicleId: string, field: string, value: any) => Promise<boolean>;
  gridContext?: any;
}) => ({
  columnDefs,
  rowData: isLoading ? [] : rows,
  defaultColDef,
  ...masterDetail,
  onCellClicked,
  onCellValueChanged: handleCellValueChanged,
  sideBar,
//   autoSizeStrategy: {
//     type: 'fitCellContents' as const,
//     skipHeader: false,
//   },

  // Row configuration
  suppressColumnVirtualisation: true,
  suppressAnimationFrame: false,
  domLayout: 'normal' as const,
  suppressRowVirtualisation: false,

  // Sizing
  rowHeight,
  getRowHeight: () => rowHeight,
  rowStyle: { 
    fontSize,
    lineHeight: `${rowHeight}px`
  },
  headerHeight: rowHeight,
  floatingFiltersHeight: rowHeight,

  // Features
  enableCharts: true,
  enableCellTextSelection: true,
  enableAggregationFunctions: true,
  components: {
    agColumnHeader: CustomHeaderComponent,
  },

  // Selection
//docs are a little confusing here or maybe im stupid
  rowSelection: {
    mode: 'multiRow',
    selectAll: 'filtered',
    headerCheckbox: true,
    enableClickSelection: false,
    enableSelectionWithoutKeys: true,
  } as RowSelectionOptions,
  selectionColumnDef: {
    pinned: 'left' as ColumnPinnedType,
  },

//   cellSelection: {
//     handle: {
//         mode: 'fill',
//         direction: 'x'
//     }
//   },
  // Clipboard
  clipboardDelimiter: ',',
  copyHeadersToClipboard: true,
  processCellForClipboard: (params: any) => {
    if (params.column.colId === 'profit') {
      return params.value ? formatCurrency(params.value) : ''
    }
    return params.value
  },
  processHeaderForClipboard: (params: any) => params.column.getColDef().headerName,

  // Column behavior
  columnHoverHighlight: true,
  suppressMovableColumns: false,
  allowDragFromColumnsToolPanel: true,
  
  // Filtering
  floatingFilter: true,
  suppressFilterSearch: false,
  multiSortKey: 'ctrl' as const,
  accentedSort: true,
  unSortIcon: true,
  // Grouping
  groupDisplayType: 'groupRows' as const,
  groupTotalRow: true,
  rowGroupPanelShow: 'always' as const,

  // Navigation
  enterNavigatesVertically: true,
  enterNavigatesVerticallyAfterEdit: true,
  // noRowsOverlayComponent: SoldPageStatusBar,

  // Editing
  stopEditingWhenCellsLoseFocus: true,
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 20,

  // Status bar
  statusBar: {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
      { statusPanel: 'agSelectedRowCountComponent', align: 'right' },
      { statusPanel: 'agAggregationComponent', align: 'right' },
      { 
        statusPanel: 'agFilteredRowCountComponent',
        key: 'filteredRowCount',
        align: 'right'
      },
      { 
        statusPanel: SoldPageStatusBar,
        align: 'left',
        statusPanelParams: {
          isSellPage,
          onToggleSoldPage,
        }
      }
    ]
  },

  // Excel export styles
  excelStyles: [
    {
      id: 'header',
      interior: {
        color: '#f8f9fa',
        pattern: 'Solid' as const,
      },
      font: {
        bold: true
      }
    },
    {
      id: 'profit-positive',
      interior: {
        color: '#e6ffe6',
        pattern: 'Solid' as const,
      }
    },
    {
      id: 'profit-negative',
      interior: {
        color: '#ffe6e6',
        pattern: 'Solid' as const,
      }
    }
  ],

  // Event handlers
  onGridReady,
  onSelectionChanged,
  onToolPanelVisibleChanged: (event: any) => {
    if (event.source === 'toolPanelUi') {
      setActiveToolPanel(event.toolPanel.id)
    }
  },
  onFirstDataRendered,
  onColumnMoved,
  onColumnVisible,
  onColumnResized,
  onColumnPinned,
  onColumnValueChanged,
  onColumnPivotChanged,
  onColumnRowGroupChanged,

  // Modules
  modules: [
    MenuModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    StatusBarModule,
    SideBarModule,
    RowGroupingModule,
    SetFilterModule,
    MultiFilterModule,
    RangeSelectionModule,
    RichSelectModule,
    GridChartsModule,
    ExcelExportModule,
    ClipboardModule,
    ServerSideRowModelModule,
    MasterDetailModule,
    SparklinesModule
  ],

  // Context for cell editors
  context: gridContext || {
    handleCellValueChange,
  },
})
