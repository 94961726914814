// web/app/src/views/SuperAdmin/Management/LeadManagement/components/LeadCard.tsx
import { Draggable } from '@hello-pangea/dnd';
import { Delete, Edit, Email, Phone } from '@mui/icons-material';
import { Box, Card, CardContent, Chip, IconButton, Typography, Tooltip, Stack, Button, CardActions, Divider } from '@mui/material';
import { Lead, LeadStatus } from '@otw/models/leads/lead';
import { memo, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface LeadCardProps {
  lead: Lead;
  index: number;
  status: LeadStatus;
  onLeadSelect: (lead: Lead) => void;
  onViewDetails: (lead: Lead) => void;
  onViewSuggestedTrucks: (lead: Lead) => void;
  onDelete?: (leadId: string) => Promise<void>;
  onUpdate?: (leadId: string, updates: Partial<Lead>) => Promise<void>;
  columnName?: string;
}

// Status color definitions with semantic meaning
const STATUS_COLORS = {
  new: '#2196f3',         // Blue - New/fresh leads
  inProgress: '#ff9800',  // Orange - Active work/in progress
  waitingFinance: '#ffc107', // Yellow/Amber - Waiting/on hold
  closed: '#4caf50',      // Green - Success/completed
  lost: '#f44336'         // Red - Lost/negative outcome
};

// MUI color names that match our semantic colors
const getStatusColor = (status: LeadStatus) => {
  const colors = {
    new: 'info',          // Blue
    inProgress: 'warning', // Orange
    waitingFinance: 'warning', // Yellow/Amber (using warning as closest match)
    closed: 'success',     // Green
    lost: 'error'          // Red
  };
  return colors[status];
};

const getStatusLabel = (status: LeadStatus | string) => {
  const labels: Record<string, string> = {
    new: 'New',
    inProgress: 'In Progress',
    waitingFinance: 'Waiting Finance',
    closed: 'Closed',
    lost: 'Lost'
  };
  
  // Return the predefined label if it exists, otherwise capitalize the first letter
  return labels[status] || status.charAt(0).toUpperCase() + status.slice(1);
};

// Get border color based on status
const getBorderColor = (status: LeadStatus | string): string => {
  const colors: Record<string, string> = STATUS_COLORS;
  
  // Return the predefined color if it exists, otherwise use a default color
  return colors[status] || '#9c27b0'; // Default to purple for custom columns
};

const LeadCard = memo(({
  lead,
  index,
  status,
  onLeadSelect,
  onViewDetails,
  onViewSuggestedTrucks,
  onDelete,
  onUpdate,
  columnName
}: LeadCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle card click to view details
  const handleCardClick = () => {
    onViewDetails(lead);
  };

  return (
    <Draggable draggableId={lead.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: snapshot.isDragging ? 'rgba(255, 165, 0, 0.1)' : undefined,
            borderColor: snapshot.isDragging ? 'orange' : undefined,
            boxShadow: snapshot.isDragging ? '0 0 10px rgba(255, 165, 0, 0.3)' : undefined,
          }}
        >
          <Card
            sx={{
              mb: 1,
              '&:hover': {
                boxShadow: 3,
                cursor: 'pointer'
              },
              transition: 'all 0.2s ease-in-out',
              borderLeft: `4px solid ${getBorderColor(status)}`,
              position: 'relative'
            }}
            onClick={handleCardClick}
          >
            <Chip
              label={columnName || getStatusLabel(status)}
              size="small"
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                backgroundColor: getBorderColor(status),
                color: '#fff',
                fontSize: isMobile ? '0.7rem' : '0.8rem',
                fontWeight: 600,
                height: isMobile ? '18px' : '20px',
                '& .MuiChip-label': {
                  px: isMobile ? 0.75 : 1,
                  py: 0
                }
              }}
            />

            <CardContent sx={{ p: isMobile ? 1 : 1.5, '&:last-child': { pb: isMobile ? 1 : 1.5 } }}>
              <Stack spacing={isMobile ? 0.5 : 1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box sx={{ maxWidth: '100%' }}>
                    <Typography 
                      variant={isMobile ? "body1" : "subtitle1"} 
                      component="div" 
                      sx={{ fontWeight: 'medium', lineHeight: 1.2 }} 
                      noWrap
                    >
                      {lead.customerName}
                    </Typography>
                    {lead.company && (
                      <Typography 
                        variant={isMobile ? "caption" : "body2"} 
                        color="text.secondary" 
                        sx={{ lineHeight: 1.2 }} 
                        noWrap
                      >
                        {lead.company}
                      </Typography>
                    )}
                  </Box>
                </Box>
                
                <Stack direction="row" spacing={isMobile ? 0.5 : 1} sx={{ flexWrap: 'wrap' }}>
                  {lead.phone && (
                    <Box display="flex" alignItems="center" sx={{ mr: isMobile ? 0.5 : 1, maxWidth: '100%' }}>
                      <Phone fontSize="small" color="action" sx={{ mr: 0.5, fontSize: isMobile ? '0.8rem' : '0.9rem' }} />
                      <Typography variant={isMobile ? "caption" : "body2"} color="text.secondary" noWrap>
                        {lead.phone}
                      </Typography>
                    </Box>
                  )}
                  {lead.email && (
                    <Box display="flex" alignItems="center" sx={{ maxWidth: '100%' }}>
                      <Email fontSize="small" color="action" sx={{ mr: 0.5, fontSize: isMobile ? '0.8rem' : '0.9rem' }} />
                      <Typography variant={isMobile ? "caption" : "body2"} color="text.secondary" noWrap>
                        {lead.email}
                      </Typography>
                    </Box>
                  )}
                </Stack>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    {lead.truckIds && lead.truckIds.length > 0 ? (
                      <Chip 
                        size="small" 
                        label={`${lead.truckIds.length} vehicle${lead.truckIds.length > 1 ? 's' : ''}`} 
                        variant="outlined" 
                        color="primary"
                        sx={{ height: isMobile ? 18 : 22, fontSize: isMobile ? '0.65rem' : 'inherit' }}
                      />
                    ) : null}
                  </Box>
                  
                  <Box display="flex">
                    <Tooltip title="Edit Lead">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onLeadSelect(lead);
                        }}
                        sx={{ p: isMobile ? 0.3 : 0.5 }}
                      >
                        <Edit fontSize={isMobile ? "small" : "medium"} sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }} />
                      </IconButton>
                    </Tooltip>
                    {onDelete && (
                      <Tooltip title="Delete Lead">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete(lead.id);
                          }}
                          sx={{ p: isMobile ? 0.3 : 0.5 }}
                        >
                          <Delete fontSize={isMobile ? "small" : "medium"} sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>

                <Typography 
                  variant="caption" 
                  color="text.secondary" 
                  sx={{ 
                    display: 'block', 
                    mt: isMobile ? 0.5 : 1,
                    fontSize: isMobile ? '0.65rem' : '0.75rem'
                  }}
                >
                  {lead.updatedAt
                    ? `Updated ${formatDistanceToNow(new Date(lead.updatedAt), { addSuffix: true })}`
                    : ''}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </div>
      )}
    </Draggable>
  );
});

LeadCard.displayName = 'LeadCard';

export default LeadCard;