import { Box, Button, TextField, Tooltip, Typography, Grid } from '@mui/material'
import { useSessionStorageState } from '@toolpad/core'
import { Formula } from '@otw/models'
import React from 'react'
import { calculatePrice } from '../../utils/calculators'
import NumberInput from 'components/NumberInput'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useFormulaRules } from 'hooks/graybook/useFormulaRules'
import { useIsMobile } from 'hooks/screen/useIsMobile'

interface CalculatorProps {
  formula: Formula
}

type CalculatorState = {
  mileage: number
  year: number
  calculatedPrice: number | null
  yearDepreciation: number
  mileageDepreciation: number
}

const calculatorSchema = z.object({
  year: z
    .number()
    .min(1990, 'Year must be at least 1990')
    .max(new Date().getFullYear(), 'Year cannot be in the future'),
  mileage: z.number().min(0, 'Mileage must be a positive number'),
})

type CalculatorFormData = z.infer<typeof calculatorSchema>

const Calculator: React.FC<CalculatorProps> = ({ formula }) => {
  const currentYear = new Date().getFullYear()
  const [calculatedPrice, setCalculatedPrice] = React.useState<number | null>(null)
  const [yearDepreciation, setYearDepreciation] = React.useState(0)
  const [mileageDepreciation, setMileageDepreciation] = React.useState(0)
  const isMobile = useIsMobile()

  const [calculatorState, setCalculatorState] = useSessionStorageState<CalculatorState>(
    'calculator_state',
    {
      mileage: 0,
      year: currentYear,
      calculatedPrice: null,
      yearDepreciation: 0,
      mileageDepreciation: 0,
    },
    {
      codec: {
        stringify: JSON.stringify,
        parse: (storedValue: string) => {
          try {
            return JSON.parse(storedValue)
          } catch (error) {
            return null
          }
        },
      },
    },
  )

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CalculatorFormData>({
    resolver: zodResolver(calculatorSchema),
    defaultValues: {
      year: currentYear,
      mileage: 0,
    },
  })

  // Set initial values from session storage
  React.useEffect(() => {
    console.log('setting new state')
    if (calculatorState) {
      setValue('year', calculatorState.year)
      setValue('mileage', calculatorState.mileage)
      setCalculatedPrice(calculatorState.calculatedPrice)
      setYearDepreciation(calculatorState.yearDepreciation)
      setMileageDepreciation(calculatorState.mileageDepreciation)
    }
  }, [])

  const { rules } = useFormulaRules({
    rulesCollection: 'gray_book_formulas',
    subcollection: {
      name: 'rules',
      parentDocId: formula.id,
    },
  })

  const onSubmit = (data: CalculatorFormData) => {
    if (!formula.maxPrice) return

    const yearRules = rules?.filter(rule => rule.targetFactor === 'year') || []
    const mileageRules = rules?.filter(rule => rule.targetFactor === 'mileage') || []

    const {
      price: newPrice,
      breakdown: { yearDepreciation, mileageDepreciation },
    } = calculatePrice({
      basePrice: formula.maxPrice,
      year: data.year,
      mileage: data.mileage,
      yearRules,
      mileageRules,
      minPrice: formula.minPrice ?? 0,
    })

    setCalculatedPrice(newPrice)
    setYearDepreciation(yearDepreciation)
    setMileageDepreciation(mileageDepreciation)
    setCalculatorState({
      mileage: data.mileage,
      year: data.year,
      calculatedPrice: newPrice,
      yearDepreciation,
      mileageDepreciation,
    })
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={isMobile ? 6 : 12}>
          <Controller
            name='year'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label='Year'
                type='number'
                value={field.value || ''}
                onChange={e => field.onChange(e.target.value === '' ? currentYear : Number(e.target.value))}
                helperText={errors.year?.message || (isMobile ? '' : `Enter a year between 1990 and ${currentYear}`)}
                error={!!errors.year}
                size={isMobile ? 'small' : 'medium'}
              />
            )}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 12}>
          <Controller
            name='mileage'
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                fullWidth
                label='Mileage'
                value={field.value || null}
                onChange={value => field.onChange(value ?? 0)}
                error={!!errors.mileage}
                helperText={errors.mileage?.message}
                size={isMobile ? 'small' : 'medium'}
              />
            )}
          />
        </Grid>
      </Grid>

      <Button variant='contained' onClick={handleSubmit(onSubmit)} fullWidth sx={{ mb: 2 }}>
        Calculate
      </Button>

      {calculatedPrice !== null && (
        <Box sx={{ mt: 3, p: isMobile ? 2 : 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
          <Typography variant='h5' gutterBottom color='primary' align='center' sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
            Calculated Prices
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 3, flexWrap: 'wrap', gap: isMobile ? 2 : 4 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant='subtitle1' color='text.secondary'>
                Retail Price
              </Typography>
              <Typography variant='h3' sx={{ color: 'primary.dark', fontSize: isMobile ? '1.8rem' : '3rem' }}>
                ${calculatedPrice.toLocaleString()}
              </Typography>
            </Box>

            {formula.wholesaleDiscount !== undefined && (
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant='subtitle1' color='text.secondary'>
                  Wholesale Price {formula.wholesaleDiscount > 0 && `(${formula.wholesaleDiscount}% off)`}
                </Typography>
                <Typography variant='h3' sx={{ color: '#d3af37', fontSize: isMobile ? '1.8rem' : '3rem' }}>
                  ${(calculatedPrice * (1 - formula.wholesaleDiscount / 100)).toLocaleString()}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ bgcolor: 'grey.50', p: isMobile ? 1.5 : 2, borderRadius: 1 }}>
            <Typography variant='subtitle1' sx={{ mb: 2, color: 'primary' }}>
              Price Breakdown
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='body1' color='text.secondary'>
                  Original Price:
                </Typography>
                <Typography variant='body1' component='span'>
                  ${formula.maxPrice.toLocaleString()}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='body1' color='text.secondary'>
                  Year Depreciation:
                </Typography>
                <Box>
                  <Typography variant='body1' component='span' color='error.main'>
                    -${yearDepreciation.toLocaleString()}
                  </Typography>
                  <Typography variant='body2' component='span' color='text.secondary' sx={{ ml: 1 }}>
                    ({((yearDepreciation / formula.maxPrice) * 100).toFixed(1)}%)
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='body1' color='text.secondary'>
                  Mileage Depreciation:
                </Typography>
                <Box>
                  <Typography variant='body1' component='span' color='error.main'>
                    -${mileageDepreciation.toLocaleString()}
                  </Typography>
                  <Typography variant='body2' component='span' color='text.secondary' sx={{ ml: 1 }}>
                    ({((mileageDepreciation / formula.maxPrice) * 100).toFixed(1)}%)
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pt: 2,
                  pb: 1,
                  mt: 1,
                  borderTop: '2px solid',
                  borderColor: 'primary.main',
                  px: isMobile ? 1 : 2,
                }}
              >
                <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold', fontSize: isMobile ? '1rem' : '1.25rem' }}>
                  Calculated Price:
                </Typography>
                <Box>
                  <Typography variant='h6' component='span' color='primary.main' sx={{ fontWeight: 'bold', fontSize: isMobile ? '1rem' : '1.25rem' }}>
                    ${calculatedPrice.toLocaleString()}
                  </Typography>
                  {formula.minPrice && calculatedPrice === formula.minPrice && (
                    <Typography variant='caption' color='warning.main' sx={{ display: 'block', textAlign: 'right' }}>
                      (Minimum price applied)
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Calculator
