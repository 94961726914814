import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Avatar,
  useTheme
} from '@mui/material';
import { Vehicle } from '@otw/models';
import { formatPrice } from 'core/utils/inventoryUtils';
import dayjs from 'dayjs';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface RecentSalesProps {
  vehicles: Vehicle[];
  dateRange: {
    start: Date;
    end: Date;
  };
}

export const RecentSales: React.FC<RecentSalesProps> = ({ vehicles, dateRange }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  
  // Filter to only sold vehicles and sort by sold date (most recent first)
  const recentSales = vehicles
    .filter(vehicle => vehicle.status === 'Sold' && vehicle.sold_date)
    .sort((a, b) => {
      const dateA = a.sold_date ? new Date(a.sold_date).getTime() : 0;
      const dateB = b.sold_date ? new Date(b.sold_date).getTime() : 0;
      return dateB - dateA;
    })
    .slice(0, 5); // Only show the 5 most recent sales
  
  return (
    <Card>
      <CardHeader 
        title="Recent Sales" 
        subheader={`${dayjs(dateRange.start).format('MMM D, YYYY')} - ${dayjs(dateRange.end).format('MMM D, YYYY')}`}
      />
      <Divider />
      <CardContent sx={{ p: { xs: 0, md: 2 } }}>
        {recentSales.length > 0 ? (
          <TableContainer>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <TableCell>Vehicle</TableCell>
                  {!isMobile && <TableCell>Stock #</TableCell>}
                  <TableCell>Date Sold</TableCell>
                  <TableCell align="right">Sale Price</TableCell>
                  {!isMobile && <TableCell align="right">Profit</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {recentSales.map((vehicle) => (
                  <TableRow key={vehicle.id}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar 
                          sx={{ 
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            width: 32,
                            height: 32,
                            mr: 1
                          }}
                        >
                          <DirectionsCarIcon fontSize="small" />
                        </Avatar>
                        <Box>
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            {vehicle.year} {vehicle.make} {vehicle.model}
                          </Typography>
                          {isMobile && (
                            <Typography variant="caption" color="text.secondary">
                              Stock #{vehicle.stock_number}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        <Chip 
                          label={vehicle.stock_number} 
                          size="small" 
                          variant="outlined"
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      {vehicle.sold_date ? dayjs(vehicle.sold_date).format('MMM D, YYYY') : 'N/A'}
                    </TableCell>
                    <TableCell align="right">
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontWeight: 600, 
                          color: theme.palette.success.main 
                        }}
                      >
                        {formatPrice(vehicle.optic_list_price || 0)}
                      </Typography>
                    </TableCell>
                    {!isMobile && (
                      <TableCell align="right">
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            fontWeight: 600, 
                            color: vehicle.profit && vehicle.profit > 0 
                              ? theme.palette.success.main 
                              : theme.palette.error.main
                          }}
                        >
                          {formatPrice(vehicle.profit || 0)}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="body1" color="text.secondary">
              No sales data available for the selected period
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default RecentSales; 