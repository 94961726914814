import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc, addDoc, orderBy } from 'firebase/firestore'
import { db } from 'config/firebase'
import { Expense, ExpenseCategory } from '@otw/models'
import { expenseConverter } from '@otw/models/converters'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { useInventory } from './inventory'

interface UseExpensesProps {
  vin?: string // Optional VIN to filter expenses for a specific vehicle
  dateRange?: {
    start: Date
    end: Date
  }
  options?: {
    onSuccess?: () => void
    onError?: (error: Error) => void
    dontShowUpdates?: boolean
  }
}

export const useExpenses = ({ vin, dateRange, options }: UseExpensesProps = {}) => {
  const { userInfo } = useAuth()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const expensesCollection = collection(db, 'expenses').withConverter(expenseConverter)
  
  // Use the inventory hook to handle vehicle updates
  const { 
    vehicles, 
    updateDocument: updateVehicle 
  } = useInventory('master_inventory', undefined, {
    dontShowUpdates: true,
  })

  const queryKey = ['expenses', vin, dateRange?.start?.toISOString(), dateRange?.end?.toISOString()]

  const {
    data: expenses = [],
    isLoading,
    isError,
    error,
    refetch: refetchExpenses,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      // Get all expenses without filtering in the query
      const querySnapshot = await getDocs(expensesCollection)
      return querySnapshot.docs.map(doc => doc.data())
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  // Helper function to update vehicle costs when an expense changes
  const updateVehicleCosts = async (vehicleId: string, expenseCategory: ExpenseCategory, amountDifference: number) => {
    if (!vehicleId) return

    try {
      // Find the vehicle in the cached vehicles data
      const vehicle = vehicles?.find(v => v.id === vehicleId)
      
      if (!vehicle) {
        console.warn(`No vehicle found with ID: ${vehicleId}`)
        return
      }
      
      // Update the appropriate cost field based on expense category
      let updateField = {}
      
      switch (expenseCategory) {
        case 'Shipping':
          updateField = { shipping_cost: (vehicle.shipping_cost || 0) + amountDifference }
          break
        case 'Repairs':
          updateField = { repair_cost: (vehicle.repair_cost || 0) + amountDifference }
          break
        case 'RentalReimbursement':
          updateField = { rental_cost: (vehicle.rental_cost || 0) + amountDifference }
          break
        case 'Other':
          updateField = { other_cost: (vehicle.other_cost || 0) + amountDifference }
          break
      }
      
      // Use the inventory hook to update the vehicle
      await updateVehicle({
        id: vehicle.id,
        data: updateField
      })
    } catch (error) {
      console.error('Error updating vehicle costs:', error)
    }
  }

  // Add a new expense
  const addExpense = useMutation({
    mutationFn: async (expense: Omit<Expense, 'id' | 'created_by' | 'created_at'>) => {
      if (!userInfo) {
        throw new Error('User must be authenticated to add an expense')
      }

      const now = new Date()

      // Create a properly typed expense object
      const newExpense = {
        ...expense,
        created_by: userInfo.id || userInfo.email || '',
        created_at: now,
        // Ensure customCategory is properly typed as string | null
        customCategory: expense.customCategory === undefined ? null : expense.customCategory,
      } as Omit<Expense, 'id'>

      const docRef = await addDoc(expensesCollection, newExpense)
      
      // Update vehicle costs if this expense is linked to a vehicle
      if (expense.vehicleId) {
        await updateVehicleCosts(expense.vehicleId, expense.category, expense.amount)
      }

      return {
        ...newExpense,
        id: docRef.id,
      } as Expense
    },
    onMutate: async newExpense => {
      await queryClient.cancelQueries({ queryKey })
      const previousExpenses = queryClient.getQueryData<Expense[]>(queryKey)

      const optimisticExpense = {
        ...newExpense,
        id: `temp-${Date.now()}`,
        created_by: userInfo?.id || userInfo?.email || '',
        created_at: new Date(),
      } as Expense

      queryClient.setQueryData<Expense[]>(queryKey, old => {
        return [...(old || []), optimisticExpense]
      })

      return { previousExpenses, optimisticExpense }
    },
    onError: (err, newExpense, context) => {
      queryClient.setQueryData(queryKey, context?.previousExpenses)
      if (!options?.dontShowUpdates) {
        showSnackbar('Error adding expense', 'error')
      }
      console.error('Error adding expense:', err)
      options?.onError?.(err instanceof Error ? err : new Error(String(err)))
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData<Expense[]>(queryKey, old => {
        return old?.map(expense => (expense.id === context?.optimisticExpense.id ? result : expense))
      })
      if (!options?.dontShowUpdates) {
        showSnackbar('Expense added successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  // Update an existing expense
  const updateExpense = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Expense> }) => {
      if (!userInfo) {
        throw new Error('User must be authenticated to update an expense')
      }

      // Get the current expense to calculate cost differences
      const currentExpense = expenses.find(expense => expense.id === id)
      
      if (currentExpense) {
        // If the expense is linked to a vehicle, update vehicle costs
        if (currentExpense.vehicleId) {
          // If amount changed
          if (data.amount !== undefined && data.amount !== currentExpense.amount) {
            const amountDifference = data.amount - currentExpense.amount
            await updateVehicleCosts(
              currentExpense.vehicleId, 
              data.category || currentExpense.category, 
              amountDifference
            )
          }
          
          // If category changed with the same VIN
          if (data.category !== undefined && 
              data.category !== currentExpense.category && 
              (!data.vehicleId || data.vehicleId === currentExpense.vehicleId)) {
            // Remove amount from old category
            await updateVehicleCosts(currentExpense.vehicleId, currentExpense.category, -currentExpense.amount)
            // Add amount to new category
            await updateVehicleCosts(currentExpense.vehicleId, data.category, data.amount || currentExpense.amount)
          }
          
          // If VIN changed
          if (data.vehicleId !== undefined && data.vehicleId !== currentExpense.vehicleId) {
            // Remove amount from old vehicle
            await updateVehicleCosts(currentExpense.vehicleId, currentExpense.category, -currentExpense.amount)
            
            // Add amount to new vehicle if there is one
            if (data.vehicleId) {
              await updateVehicleCosts(
                data.vehicleId, 
                data.category || currentExpense.category, 
                data.amount || currentExpense.amount
              )
            }
          }
        } 
        // If expense wasn't linked to a vehicle before but now is
        else if (data.vehicleId) {
          await updateVehicleCosts(
            data.vehicleId, 
            data.category || currentExpense.category, 
            data.amount || currentExpense.amount
          )
        }
      }

      const expenseRef = doc(expensesCollection, id)
      const updateData = {
        ...data,
        updated_by: userInfo.id || userInfo.email || '',
        updated_at: new Date(),
      }

      await updateDoc(expenseRef, updateData)
      return { id, ...data }
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousExpenses = queryClient.getQueryData<Expense[]>(queryKey)

      queryClient.setQueryData<Expense[]>(queryKey, old => {
        if (!old) return old
        return old.map(expense =>
          expense.id === id
            ? {
                ...expense,
                ...data,
                updated_at: new Date(),
                updated_by: userInfo?.id || userInfo?.email || '',
              }
            : expense,
        )
      })

      return { previousExpenses }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousExpenses)
      if (!options?.dontShowUpdates) {
        showSnackbar('Error updating expense', 'error')
      }
      console.error('Error updating expense:', err)
      options?.onError?.(err instanceof Error ? err : new Error(String(err)))
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Expense updated successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  // Delete an expense
  const deleteExpense = useMutation({
    mutationFn: async (id: string) => {
      // Get the expense before deleting to update vehicle costs
      const expense = expenses.find(expense => expense.id === id)
      
      if (expense && expense.vehicleId) {
        // Subtract the expense amount from the vehicle
        await updateVehicleCosts(expense.vehicleId, expense.category, -expense.amount)
      }
      
      const expenseRef = doc(expensesCollection, id)
      await deleteDoc(expenseRef)
      return id
    },
    onMutate: async id => {
      await queryClient.cancelQueries({ queryKey })
      const previousExpenses = queryClient.getQueryData<Expense[]>(queryKey)

      queryClient.setQueryData<Expense[]>(queryKey, old => {
        if (!old) return old
        return old.filter(expense => expense.id !== id)
      })

      return { previousExpenses }
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(queryKey, context?.previousExpenses)
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting expense', 'error')
      }
      console.error('Error deleting expense:', err)
      options?.onError?.(err instanceof Error ? err : new Error(String(err)))
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Expense deleted successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  // Calculate total expenses by category
  const totalExpensesByCategory = expenses.reduce(
    (acc, expense) => {
      const category = expense.category
      acc[category] = (acc[category] || 0) + expense.amount
      return acc
    },
    {
      Shipping: 0,
      Repairs: 0,
      RentalReimbursement: 0,
      Other: 0,
    } as Record<ExpenseCategory, number>,
  )

  // Calculate total expenses
  const totalExpenses = Object.values(totalExpensesByCategory).reduce((sum, amount) => sum + amount, 0)

  return {
    expenses,
    isLoading,
    isError,
    error,
    refetchExpenses,
    addExpense: addExpense.mutateAsync,
    updateExpense: updateExpense.mutateAsync,
    deleteExpense: deleteExpense.mutateAsync,
    totalExpensesByCategory,
    totalExpenses,
  }
}
