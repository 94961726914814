import { FilterList } from '@mui/icons-material'
import ListIcon from '@mui/icons-material/List'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Fab,
  Grid,
  Skeleton,
  Stack,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material'
import { useLocalStorageState } from '@toolpad/core'
import { AgGridReact } from 'ag-grid-react'
import { UndoLastAction } from 'components/UndoLastAction'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import { handleInventoryCsvUpload } from 'core/api/inventory/handleInventoryCsvUpload'
import { removeFromWebsiteInventory } from 'core/api/inventory/removeFromWebsiteInventory'
import { addToWebsiteInventory } from 'core/api/inventory/showHideOnWebsiteInventory'
import { calculateProfit, sanitizeNumber } from 'core/utils/inventoryUtils'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useInventory } from 'hooks/inventory/useInventory'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AgGridFirestoreCRUD from './components/AgGridInventory/AgGridInventory'
import VehicleCard from './components/Cards/VehicleCard'
import ManagePhotosDialog from './components/Dialogs/ManagePhotosDialog'
import PhotoUploadDialog from './components/Dialogs/PhotoUploadDialog'
import SelectMainPhotoDialog from './components/Dialogs/SelectMainWebsitePhotoDialog'
import VehicleForm from './components/Forms/VehicleForm'
import { LoadingInventory } from './components/LoadingInventory'
import { Vehicle } from '@otw/models/inventory'
import { useInventoryFilter } from 'hooks/inventory'
import { getVehicleModelOptions, getVehicleSizeOptions } from 'core/constants/inventory'
import { DocumentData } from 'firebase/firestore'

const GRID_THEMES = [
  { name: 'Default', rowHeight: 32, fontSize: '14px' },
  // { name: 'Compact', rowHeight: 16, fontSize: '12px' },
  // { name: 'Large', rowHeight: 64, fontSize: '32px' },
]

const InventoryV3 = ({ inventoryCollection }: { inventoryCollection: 'master_inventory' | 'shield_inventory' }) => {
  return <InventoryContent key={inventoryCollection} inventoryCollection={inventoryCollection} />
}

const parseFilterHash = (hash: string): any => {
  try {
    // Remove the # symbol and decode
    const decodedHash = decodeURIComponent(hash.substring(1))
    // Debug log
    const filterModel = JSON.parse(decodedHash)
    // Debug log
    return filterModel
  } catch (error) {
    console.error('Error parsing filter hash:', error)
    return null
  }
}

const InventoryContent = ({
  inventoryCollection,
}: {
  inventoryCollection: 'master_inventory' | 'shield_inventory'
}) => {
  const gridRef = useRef<AgGridReact>(null)
  const { userInfo } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [selectedTheme, setSelectedTheme] = useState(GRID_THEMES[0])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [openVehicleForm, setOpenVehicleForm] = useState(false)
  const [openManagePhotosDialog, setOpenManagePhotosDialog] = useState(false)
  const [openSelectMainPhotoDialog, setOpenSelectMainPhotoDialog] = useState(false)
  const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState(false)
  const [view, setView] = useLocalStorageState<'list' | 'card'>(`${inventoryCollection}-inventoryView`, 'list')
  const theme = useTheme()

  const isMobile = useIsMobile()

  const { vehicles, addDocument, updateDocument, isLoading, deleteDocument, queryClient, refetchVehicles } =
    useInventory(inventoryCollection)

  // TODO: only show first 1000 vehicles
  const {
    filteredInventory: visibleVehicles,
    FilterUI: SideBarFilter,
    SearchBarUI: SearchBar,
    activeFilters,
    SoldVehiclesToggle,
    FilterChip,
    ResetChip,
    SortUI,
    sortConfig,
  } = useInventoryFilter(vehicles || [], true, userInfo?.role === 'admin')

  // Common method for handling vehicle edits that can be used by both AgGridInventory and VehicleForm
  const sanitizeVehicleData = (data: { [key: string]: any }, vehicle?: Vehicle | null) => {
    // Sanitize numeric values
    const sanitizedData = { ...data }
    
    // Sanitize numeric fields
    const numericFields = ['miles', 'optic_list_price', 'seller_asking_price']
    numericFields.forEach(field => {
      if (sanitizedData[field] !== undefined) {
        sanitizedData[field] = sanitizeNumber(sanitizedData[field])
      }
    })

    // Handle dependencies between fields
    // If make is changing, check if model needs to be reset
    if (sanitizedData.make !== undefined && vehicle && sanitizedData.make !== vehicle.make) {
      const newMake = String(sanitizedData.make)
      const currentModel = vehicle.model
      const validModels = getVehicleModelOptions(newMake)
      
      if (currentModel && !validModels.includes(currentModel)) {
        // Current model is not valid for the new make, reset it
        sanitizedData.model = ''
      }
    }
    
    // If type is changing, check if size needs to be reset
    if (sanitizedData.type !== undefined && vehicle && sanitizedData.type !== vehicle.type) {
      const newType = String(sanitizedData.type)
      const currentSize = vehicle.size
      const validSizes = getVehicleSizeOptions(newType)
      
      if (currentSize && !validSizes.includes(currentSize)) {
        // Current size is not valid for the new type, reset it
        sanitizedData.size = ''
      }
    }

    // Calculate profit if both prices are present
    if (sanitizedData.optic_list_price && vehicle?.seller_asking_price) {
      sanitizedData.profit = Number(calculateProfit(String(vehicle.seller_asking_price), String(sanitizedData.optic_list_price)))
    } else if (sanitizedData.seller_asking_price && vehicle?.optic_list_price) {
      sanitizedData.profit = Number(calculateProfit(String(sanitizedData.seller_asking_price), String(vehicle.optic_list_price)))
    }

    // Handle status changes to/from "sold"
    if (sanitizedData.status) {
      const newStatus = String(sanitizedData.status).toLowerCase()
      const oldStatus = vehicle?.status?.toLowerCase() || ''
      
      // If changing to sold, ensure show_on_website is false and set sold_date if not provided
      if (newStatus === 'sold' && oldStatus !== 'sold') {
        sanitizedData.show_on_website = false
        
        // Always set sold_date to current date when marking as sold if not explicitly provided
        if (!sanitizedData.sold_date && !vehicle?.sold_date) {
          sanitizedData.sold_date = new Date().toISOString()
        }
      }
      
      // If changing from sold, clear sold-related fields
      if (oldStatus === 'sold' && newStatus !== 'sold') {
        sanitizedData.sold_date = ''
        sanitizedData.sold_by_user = null
        // Keep sold_to as historical record
      }
    }

    // Ensure sold_date is properly formatted if it's being changed
    if (sanitizedData.sold_date != null && sanitizedData.sold_date != '') {
      try {
        // Try to parse the date to ensure it's valid
        const dateObj = new Date(sanitizedData.sold_date)
        if (!isNaN(dateObj.getTime())) {
          // Format as ISO string for consistency
          sanitizedData.sold_date = dateObj.toISOString()
        } else {
          return { error: 'Invalid sold date format' }
        }
      } catch (error) {
        return { error: 'Invalid sold date format' }
      }
    }

    // Filter out undefined values
    return { 
      sanitizedData: Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined)) 
    }
  }

  const handleVehicleEdit = async (vehicleId: string, data: { [key: string]: string | number | boolean }) => {
    // Check if trying to modify sold_date on an already sold vehicle
    const vehicle = vehicles?.find(v => v.id === vehicleId)
    const isAlreadySold = vehicle?.status?.toLowerCase() === 'sold'
    const isChangingSoldDate = data.hasOwnProperty('sold_date') && data.sold_date !== vehicle?.sold_date
    
    // Only owner can modify sold_date on already sold vehicles
    if (isAlreadySold && isChangingSoldDate && userInfo?.role !== 'admin' && !userInfo?.isOwner) {
      showSnackbar('Only the owner can modify the sold date of a sold vehicle', 'error')
      return false
    }

    // Sanitize and process the data
    const { sanitizedData, error } = sanitizeVehicleData(data, vehicle)
    
    if (error) {
      showSnackbar(error, 'error')
      return false
    }

    try {
      updateDocument({
        id: vehicleId,
        data: sanitizedData as Partial<DocumentData>,
      })
      return true
    } catch (error) {
      showSnackbar('Error updating vehicle', 'error')
      console.error('Error updating vehicle:', error)
      return false
    }
  }


  const handleCellValuesChange = async (vehicleId: string, data: { [key: string]: string | number | boolean }) => {
    return handleVehicleEdit(vehicleId, data)
  }

  const handleAddVehicle = async (data: { [key: string]: string | number | boolean }) => {
    const newValues = Object.fromEntries(Object.entries(data).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }
    addDocument(newValues)
    setOpenVehicleForm(false)
  }

  const handleEditVehicleForm = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return console.log('No vehicle selected')

    const success = await handleVehicleEdit(selectedVehicle.id, data)
    if (success) {
      setOpenVehicleForm(false)
    }
  }

  const handleDownloadCSVTemplate = () => {
    window.open('/inventory_template.csv')
  }

  const handleDeleteVehicle = (vehicleId: string) => {
    deleteDocument(vehicleId)
  }

  const handleManagePhotosClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle as Vehicle)
    setOpenManagePhotosDialog(true)
  }

  const handleShowHideOnWebsiteClick = async (vehicle: Vehicle) => {
    if (vehicle.show_on_website !== true) {
      setOpenSelectMainPhotoDialog(true)
    }
    if (vehicle.show_on_website) {
      await removeFromWebsiteInventory(vehicle.id)
    } else {
      await addToWebsiteInventory(vehicle.id)
    }
    await updateDocument({
      id: vehicle.id,
      data: { show_on_website: !vehicle.show_on_website },
    })
  }
  const uType = userInfo?.role

  // Add this state to track if we're switching views
  const [isViewChanging, setIsViewChanging] = useState(false)

  // Add this loading state specifically for card view
  const [isCardViewLoading, setIsCardViewLoading] = useState(false)

  // Add this near the other state declarations
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  // Update the useEffect that handles initial load
  useEffect(() => {
    if (view === 'card') {
      setIsCardViewLoading(true)
      // Only show loading state briefly if we have data
      const timer = setTimeout(() => {
        if (vehicles) {
          setIsCardViewLoading(false)
          setIsInitialLoad(false)
        }
      }, 500)
      return () => clearTimeout(timer)
    } else {
      // If not in card view, make sure loading states are false
      setIsCardViewLoading(false)
      setIsInitialLoad(false)
    }
  }, [view, vehicles]) // Add vehicles to dependency array

  // Modify the view toggle handler
  const handleViewChange = (_event: any, newView: 'list' | 'card' | null) => {
    if (newView) {
      setIsViewChanging(true)
      setView(newView)
      if (newView === 'card') {
        setIsCardViewLoading(true)
      }
      // Only hide loading state if we have data
      if (vehicles) {
        setTimeout(() => {
          setIsViewChanging(false)
          setIsCardViewLoading(false)
        }, 100)
      }
    }
  }

  const [isLoadingVehicleForm, setIsLoadingVehicleForm] = useState(false)

  // Add this state near other state declarations
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true)

  // Add this useEffect at the top level
  useEffect(() => {
    // Show loading state on mount
    setIsInitialPageLoad(true)

    // Hide loading state after a delay
    const timer = setTimeout(() => {
      setIsInitialPageLoad(false)
    }, 1000) // Adjust timing as needed

    return () => clearTimeout(timer)
  }, []) // Empty dependency array means this runs once on mount

  const VehicleFilter = () => {
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    // Add event listener to close drawer when custom event is triggered
    useEffect(() => {
      const handleCloseDrawer = () => {
        setIsFilterOpen(false)
      }
      
      window.addEventListener('closeFilterDrawer', handleCloseDrawer)
      return () => {
        window.removeEventListener('closeFilterDrawer', handleCloseDrawer)
      }
    }, [])

    if (!isMobile) {
      return (
        <Box
          sx={{
            width: '300px',
            flexShrink: 0,
            position: 'sticky',
            top: 24,
            alignSelf: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
            <SoldVehiclesToggle />
          </Box>
          <SideBarFilter />
        </Box>
      )
    }

    return (
      <Box>
        <Fab
          variant='extended'
          size='medium'
          color='primary'
          aria-label='filter'
          onClick={() => setIsFilterOpen(true)}
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: 'fixed',
            bottom: 16,
            left: 16,
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterList sx={{ marginRight: 1 }} />
            <Typography variant='button'>Filter</Typography>
          </Box>
        </Fab>
        <SwipeableDrawer
          open={isFilterOpen}
          onOpen={e => {
            e.stopPropagation()
            setIsFilterOpen(true)
          }}
          onClose={e => {
            // Only close if click is on the backdrop
            if (e.target === e.currentTarget) {
              e.stopPropagation()
              setIsFilterOpen(false)
            }
          }}
          anchor='bottom'
          sx={{
            '& .MuiDrawer-paper': {
              maxHeight: '80vh',
              overflow: 'auto',
            },
          }}
          ModalProps={{
            keepMounted: true,
            disableAutoFocus: true,
            disableEnforceFocus: true,
          }}
        >
          <SideBarFilter />
        </SwipeableDrawer>
      </Box>
    )
  }

  const location = useLocation()
  const navigate = useNavigate()
  const [initialFilterModel, setInitialFilterModel] = useState<any>(null)

  // Add this useEffect to handle URL hash changes
  useEffect(() => {
    // Debug log
    if (location.hash) {
      const filterModel = parseFilterHash(location.hash)
      if (filterModel) {
        // Debug log
        setInitialFilterModel(filterModel)
      }
    } else {
      // Clear the filter model when hash is empty
      // Debug log
      setInitialFilterModel(null)
    }
  }, [location.hash])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // height: '70vh',
      }}
    >
      {isInitialPageLoad ? (
        <LoadingInventory />
      ) : (
        <>
          {/* Header section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
            }}
          >
            {/* Title Section */}
            {!isMobile && (
              <Typography variant='h6' sx={{ color: '#666666' }}>
                {inventoryCollection === 'master_inventory'
                  ? 'Optic Inventory Management 2.0'
                  : 'Shield Inventory Management 2.0'}
              </Typography>
            )}
            {isMobile && <SoldVehiclesToggle />}

            {/* Controls Section */}
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems={isMobile ? 'stretch' : 'center'}>
              {/* Primary Actions */}
              <Stack direction='row' spacing={2}>
                <ButtonGroup variant='contained' sx={{ bgcolor: 'background.paper' }}>
                  <Button
                    variant='contained'
                    startIcon={<LocalShippingIcon />}
                    onClick={() => {
                      setSelectedVehicle(null)
                      setOpenVehicleForm(true)
                    }}
                    sx={{
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      bgcolor: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: theme.palette.text.secondary,
                      },
                    }}
                  >
                    Add Truck
                  </Button>
                  {isMobile && <VehicleFilter />}
                  {!isMobile && (
                    <Button
                      sx={{
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                        bgcolor: '#808080',
                        '&:hover': { bgcolor: '#666666' },
                      }}
                      onClick={handleDownloadCSVTemplate}
                    >
                      Download CSV Template
                    </Button>
                  )}
                  {!isMobile && (
                    <Button
                      component='label'
                      sx={{
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                        bgcolor: '#808080',
                        '&:hover': { bgcolor: '#666666' },
                      }}
                    >
                      Upload CSV
                      <input
                        type='file'
                        hidden
                        onChange={event => handleInventoryCsvUpload(event, inventoryCollection)}
                      />
                    </Button>
                  )}
                </ButtonGroup>

                <UndoLastAction inventoryCollection={inventoryCollection} />
                {!isMobile && (
                  <ButtonGroup>
                    <ToggleButtonGroup
                      value={view}
                      exclusive
                      onChange={handleViewChange}
                      aria-label='view mode'
                      size='small'
                    >
                      <ToggleButton value='list' aria-label='list view'>
                        <ListIcon />
                      </ToggleButton>
                      <ToggleButton value='card' aria-label='card view'>
                        <SpaceDashboardIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonGroup>
                )}
              </Stack>
            </Stack>
          </Box>

          {(isMobile || view === 'card') && (
            <>
              {!isMobile && (
                // Desktop layout: Sort UI on top, Search Bar below
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                    mb: 2,
                    mt: 2
                  }}
                >
                  {/* Sort Control on top with fixed width */}
                  <Box sx={{ width: '200px', flexShrink: 0, mt: 0, height: '100px'}}>
                    <SortUI />
                  </Box>
                  
                  {/* Search Bar below */}
                  <Box sx={{ width: '100%' }}>
                    <SearchBar />
                  </Box>
                </Box>
              )}
              
              {isMobile && (
                // Mobile layout: Search Bar on top, then chips, then sort
                <>
                  <SearchBar />
                  
                  <Box 
                    sx={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      alignItems: 'center', 
                      gap: 1, 
                      mb: activeFilters.length > 0 ? 2 : 1,
                      mt: 1
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <SortUI />
                    </Box>
                    
                    {activeFilters.length > 0 && (
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap', 
                          gap: 1, 
                          width: '100%',
                          minHeight: '36px',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          pl: 0,
                          mb: 1,
                        }}
                      >
                        {activeFilters.map(([key, value]) => (
                          <FilterChip key={`${key}-${value}`} filterKey={key} value={value} />
                        ))}
                        {activeFilters.length > 0 && <ResetChip />}
                      </Box>
                    )}
                  </Box>
                </>
              )}
              
              {/* Filter Chips for Desktop - below search bar */}
              {!isMobile && activeFilters.length > 0 && (
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    gap: 1, 
                    width: '100%',
                    minHeight: '36px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    pl: 0,
                    mb: 2,
                  }}
                >
                  {activeFilters.map(([key, value]) => (
                    <FilterChip key={`${key}-${value}`} filterKey={key} value={value} />
                  ))}
                  {activeFilters.length > 0 && <ResetChip />}
                </Box>
              )}
            </>
          )}
          {isLoading || !vehicles ? (
            <LoadingInventory />
          ) : isMobile || view === 'card' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexGrow: 1,
                overflow: 'hidden',
                height: 'calc(100% - 80px)',
                gap: 2,
              }}
            >
              <VehicleFilter />
              {/* Grid container with loading state */}
              <Box sx={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  {isCardViewLoading || isLoading || (isInitialLoad && view === 'card' && !vehicles)
                    ? Array.from({ length: 12 }).map((_, index) => (
                        <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Box sx={{ height: '300px', bgcolor: 'background.paper', p: 2 }}>
                            <Skeleton variant='rectangular' width='100%' height={160} />
                            <Skeleton variant='text' width='60%' />
                            <Skeleton variant='text' width='40%' />
                          </Box>
                        </Grid>
                      ))
                    : visibleVehicles?.map(vehicle => (
                        <Grid item key={vehicle.id} xs={12} sm={6} md={6} lg={4} xl={3}>
                          <VehicleCard
                            collectionName={inventoryCollection}
                            vehicle={vehicle}
                            onDeleteClick={() => handleDeleteVehicle(vehicle.id)}
                            onManagePhotosClick={() => handleManagePhotosClick(vehicle)}
                            onShowHideOnWebsiteClick={() => handleShowHideOnWebsiteClick(vehicle)}
                            isAdmin={uType === 'admin'}
                            vehicles={vehicles}
                          />
                        </Grid>
                      ))}
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box sx={{ position: 'relative', flexGrow: 1 }}>
              <AgGridFirestoreCRUD
                gridRef={gridRef as any}
                gridTheme={selectedTheme}
                rows={vehicles || []}
                isLoading={isViewChanging}
                inventoryCollection={inventoryCollection}
                initialFilterModel={initialFilterModel}
                handleCellValuesChange={handleCellValuesChange}
              />
            </Box>
          )}
          <Dialog open={isLoadingVehicleForm} sx={{ backgroundColor: 'transparent' }}>
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          </Dialog>

          <VehicleForm
            open={openVehicleForm}
            inventoryCollection={inventoryCollection}
            vehicle={selectedVehicle}
            onClose={() => {
              setOpenVehicleForm(false)
              setIsLoadingVehicleForm(false)
            }}
            onSubmit={selectedVehicle ? handleEditVehicleForm : handleAddVehicle}
            generateStockNumber={() =>
              generateOpticStockNumber(
                (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
                userInfo?.first_name || '',
                userInfo?.last_name || '',
              )
            }
          />
          {selectedVehicle && (
            <>
              <PhotoUploadDialog
                open={openPhotoUploadDialog}
                onClose={() => setOpenPhotoUploadDialog(false)}
                vehicle={selectedVehicle}
              />
              <ManagePhotosDialog
                collectionName={inventoryCollection}
                open={openManagePhotosDialog}
                onClose={() => setOpenManagePhotosDialog(false)}
                vehicle={selectedVehicle}
              />
              <SelectMainPhotoDialog
                collectionName={inventoryCollection}
                open={openSelectMainPhotoDialog}
                onClose={() => setOpenSelectMainPhotoDialog(false)}
                vehicle={selectedVehicle}
              />
            </>
          )}
          {/* Render PhotoQueryManager for each vehicle */}
          {/* {visibleVehicles?.map(vehicle => <PhotoQueryManager key={vehicle.id} vehicleId={vehicle.id} />)} */}
        </>
      )}
    </Box>
  )
}

export default InventoryV3
