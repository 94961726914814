import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Chip,
  Grid,
  IconButton,
  TextField,
  Stack,
  Menu,
  MenuItem,
  useMediaQuery,
  Theme,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { 
  Close, 
  Add, 
  Edit, 
  Phone, 
  Email, 
  Business, 
  LocationOn, 
  AttachMoney, 
  Schedule, 
  LocalShipping,
  CalendarToday,
  DirectionsCar,
  LocalGasStation,
  Inventory,
  CheckCircle,
  RadioButtonUnchecked,
  RemoveCircle,
  Download,
  FilterList
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'
import SpeedIcon from '@mui/icons-material/Speed'
import { Lead, FollowUpNote, TruckType } from '@otw/models/leads/lead';
import { useLeads } from 'hooks/admin/useLeads';
import { useAuth } from 'contexts/AuthContext';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { useInventory } from 'hooks/inventory/useInventory';
import { Vehicle } from '@otw/models/inventory/inventory';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import { format } from 'date-fns';
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject';
import { formatPrice, formatDistance } from 'core/utils/inventoryUtils';
import { cargoVan, boxTruck, cutaway, stepVan } from 'assets';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTheme } from '@mui/material/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lead-detail-tabpanel-${index}`}
      aria-labelledby={`lead-detail-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface LeadDetailDialogProps {
  open: boolean;
  onClose: () => void;
  lead: Lead;
  onEdit: (lead: Lead) => void;
}

// Define similarity levels
type SimilarityLevel = 'exact' | 'similar' | 'none';

interface VehicleWithSimilarity extends Vehicle {
  similarityLevel: SimilarityLevel;
  matchScore: number;
  matchingType?: TruckType;
  typeKey?: string;
}

// Helper component to display vehicle image (copied from SuggestedTrucksDialog)
const VehicleImage = ({ vehicle }: { vehicle: Vehicle }) => {
  const { data: photos, isLoading } = usePhotosForInventoryObject('master_inventory', vehicle.id, {
    onlyMainPhoto: true,
    enabled: !!vehicle.photo_storage_path
  });
  
  const fallbackImage = getVehicleIcon(vehicle.type);
  
  if (isLoading || !photos || photos.length === 0) {
    return (
      <CardMedia
        component="img"
        height="140"
        image={fallbackImage}
        alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
        sx={{ objectFit: 'contain', bgcolor: '#f5f5f5' }}
      />
    );
  }
  
  return (
    <CardMedia
      component="img"
      height="140"
      image={photos[0].url}
      alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
      sx={{ objectFit: 'cover' }}
    />
  );
};

// Helper function to get vehicle icon based on type
const getVehicleIcon = (type?: string | null) => {
  if (!type) return stepVan;
  
  switch (type.toLowerCase()) {
    case 'box truck':
      return boxTruck;
    case 'cutaway':
      return cutaway;
    case 'cargo van':
      return cargoVan;
    default:
      return stepVan;
  }
};

// Get label for similarity level
const getSimilarityLabel = (level: SimilarityLevel) => {
  switch (level) {
    case 'exact':
      return 'Exact Match';
    case 'similar':
      return 'Similar Match';
    default:
      return 'Match';
  }
};

// Get icon for similarity level
const getSimilarityIcon = (level: SimilarityLevel) => {
  switch (level) {
    case 'exact':
      return <CheckCircle fontSize="small" color="success" />;
    case 'similar':
      return <RadioButtonUnchecked fontSize="small" color="primary" />;
    default:
      return null;
  }
};

const LeadDetailDialog: React.FC<LeadDetailDialogProps> = ({
  open,
  onClose,
  lead,
  onEdit,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [newNote, setNewNote] = useState('');
  const { updateLead } = useLeads();
  const { userInfo } = useAuth();
  const { vehicles } = useInventory("master_inventory");
  const isMobile = useIsMobile();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  // Add state for menu
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null);
  
  // Add states for suggested trucks functionality
  const [suggestedVehicles, setSuggestedVehicles] = useState<VehicleWithSimilarity[]>([]);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(true);
  const [attachingVehicle, setAttachingVehicle] = useState<string | null>(null);
  const [exactMatches, setExactMatches] = useState<VehicleWithSimilarity[]>([]);
  const [similarMatches, setSimilarMatches] = useState<VehicleWithSimilarity[]>([]);
  // Track attached vehicles locally
  const [attachedVehicleIds, setAttachedVehicleIds] = useState<string[]>([]);

  // Add a state to track linked vehicles
  const [linkedVehicles, setLinkedVehicles] = useState<Vehicle[]>([]);
  
  // Add state for truck type filters
  const [selectedTruckTypes, setSelectedTruckTypes] = useState<string[]>([]);
  const [allTruckTypes, setAllTruckTypes] = useState<{typeKey: string, displayName: string, size: string}[]>([]);
  const [filteredVehicles, setFilteredVehicles] = useState<VehicleWithSimilarity[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  // Add menu handlers
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleMobileMenuSelect = (index: number) => {
    setTabValue(index);
    setMobileMenuAnchor(null);
  };

  const handleAddNote = async () => {
    if (!lead || !newNote.trim() || !userInfo) return;

    const note: FollowUpNote = {
      id: uuidv4(),
      text: newNote.trim(),
      author: userInfo.id,
      createdAt: new Date(),
    };

    const updatedNotes = [...(lead.followUpNotes || []), note];
    
    await updateLead({
      id: lead.id,
      updates: { 
        followUpNotes: updatedNotes,
        lastContactDate: new Date(),
      },
    });

    setNewNote('');
  };

  const handleEdit = () => {
    onEdit(lead);
  };

  // Initialize attached vehicle IDs from lead when dialog opens
  useEffect(() => {
    if (open && lead) {
      setAttachedVehicleIds(lead.truckIds || []);
      
      // Initialize truck type filters - all selected by default
      if (lead.truckRequest?.types) {
        const types = lead.truckRequest.types.map((type, index) => ({
          typeKey: `type-${index}`,
          displayName: getDisplayName(type),
          size: type.size || 'Any'
        }));
        setAllTruckTypes(types);
        setSelectedTruckTypes(types.map(t => t.typeKey));
      }
    }
  }, [open, lead]);

  // Add a useEffect to update linkedVehicles when attachedVehicleIds or vehicles change
  useEffect(() => {
    if (vehicles && attachedVehicleIds.length > 0) {
      const linked = vehicles.filter(vehicle => 
        attachedVehicleIds.includes(vehicle.id)
      );
      setLinkedVehicles(linked);
    } else {
      setLinkedVehicles([]);
    }
  }, [vehicles, attachedVehicleIds]);

  // Calculate similarity score between vehicle and truck type
  const calculateSimilarity = (vehicle: Vehicle, truckType: TruckType): { score: number, level: SimilarityLevel } => {
    let score = 0;
    const maxScore = 10; // Maximum possible score
    let exceedsMileageLimit = false;
    let sizeMismatch = false;
    let fuelTypeMismatch = false;
    
    // Type match is guaranteed since we pre-filtered
    score += 3;
    
    // Size match - STRICT ENFORCEMENT
    if (truckType.size && vehicle.size) {
      if (truckType.size === 'Any' || truckType.size === vehicle.size) {
        score += 2;
      } else {
        // If sizes don't match exactly, mark as mismatch
        sizeMismatch = true;
      }
    }
    
    // Fuel type match - STRICT ENFORCEMENT
    if (truckType.fuelType && vehicle.fuel) {
      // Handle fuelType as array
      const fuelTypesList = Array.isArray(truckType.fuelType) ? truckType.fuelType : [truckType.fuelType];
      if (fuelTypesList.includes('Any') || fuelTypesList.includes(vehicle.fuel)) {
        score += 0.5;
      } else {
        // If fuel types don't match, mark as mismatch
        fuelTypeMismatch = true;
      }
    }
    
    // Condition match
    if (truckType.condition && vehicle.condition) {
      if (truckType.condition === vehicle.condition) {
        score += 1;
      }
    }
    
    // Make match
    if (truckType.make && vehicle.make) {
      // Handle make as array
      const makesList = Array.isArray(truckType.make) ? truckType.make : [truckType.make];
      if (makesList.includes('Any') || makesList.includes(vehicle.make)) {
        score += 1;
      }
    }
    
    // Model match
    if (truckType.model && vehicle.model) {
      // Handle model as array
      const modelsList = Array.isArray(truckType.model) ? truckType.model : [truckType.model];
      if (modelsList.includes('Any') || modelsList.includes(vehicle.model)) {
        score += 1;
      }
    }
    
    // Year range match
    if (vehicle.year) {
      let yearScore = 0;
      
      if (truckType.minYear && vehicle.year >= truckType.minYear) {
        yearScore += 0.5;
      }
      
      if (truckType.maxYear && vehicle.year <= truckType.maxYear) {
        yearScore += 0.5;
      }
      
      // If no year range specified, give full points
      if (!truckType.minYear && !truckType.maxYear) {
        yearScore = 1;
      }
      
      score += yearScore;
    }
    
    // Mileage match - STRICT ENFORCEMENT
    if (truckType.maxMileage && vehicle.miles) {
      // maxMileage is now always a number, no need for conversion
      const requestedMaxMileage = truckType.maxMileage;
      
      // Check if vehicle exceeds max mileage by more than 10,000 miles
      if (vehicle.miles > requestedMaxMileage + 15000) {
        exceedsMileageLimit = true;
        score -= 1; // Penalty for exceeding max mileage
      } else if (vehicle.miles <= requestedMaxMileage) {
        // Vehicle is within requested mileage
        score += 0.5;
      }
    }
    
    let exceedBudget = false;
    // Budget match
    if (truckType.budget && vehicle.optic_list_price) {
      const budget = typeof truckType.budget === 'string' 
        ? parseFloat(String(truckType.budget).replace(/[^\d.]/g, '')) 
        : truckType.budget;
      
      if (budget) {
        // Exact match (within 10%)
        if (vehicle.optic_list_price <= budget) {
          score += 0.5;
        }
        // Similar match (within 10%)
        else if (vehicle.optic_list_price <= budget * 1.2) {
          score += 0;
        }
        else if (vehicle.optic_list_price > budget * 1.2) {
          exceedBudget = true;
        }
      }
    }
    
    // Calculate similarity level based on score percentage
    const percentage = (score / maxScore) * 100;
    
    // Determine similarity level - only 'exact' or 'similar', no 'partial'
    let level: SimilarityLevel;
    
    // If size or fuel type doesn't match exactly, immediately set to 'none'
    if (sizeMismatch || fuelTypeMismatch) {
      level = 'none';
    }
    else if (percentage >= 90 && !exceedsMileageLimit && !exceedBudget) {
      level = 'exact';
    } 
    else if (percentage >= 80 && !exceedsMileageLimit && !exceedBudget) {
      level = 'similar';
    }
    else {
      level = 'none';
    }

    return { score, level };
  };

  // Create a display name for each truck type that includes all makes and models with pipe separators
  const getDisplayName = (config: any) => {
    const parts = [];
    
    // Add type
    if (config.type) {
      parts.push(config.type);
    }
    
    // Add size if specified
    if (config.size && config.size !== 'Any') {
      parts.push(config.size);
    }
    
    // Add all makes if available
    if (config.make) {
      const makesList = Array.isArray(config.make) ? config.make : [config.make];
      if (makesList.length > 0 && !makesList.includes('Any')) {
        parts.push(makesList.join('/'));
      }
    }
    
    // Add all models if available
    if (config.model) {
      const modelsList = Array.isArray(config.model) ? config.model : [config.model];
      if (modelsList.length > 0 && !modelsList.includes('Any')) {
        parts.push(modelsList.join('/'));
      }
    }
    
    // Join parts with pipe separator
    return parts.join(' | ');
  };

  // Modify findMatchingVehicles to remove filtering by visible truck types
  const findMatchingVehicles = () => {
    if (!vehicles || !lead.truckRequest || !lead.truckRequest.types) {
      return [];
    }
    
    const matchingVehicles: VehicleWithSimilarity[] = [];
    
    // Get available vehicles (not sold)
    const availableVehicles = vehicles.filter(v => 
      v.status === 'Available' || 
      v.status === 'Pending Available'
    );
    
    // Process all truck types
    lead.truckRequest.types.forEach((truckType, index) => {
      if (!truckType.type) return;
      
      // STRICT TYPE FILTERING: Only consider vehicles with EXACTLY the same type
      const typeMatches = availableVehicles.filter(vehicle => 
        vehicle.type && 
        vehicle.type.toLowerCase() === truckType.type?.toLowerCase()
      );
      
      // For each vehicle with matching type, calculate other similarity factors
      typeMatches.forEach(vehicle => {
        const { score, level } = calculateSimilarity(vehicle, truckType);
        
        // Only include vehicles with a similarity level of 'exact' or 'similar'
        if (level === 'exact' || level === 'similar') {
          matchingVehicles.push({
            ...vehicle,
            similarityLevel: level,
            matchScore: score,
            matchingType: truckType,
            typeKey: `type-${index}` // Add typeKey to track which truck type this matches
          });
        }
      });
    });
    
    return matchingVehicles.sort((a, b) => {
      // First sort by similarity level
      const levelOrder: Record<SimilarityLevel, number> = { exact: 0, similar: 1, none: 2 };
      const levelDiff = levelOrder[a.similarityLevel] - levelOrder[b.similarityLevel];
      
      if (levelDiff !== 0) return levelDiff;
      
      // Then by score (higher is better)
      return b.matchScore - a.matchScore;
    });
  };

  // Add a function to handle truck type filter changes
  const handleTruckTypeFilterChange = (typeKey: string) => {
    setSelectedTruckTypes(prev => {
      if (prev.includes(typeKey)) {
        return prev.filter(key => key !== typeKey);
      } else {
        return [...prev, typeKey];
      }
    });
  };

  // Update the useEffect to filter vehicles based on selected truck types
  useEffect(() => {
    if (vehicles && lead && tabValue === 4) { // Only load when the Suggested Trucks tab is active
      setIsLoadingVehicles(true);
      
      // Find matching vehicles without filters
      const matches = findMatchingVehicles();
      
      // Store all matches
      setSuggestedVehicles(matches);
      
      // Filter matches based on selected truck types
      const filtered = matches.filter(vehicle => 
        vehicle.typeKey && selectedTruckTypes.includes(vehicle.typeKey)
      );
      
      // Sort filtered matches by match score (higher is better)
      const sortedFiltered = [...filtered].sort((a, b) => {
        return b.matchScore - a.matchScore;
      });
      
      setFilteredVehicles(sortedFiltered);
      
      // Separate by similarity level (still needed for counts)
      const exact = filtered.filter(v => v.similarityLevel === 'exact');
      const similar = filtered.filter(v => v.similarityLevel === 'similar');
      
      setExactMatches(exact);
      setSimilarMatches(similar);
      
      setIsLoadingVehicles(false);
    }
  }, [vehicles, lead, tabValue, selectedTruckTypes]); // Add selectedTruckTypes as a dependency

  // Update the calculateTruckTypeCounts function to use a stable index
  const calculateTruckTypeCounts = () => {
    if (!lead?.truckRequest?.types || !vehicles) {
      return [];
    }
    
    // Get the attached vehicles
    const attachedVehicles = vehicles.filter(v => attachedVehicleIds.includes(v.id));
    
    // Initialize counts for each truck type with stable indices
    const typeCounts: Record<string, any> = {};
    
    // Count requested trucks by type
    lead.truckRequest.types.forEach((truckType, index) => {
      const typeKey = `type-${index}`;
      
      if (!typeCounts[typeKey]) {
        typeCounts[typeKey] = {
          typeKey,
          index: index.toString(), // Store the original index
          type: truckType.type,
          size: truckType.size,
          make: truckType.make,
          model: truckType.model,
          requested: 0,
          attached: 0
        };
      }
      
      typeCounts[typeKey].requested += truckType.quantity || 1;
    });
    
    // Count attached trucks by type
    attachedVehicles.forEach(vehicle => {
      let bestMatch = '';
      let bestMatchScore = 0;
      
      // Find the best matching truck type for this vehicle
      Object.entries(typeCounts).forEach(([typeKey, config]) => {
        let score = 0;
        
        // Type must match
        if (vehicle.type?.toLowerCase() === config.type.toLowerCase()) {
          score += 3;
          
          // Size match
          if (config.size && vehicle.size) {
            if (config.size === 'Any' || config.size === vehicle.size) {
              score += 2;
            }
          }
          
          // Make match - handle array
          if (config.make && vehicle.make) {
            const makesList = Array.isArray(config.make) ? config.make : [config.make];
            if (makesList.includes('Any') || makesList.includes(vehicle.make)) {
              score += 1;
            }
          }
          
          // Model match - handle array
          if (config.model && vehicle.model) {
            const modelsList = Array.isArray(config.model) ? config.model : [config.model];
            if (modelsList.includes('Any') || modelsList.includes(vehicle.model)) {
              score += 1;
            }
          }
          
          if (score > bestMatchScore) {
            bestMatchScore = score;
            bestMatch = typeKey;
          }
        }
      });
      
      // If we found a match, increment the attached count
      if (bestMatch && bestMatchScore >= 3) { // At least type must match
        typeCounts[bestMatch].attached += 1;
      }
    });
    
    // Return the counts
    return Object.entries(typeCounts).map(([typeKey, count]) => ({
      typeKey,
      index: count.index,
      type: count.type,
      size: count.size,
      make: count.make,
      model: count.model,
      requested: count.requested,
      attached: count.attached,
      fulfilled: count.attached >= count.requested,
      displayName: getDisplayName(count)
    }));
  };

  // Add a refresh function to reload the lead data
  const refreshLeadData = async () => {
    // This depends on how your app fetches lead data
    // You might need to call a function from useLeads or another hook
    // For example:
    // await fetchLeadById(lead.id);
  };

  // Function to attach vehicle to lead
  const attachVehicleToLead = async (vehicle: Vehicle) => {
    try {
      setAttachingVehicle(vehicle.id);
      
      // Check if vehicle is already attached
      if (attachedVehicleIds.includes(vehicle.id)) {
        // Already attached, no need to update
        setAttachingVehicle(null);
        return;
      }
      
      // Add vehicle ID to lead's truckIds
      const updatedTruckIds = [...attachedVehicleIds, vehicle.id];
      
      console.log('Attaching vehicle to lead:', {
        leadId: lead.id,
        vehicleId: vehicle.id,
        updatedTruckIds
      });
      
      // Update the lead in the database - make sure this matches the expected signature from SuggestedTrucksDialog
      await updateLead({
        id: lead.id,
        updates: {
          truckIds: updatedTruckIds
        }
      });
      
      // Update local state to reflect the change immediately
      setAttachedVehicleIds(updatedTruckIds);
      
    } catch (error) {
      console.error('Error attaching vehicle to lead:', error);
    } finally {
      setAttachingVehicle(null);
    }
  };

  // Function to remove vehicle from lead
  const removeVehicleFromLead = async (vehicle: Vehicle) => {
    try {
      setAttachingVehicle(vehicle.id);
      
      // Filter out the vehicle ID from the lead's truckIds
      const updatedTruckIds = attachedVehicleIds.filter(id => id !== vehicle.id);
      
      // Update the lead in the database
      await updateLead({
        id: lead.id,
        updates: {
          truckIds: updatedTruckIds
        }
      });
      
      // Update local state to reflect the change immediately
      setAttachedVehicleIds(updatedTruckIds);
      
      // Update linkedVehicles state
      setLinkedVehicles(prev => prev.filter(v => v.id !== vehicle.id));
      
      console.log('Vehicle removed successfully:', vehicle.id);
      
    } catch (error) {
      console.error('Error removing vehicle from lead:', error);
    } finally {
      setAttachingVehicle(null);
    }
  };

  // Add this function inside the LeadDetailDialog component, before the return statement
  const generatePDF = () => {
    if (!suggestedVehicles.length) return;
    
    // Create a new PDF document
    const doc = new jsPDF();
    
    // Get primary color from theme (converting to RGB array for jsPDF)
    // MUI's primary color is typically in hex format (#1976d2 by default)
    // We need to convert it to RGB values for jsPDF
    const primaryColorHex = theme.palette.primary.main.replace('#', '');
    const primaryColorRGB = [
      parseInt(primaryColorHex.substring(0, 2), 16),
      parseInt(primaryColorHex.substring(2, 4), 16),
      parseInt(primaryColorHex.substring(4, 6), 16)
    ];
    
    // Add title with primary color
    doc.setFontSize(16);
    doc.setTextColor(primaryColorRGB[0], primaryColorRGB[1], primaryColorRGB[2]);
    doc.text(`Suggested Trucks for ${lead.customerName || 'Lead'}`, 14, 15);
    
    // Add lead info with primary color
    doc.setFontSize(10);
    doc.setTextColor(primaryColorRGB[0], primaryColorRGB[1], primaryColorRGB[2]);
    doc.text(`Generated on: ${format(new Date(), 'MM/dd/yyyy')}`, 14, 22);
    if (lead.company) {
      doc.text(`Company: ${lead.company}`, 14, 27);
    }
    
    // Prepare table data
    const tableColumn = [
      "Type", 
      "Year/Make/Model", 
      "Size", 
      "Mileage", 
      "Condition", 
      "Price", 
      "Match"
    ];
    
    const tableRows = suggestedVehicles.map(vehicle => [
      vehicle.type || 'N/A',
      `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''}`.trim() || 'N/A',
      vehicle.size || 'N/A',
      vehicle.miles ? formatDistance(vehicle.miles) : 'N/A',
      vehicle.condition || 'N/A',
      vehicle.optic_list_price ? formatPrice(vehicle.optic_list_price) : 'N/A',
      vehicle.similarityLevel === 'exact' ? 'Exact Match' : 'Similar Match'
    ]);
    
    // Add the table
    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 32,
      styles: { fontSize: 8 },
      headStyles: { 
        fillColor: primaryColorRGB,  // Use primary color for header background
        textColor: [255, 255, 255]   // Keep text white for contrast
      },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      rowStyles: (rowIndex: number) => {
        const vehicle = suggestedVehicles[rowIndex];
        if (vehicle.similarityLevel === 'exact') {
          return { textColor: [0, 128, 0] }; // Keep exact matches green for emphasis
        }
        return {};
      },
      // Add theme color to column text
      didParseCell: (data: any) => {
        // Apply primary color to all text in the body (not headers)
        if (data.section === 'body' && !data.cell.raw.includes('Exact Match')) {
          data.cell.styles.textColor = primaryColorRGB;
        }
        else if (data.section === 'body'){
          data.cell.styles.textColor = [0, 128, 0];
        }
      }
    });
    
    doc.save(`Suggested Trucks for ${lead.customerName}.pdf`);
  };

  // Add this function inside the LeadDetailDialog component, before the return statement
  const generateLinkedVehiclesPDF = () => {
    if (!linkedVehicles.length) return;
    
    // Create a new PDF document
    const doc = new jsPDF();
    
    // Get primary color from theme (converting to RGB array for jsPDF)
    const primaryColorHex = theme.palette.primary.main.replace('#', '');
    const primaryColorRGB = [
      parseInt(primaryColorHex.substring(0, 2), 16),
      parseInt(primaryColorHex.substring(2, 4), 16),
      parseInt(primaryColorHex.substring(4, 6), 16)
    ];
    
    // Add title with primary color
    doc.setFontSize(16);
    doc.setTextColor(primaryColorRGB[0], primaryColorRGB[1], primaryColorRGB[2]);
    doc.text(`Linked Vehicles for ${lead.customerName || 'Lead'}`, 14, 15);
    
    // Add lead info with primary color
    doc.setFontSize(10);
    doc.setTextColor(primaryColorRGB[0], primaryColorRGB[1], primaryColorRGB[2]);
    doc.text(`Generated on: ${format(new Date(), 'MM/dd/yyyy')}`, 14, 22);
    if (lead.company) {
      doc.text(`Company: ${lead.company}`, 14, 27);
    }
    
    // Prepare table data
    const tableColumn = [
      "Type", 
      "Year/Make/Model", 
      "Size", 
      "Mileage", 
      "Condition", 
      "Price", 
      "Status"
    ];
    
    const tableRows = linkedVehicles.map(vehicle => [
      vehicle.type || 'N/A',
      `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''}`.trim() || 'N/A',
      vehicle.size || 'N/A',
      vehicle.miles ? formatDistance(vehicle.miles) : 'N/A',
      vehicle.condition || 'N/A',
      vehicle.optic_list_price ? formatPrice(vehicle.optic_list_price) : 'N/A',
      vehicle.status || 'N/A'
    ]);
    
    // Add the table
    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 32,
      styles: { fontSize: 8 },
      headStyles: { 
        fillColor: primaryColorRGB,  // Use primary color for header background
        textColor: [255, 255, 255]   // Keep text white for contrast
      },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      // Add theme color to column text
      didParseCell: (data: any) => {
        // Apply primary color to all text in the body (not headers)
        if (data.section === 'body') {
          data.cell.styles.textColor = primaryColorRGB;
        }
      }
    });
    
    doc.save(`Linked Vehicles for ${lead.customerName}.pdf`);
  };

  if (!lead) return null;

  // Format status for display
  const getStatusDisplay = (status: string | null) => {
    switch(status) {
      case 'new': return 'New';
      case 'inProgress': return 'In Progress';
      case 'waitingFinance': return 'Waiting Finance';
      case 'closed': return 'Closed';
      case 'lost': return 'Lost';
      case null: return 'Not Set';
      default: return status || 'Unknown';
    }
  };

  // Render truck preferences section
  const renderTruckPreferences = () => {
    if (!lead.truckRequest) return null;
    
    // Check if we have truck types array
    const hasTruckTypes = lead.truckRequest.types && Array.isArray(lead.truckRequest.types) && lead.truckRequest.types.length > 0;
    
    if (hasTruckTypes) {
      // Render each truck type as a separate section
      return (
        <>
          <Typography variant="h6" gutterBottom fontSize={{xs: '1rem', md: '1.1rem'}} fontWeight="bold">
            Truck Preferences
          </Typography>
          
          {lead.truckRequest?.types?.map((truckType, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1" gutterBottom fontSize={{xs: '0.8rem', md: '1rem'}} fontWeight="bold">
                {truckType.type} {truckType.size && `- ${truckType.size}`}
              </Typography>
              
              <Grid container spacing={2}>
                {truckType.condition && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Condition:
                    </Typography>
                    <Typography variant="body1">
                      {truckType.condition}
                    </Typography>
                  </Grid>
                )}
                
                {truckType.quantity && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Quantity:
                    </Typography>
                    <Typography variant="body1">
                      {truckType.quantity}
                    </Typography>
                  </Grid>
                )}
                
                {truckType.make && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Makes:
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                      {Array.isArray(truckType.make) ? (
                        truckType.make.length > 0 ? (
                          truckType.make.map((make, i) => (
                            <Chip key={i} label={make} size="small" />
                          ))
                        ) : (
                          <Typography variant="body1">Any</Typography>
                        )
                      ) : (
                        <Typography variant="body1">{truckType.make}</Typography>
                      )}
                    </Box>
                  </Grid>
                )}
                
                {truckType.model && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Models:
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                      {Array.isArray(truckType.model) ? (
                        truckType.model.length > 0 ? (
                          truckType.model.map((model, i) => (
                            <Chip key={i} label={model} size="small" />
                          ))
                        ) : (
                          <Typography variant="body1">Any</Typography>
                        )
                      ) : (
                        <Typography variant="body1">{truckType.model}</Typography>
                      )}
                    </Box>
                  </Grid>
                )}
                
                {(truckType.minYear || truckType.maxYear) && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Year Range:
                    </Typography>
                    <Typography variant="body1">
                      {truckType.minYear ? truckType.minYear : 'Any'} - {truckType.maxYear ? truckType.maxYear : 'Any'}
                    </Typography>
                  </Grid>
                )}
                
                {truckType.fuelType && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Fuel Types:
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                      {Array.isArray(truckType.fuelType) ? (
                        truckType.fuelType.length > 0 ? (
                          truckType.fuelType.map((fuel, i) => (
                            <Chip key={i} label={fuel} size="small" />
                          ))
                        ) : (
                          <Typography variant="body1">Any</Typography>
                        )
                      ) : (
                        <Typography variant="body1">{truckType.fuelType}</Typography>
                      )}
                    </Box>
                  </Grid>
                )}
                
                {truckType.maxMileage && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Max Mileage:
                    </Typography>
                    <Typography variant="body1">
                      {typeof truckType.maxMileage === 'number' 
                        ? truckType.maxMileage.toLocaleString('en-US')
                        : truckType.maxMileage}
                    </Typography>
                  </Grid>
                )}
                
                {truckType.budget && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                      Budget:
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      ${typeof truckType.budget === 'number' 
                        ? truckType.budget.toLocaleString('en-US') 
                        : truckType.budget}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          ))}
          
          {lead.truckRequest.notes && (
            <Box mt={2}>
              <Typography variant="body2" color="text.secondary">
                Additional Notes:
              </Typography>
              <Typography variant="body1">
                {lead.truckRequest.notes}
              </Typography>
            </Box>
          )}
        </>
      );
    } else {
      // Fallback to legacy format if no truck types array
      return (
        <>
          <Typography variant="h6" gutterBottom>
            Truck Preferences
          </Typography>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {lead.truckRequest.types && lead.truckRequest.types.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    Type:
                  </Typography>
                  <Typography variant="body1">
                    {lead.truckRequest.types.map(type => type.type).join(', ')}
                  </Typography>
                </Grid>
              )}
              
              {/* Include the rest of the legacy format fields */}
              {/* ... existing code for legacy format ... */}
            </Grid>
          </Paper>
        </>
      );
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: {
            xs: '95vh',
            sm: '90vh',
          },
          maxHeight: '1200px',
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">
            {lead.customerName || 'Lead Details'}
          </Typography>
          <Box>
            <IconButton onClick={handleEdit} color="primary">
              <Edit />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      {/* Conditional rendering based on screen size */}
      <Box sx={{ borderBottom: 0, borderColor: 'divider', display: 'flex', alignItems: 'center', px:4 }}>
        {isSmallScreen ? (
          <>
            <Typography variant="body1" sx={{ flexGrow: 1, fontSize: '1rem'}}>
              {tabValue === 0 ? 'Overview' : 
               tabValue === 1 ? 'Status History' : 
               tabValue === 2 ? 'Follow-up Notes' : 
               tabValue === 3 ? 'Linked Items' :
               tabValue === 4 ? 'Suggested Trucks' : ''}
            </Typography>
            <IconButton 
              edge="end" 
              color="inherit" 
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={mobileMenuAnchor}
              open={Boolean(mobileMenuAnchor)}
              onClose={handleMobileMenuClose}
            >
              <MenuItem onClick={() => handleMobileMenuSelect(0)} selected={tabValue === 0} sx={{ fontSize: '0.8rem' }}>
                Overview
              </MenuItem>
              <MenuItem onClick={() => handleMobileMenuSelect(1)} selected={tabValue === 1} sx={{ fontSize: '0.8rem' }}>
                Status History
              </MenuItem>
              <MenuItem onClick={() => handleMobileMenuSelect(2)} selected={tabValue === 2} sx={{ fontSize: '0.8rem' }}>
                Follow-up Notes
              </MenuItem>
              <MenuItem onClick={() => handleMobileMenuSelect(3)} selected={tabValue === 3} sx={{ fontSize: '0.8rem' }}>
                Linked Items
              </MenuItem>
              <MenuItem onClick={() => handleMobileMenuSelect(4)} selected={tabValue === 4} sx={{ fontSize: '0.8rem' }}>
                Suggested Trucks
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            variant="standard"
          >
            <Tab label="Overview" sx={{ fontSize: '1rem' }}/>
            <Tab label="Status History" sx={{ fontSize: '1rem' }}/>
            <Tab label="Follow-up Notes" sx={{ fontSize: '1rem' }}/>
            <Tab label="Linked Items" sx={{ fontSize: '1rem' }}/>
            <Tab label="Suggested Trucks" sx={{ fontSize: '1rem' }}/>
          </Tabs>
        )}
      </Box>

      <DialogContent dividers sx={{ p: 0 }}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom fontSize={{xs: '1rem', md: '1.1rem'}}>
                  Customer Information
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Name
                  </Typography>
                  <Typography variant="body1">
                    {lead.customerName || 'N/A'}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Company
                  </Typography>
                  <Typography variant="body1">
                    {lead.company || 'N/A'}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body1">
                    {lead.email || 'N/A'}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body1">
                    {lead.phone || 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Location
                  </Typography>
                  <Typography variant="body1">
                    {lead.location || 'N/A'}
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <Typography variant="h6" fontWeight="bold" gutterBottom fontSize={{xs: '1rem', md: '1.1rem'}}>
                  Lead Status
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Current Status
                  </Typography>
                  <Chip 
                    label={getStatusDisplay(lead.status)} 
                    color={
                      lead.status === 'new' ? 'info' :
                      lead.status === 'inProgress' ? 'primary' :
                      lead.status === 'waitingFinance' ? 'warning' :
                      lead.status === 'closed' ? 'success' :
                      lead.status === 'lost' ? 'error' :
                      'default'
                    }
                    size="small"
                    sx={{ mt: 0.5 }}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Financing Status
                  </Typography>
                  <Typography variant="body1">
                    {lead.financingStatus || 'N/A'}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Time Frame
                  </Typography>
                  <Typography variant="body1">
                    {lead.timeFrame || 'N/A'}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Priority
                  </Typography>
                  <Chip 
                    label={lead.priority || 'Medium'} 
                    color={
                      lead.priority === 'high' ? 'error' :
                      lead.priority === 'medium' ? 'warning' :
                      'success'
                    }
                    size="small"
                    sx={{ mt: 0.5 }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Tags
                  </Typography>
                  <Box sx={{ mt: 0.5, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {lead.tags && lead.tags.length > 0 ? (
                      lead.tags.map(tag => (
                        <Chip key={tag} label={tag} size="small" variant="outlined" />
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No tags
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              {renderTruckPreferences()}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom fontSize={{xs: '1rem', md: '1.1rem'}}>
              Status History
            </Typography>
            {lead.statusHistory && lead.statusHistory.length > 0 ? (
              <List>
                {lead.statusHistory.slice().reverse().map((history) => (
                  <ListItem key={history.id} divider>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center" gap={1}>
                          <Typography variant="body1">
                            {history.previousStatus === null ? 'Created' : `Changed from ${getStatusDisplay(history.previousStatus)} to ${getStatusDisplay(history.newStatus)}`}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            {DateTime.fromJSDate(new Date(history.changedAt)).toLocaleString(DateTime.DATETIME_MED)}
                          </Typography>
                          {history.notes && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              {history.notes}
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No status history available
              </Typography>
            )}
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom fontSize={{xs: '1rem', md: '1.1rem'}}>
              Follow-up Notes
            </Typography>
            
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Add a new note..."
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                sx={{ mb: 1 }}
              />
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleAddNote}
                disabled={!newNote.trim()}
              >
                Add Note
              </Button>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            {lead.followUpNotes && lead.followUpNotes.length > 0 ? (
              <List>
                {lead.followUpNotes.slice().reverse().map((note) => (
                  <ListItem key={note.id} divider>
                    <ListItemText
                      primary={note.text}
                      secondary={
                        <Typography variant="caption" color="text.secondary">
                          {DateTime.fromJSDate(new Date(note.createdAt)).toLocaleString(DateTime.DATETIME_MED)}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No follow-up notes available
              </Typography>
            )}
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Stack spacing={3}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 2
              }}>
                <Typography variant="h6" fontWeight="bold" fontSize={{xs: '1rem', md: '1.1rem'}}>
                  Linked Vehicles
                </Typography>
                {linkedVehicles.length > 0 && (
                  <Button
                    variant="outlined"
                    startIcon={<Download />}
                    onClick={generateLinkedVehiclesPDF}
                    size="small"
                  >
                    Download PDF
                  </Button>
                )}
              </Box>
              
              {linkedVehicles.length > 0 ? (
                <Grid container spacing={2}>
                  {linkedVehicles.map((vehicle: Vehicle) => (
                    <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
                      <Card 
                        sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          flexDirection: 'column',
                          position: 'relative',
                        }}
                      >
                        <VehicleImage vehicle={vehicle} />
                        
                        <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                          <Typography variant="h6" component="div" gutterBottom>
                            {vehicle.year} {vehicle.make} {vehicle.model}
                          </Typography>
                          
                          <Typography variant="body2" gutterBottom>
                            {vehicle.type} {vehicle.size && `- ${vehicle.size}`}
                          </Typography>
                          
                          {/* Stock Number and VIN */}
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" gutterBottom>
                              Stock #: {vehicle.stock_number || 'N/A'}
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ 
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}>
                              VIN: {vehicle.vin || 'N/A'}
                            </Typography>
                          </Box>
                          
                          {/* Status */}
                          <Box sx={{ mb: 1 }}>
                            <Chip 
                              label={vehicle.status || 'Unknown'} 
                              size="small" 
                              color={
                                vehicle.status === 'Available' ? 'success' :
                                vehicle.status === 'Pending Available' ? 'info' :
                                vehicle.status === 'Pending Sale' ? 'warning' :
                                vehicle.status === 'Sold' ? 'error' :
                                'default'
                              }
                              sx={{ mb: 0.5 }}
                            />
                            {vehicle.who && (
                              <Box sx={{ mt: 0.5 }}>
                                <Chip 
                                  label={vehicle.who}
                                  size="small" 
                                  variant="outlined"
                                  color="secondary"
                                  sx={{ maxWidth: '100%' }}
                                />
                              </Box>
                            )}
                          </Box>
                          
                          <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                            {vehicle.miles && (
                              <Chip 
                                icon={<SpeedIcon fontSize="small" />} 
                                label={formatDistance(vehicle.miles)} 
                                size="small" 
                                variant="outlined"
                              />
                            )}
                            {vehicle.fuel && (
                              <Chip 
                                icon={<LocalGasStation fontSize="small" />} 
                                label={vehicle.fuel} 
                                size="small" 
                                variant="outlined"
                              />
                            )}
                            {vehicle.condition && (
                              <Chip 
                                icon={<LocalShipping fontSize="small" />} 
                                label={vehicle.condition} 
                                size="small" 
                                variant="outlined"
                              />
                            )}
                          </Box>
                          
                          <Divider sx={{ my: 1 }} />
                          
                          {/* Price and Profit Information */}
                          <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            {vehicle.optic_list_price && (
                              <Typography variant="h6" color="primary.main">
                                {formatPrice(vehicle.optic_list_price)}
                              </Typography>
                            )}
                            {vehicle.profit !== null && vehicle.profit !== undefined && (
                              <Typography 
                                variant="body2" 
                                fontWeight="bold"
                                sx={{ 
                                  color: vehicle.profit > 5000 ? 'success.main' : 
                                         vehicle.profit >= 0 ? 'warning.main' : 
                                         'error.main'
                                }}
                              >
                                Profit: {formatPrice(vehicle.profit)}
                              </Typography>
                            )}
                          </Box>
                        </CardContent>
                        
                        <CardActions sx={{ pt: 0, justifyContent: 'center' }}>
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<RemoveCircle />}
                            onClick={() => removeVehicleFromLead(vehicle)}
                            disabled={attachingVehicle === vehicle.id}
                            fullWidth
                            sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                          >
                            {attachingVehicle === vehicle.id ? 'Removing...' : 'Remove'}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No vehicles linked to this lead
                </Typography>
              )}
            </Paper>

            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" fontWeight="bold" gutterBottom fontSize={{xs: '1rem', md: '1.1rem'}}>
                Linked Applications
              </Typography>
              {lead.applicationIds && lead.applicationIds.length > 0 ? (
                <List>
                  {lead.applicationIds.map((appId) => (
                    <ListItem key={appId} divider>
                      <ListItemText
                        primary={
                          <Typography variant="body1">
                            Application ID: {appId}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No applications linked to this lead
                </Typography>
              )}
            </Paper>
          </Stack>
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <Box sx={{ position: 'relative' }}>
            {/* Sticky container for headings - adjusted to be responsive */}
            <Box sx={{ 
              position: { xs: 'static', md: 'sticky' }, // Only sticky on md and up
              top: 0,
              zIndex: 10,
              bgcolor: 'background.paper',
              pb: 1.5,
              pt: 0,
              mx: -3, // Negative margin to extend beyond the TabPanel padding
              px: 3,  // Add padding back to match the TabPanel
              width: 'calc(100% + 48px)', // Ensure it spans the full width (accounting for TabPanel padding)
              boxShadow: { xs: 'none', md: '0 2px 4px rgba(0,0,0,0.05)' } // Only show shadow when sticky
            }}>
              {/* Truck type counters with toggle switches */}
              <Box sx={{ mb: 1.5 }}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1
                }}>
                  <Typography variant="h6" fontSize={{xs: '1rem', md: '1.1rem'}} sx={{ mt: 0 }}>
                    Desired Trucks:
                  </Typography>
                </Box>
                
                <Box sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 1.5,
                  justifyContent: { xs: 'center', sm: 'flex-start' }
                }}>
                  {calculateTruckTypeCounts().map((count) => (
                    <Box
                      key={count.typeKey}
                      onClick={() => handleTruckTypeFilterChange(count.typeKey)}
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        px: 1,
                        py: 0.75,
                        border: 2,
                        borderColor: count.fulfilled 
                          ? "success.main" 
                          : "primary.main",
                        borderRadius: 1,
                        width: { xs: '100%', md: 'auto' },
                        cursor: 'pointer',
                        opacity: selectedTruckTypes.includes(count.typeKey) ? 1 : 0.6,
                        transition: 'opacity 0.2s ease',
                        '&:hover': {
                          opacity: 0.8
                        }
                      }}
                    >
                      <Checkbox 
                        checked={selectedTruckTypes.includes(count.typeKey)}
                        onChange={(e) => {
                          e.stopPropagation(); // Stop event from propagating to parent
                          handleTruckTypeFilterChange(count.typeKey);
                        }}
                        onClick={(e) => e.stopPropagation()} // Also stop click event propagation
                        size="small"
                        sx={{ 
                          p: 0.5, 
                          mr: 0.5,
                          color: count.fulfilled ? "success.main" : "primary.main"
                        }}
                      />
                      <Typography 
                        variant="h6" 
                        color={count.fulfilled 
                          ? "success.main" 
                          : "primary.main"
                        }
                        sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                      >
                        {count.displayName}: {count.attached}/{count.requested}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Match summary - reduced vertical spacing */}
              <Box sx={{ 
                mb: 1, 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' }, 
                gap: 1.5 
              }}>
                <Typography variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>
                  Found {filteredVehicles.length} matching trucks:
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap',
                  gap: 1.5,
                  mt: { xs: 0.5, sm: 0 }
                }}>
                  <Chip 
                    icon={<CheckCircle fontSize="small" color="success" />}
                    label={
                      <Typography variant="h6" sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}>
                        {exactMatches.length} Exact Matches
                      </Typography>
                    }
                    color="success"
                    variant="outlined"
                    sx={{ height: 'auto', py: 0.5 }}
                  />
                  <Chip 
                    icon={<RadioButtonUnchecked fontSize="small" color="primary" />}
                    label={
                      <Typography variant="h6" sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}>
                        {similarMatches.length} Similar Matches
                      </Typography>
                    }
                    color="primary"
                    variant="outlined"
                    sx={{ height: 'auto', py: 0.5 }}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<Download />}
                    onClick={generatePDF}
                    disabled={filteredVehicles.length === 0}
                    size="small"
                  >
                    Download PDF
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* Add a small spacer to prevent content from jumping under the sticky header - only on md and up */}
            <Box sx={{ height: { xs: 0, md: 1 } }} />

            {/* Suggested vehicles grid - use filteredVehicles instead of suggestedVehicles */}
            {isLoadingVehicles ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
              </Box>
            ) : filteredVehicles.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography variant="h6" color="text.secondary">
                  No matching vehicles found
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {filteredVehicles.map(vehicle => (
                  <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
                    <Card 
                      sx={{ 
                        height: '100%', 
                        display: 'flex', 
                        flexDirection: 'column',
                        position: 'relative',
                        border: '4px solid',
                        borderColor: vehicle.similarityLevel === 'exact' 
                          ? 'success.main' 
                          : vehicle.similarityLevel === 'similar' 
                            ? 'primary.main' 
                            : 'grey.400'
                      }}
                    >
                      {/* Similarity badge */}
                      <Box 
                        sx={{ 
                          position: 'absolute', 
                          top: 8, 
                          right: 8, 
                          zIndex: 1,
                          bgcolor: 'background.paper',
                          borderRadius: '16px',
                          px: 1,
                          py: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          boxShadow: 1
                        }}
                      >
                        {getSimilarityIcon(vehicle.similarityLevel)}
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            ml: 0.5, 
                            fontWeight: 'bold',
                            color: vehicle.similarityLevel === 'exact' ? 'success.main' : 'primary.main',
                            fontSize: { xs: '0.7rem', md: '0.8rem' } 
                          }}
                        >
                          {getSimilarityLabel(vehicle.similarityLevel)}
                        </Typography>
                      </Box>
                      
                      <VehicleImage vehicle={vehicle} />
                      
                      <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                        <Typography variant="h6" component="div" gutterBottom>
                          {vehicle.year} {vehicle.make} {vehicle.model}
                        </Typography>
                        
                        <Typography variant="body2" gutterBottom>
                          {vehicle.type} {vehicle.size && `- ${vehicle.size}`}
                        </Typography>
                        
                        {/* Stock Number and VIN */}
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body2" gutterBottom>
                            Stock #: {vehicle.stock_number || 'N/A'}
                          </Typography>
                          <Typography variant="body2" gutterBottom sx={{ 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                            VIN: {vehicle.vin || 'N/A'}
                          </Typography>
                        </Box>
                        
                        {/* Status */}
                        <Box sx={{ mb: 1 }}>
                          <Chip 
                            label={vehicle.status || 'Unknown'} 
                            size="small" 
                            color={
                              vehicle.status === 'Available' ? 'success' :
                              vehicle.status === 'Pending Available' ? 'info' :
                              vehicle.status === 'Pending Sale' ? 'warning' :
                              vehicle.status === 'Sold' ? 'error' :
                              'default'
                            }
                            sx={{ mb: 0.5 }}
                          />
                          {vehicle.who && (
                            <Box sx={{ mt: 0.5 }}>
                              <Chip 
                                label={vehicle.who} 
                                size="small" 
                                variant="outlined"
                                color="secondary"
                                sx={{ maxWidth: '100%' }}
                              />
                            </Box>
                          )}
                        </Box>
                        
                        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                          {vehicle.miles && (
                            <Chip 
                              icon={<SpeedIcon fontSize="small" />} 
                              label={formatDistance(vehicle.miles)} 
                              size="small" 
                              variant="outlined"
                            />
                          )}
                          {vehicle.fuel && (
                            <Chip 
                              icon={<LocalGasStation fontSize="small" />} 
                              label={vehicle.fuel} 
                              size="small" 
                              variant="outlined"
                            />
                          )}
                          {vehicle.condition && (
                            <Chip 
                              icon={<LocalShipping fontSize="small" />} 
                              label={vehicle.condition} 
                              size="small" 
                              variant="outlined"
                            />
                          )}
                        </Box>
                        
                        <Divider sx={{ my: 1 }} />
                        
                        {/* Price and Profit Information */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                          {vehicle.optic_list_price && (
                            <Typography variant="h6" color="primary.main">
                              {formatPrice(vehicle.optic_list_price)}
                            </Typography>
                          )}
                          {vehicle.profit !== null && vehicle.profit !== undefined && (
                            <Typography 
                              variant="body2" 
                              fontWeight="bold"
                              sx={{ 
                                color: vehicle.profit > 5000 ? 'success.main' : 
                                       vehicle.profit >= 0 ? 'warning.main' : 
                                       'error.main'
                              }}
                            >
                              Profit: {formatPrice(vehicle.profit)}
                            </Typography>
                          )}
                        </Box>
                      </CardContent>
                      
                      <CardActions sx={{ pt: 0, justifyContent: 'center' }}>
                        {attachedVehicleIds.includes(vehicle.id) ? (
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<RemoveCircle />}
                            onClick={() => removeVehicleFromLead(vehicle)}
                            disabled={attachingVehicle === vehicle.id}
                            fullWidth
                            sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                          >
                            {attachingVehicle === vehicle.id ? 'Removing...' : 'Remove'}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<Inventory />}
                            onClick={() => attachVehicleToLead(vehicle)}
                            disabled={attachingVehicle === vehicle.id}
                            fullWidth
                            sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                          >
                            {attachingVehicle === vehicle.id ? 'Attaching...' : 'Attach to Lead'}
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </TabPanel>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color="error" sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadDetailDialog; 