// web/app/src/views/SuperAdmin/Management/LeadManagement/components/TruckRequestForm.tsx
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { TruckRequest, TruckType } from '@otw/models/leads/lead'
import {
  getVehicleModelOptions,
  getVehicleSizeOptions,
  makeOptions,
  vehicleFuelOptions,
  vehicleTypeOptions,
  conditionOptions,
} from 'core/constants/inventory'
import React, { useEffect, useState } from 'react'
import { Control, Controller, FieldErrors, UseFormRegister, useWatch, UseFormSetValue } from 'react-hook-form'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

interface TruckRequestFormProps {
  register: UseFormRegister<any>
  errors?: FieldErrors<TruckRequest>
  control?: Control<any>
  setValue?: UseFormSetValue<any>
}

export const TruckRequestForm: React.FC<TruckRequestFormProps> = ({ 
  register, 
  errors, 
  control,
  setValue 
}) => {
  const [truckTypes, setTruckTypes] = useState<(TruckType & { id: string })[]>([]);
  const [initialized, setInitialized] = useState(false);

  // Watch the entire truck types array
  const watchedTypes = control ? useWatch({
    control,
    name: 'truckRequest.types',
    defaultValue: []
  }) : [];

  // Watch all makes for all truck types at once
  const allMakesForTypes = control ? useWatch({
    control,
    name: 'truckRequest.types',
    defaultValue: []
  }) : [];

  // Log the watched types for debugging
  useEffect(() => {
    console.log('Watched truck types in TruckRequestForm:', watchedTypes);
  }, [watchedTypes]);

  // Update local state from form state without auto-initializing
  useEffect(() => {
    if (Array.isArray(watchedTypes)) {
      console.log('Watched truck types:', watchedTypes);
      
      if (watchedTypes.length > 0) {
        // Only update local state if there are actual truck types in the form
        setTruckTypes(watchedTypes.map((type, index) => ({
          ...type,
          id: (index + 1).toString()
        })));
        setInitialized(true);
      } else if (!initialized) {
        // If there are no truck types and we haven't initialized yet,
        // add a default empty truck type
        addTruckType();
      }
    }
  }, [watchedTypes, initialized]);

  // Pre-calculate available models for all truck types
  const availableModelsForTypes = React.useMemo(() => {
    if (!allMakesForTypes || !Array.isArray(allMakesForTypes)) return [];
    
    return allMakesForTypes.map(truckType => {
      const makes = truckType?.make || '';
      // Handle both string and array formats for backward compatibility
      const makesList = Array.isArray(makes) ? makes : (makes ? [makes] : []);
      
      if (makesList.length > 0) {
        // Get models for all selected makes
        let allModels: string[] = []; // Explicitly type as string array
        for (const make of makesList) {
          if (make && make !== 'Any') {
            const models = getVehicleModelOptions(make);
            allModels = [...allModels, ...models];
          }
        }
        
        // Add "Any" option and remove duplicates
        return [...new Set([...allModels, 'Any'])];
      }
      return [];
    });
  }, [allMakesForTypes]);

  // Function to get available sizes based on truck type
  const getAvailableSizesByType = (type: string): string[] => {
    if (!type) return [];
    
    // Get sizes from inventory.ts and add "Any" option
    const sizes = getVehicleSizeOptions(type);
    return [...sizes, 'Any'];
  };

  const addTruckType = () => {
    const newTruckType: TruckType & { id: string } = { 
      id: (truckTypes.length + 1).toString(), 
      type: '', 
      size: '', 
      condition: null, 
      quantity: 1, 
      make: [], // Initialize as array for multiple selection
      model: [], // Initialize as array for multiple selection
      minYear: undefined,
      maxYear: undefined,
      fuelType: [], // Initialize as array for multiple selection
      maxMileage: undefined,
      budget: undefined 
    };
    
    const newTypes = [...truckTypes, newTruckType];
    setTruckTypes(newTypes);
    setInitialized(true);
    
    if (setValue) {
      // Update the form with the new entry (without the id field)
      const formValues = newTypes.map(({ id, ...rest }) => rest);
      setValue('truckRequest.types', formValues);
    }
  };

  const removeTruckType = (id: string) => {
    if (truckTypes.length > 1) {
      const newTypes = truckTypes.filter(entry => entry.id !== id);
      setTruckTypes(newTypes);
      
      if (control) {
        // Update the form with the remaining entries (without the id field)
        const formValues = newTypes.map(({ id, ...rest }) => rest);
        (control as any).setValue('truckRequest.types', formValues);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      {truckTypes.length === 0 ? (
        <Grid item xs={12}>
          <Box sx={{ 
            p: 3, 
            border: '1px dashed', 
            borderColor: 'divider', 
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              No truck types added yet
            </Typography>
            <Button 
              startIcon={<AddIcon />} 
              onClick={addTruckType}
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              Add Truck Type
            </Button>
          </Box>
        </Grid>
      ) : (
        <>
          {truckTypes.map((entry, index) => {
            // Get available sizes for this truck type
            const currentType = watchedTypes[index]?.type || '';
            const availableSizes = getAvailableSizesByType(currentType);
            
            // Get available models for this truck type from pre-calculated array
            const availableModelsForType = availableModelsForTypes[index] || [];
            
            return (
              <React.Fragment key={entry.id}>
                <Grid item xs={12}>
                  <Box sx={{ 
                    p: 2, 
                    border: '1px solid', 
                    borderColor: 'divider', 
                    borderRadius: 1,
                    position: 'relative',
                    mb: 2
                  }}>
                    {index > 0 && (
                      <IconButton 
                        size="small" 
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => removeTruckType(entry.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                    
                    <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1.2rem'}, mb: 2 }} gutterBottom>
                      Truck Type {index + 1}
                    </Typography>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        {control ? (
                          <Controller
                            name={`truckRequest.types[${index}].type`}
                            control={control}
                            defaultValue={entry.type}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors?.types?.[index]?.type}>
                                <InputLabel id={`truck-type-label-${index}`}>Type</InputLabel>
                                <Select 
                                  {...field} 
                                  labelId={`truck-type-label-${index}`} 
                                  label= "Type"
                                  sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                                >
                                  {vehicleTypeOptions.map(type => (
                                    <MenuItem key={type} value={type} sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.types?.[index]?.type && (
                                  <FormHelperText>
                                    {(errors.types?.[index]?.type as any)?.message ?? String(errors.types[index].type)}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        ) : (
                          <TextField
                            {...register(`truckRequest.types[${index}].type`)}
                            label='Type'
                            fullWidth
                            error={!!errors?.types?.[index]?.type}
                            helperText={(errors?.types?.[index]?.type as any)?.message ?? (errors?.types?.[index]?.type ? String(errors?.types?.[index]?.type) : '')}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {control ? (
                          <Controller
                            name={`truckRequest.types[${index}].size`}
                            control={control}
                            defaultValue={entry.size}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors?.types?.[index]?.size} disabled={!currentType}>
                                <InputLabel id={`vehicle-size-label-${index}`}>Vehicle Size</InputLabel>
                                <Select 
                                  {...field} 
                                  labelId={`vehicle-size-label-${index}`} 
                                  label='Vehicle Size'
                                  sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                                >
                                  {availableSizes.map(size => (
                                    <MenuItem key={size} value={size} sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                      {size}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.types?.[index]?.size && (
                                  <FormHelperText error>
                                    {(errors.types?.[index]?.size as any)?.message ?? String(errors.types[index].size)}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        ) : (
                          <TextField
                            {...register(`truckRequest.types[${index}].size`)}
                            label='Vehicle Size'
                            fullWidth
                            error={!!errors?.types?.[index]?.size}
                            helperText={(errors?.types?.[index]?.size as any)?.message ?? (errors?.types?.[index]?.size ? String(errors?.types?.[index]?.size) : '')}
                            disabled={!currentType}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {control ? (
                          <Controller
                            name={`truckRequest.types[${index}].condition`}
                            control={control}
                            defaultValue={entry.condition}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors?.types?.[index]?.condition}>
                                <InputLabel id={`condition-label-${index}`}>Condition</InputLabel>
                                <Select {...field} labelId={`condition-label-${index}`} label='Condition' sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                  {conditionOptions.map(condition => (
                                    <MenuItem key={condition} value={condition} sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                      {condition}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.types?.[index]?.condition && (
                                  <FormHelperText>
                                    {(errors.types?.[index]?.condition as any)?.message ?? String(errors.types[index].condition)}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        ) : (
                          <TextField
                            {...register(`truckRequest.types[${index}].condition`)}
                            label='Condition'
                            fullWidth
                            error={!!errors?.types?.[index]?.condition}
                            helperText={(errors?.types?.[index]?.condition as any)?.message ?? (errors?.types?.[index]?.condition ? String(errors?.types?.[index]?.condition) : '')}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          {...register(`truckRequest.types[${index}].quantity`, {
                            valueAsNumber: true,
                            min: 1,
                          })}
                          label='Quantity'
                          type='number'
                          fullWidth
                          defaultValue={entry.quantity}
                          error={!!errors?.types?.[index]?.quantity}
                          helperText={(errors?.types?.[index]?.quantity as any)?.message ?? (errors?.types?.[index]?.quantity ? String(errors?.types?.[index]?.quantity) : '')}
                          InputProps={{ 
                            inputProps: { min: 1 },
                            sx: { fontSize: {xs: '0.8rem', md: '1rem'} }
                          }}
                          sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                        />
                      </Grid>
                      
                      {/* Make Preference - Multi-select */}
                      <Grid item xs={12} md={6}>
                        {control && (
                          <Controller
                            name={`truckRequest.types[${index}].make`}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors?.types?.[index]?.make}>
                                <InputLabel id={`make-label-${index}`}>Makes</InputLabel>
                                <Select
                                  {...field}
                                  labelId={`make-label-${index}`}
                                  label="Makes"
                                  multiple
                                  value={Array.isArray(field.value) ? field.value : (field.value ? [field.value] : [])}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {(selected as string[]).map((value) => (
                                        <Chip key={value} label={value} size="small" sx={{ fontSize: {xs: '0.7rem', md: '0.8rem'} }} />
                                      ))}
                                    </Box>
                                  )}
                                  sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                                >
                                  {makeOptions.map(make => (
                                    <MenuItem key={make} value={make} sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                      {make}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.types?.[index]?.make && (
                                  <FormHelperText>
                                    {(errors.types?.[index]?.make as any)?.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        )}
                      </Grid>

                      {/* Model Preference - Multi-select */}
                      <Grid item xs={12} md={6}>
                        {control && (
                          <Controller
                            name={`truckRequest.types[${index}].model`}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors?.types?.[index]?.model} disabled={availableModelsForType.length === 0}>
                                <InputLabel id={`model-label-${index}`}>Models</InputLabel>
                                <Select
                                  {...field}
                                  labelId={`model-label-${index}`}
                                  label="Models"
                                  multiple
                                  value={Array.isArray(field.value) ? field.value : (field.value ? [field.value] : [])}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {(selected as string[]).map((value) => (
                                        <Chip key={value} label={value} size="small" sx={{ fontSize: {xs: '0.7rem', md: '0.8rem'} }} />
                                      ))}
                                    </Box>
                                  )}
                                  sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                                >
                                  {availableModelsForType.map(model => (
                                    <MenuItem key={model} value={model} sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                      {model}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.types?.[index]?.model && (
                                  <FormHelperText>
                                    {(errors.types?.[index]?.model as any)?.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        )}
                      </Grid>
                      
                      {/* Year Range Fields - moved from Year Range section */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...register(`truckRequest.types[${index}].minYear`, {
                            valueAsNumber: true,
                          })}
                          label='Min Year'
                          type='number'
                          fullWidth
                          error={!!errors?.types?.[index]?.minYear}
                          helperText={(errors?.types?.[index]?.minYear as any)?.message ?? (errors?.types?.[index]?.minYear ? String(errors?.types?.[index]?.minYear) : '')}
                          InputProps={{ 
                            inputProps: { 
                              min: 1990, 
                              max: new Date().getFullYear() + 1 
                            },
                            sx: { fontSize: {xs: '0.8rem', md: '1rem'} }
                          }}
                          sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          {...register(`truckRequest.types[${index}].maxYear`, {
                            valueAsNumber: true,
                          })}
                          label='Max Year'
                          type='number'
                          fullWidth
                          error={!!errors?.types?.[index]?.maxYear}
                          helperText={(errors?.types?.[index]?.maxYear as any)?.message ?? (errors?.types?.[index]?.maxYear ? String(errors?.types?.[index]?.maxYear) : '')}
                          InputProps={{ 
                            inputProps: { 
                              min: 1990, 
                              max: new Date().getFullYear() + 1 
                            },
                            sx: { fontSize: {xs: '0.8rem', md: '1rem'} }
                          }}
                          sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                        />
                      </Grid>
                      
                      {/* Fuel Type Field - Multi-select */}
                      <Grid item xs={12} md={6}>
                        {control && (
                          <Controller
                            name={`truckRequest.types[${index}].fuelType`}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors?.types?.[index]?.fuelType}>
                                <InputLabel id={`fuel-type-label-${index}`}>Fuel Types</InputLabel>
                                <Select
                                  {...field}
                                  labelId={`fuel-type-label-${index}`}
                                  label="Fuel Types"
                                  multiple
                                  value={Array.isArray(field.value) ? field.value : (field.value ? [field.value] : [])}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {(selected as string[]).map((value) => (
                                        <Chip key={value} label={value} size="small" sx={{ fontSize: {xs: '0.7rem', md: '0.8rem'} }} />
                                      ))}
                                    </Box>
                                  )}
                                  sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                                >
                                  {vehicleFuelOptions.map(fuelType => (
                                    <MenuItem key={fuelType} value={fuelType} sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                                      {fuelType}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.types?.[index]?.fuelType && (
                                  <FormHelperText>
                                    {(errors.types?.[index]?.fuelType as any)?.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        )}
                      </Grid>

                      {/* Max Mileage Field */}
                      <Grid item xs={12} md={6}>
                        <Controller
                          name={`truckRequest.types[${index}].maxMileage`}
                          control={control}
                          defaultValue={undefined}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={field.value === null || field.value === undefined || isNaN(field.value) 
                                ? '' 
                                : Number(field.value).toLocaleString('en-US')}
                              label='Max Mileage'
                              fullWidth
                              error={!!errors?.types?.[index]?.maxMileage}
                              helperText={(errors?.types?.[index]?.maxMileage as any)?.message ?? (errors?.types?.[index]?.maxMileage ? String(errors?.types?.[index]?.maxMileage) : '')}
                              InputProps={{
                                sx: { fontSize: {xs: '0.8rem', md: '1rem'} }
                              }}
                              sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                              onChange={(e) => {
                                // Remove non-digit and comma characters
                                let value = e.target.value.replace(/[^\d,]/g, '');
                                // Remove existing commas for processing
                                value = value.replace(/,/g, '');
                                
                                if (value === '') {
                                  field.onChange(undefined);
                                } else {
                                  // Store the raw number in the form state
                                  field.onChange(Number(value));
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      
                      {/* Budget Field - now full width */}
                      <Grid item xs={12}>
                        <TextField
                          {...register(`truckRequest.types[${index}].budget`)}
                          label='Budget'
                          fullWidth
                          error={!!errors?.types?.[index]?.budget}
                          helperText={(errors?.types?.[index]?.budget as any)?.message ?? (errors?.types?.[index]?.budget ? String(errors?.types?.[index]?.budget) : '')}
                          InputProps={{
                            startAdornment: <span style={{ marginRight: 8 }}>$</span>,
                            sx: { fontSize: {xs: '0.8rem', md: '1rem'} }
                          }}
                          sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            let value = e.target.value.replace(/[^\d,]/g, '');
                            value = value.replace(/,/g, '');
                            if (value) {
                              value = Number(value).toLocaleString('en-US');
                            }
                            if (setValue) {
                              setValue(`truckRequest.types[${index}].budget`, value);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </React.Fragment>
            )
          })}
        </>
      )}
      
      {/* Add Truck Type Button */}
      {truckTypes.length > 0 && (
        <Grid item xs={12}>
          <Button 
            startIcon={<AddIcon />} 
            onClick={addTruckType}
            variant="outlined"
            color="primary"
            sx={{ fontSize: {xs: '0.8rem', md: '1rem'}, mt: -2 }}
          >
            Add Another Truck Type
          </Button>
        </Grid>
      )}
      
      {/* Notes Field */}
      <Grid item xs={12}>
        <TextField
          {...register('truckRequest.notes')}
          label="Additional Notes"
          multiline
          rows={4}
          fullWidth
          InputProps={{
            sx: { fontSize: {xs: '0.8rem', md: '1rem'} }
          }}
          sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}
        />
      </Grid>
    </Grid>
  )
}