import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import { PrivateUserInfoType } from '@otw/models'
import { useQueryClient } from '@tanstack/react-query'
import { useManageUsers } from 'hooks/useManageUsers'
import React, { useEffect, useMemo, useState } from 'react'
import { UserSelector } from 'views/Shared/UserSelector'

interface SoldDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (soldToUser: { id: string; name: string }, soldByUser: { id: string; name: string }) => void
  itemName?: string
}

const SoldDialog: React.FC<SoldDialogProps> = ({ open, onClose, onSubmit, itemName }) => {
  const [salesRepSearchQuery, setSalesRepSearchQuery] = useState('')
  const queryClient = useQueryClient()
  const { users, isLoading } = useManageUsers()
  const [selectedClient, setSelectedClient] = useState<PrivateUserInfoType | null>(null)
  const [selectedSalesRep, setSelectedSalesRep] = useState<PrivateUserInfoType | null>(null)
  const [activeTab, setActiveTab] = useState(0)
  const [salesRepType, setSalesRepType] = useState<'house' | 'representative'>('house')
  const [showSalesRepList, setShowSalesRepList] = useState(false)

  // Add filtered sales reps logic
  const filteredSalesReps = useMemo(() => {
    // Ensure users is an array
    if (!Array.isArray(users)) return []

    return users.filter(user => {
      const searchLower = salesRepSearchQuery.toLowerCase()
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase()
      const email = user.email?.toLowerCase() || ''
      const isSalesRep = user.role === 'salesAgent' || user.email === 'Scott@optictruckworks.com' // temporary fix until salesAgent properly identified

      return isSalesRep && (fullName.includes(searchLower) || email.includes(searchLower))
    })
  }, [users, salesRepSearchQuery])

  const handleClientSelect = (user: PrivateUserInfoType) => {
    setSelectedClient(user)
    setActiveTab(1) // Move to sales rep tab after selecting client
  }

  const handleSalesRepSelect = (user: PrivateUserInfoType) => {
    setSelectedSalesRep(user)
  }

  const handleSalesRepTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as 'house' | 'representative'
    setSalesRepType(value)

    if (value === 'house') {
      setSelectedSalesRep(null)
      setShowSalesRepList(false)
    } else {
      setShowSalesRepList(true)
    }
  }

  const handleSubmit = () => {
    if (selectedClient) {
      const soldToUser = {
        id: selectedClient.id,
        name: `${selectedClient.first_name} ${selectedClient.last_name}`,
      }

      // Use "House" as the default if salesRepType is 'house'
      const soldByUser =
        salesRepType === 'representative' && selectedSalesRep
          ? {
              id: selectedSalesRep.id,
              name: `${selectedSalesRep.first_name} ${selectedSalesRep.last_name}`,
            }
          : {
              id: 'house',
              name: 'House',
            }

      onSubmit(soldToUser, soldByUser)
      onClose()
    }
  }

  // Add observer for user creation events
  useEffect(() => {
    const handleUserCreated = () => {
      // Use React Query's invalidation instead of a direct refetch
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }

    window.addEventListener('userCreated', handleUserCreated)
    return () => {
      window.removeEventListener('userCreated', handleUserCreated)
    }
  }, [queryClient])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      keepMounted={false}
      disableRestoreFocus
      PaperProps={{
        sx: {
          height: {
            xs: '90vh', // 90% of viewport height on mobile
            sm: '70vh', // 70% of viewport height on tablet/desktop
          },
          maxHeight: '600px', // Maximum height cap
        },
      }}
    >
      <DialogTitle>Mark Item as Sold</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: { xs: 1, sm: 2 }, // Responsive padding
        }}
      >
        {itemName && (
          <Typography variant='body1' sx={{ mb: 2 }}>
            Item: {itemName}
          </Typography>
        )}

        <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label='Select Client' />
          <Tab label='Select Sales Rep' disabled={!selectedClient} />
        </Tabs>

        {activeTab === 0 && (
          <>
            {selectedClient && (
              <Box sx={{ mb: 2, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
                <Typography variant='subtitle1'>Selected Client:</Typography>
                <Typography variant='body1'>
                  {selectedClient.first_name} {selectedClient.last_name}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {selectedClient.email}
                </Typography>
              </Box>
            )}
            <UserSelector
              onUserSelect={handleClientSelect}
              title='Select Client'
              placeholder='Search clients...'
              showCreateButton={true}
            />
          </>
        )}

        {activeTab === 1 && (
          <>
            <FormControl component='fieldset' sx={{ mb: 2 }}>
              <FormLabel component='legend'>Sales Representative Type</FormLabel>
              <RadioGroup row name='salesRepType' value={salesRepType} onChange={handleSalesRepTypeChange}>
                <FormControlLabel value='house' control={<Radio />} label='House' />
                <FormControlLabel value='representative' control={<Radio />} label='Sales Representative' />
              </RadioGroup>
            </FormControl>

            {salesRepType === 'house' && (
              <Box sx={{ mb: 2, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
                <Typography variant='subtitle1'>Selected Sales Rep:</Typography>
                <Typography variant='body1'>House</Typography>
              </Box>
            )}

            {salesRepType === 'representative' && (
              <>
                <TextField
                  autoFocus={salesRepType === 'representative'}
                  margin='dense'
                  fullWidth
                  value={salesRepSearchQuery}
                  onChange={e => setSalesRepSearchQuery(e.target.value)}
                  placeholder='Search sales representatives...'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />
                {!selectedSalesRep && (
                  <Typography variant='body2' color='error' sx={{ mb: 2 }}>
                    Please select a sales representative to continue
                  </Typography>
                )}
                {selectedSalesRep && (
                  <Box sx={{ mb: 2, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
                    <Typography variant='subtitle1'>Selected Sales Rep:</Typography>
                    <Typography variant='body1'>
                      {selectedSalesRep.first_name} {selectedSalesRep.last_name}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {selectedSalesRep.email}
                    </Typography>
                  </Box>
                )}
                {isLoading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <List
                    sx={{
                      flex: 1,
                      bgcolor: 'background.paper',
                      borderRadius: 1,
                      border: 1,
                      borderColor: 'divider',
                      overflow: 'auto',
                      '& .MuiListItem-root': {
                        height: {
                          xs: '60px', // Smaller height on mobile
                          sm: '72px', // Regular height on tablet/desktop
                        },
                      },
                    }}
                  >
                    {filteredSalesReps.length > 0 ? (
                      filteredSalesReps.slice(0, 5).map((salesRep: PrivateUserInfoType) => (
                        <ListItem
                          key={salesRep.firestoreDocId}
                          disablePadding
                          sx={{
                            '&:hover': {
                              bgcolor: 'action.hover',
                            },
                          }}
                        >
                          <ListItemButton onClick={() => handleSalesRepSelect(salesRep)}>
                            <ListItemText
                              primary={
                                <Typography variant='body1' noWrap>
                                  {salesRep.first_name + ' ' + salesRep.last_name || 'Unnamed'}
                                </Typography>
                              }
                              secondary={
                                <Typography variant='body2' color='text.secondary' noWrap>
                                  {salesRep.email} ({salesRep.role})
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary='No sales representatives found'
                          secondary='Try adjusting your search terms'
                        />
                      </ListItem>
                    )}
                  </List>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: { xs: 1, sm: 2 } }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          disabled={!selectedClient || (salesRepType === 'representative' && !selectedSalesRep)}
        >
          Mark as Sold
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SoldDialog
