import styled from '@emotion/styled'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PendingIcon from '@mui/icons-material/Pending'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import SearchIcon from '@mui/icons-material/Search'
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material'
import { BarChart } from '@mui/x-charts'
import { Vehicle } from '@otw/models'
import { Application, ApplicationStatus } from '@otw/models/application/application'
import dayjs from 'dayjs'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useMemo, useState } from 'react'

interface ApplicationsMonitorProps {
  applications?: Application[]
  dateRange?: { start: Date; end: Date }
  initialView?: 'recent' | 'top'
}

// Status color mapping based on ApplicationManagement.tsx
const getStatusColor = (status: string) => {
  switch (status) {
    case 'ready':
      return '#2196F3' // Blue
    case 'corrections_needed':
      return '#F44336' // Red
    case 'pending':
      return '#FFA000' // Amber
    case 'viewed':
      return '#9C27B0' // Purple
    case 'approved':
      return '#4CAF50' // Green
    case 'denied':
      return '#D32F2F' // Dark Red
    case 'signing':
      return '#FF9800' // Orange
    case 'draft':
      return '#757575' // Grey
    default:
      return 'text.primary'
  }
}

// Status display names
const statusDisplayNames: Record<ApplicationStatus, string> = {
  signing: 'Signing',
  ready: 'Ready',
  draft: 'Draft',
  pending: 'Pending',
  viewed: 'Viewed',
  corrections_needed: 'Needs Corrections',
  approved: 'Approved',
  denied: 'Denied',
}

export const ApplicationsMonitor: React.FC<ApplicationsMonitorProps> = ({
  applications = [],
  dateRange = {
    start: dayjs().startOf('month').toDate(),
    end: dayjs().toDate(),
  },
  initialView = 'recent'
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [topApplicantsSearchTerm, setTopApplicantsSearchTerm] = useState('')
  const [mobileView, setMobileView] = useState<'recent' | 'top'>(initialView)
  const isMobile = useIsMobile()
  const theme = useTheme()


  // Filter applications based on search term
  const filteredApplications = useMemo(() => {
    if (!searchTerm) return applications

    const lowerSearchTerm = searchTerm.toLowerCase()
    return applications.filter(
      app =>
        (app.user_name && app.user_name.toLowerCase().includes(lowerSearchTerm)) ||
        (app.user_email && app.user_email.toLowerCase().includes(lowerSearchTerm)) ||
        statusDisplayNames[app.status].toLowerCase().includes(lowerSearchTerm),
    )
  }, [applications, searchTerm])

  // Count applications by status
  const statusCounts = useMemo(() => {
    const counts: Record<ApplicationStatus, number> = {
      signing: 0,
      ready: 0,
      draft: 0,
      pending: 0,
      viewed: 0,
      corrections_needed: 0,
      approved: 0,
      denied: 0,
    }

    applications.forEach(app => {
      counts[app.status]++
    })

    return counts
  }, [applications])

  // Count applications by user
  const applicationsByUser = useMemo(() => {
    return applications.reduce(
      (acc: Record<string, { count: number; statuses: Record<ApplicationStatus, number> }>, app) => {
        const userName = app.user_name || app.user_email || 'Unknown'

        if (!acc[userName]) {
          acc[userName] = {
            count: 0,
            statuses: {
              signing: 0,
              ready: 0,
              draft: 0,
              pending: 0,
              viewed: 0,
              corrections_needed: 0,
              approved: 0,
              denied: 0,
            },
          }
        }

        acc[userName].count++
        acc[userName].statuses[app.status]++

        return acc
      },
      {},
    )
  }, [applications])

  // Filter top applicants based on search term
  const filteredTopApplicants = useMemo(() => {
    if (!topApplicantsSearchTerm) {
      return Object.entries(applicationsByUser).sort(([_, a], [__, b]) => b.count - a.count)
    }

    const lowerSearchTerm = topApplicantsSearchTerm.toLowerCase()
    return Object.entries(applicationsByUser)
      .filter(([user]) => user.toLowerCase().includes(lowerSearchTerm))
      .sort(([_, a], [__, b]) => b.count - a.count)
  }, [applicationsByUser, topApplicantsSearchTerm])

  // Get total applications count
  const totalApplications = applications.length

  // Get active applications (not approved or denied)
  const activeApplications = useMemo(() => {
    return filteredApplications.filter(app => app.status !== 'approved' && app.status !== 'denied')
  }, [filteredApplications])

  // Get applications that need attention
  const needsAttentionApplications = useMemo(() => {
    return filteredApplications.filter(app => app.status === 'corrections_needed' || app.status === 'pending')
  }, [filteredApplications])

  // Get new applications within the date range
  const newApplicationsInRange = useMemo(() => {
    return applications.filter(app => {
      const creationDate = new Date(app.time_created)
      return creationDate >= dateRange.start && creationDate <= dateRange.end
    })
  }, [applications, dateRange])

  // Calculate applications by day for the selected period
  const applicationsByDay = useMemo(() => {
    const dayMap: Record<string, number> = {}
    const days = Math.ceil((dateRange.end.getTime() - dateRange.start.getTime()) / (1000 * 60 * 60 * 24))

    // Initialize all days with 0
    for (let i = 0; i < days; i++) {
      const day = dayjs(dateRange.start).add(i, 'day').format('YYYY-MM-DD')
      dayMap[day] = 0
    }

    // Count applications by day
    newApplicationsInRange.forEach(app => {
      const day = dayjs(app.time_created).format('YYYY-MM-DD')
      if (dayMap[day] !== undefined) {
        dayMap[day]++
      }
    })

    return dayMap
  }, [newApplicationsInRange, dateRange])

  // Get the day with most applications
  const maxApplicationsPerDay = useMemo(() => {
    return Math.max(...Object.values(applicationsByDay), 1)
  }, [applicationsByDay])

  // Handle mobile view toggle
  const handleMobileViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'recent' | 'top' | null,
  ) => {
    if (newView !== null) {
      setMobileView(newView)
    }
  }

  // Custom styled chip to match ApplicationManagement.tsx
  const StatusChip = styled(Chip)<{ statuscolor: string }>(({ statuscolor, theme }) => ({
    backgroundColor: `${statuscolor}20`, // 20% opacity background
    color: statuscolor,
    borderColor: statuscolor,
    fontWeight: 500,
    '& .MuiChip-label': {
      color: statuscolor,
    },
  }))

  return (
    <Paper elevation={3} sx={{ 
      p: { xs: 1.5, sm: 2, md: 3 }, 
      height: '100%',
      overflow: 'hidden'
    }}>
      <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
        Financing Applications
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={isMobile ? 1 : 2}>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<AssignmentIcon />} 
              label={`Total: ${totalApplications}`} 
              color='primary' 
              sx={{ width: '100%', justifyContent: 'flex-start' }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<PendingIcon />} 
              label={`Active: ${activeApplications.length}`} 
              color='warning' 
              sx={{ width: '100%', justifyContent: 'flex-start' }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Chip
              icon={<AssignmentLateIcon />}
              label={`Needs: ${needsAttentionApplications.length}`}
              color='error'
              sx={{ width: '100%', justifyContent: 'flex-start' }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<AddCircleIcon />} 
              label={`New: ${newApplicationsInRange.length}`} 
              color='success' 
              sx={{ width: '100%', justifyContent: 'flex-start' }}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* New Applications in Date Range Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant='subtitle2' color='text.secondary' gutterBottom>
          New Applications ({dayjs(dateRange.start).format('MMM D')} - {dayjs(dateRange.end).format('MMM D, YYYY')})
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
          <Typography variant='body2' color='text.secondary'>
            {newApplicationsInRange.length} new applications in this period
          </Typography>
        </Box>

        <Box sx={{ height: isMobile ? 150 : 200, mt: 2, mb: 1 }}>
          <BarChart
            dataset={Object.entries(applicationsByDay).map(([day, count]) => ({
              day: dayjs(day).format('MMM D'),
              count,
            }))}
            xAxis={[
              {
                scaleType: 'band',
                dataKey: 'day',
                tickLabelStyle: {
                  fontSize: 9,
                  angle: 45,
                  textAnchor: 'start',
                },
              },
            ]}
            yAxis={[
              {
                dataKey: 'count',
                min: 0,
                max: Math.max(maxApplicationsPerDay, 1),
              },
            ]}
            series={[
              {
                dataKey: 'count',
                label: 'Applications',
                valueFormatter: value => `${value} applications`,
                color: '#4caf50', // Changed to MUI success color
              },
            ]}
            tooltip={{ trigger: 'item' }}
            height={isMobile ? 130 : 180}
            margin={{ top: 10, right: 10, bottom: 30, left: 40 }}
            slotProps={{
              legend: {
                position: { vertical: 'top', horizontal: 'right' },
                padding: { top: 0, bottom: 5 },
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant='caption' color='text.secondary'>
            {dayjs(dateRange.start).format('MMM D')}
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            {dayjs(dateRange.end).format('MMM D')}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant='subtitle2' color='text.secondary' gutterBottom>
        Application Status Overview
      </Typography>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        {Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .slice(0, 4) // Show only top 4 statuses
          .map(([status, count]) => {
            const statusKey = status as ApplicationStatus
            const percentage = Math.round((count / totalApplications) * 100)
            const statusColor = getStatusColor(statusKey)

            return (
              <Grid item xs={6} key={status}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='body2'>{statusDisplayNames[statusKey]}</Typography>
                    <Typography variant='body2'>{count}</Typography>
                  </Box>
                  <LinearProgress
                    variant='determinate'
                    value={percentage}
                    sx={{
                      height: 8,
                      borderRadius: 1,
                      my: 0.5,
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: statusColor,
                      },
                    }}
                  />
                </Box>
              </Grid>
            )
          })}
      </Grid>

      <Divider sx={{ my: 2 }} />
      
      {/* Mobile Toggle View */}
      {isMobile && (
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup
            value={mobileView}
            exclusive
            onChange={handleMobileViewChange}
            aria-label="application view"
            size="small"
            sx={{
              mb: 2,
              '& .MuiToggleButton-root': {
                color: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                '&.Mui-selected': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  }
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                }
              }
            }}
          >
            <ToggleButton value="recent">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AssignmentIcon sx={{ mr: 1 }} />
                Recent Applications
              </Box>
            </ToggleButton>
            <ToggleButton value="top">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PeopleIcon sx={{ mr: 1 }} />
                Top Applicants
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      
      {/* Desktop Layout or Mobile Selected View */}
      <Box 
        display='flex' 
        flexDirection={isMobile ? 'column' : 'row'} 
        justifyContent='space-between' 
        sx={{ px: isMobile ? 0 : 2 }}
      >
        {/* Recent Applications */}
        {(!isMobile || (isMobile && mobileView === 'recent')) && (
          <Box sx={{ flex: 1, pr: isMobile ? 0 : 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                Recent Applications
              </Typography>

              <TextField
                placeholder='Search applications...'
                variant='outlined'
                size='small'
                fullWidth
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
            </Box>
            {activeApplications.length > 0 ? (
              <List dense sx={{ maxHeight: 300, overflow: 'auto' }}>
                {activeApplications.map(application => (
                  <React.Fragment key={application.id}>
                    <ListItem alignItems='flex-start'>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            width: 40,
                            height: 40,
                          }}
                        >
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={application.user_name || application.user_email || 'Unknown'}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component='span'
                              variant='body2'
                              color='text.primary'
                              sx={{ fontSize: '0.75rem' }}
                            >
                              {new Date(application.time_created).toLocaleDateString()}
                            </Typography>
                          </React.Fragment>
                        }
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                      <StatusChip
                        label={statusDisplayNames[application.status]}
                        size="small"
                        variant="outlined"
                        statuscolor={getStatusColor(application.status)}
                        sx={{ fontWeight: 500 }}
                      />
                    </ListItem>
                    <Divider variant='inset' component='li' />
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <Typography variant='body2' color='text.secondary'>
                No active applications match your search.
              </Typography>
            )}
          </Box>
        )}

        {!isMobile && <Divider orientation='vertical' flexItem />}
        {isMobile && mobileView === 'top' && <Divider sx={{ my: 2 }} />}

        {/* Top Applicants */}
        {(!isMobile || (isMobile && mobileView === 'top')) && (
          <Box sx={{ flex: 1, pl: isMobile ? 0 : 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                Top Applicants
              </Typography>

              <TextField
                placeholder='Search applicants...'
                variant='outlined'
                size='small'
                fullWidth
                value={topApplicantsSearchTerm}
                onChange={e => setTopApplicantsSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
            </Box>

            <List dense sx={{ maxHeight: 300, overflow: 'auto' }}>
              {filteredTopApplicants.length > 0 ? (
                filteredTopApplicants.map(([user, data]) => {
                  // Find the most common status for this user
                  let mostCommonStatus: ApplicationStatus = 'pending'
                  let maxCount = 0

                  Object.entries(data.statuses).forEach(([status, count]) => {
                    if (count > maxCount) {
                      maxCount = count
                      mostCommonStatus = status as ApplicationStatus
                    }
                  })

                  return (
                    <ListItem key={user}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            width: 40,
                            height: 40,
                          }}
                        >
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={user}
                        secondary={`Most common: ${statusDisplayNames[mostCommonStatus]}`}
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                      <StatusChip label={data.count} size='small' statuscolor={getStatusColor(mostCommonStatus)} />
                    </ListItem>
                  )
                })
              ) : (
                <Typography variant='body2' color='text.secondary'>
                  No applicants match your search.
                </Typography>
              )}
            </List>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
