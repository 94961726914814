import AddIcon from '@mui/icons-material/Add'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BuildIcon from '@mui/icons-material/Build'
import DeleteIcon from '@mui/icons-material/Delete'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LinkIcon from '@mui/icons-material/Link'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  Autocomplete,
  Badge,
  LinearProgress,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Expense, ExpenseCategory, Vehicle } from '@otw/models'
import { formatPrice } from 'core/utils/inventoryUtils'
import dayjs from 'dayjs'
import { useInventory } from 'hooks/inventory/useInventory'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useExpenses } from 'hooks/useExpenses'
import PersonIcon from '@mui/icons-material/Person'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import InfoIcon from '@mui/icons-material/Info'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import CloseIcon from '@mui/icons-material/Close'

interface FinancialOverviewProps {
  vehicles: Vehicle[]
  dateRange: {
    start: Date
    end: Date
  }
}

// Separate ExpenseDialog component
interface ExpenseDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (expense: Omit<Expense, 'id' | 'created_by' | 'created_at'>) => Promise<void>
}

const ExpenseDialog: React.FC<ExpenseDialogProps> = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState<{
    category: ExpenseCategory
    amount: string
    description: string
    date: Date
    customCategory: string | null
    vehicleId: string | null
    vehicleStockNumber: string | null
  }>({
    category: 'Shipping',
    amount: '',
    description: '',
    date: new Date(),
    customCategory: null,
    vehicleId: null,
    vehicleStockNumber: null,
  })
  const [formError, setFormError] = useState<string | null>(null)
  const isMobile = useIsMobile()

  // Get vehicles for linking to repair expenses
  const { vehicles } = useInventory('master_inventory')

  // Handle form input changes for text fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === 'amount') {
      // Only allow numbers and decimal point
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        setFormData(prev => ({
          ...prev,
          [name]: value,
        }))
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  // Handle select changes
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value,
      // Reset customCategory when changing category away from "Other"
      ...(name === 'category' && value !== 'Other' ? { customCategory: null } : {}),
      // Reset vehicle selection when changing away from "Repairs"
      ...(name === 'category' && value !== 'Repairs' ? { vehicleId: null, vehicleStockNumber: null } : {}),
    }))
  }

  // Handle date change
  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setFormData(prev => ({
        ...prev,
        date: date.toDate(),
      }))
    }
  }

  // Handle vehicle selection
  const handleVehicleChange = (_: any, vehicle: Vehicle | null) => {
    if (vehicle) {
      setFormData(prev => ({
        ...prev,
        vehicleId: vehicle.id,
        vehicleStockNumber: vehicle.stock_number || null,
      }))
    } else {
      setFormData(prev => ({
        ...prev,
        vehicleId: null,
        vehicleStockNumber: null,
      }))
    }
  }

  // Reset form
  const resetForm = () => {
    setFormData({
      category: 'Shipping',
      amount: '',
      description: '',
      date: new Date(),
      customCategory: null,
      vehicleId: null,
      vehicleStockNumber: null,
    })
    setFormError(null)
  }

  // Handle dialog close
  const handleClose = () => {
    resetForm()
    onClose()
  }

  // Handle expense submission
  const handleSubmit = async () => {
    // Validate form data
    const amount = formData.amount === '' ? 0 : parseFloat(formData.amount)

    if (amount <= 0) {
      setFormError('Amount must be greater than 0')
      return
    }
    if (!formData.description) {
      setFormError('Description is required')
      return
    }

    try {
      // Add the expense with the correct type for customCategory
      await onSubmit({
        category: formData.category,
        amount: amount,
        description: formData.description,
        date: formData.date,
        // Use type assertion to fix the type mismatch
        customCategory: formData.category === 'Other' ? formData.customCategory || null : null,
        // Include vehicle information only if Repairs category is selected and a vehicle is chosen
        vehicleId: formData.category === 'Repairs' ? formData.vehicleId : null,
        vehicleStockNumber: formData.category === 'Repairs' ? formData.vehicleStockNumber : null,
      } as any)

      // Close the dialog and reset form
      handleClose()
    } catch (error) {
      console.error('Error adding expense:', error)
      setFormError('Failed to add expense. Please try again.')
    }
  }

  // Get category color
  const getCategoryColor = (category: ExpenseCategory): 'primary' | 'error' | 'success' | 'warning' => {
    switch (category) {
      case 'Shipping':
        return 'primary'
      case 'Repairs':
        return 'error'
      case 'RentalReimbursement':
        return 'success'
      case 'Other':
        return 'warning'
      default:
        return 'primary' // Default to primary instead of 'default'
    }
  }

  // Get category icon
  const getCategoryIcon = (category: ExpenseCategory) => {
    switch (category) {
      case 'Shipping':
        return <LocalShippingIcon />
      case 'Repairs':
        return <BuildIcon />
      case 'RentalReimbursement':
        return <DirectionsCarIcon />
      case 'Other':
        return <ApartmentIcon />
      default:
        return null
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth fullScreen={isMobile}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor:
            formData.category === 'Shipping'
              ? 'primary.light'
              : formData.category === 'Repairs'
                ? 'error.light'
                : formData.category === 'RentalReimbursement'
                  ? 'success.light'
                  : formData.category === 'Other'
                    ? 'warning.light'
                    : 'primary.light',
          color: 'text.primary',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {formData.category && getCategoryIcon(formData.category)}
          <Typography variant='h6'>Add New {formData.category} Expense</Typography>
        </Box>
        {isMobile && (
          <IconButton edge='end' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          overflowY: 'auto',
          flex: 1,
          pt: isMobile ? 2 : undefined,
        }}
      >
        {formError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {formError}
          </Alert>
        )}
        <Box component='form' sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id='category-select-label'>Category</InputLabel>
                <Select
                  labelId='category-select-label'
                  id='category-select'
                  value={formData.category}
                  label='Category'
                  name='category'
                  onChange={handleSelectChange}
                >
                  <MenuItem value='Shipping'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <LocalShippingIcon fontSize='small' />
                      <span>Shipping</span>
                    </Box>
                  </MenuItem>
                  <MenuItem value='Repairs'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <BuildIcon fontSize='small' />
                      <span>Repairs</span>
                    </Box>
                  </MenuItem>
                  <MenuItem value='RentalReimbursement'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <DirectionsCarIcon fontSize='small' />
                      <span>Rental Reimbursement</span>
                    </Box>
                  </MenuItem>
                  <MenuItem value='Other'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ApartmentIcon fontSize='small' />
                      <span>Other</span>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='amount'
                label='Amount'
                name='amount'
                value={formData.amount}
                onChange={handleInputChange}
                placeholder='0.00'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
              />
            </Grid>
            {formData.category === 'Other' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='customCategory'
                  label='Custom Category'
                  name='customCategory'
                  value={formData.customCategory || ''}
                  onChange={handleInputChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='description'
                label='Description'
                name='description'
                value={formData.description}
                onChange={handleInputChange}
                multiline
                rows={isMobile ? 3 : 2}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Date'
                  value={dayjs(formData.date)}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={vehicles || []}
                getOptionLabel={option =>
                  `${option.stock_number || 'No Stock #'} - ${option.make || ''} ${option.model || ''}`
                }
                renderInput={params => <TextField {...params} label='Link to Vehicle (Optional)' />}
                onChange={handleVehicleChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              {formData.vehicleId && formData.vehicleStockNumber && (
                <Typography variant='caption' color='primary' sx={{ display: 'block', mt: 0.5 }}>
                  Selected: {formData.vehicleStockNumber}{' '}
                  {vehicles?.find((v: Vehicle) => v.id === formData.vehicleId)?.make}{' '}
                  {vehicles?.find((v: Vehicle) => v.id === formData.vehicleId)?.model}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: isMobile ? 2 : undefined,
          flexDirection: isMobile ? 'column' : 'row',
          '& > button': isMobile
            ? {
                width: '100%',
                mb: 1,
                py: 1.5,
              }
            : {},
        }}
      >
        {isMobile ? (
          <>
            <Button onClick={handleSubmit} variant='contained' color='primary' fullWidth size='large'>
              Add Expense
            </Button>
            <Button onClick={handleClose} variant='outlined' fullWidth>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant='contained' color='primary'>
              Add Expense
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

// ExpenseList component to display detailed expenses
interface ExpenseListProps {
  expenses: Expense[]
  onDelete: (id: string) => Promise<void>
}

const ExpenseList: React.FC<ExpenseListProps> = ({ expenses, onDelete }) => {
  const [confirmDelete, setConfirmDelete] = useState<string | null>(null)
  const { vehicles } = useInventory('master_inventory')

  const handleDeleteClick = (id: string) => {
    setConfirmDelete(id)
  }

  const handleConfirmDelete = async () => {
    if (confirmDelete) {
      try {
        await onDelete(confirmDelete)
        setConfirmDelete(null)
      } catch (error) {
        console.error('Error deleting expense:', error)
      }
    }
  }

  const handleCancelDelete = () => {
    setConfirmDelete(null)
  }

  // Get icon for expense category
  const getCategoryIcon = (category: ExpenseCategory) => {
    switch (category) {
      case 'Shipping':
        return <LocalShippingIcon fontSize='small' />
      case 'Repairs':
        return <BuildIcon fontSize='small' />
      case 'RentalReimbursement':
        return <DirectionsCarIcon fontSize='small' />
      case 'Other':
        return <ApartmentIcon fontSize='small' />
      default:
        return <MoreVertIcon fontSize='small' />
    }
  }

  // Get vehicle details by ID
  const getVehicleDetails = (vehicleId: string | null) => {
    if (!vehicleId || !vehicles) return null
    return vehicles.find(v => v.id === vehicleId) || null
  }

  return (
    <>
      <Box sx={{ height: 220, overflow: 'auto' }}>
        <Table size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='center'>Vehicle</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.length > 0 ? (
              expenses.map(expense => {
                const linkedVehicle = getVehicleDetails(expense.vehicleId)

                return (
                  <TableRow key={expense.id}>
                    <TableCell>{new Date(expense.date).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {getCategoryIcon(expense.category)}
                        {expense.category === 'Other' && expense.customCategory
                          ? expense.customCategory
                          : expense.category}
                      </Box>
                    </TableCell>
                    <TableCell>{expense.description}</TableCell>
                    <TableCell align='right'>{formatPrice(expense.amount)}</TableCell>
                    <TableCell align='center'>
                      {linkedVehicle ? (
                        <Tooltip
                          title={
                            <Box>
                              <Typography variant='body2'>Stock #: {linkedVehicle.stock_number}</Typography>
                              <Typography variant='body2'>
                                {linkedVehicle.year} {linkedVehicle.make} {linkedVehicle.model}
                              </Typography>
                              {linkedVehicle.vin && (
                                <Typography variant='body2'>VIN: {linkedVehicle.vin}</Typography>
                              )}
                            </Box>
                          }
                        >
                          <IconButton size='small' color='primary' sx={{ p: 0.5 }}>
                            <Badge color='primary' variant='dot' overlap='circular'>
                              <LinkIcon fontSize='small' />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Typography variant='body2' color='text.secondary'>
                          -
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip title='Delete'>
                        <IconButton size='small' color='error' onClick={() => handleDeleteClick(expense.id)}>
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  No expenses found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>

      {/* Confirm Delete Dialog */}
      <Dialog open={!!confirmDelete} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this expense?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color='error' variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const FinancialOverview: React.FC<FinancialOverviewProps> = ({ vehicles, dateRange }) => {
  const { expenses, isLoading, totalExpensesByCategory, totalExpenses, addExpense, deleteExpense } = useExpenses({
    dateRange,
  })
  const [openExpenseDialog, setOpenExpenseDialog] = useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false)
  const [showExpenseList, setShowExpenseList] = useState(false)
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  // Calculate financial metrics
  const soldVehicles = vehicles.filter(v => v.status?.toLowerCase() === 'sold')
  const totalRevenue = soldVehicles.reduce((sum, vehicle) => {
    return sum + (vehicle.optic_list_price || 0)
  }, 0)

  // Calculate profit by subtracting all expenses from the vehicle profits
  const vehicleProfit = soldVehicles.reduce((sum, vehicle) => {
    const profit = vehicle.profit || 0
    const shipping = vehicle.shipping_cost || 0
    const repairs = vehicle.repair_cost || 0
    return sum + profit - shipping - repairs
  }, 0)

  // Subtract additional expenses from total profit
  const totalProfit = vehicleProfit - totalExpenses

  const roi = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0

  // Calculate average days to sell
  const avgDaysToSell =
    soldVehicles.length > 0
      ? soldVehicles.reduce((sum, vehicle) => {
          if (vehicle.date_added && vehicle.sold_date) {
            const dateAdded = new Date(vehicle.date_added)
            const dateSold = new Date(vehicle.sold_date)
            const daysDiff = Math.floor((dateSold.getTime() - dateAdded.getTime()) / (1000 * 60 * 60 * 24))
            return sum + daysDiff
          }
          return sum
        }, 0) / soldVehicles.length
      : 0

  // Count linked vehicles for each expense category
  const getLinkedVehicleCount = (category: ExpenseCategory) => {
    return expenses.filter(expense => expense.category === category && expense.vehicleId !== null).length
  }

  // Group expenses by categories for chart
  const expenseCategories = [
    {
      name: 'Shipping',
      value: totalExpensesByCategory.Shipping,
      icon: <LocalShippingIcon />,
      linkedVehicles: getLinkedVehicleCount('Shipping'),
    },
    {
      name: 'Repairs',
      value: totalExpensesByCategory.Repairs,
      icon: <BuildIcon />,
      linkedVehicles: getLinkedVehicleCount('Repairs'),
    },
    {
      name: 'Rental Reimbursement',
      value: totalExpensesByCategory.RentalReimbursement,
      icon: <DirectionsCarIcon />,
      linkedVehicles: getLinkedVehicleCount('RentalReimbursement'),
    },
    {
      name: 'Other',
      value: totalExpensesByCategory.Other,
      icon: <ApartmentIcon />,
      linkedVehicles: getLinkedVehicleCount('Other'),
    },
  ]

  // Handle expense dialog open/close
  const handleOpenExpenseDialog = () => {
    setOpenExpenseDialog(true)
  }

  const handleCloseExpenseDialog = () => {
    setOpenExpenseDialog(false)
  }

  // Handle vehicle dialog open/close
  const handleOpenVehicleDialog = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleDialog(true)
  }

  const handleCloseVehicleDialog = () => {
    setOpenVehicleDialog(false)
    setSelectedVehicle(null)
  }

  // Handle expense submission
  const handleSubmitExpense = async (expense: Omit<Expense, 'id' | 'created_by' | 'created_at'>) => {
    await addExpense(expense)
  }

  // Handle vehicle click to show details dialog
  const handleVehicleClick = (vehicle: Vehicle) => {
    handleOpenVehicleDialog(vehicle)
  }

  // Handle expense deletion
  const handleDeleteExpense = async (id: string) => {
    await deleteExpense(id)
  }

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
    >
      <CardHeader
        title='Financial Overview'
        sx={{
          pb: 1,
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
      />
      <Divider />
      {isLoading ? (
        <CardContent
          sx={{
            p: { xs: 1, sm: 2, md: 3 },
            minHeight: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size={40} />
            <Typography variant='body1' sx={{ mt: 2 }}>
              Loading financial data...
            </Typography>
          </Box>
        </CardContent>
      ) : (
        <CardContent
          sx={{
            p: { xs: 1, sm: 2, md: 3 },
            '&:last-child': { pb: { xs: 1, sm: 2, md: 3 } },
          }}
        >
          <Grid container spacing={isMobile ? 1.5 : 3}>
            {/* Financial Metrics */}
            <Grid item xs={12} md={12}>
              <Card elevation={3}>
                <CardHeader title='Financial Metrics' />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant='subtitle2' color='text.secondary'>
                          Total Revenue
                        </Typography>
                        <Typography variant='h5' fontWeight='bold' color='primary'>
                          {formatPrice(totalRevenue)}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant='subtitle2' color='text.secondary'>
                          Total Profit
                        </Typography>
                        <Typography
                          variant='h5'
                          fontWeight='bold'
                          color={totalProfit >= 0 ? 'success.main' : 'error.main'}
                        >
                          {formatPrice(totalProfit)}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant='subtitle2' color='text.secondary'>
                          ROI
                        </Typography>
                        <Typography variant='h5' fontWeight='bold' color={roi >= 0 ? 'success.main' : 'error.main'}>
                          {roi.toFixed(2)}%
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant='subtitle2' color='text.secondary'>
                          Avg Days to Sell
                        </Typography>
                        <Typography variant='h5' fontWeight='bold'>
                          {Math.round(avgDaysToSell)} days
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Expense Summary */}
            <Grid item xs={12} md={7}>
              <Card elevation={3} sx={{ height: isMobile ? 'auto' : '100%' }}>
                <CardHeader
                  title={showExpenseList ? 'Expense Details' : 'Expense Summary'}
                  action={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: 1,
                      }}
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => setShowExpenseList(!showExpenseList)}
                        fullWidth={isMobile}
                      >
                        {showExpenseList ? 'Show Summary' : 'Show Details'}
                      </Button>
                      <Button
                        variant='contained'
                        startIcon={<AddIcon />}
                        onClick={handleOpenExpenseDialog}
                        size='small'
                        fullWidth={isMobile}
                      >
                        Add Expense
                      </Button>
                    </Box>
                  }
                />
                <Divider />
                <CardContent
                  sx={{
                    height: isMobile ? 'auto' : 'calc(100% - 73px)',
                    maxHeight: isMobile ? 'none' : 300,
                    overflow: 'auto',
                  }}
                >
                  {showExpenseList ? (
                    <ExpenseList expenses={expenses} onDelete={handleDeleteExpense} />
                  ) : isMobile ? (
                    <Box sx={{ overflow: 'visible' }}>
                      <Stack spacing={1.5}>
                        {expenseCategories.map(category => (
                          <Paper key={category.name} elevation={1} sx={{ p: 1.5 }}>
                            <Box
                              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {category.icon}
                                <Typography variant='subtitle2'>{category.name}</Typography>
                                {category.linkedVehicles > 0 && (
                                  <Tooltip
                                    title={`${category.linkedVehicles} expense${category.linkedVehicles > 1 ? 's' : ''} linked to vehicle${category.linkedVehicles > 1 ? 's' : ''}`}
                                  >
                                    <LinkIcon
                                      fontSize='small'
                                      sx={{
                                        opacity: 0.6,
                                        color: 'primary.main',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                              <Typography variant='body1' fontWeight='bold'>
                                {formatPrice(category.value)}
                              </Typography>
                            </Box>
                            <LinearProgress
                              variant='determinate'
                              value={totalExpenses > 0 ? (category.value / totalExpenses) * 100 : 0}
                              sx={{
                                height: 8,
                                borderRadius: 1,
                                mb: 0.5,
                              }}
                            />
                            <Typography variant='caption' color='text.secondary' align='right' display='block'>
                              {totalExpenses > 0 ? `${((category.value / totalExpenses) * 100).toFixed(1)}%` : '0%'}
                            </Typography>
                          </Paper>
                        ))}
                        <Paper
                          elevation={2}
                          sx={{
                            p: 1.5,
                            bgcolor: 'primary.light',
                            color: 'primary.contrastText',
                          }}
                        >
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='subtitle1' fontWeight='bold'>
                              Total
                            </Typography>
                            <Typography variant='subtitle1' fontWeight='bold'>
                              {formatPrice(totalExpenses)}
                            </Typography>
                          </Box>
                        </Paper>
                      </Stack>
                    </Box>
                  ) : (
                    <TableContainer component={Box} sx={{ maxHeight: 300 }}>
                      <Table size='small' stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell align='right'>Amount</TableCell>
                            <TableCell align='right'>% of Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {expenseCategories.map(category => (
                            <TableRow key={category.name}>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  {category.icon}
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {category.name}
                                    {category.linkedVehicles > 0 && (
                                      <Tooltip
                                        title={`${category.linkedVehicles} expense${category.linkedVehicles > 1 ? 's' : ''} linked to vehicle${category.linkedVehicles > 1 ? 's' : ''}`}
                                      >
                                        <LinkIcon
                                          fontSize='small'
                                          sx={{
                                            ml: 0.5,
                                            opacity: 0.6,
                                            color: 'primary.main',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align='right'>{formatPrice(category.value)}</TableCell>
                              <TableCell align='right'>
                                {totalExpenses > 0
                                  ? `${((category.value / totalExpenses) * 100).toFixed(1)}%`
                                  : '0%'}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                            <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                              {formatPrice(totalExpenses)}
                            </TableCell>
                            <TableCell align='right'>100%</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Recent Sales */}
            <Grid item xs={12} md={5}>
              <Card elevation={3}>
                <CardHeader title='Recent Sales' />
                <Divider />
                <CardContent>
                  {isMobile ? (
                    <Box sx={{ overflow: 'auto', maxHeight: 300 }}>
                      <Stack spacing={1.5}>
                        {soldVehicles.length === 0 ? (
                          <Paper sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant='body1'>No sales data available</Typography>
                          </Paper>
                        ) : (
                          soldVehicles.slice(0, 5).map(vehicle => (
                            <Paper
                              key={vehicle.id}
                              elevation={1}
                              sx={{
                                p: 1.5,
                                cursor: 'pointer',
                                position: 'relative',
                                '&:hover': {
                                  boxShadow: 3,
                                  transform: 'translateY(-2px)',
                                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                },
                                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                borderLeft: '4px solid',
                                borderColor: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
                              }}
                              onClick={() => handleVehicleClick(vehicle)}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={10}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'flex-start',
                                      mb: 0.5,
                                    }}
                                  >
                                    <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                                      {vehicle.year} {vehicle.make} {vehicle.model}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    variant='body2'
                                    sx={{
                                      color: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
                                      fontWeight: 'bold',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {formatPrice(vehicle.profit || 0)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ flex: 1 }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                        <Typography variant='caption' color='text.secondary' noWrap>
                                          {vehicle.sold_to_user?.name || 'N/A'}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                        <Typography variant='caption' color='text.secondary' noWrap>
                                          {vehicle.sold_by_user?.name || 'N/A'}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box sx={{ ml: 1 }}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 0.5,
                                          justifyContent: 'flex-end',
                                        }}
                                      >
                                        <CalendarTodayIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                        <Typography variant='caption' color='text.secondary'>
                                          {vehicle.sold_date
                                            ? new Date(vehicle.sold_date).toLocaleDateString()
                                            : 'N/A'}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Paper>
                          ))
                        )}
                      </Stack>
                    </Box>
                  ) : (
                    <Box sx={{ height: 220, overflow: 'auto' }}>
                      <Table size='small' stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Vehicle</TableCell>
                            <TableCell>Sold By</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell align='right'>Profit</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {soldVehicles.slice(0, 5).map(vehicle => (
                            <TableRow
                              key={vehicle.id}
                              hover
                              onClick={() => handleVehicleClick(vehicle)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell>
                                {vehicle.year} {vehicle.make} {vehicle.model}
                              </TableCell>
                              <TableCell>{vehicle.sold_by_user?.name || 'N/A'}</TableCell>
                              <TableCell>{vehicle.sold_to_user?.name || 'N/A'}</TableCell>
                              <TableCell
                                align='right'
                                sx={{
                                  color: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatPrice(vehicle.profit || 0)}
                              </TableCell>
                            </TableRow>
                          ))}
                          {soldVehicles.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={4} align='center'>
                                No sales data available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      )}

      {/* Expense Dialog */}
      <ExpenseDialog open={openExpenseDialog} onClose={handleCloseExpenseDialog} onSubmit={handleSubmitExpense} />

      {/* Vehicle Dialog */}
      <Dialog
        open={openVehicleDialog}
        onClose={handleCloseVehicleDialog}
        maxWidth='md'
        fullWidth
        fullScreen={isMobile}
      >
        {selectedVehicle && (
          <>
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 1,
                borderBottom: '4px solid',
                borderColor: (selectedVehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
              }}
            >
              <Typography variant='h6'>
                Vehicle Details: {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model}
              </Typography>
              {isMobile && (
                <IconButton edge='end' color='inherit' onClick={handleCloseVehicleDialog} aria-label='close'>
                  <CloseIcon />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent dividers>
              {isMobile ? (
                <Stack spacing={2}>
                  <Paper elevation={1} sx={{ p: 1.5 }}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Vehicle Information
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <DirectionsCarIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            Stock Number
                          </Typography>
                        </Box>
                        <Typography variant='body2'>{selectedVehicle.stock_number}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <VpnKeyIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            VIN
                          </Typography>
                        </Box>
                        <Typography variant='body2' sx={{ wordBreak: 'break-all' }}>
                          {selectedVehicle.vin}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <InfoIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            Status
                          </Typography>
                        </Box>
                        <Typography variant='body2'>{selectedVehicle.status}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <CalendarTodayIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            Sold Date
                          </Typography>
                        </Box>
                        <Typography variant='body2'>
                          {selectedVehicle.sold_date
                            ? new Date(selectedVehicle.sold_date).toLocaleDateString()
                            : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Paper elevation={1} sx={{ p: 1.5 }}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Sale Information
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            Sold By
                          </Typography>
                        </Box>
                        <Typography variant='body2'>{selectedVehicle.sold_by_user?.name || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            Sold To
                          </Typography>
                        </Box>
                        <Typography variant='body2'>{selectedVehicle.sold_to_user?.name || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <AttachMoneyIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            List Price
                          </Typography>
                        </Box>
                        <Typography variant='body2'>{formatPrice(selectedVehicle.optic_list_price || 0)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <TrendingUpIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant='caption' color='text.secondary'>
                            Profit
                          </Typography>
                        </Box>
                        <Typography
                          variant='body2'
                          sx={{ color: (selectedVehicle.profit || 0) > 0 ? 'success.main' : 'error.main' }}
                        >
                          {formatPrice(selectedVehicle.profit || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Stack>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      Stock Number
                    </Typography>
                    <Typography variant='body1'>{selectedVehicle.stock_number}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      VIN
                    </Typography>
                    <Typography variant='body1'>{selectedVehicle.vin}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      Status
                    </Typography>
                    <Typography variant='body1'>{selectedVehicle.status}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      Sold Date
                    </Typography>
                    <Typography variant='body1'>
                      {selectedVehicle.sold_date ? new Date(selectedVehicle.sold_date).toLocaleDateString() : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      Sold By
                    </Typography>
                    <Typography variant='body1'>{selectedVehicle.sold_by_user?.name || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      Sold To
                    </Typography>
                    <Typography variant='body1'>{selectedVehicle.sold_to_user?.name || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      List Price
                    </Typography>
                    <Typography variant='body1'>{formatPrice(selectedVehicle.optic_list_price || 0)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      Profit
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{ color: (selectedVehicle.profit || 0) > 0 ? 'success.main' : 'error.main' }}
                    >
                      {formatPrice(selectedVehicle.profit || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions sx={{ display: isMobile ? 'none' : 'flex' }}>
              <Button onClick={handleCloseVehicleDialog}>Close</Button>
            </DialogActions>
            {isMobile && (
              <Box sx={{ p: 2 }}>
                <Button variant='contained' fullWidth onClick={handleCloseVehicleDialog}>
                  Close
                </Button>
              </Box>
            )}
          </>
        )}
      </Dialog>
    </Card>
  )
}

export default FinancialOverview
