import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  CircularProgress,
  InputAdornment,
  Button,
  Paper,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { PrivateUserInfoType } from '@otw/models';
import { useManageUsers } from 'hooks/useManageUsers';
import { useQueryClient } from '@tanstack/react-query';
import { CreateDisabledUser } from 'views/SuperAdmin/Management/UserManagement/components/CreateDisabledUser';
import { useIsMobile } from 'hooks/screen/useIsMobile';

interface UserSelectorProps {
  onUserSelect: (user: PrivateUserInfoType) => void;
  title?: string;
  placeholder?: string;
  showCreateButton?: boolean;
}

const UserSelector: React.FC<UserSelectorProps> = ({
  onUserSelect,
  title = 'Select User',
  placeholder = 'Search users...',
  showCreateButton = true
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { users, isLoading } = useManageUsers();
  const [showCreateUser, setShowCreateUser] = useState(false);
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();

  // Filter users based on search query
  const filteredUsers = React.useMemo(() => {
    console.log('users', users)
    if (!Array.isArray(users)) return [];

    return users.filter(user => {
      const searchLower = searchQuery.toLowerCase();
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const email = user.email?.toLowerCase() || '';
      const company = user.company_name?.toLowerCase() || '';

      return fullName.includes(searchLower) || 
             email.includes(searchLower) || 
             company.includes(searchLower);
    });
  }, [users, searchQuery]);

  // Handle user creation events
  useEffect(() => {
    const handleUserCreated = () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    };

    window.addEventListener('userCreated', handleUserCreated);
    return () => {
      window.removeEventListener('userCreated', handleUserCreated);
    };
  }, [queryClient]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">{title}</Typography>
        {showCreateButton && (
          <CreateDisabledUser 
            onSuccess={(newUser) => {
              console.log('newUser', newUser)
              const privateUserInfo: PrivateUserInfoType = {
                ...newUser,
                role: newUser.role,
                id: newUser.id,
                permissions: newUser.permissions,
                email_verified: newUser.email_verified,
                notes: newUser.notes,
                phone: newUser.phone,
                notifications: newUser.notifications,
                dealership_name: newUser.dealership_name,
                status: newUser.status,
                is_active: newUser.is_active,
                address: newUser.address,
                createdAt: newUser.createdAt,
                updatedAt: newUser.updatedAt,
                lastLoginAt: newUser.lastLoginAt,
                lastPasswordResetRequestAt: newUser.lastPasswordResetRequestAt,
                needsPasswordReset: newUser.needsPasswordReset,
                needsInitialPasswordSet: newUser.needsInitialPasswordSet,
                initialPasswordSetAt: newUser.initialPasswordSetAt,
                passwordResetAt: newUser.passwordResetAt,
                needsOnboarding: newUser.needsOnboarding,
                onboardingCompletedAt: newUser.onboardingCompletedAt,
                lastVerificationEmailRequestAt: newUser.lastVerificationEmailRequestAt,
                lastSignInLinkRequestAt: newUser.lastSignInLinkRequestAt,
                company_name: newUser.company_name,
                isOwner: newUser.isOwner,
                default_view: newUser.default_view,
                affiliate_id: newUser.affiliate_id,
                bank_id: newUser.bank_id,
                entity_structure: newUser.entity_structure,
                w9_document_uuid: newUser.w9_document_uuid,
                external_financing_source: newUser.external_financing_source,
              };
              onUserSelect(privateUserInfo);
              //onUserSelect(newUser as PrivateUserInfoType)
              queryClient.invalidateQueries({ queryKey: ['users'] });
              // Set the search query to the new user's name
              if (newUser?.first_name || newUser?.last_name) {
                setSearchQuery(`${newUser.first_name || ''} ${newUser.last_name || ''}`.trim());
              }
            }} 
          />
        )}
      </Box>

      <TextField
        autoFocus
        fullWidth
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper 
          variant="outlined" 
          sx={{ 
            maxHeight: 300, 
            overflow: 'auto',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          {filteredUsers.length > 0 ? (
            <List disablePadding>
              {filteredUsers.slice(0, 10).map((user) => (
                <React.Fragment key={user.firestoreDocId}>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => onUserSelect(user)}>
                      <ListItemText
                        primary={
                          <Typography variant="body1" noWrap>
                            {user.first_name + ' ' + user.last_name || 'Unnamed'}
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography variant="body2" color="text.secondary" noWrap>
                              {user.email}
                            </Typography>
                            {user.company_name && (
                              <Typography variant="caption" color="text.secondary" noWrap>
                                {user.company_name}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography color="text.secondary">
                No users found
              </Typography>
              {showCreateButton && (
                <Button 
                  startIcon={<AddIcon />} 
                  onClick={() => {
                    const createUserButton = document.querySelector('[aria-label="Create User"]');
                    if (createUserButton) {
                      (createUserButton as HTMLElement).click();
                    }
                  }}
                  sx={{ mt: 1 }}
                >
                  Create New User
                </Button>
              )}
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default UserSelector; 