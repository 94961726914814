import { useEffect, useState } from 'react'
import { Formula, Vehicle } from '@otw/models'
import { useFormulaRules } from 'hooks/graybook/useFormulaRules'
import { calculatePrice } from 'views/SuperAdmin/Tools/GrayBook/utils/calculators'
import { useFormulas } from './useFormulas'

interface CalculateDealInput {
  make: string
  model: string
  type: string
  size: string
  miles: string
  year: string
  sellerAskingPrice: string
  fuel: Vehicle['fuel']
}

type DealQualityInfo = {
  label: string
  color: 'success' | 'primary' | 'warning' | 'error'
  displayText: string
}

interface CalculateDealOutput {
  grayBookWholeSalePrice: number | null
  grayBookRetailPrice: number | null
  dealQuality: Vehicle['dealQuality']
  dealQualityInfo: DealQualityInfo | null
  matchedFormula: Formula | null
}

export const getDealQuality = (askingPrice: number, wholesalePrice: number): Vehicle['dealQuality'] => {
  const priceDiff = ((askingPrice - wholesalePrice) / wholesalePrice) * 100

  if (priceDiff <= -10) return 'great'
  if (priceDiff <= 0) return 'good'
  if (priceDiff <= 10) return 'average'
  if (priceDiff <= 20) return 'belowAverage'
  return 'poor'
}

export const getDealQualityInfo = (quality: Vehicle['dealQuality']): DealQualityInfo => {
  if (!quality) {
    return { label: 'Unkown', color: 'error', displayText: 'Unknown' }
  }
  const displayText = quality.charAt(0).toUpperCase() + quality.slice(1)

  switch (quality) {
    case 'great':
      return { label: 'Great', color: 'success', displayText }
    case 'good':
      return { label: 'Good', color: 'primary', displayText }
    case 'average':
      return { label: 'Average', color: 'warning', displayText }
    case 'belowAverage':
      return { label: 'Below Average', color: 'warning', displayText }
    case 'poor':
      return { label: 'Poor', color: 'error', displayText }
    default:
      return { label: 'Unkown', color: 'error', displayText }
  }
}

export const useCalculateDeal = (input: CalculateDealInput): CalculateDealOutput => {
  const [matchedFormula, setMatchedFormula] = useState<Formula | null>(null)
  const [grayBookRetailPrice, setGrayBookRetailPrice] = useState<number | null>(null)
  const [grayBookWholeSalePrice, setGrayBookWholeSalePrice] = useState<number | null>(null)
  const [dealQuality, setDealQuality] = useState<Vehicle['dealQuality'] | null>(null)
  const [dealQualityInfo, setDealQualityInfo] = useState<DealQualityInfo | null>(null)

  const { formulas } = useFormulas({ formulasCollection: 'gray_book_formulas' })
  console.log('input', input)
  const { rules } = useFormulaRules({
    rulesCollection: 'gray_book_formulas',
    subcollection: matchedFormula
      ? {
          name: 'rules',
          parentDocId: matchedFormula.id,
        }
      : undefined,
  })

  // Match formula
  useEffect(() => {
    const { make, model, type, size, fuel } = input
    if (make && model && type && size && fuel && formulas) {
      const matched = formulas.find(
        f =>
          f?.vehicleMake?.toLowerCase() === make.toLowerCase() &&
          f?.vehicleModel?.toLowerCase() === model.toLowerCase() &&
          f?.vehicleType?.toLowerCase() === type.toLowerCase() &&
          f?.vehicleSize?.toLowerCase() === size.toLowerCase() &&
          f?.vehicleFuel?.toLowerCase() === fuel?.toLowerCase(),
      )
      setMatchedFormula(matched || null)
    } else {
      setMatchedFormula(null)
      setGrayBookRetailPrice(null)
      setGrayBookWholeSalePrice(null)
      setDealQuality(null)
    }
  }, [input, formulas])
  console.log('matchedFormula', matchedFormula)
  // Calculate price
  useEffect(() => {
    const { miles, year } = input
    if (matchedFormula && rules && miles && year) {
      const yearRules = rules?.filter(rule => rule.targetFactor === 'year') || []
      const mileageRules = rules?.filter(rule => rule.targetFactor === 'mileage') || []
      const result = calculatePrice({
        basePrice: matchedFormula.maxPrice,
        year: typeof year === 'string' ? Number(year) : year,
        mileage: typeof miles === 'string' ? Number(miles.replace(/,/g, '')) : miles,
        yearRules,
        mileageRules,
        minPrice: matchedFormula.minPrice,
      })
      setGrayBookRetailPrice(result.price)
      const wholeSalePrice = result.price * (1 - (matchedFormula.wholesaleDiscount || 0) / 100)
      setGrayBookWholeSalePrice(wholeSalePrice)
    }
  }, [matchedFormula, rules, input])

  useEffect(() => {
    if (grayBookWholeSalePrice && input.sellerAskingPrice) {
      const askingPrice = Number(input.sellerAskingPrice)
      const quality = getDealQuality(askingPrice, grayBookWholeSalePrice)
      setDealQuality(quality)
      setDealQualityInfo(getDealQualityInfo(quality))
    } else {
      setDealQuality(null)
      setDealQualityInfo(null)
    }
  }, [grayBookRetailPrice, input.sellerAskingPrice])

  return { grayBookRetailPrice, grayBookWholeSalePrice, dealQuality, dealQualityInfo, matchedFormula }
}
