import React, { useState, useRef } from 'react'
import {
  Box,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material'
import { Edit, Save, Cancel } from '@mui/icons-material'
import { useClientPrivateFinancingProfile } from 'hooks/client/useClientPrivateFinancingProfile'
import { formatCurrency } from 'utils/formatters'
import { useAuth } from 'hooks/auth/useAuth'
import { PrivateClientFinancingProfile } from '@otw/models'

type FormDataType = Omit<PrivateClientFinancingProfile, 'id' | 'date_created' | 'date_modified' | 'user_id'> & {
  years_in_business?: number
  current_year_projected_revenue?: number
  current_year_projected_expenses?: number
  personal_info: {
    first_name: string
    last_name: string
    date_of_birth: string
    ssn: string
    phone: string
    email?: string
    home_address: {
      street: string
      city: string
      state: string
      zipCode: string
      country: string
    }
    title: string
    ownership_percentage: number
    alternate_email_address?: string
  }
}

const defaultAssets: Required<FormDataType['assets']> = {
  checking_savings: 0,
  investments: 0,
  vehicles: 0,
  real_estate: 0,
  company_equity: 0,
  other_assets: 0,
}

const defaultLiabilities: Required<FormDataType['liabilities']> = {
  credit_cards: 0,
  line_of_credit: 0,
  vehicle_loans: 0,
  mortgages: 0,
  notes_payable: 0,
  other_liabilities: 0,
}

const initialFormData: FormDataType = {
  us_citizen: false,
  bankruptcy: false,
  liens: false,
  business_name: '',
  business_start_date: new Date().toISOString(),
  federal_tax_id: '',
  entity_structure: '',
  business_phone: '',
  years_in_business: 0,
  current_year_projected_revenue: 0,
  current_year_projected_expenses: 0,
  business_address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'United States',
  },
  primary_business_contact: '',
  personal_info: {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    ssn: '',
    phone: '',
    email: '',
    home_address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'United States',
    },
    title: '',
    ownership_percentage: 0,
  },
  last_year_revenue: 0,
  last_year_expenses: 0,
  last_year_net_income: 0,
  has_fleet: false,
  fleet_quantity: 0,
  fleet_value: 0,
  assets: defaultAssets,
  liabilities: defaultLiabilities,
  has_additional_owners: false,
  additionalOwners: [],
}

export const ClientFinancingProfile: React.FC = () => {
  const { financingProfile, isLoadingProfile, profileError, createFinancingProfile, isCreating } =
    useClientPrivateFinancingProfile()
  console.log('financingProfile', financingProfile)
  const { userInfo } = useAuth()
  const [error, setError] = useState<string | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [hasAdditionalOwners, setHasAdditionalOwners] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const [formData, setFormData] = useState<FormDataType>(() => {
    if (financingProfile) {
      const { id, date_created, date_modified, user_id, ...rest } = financingProfile
      return {
        ...initialFormData,
        ...rest,
      }
    }
    return initialFormData
  })

  if (isLoadingProfile) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 200px)' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (profileError) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 200px)' }}
      >
        <Typography color='error'>Error loading financing profile: {profileError.message}</Typography>
      </Box>
    )
  }

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault()
    }
    setError(null)

    try {
      const ownershipPercentage =
        hasAdditionalOwners && formData.additionalOwners
          ? 100 -
            formData.additionalOwners.reduce((total, owner) => total + (Number(owner.ownership_percentage) || 0), 0)
          : 100

      await createFinancingProfile({
        ...formData,
        personal_info: {
          ...formData.personal_info,
          ownership_percentage: ownershipPercentage,
        },
        primary_business_contact:
          userInfo?.first_name && userInfo?.last_name
            ? `${userInfo.first_name} ${userInfo.last_name}`
            : 'Not provided',
      })
      setIsEditing(false)
    } catch (error) {
      console.error('Error saving financing profile:', error)
      setError('Failed to save financing profile. Please try again.')
    }
  }

  const updateFormData = <K extends keyof FormDataType>(field: K, value: FormDataType[K]): void => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleChange = (field: keyof FormDataType, value: string | number | boolean) => {
    // Convert string values to numbers for numeric fields
    if (
      field === 'last_year_revenue' ||
      field === 'last_year_expenses' ||
      field === 'current_year_projected_revenue' ||
      field === 'current_year_projected_expenses' ||
      field === 'years_in_business'
    ) {
      updateFormData(field as any, value === '' ? '' : Number(value))
    } else {
      updateFormData(field as any, value)
    }
  }

  const handleNestedChange = (
    parentField: keyof FormDataType,
    childField: string,
    value: string | number | boolean,
  ) => {
    if (parentField === 'personal_info') {
      const updatedPersonalInfo = {
        ...formData.personal_info,
        [childField]: value,
      }
      updateFormData('personal_info', updatedPersonalInfo)
    }
  }

  const handleNestedHomeAddressChange = (field: string, value: string) => {
    const updatedPersonalInfo = {
      ...formData.personal_info,
      home_address: {
        ...formData.personal_info?.home_address,
        [field]: value,
      },
    }
    updateFormData('personal_info', updatedPersonalInfo)
  }

  const handlePersonalInfoChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleNestedChange('personal_info', field, e.target.value)
    }

  const handleAssetsChange =
    (field: keyof FormDataType['assets']) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = Number(event.target.value) || 0
      setFormData((prev: FormDataType) => ({
        ...prev,
        assets: {
          ...defaultAssets,
          ...prev.assets,
          [field]: value,
        },
      }))
    }

  const handleLiabilitiesChange =
    (field: keyof FormDataType['liabilities']) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = Number(event.target.value) || 0
      setFormData((prev: FormDataType) => ({
        ...prev,
        liabilities: {
          ...defaultLiabilities,
          ...prev.liabilities,
          [field]: value,
        },
      }))
    }

  const handleStartEdit = () => {
    if (financingProfile) {
      const { id, date_created, date_modified, user_id, ...rest } = financingProfile
      setFormData({
        ...initialFormData,
        ...rest,
      })
      setIsEditing(true)
    }
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
    setError(null)
  }

  const renderSection = (title: string, content: React.ReactNode) => (
    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
      <Typography variant='h6' gutterBottom sx={{ color: 'primary.main', fontWeight: 500 }}>
        {title}
      </Typography>
      {content}
    </Paper>
  )

  const renderField = (label: string, value: string | number | undefined) => (
    <Grid item xs={12} sm={6} md={4}>
      <Box sx={{ py: 1 }}>
        <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
          {label}
        </Typography>
        <Typography variant='body1' sx={{ fontWeight: 500 }}>
          {value || 'Not provided'}
        </Typography>
      </Box>
    </Grid>
  )

  const renderFormSection = (title: string, content: React.ReactNode) => (
    <Box sx={{ mb: 3 }}>
      <Typography variant='h6' gutterBottom sx={{ color: 'primary.main', fontWeight: 500 }}>
        {title}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {content}
    </Box>
  )

  const renderForm = () => (
    <Box component='form' ref={formRef} onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {renderFormSection(
            'Business Information',
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Business Name'
                  name='business_name'
                  value={formData.business_name || ''}
                  onChange={e => handleChange('business_name', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel id='entity-structure-label'>Entity Structure</InputLabel>
                  <Select
                    labelId='entity-structure-label'
                    id='entity-structure'
                    value={formData.entity_structure || ''}
                    label='Entity Structure'
                    onChange={e => handleChange('entity_structure', e.target.value)}
                  >
                    <MenuItem value='Sole Proprietorship'>Sole Proprietorship</MenuItem>
                    <MenuItem value='Partnership'>Partnership</MenuItem>
                    <MenuItem value='LLC'>LLC</MenuItem>
                    <MenuItem value='Corporation'>Corporation</MenuItem>
                    <MenuItem value='S-Corporation'>S-Corporation</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Years in Business'
                  name='years_in_business'
                  type='number'
                  value={formData.years_in_business || ''}
                  onChange={e => handleChange('years_in_business', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Federal Tax ID'
                  name='federal_tax_id'
                  value={formData.federal_tax_id || ''}
                  onChange={e => handleChange('federal_tax_id', e.target.value)}
                  required
                />
              </Grid>
            </Grid>,
          )}

          {renderFormSection(
            'Business Address',
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Street Address'
                  value={formData.business_address?.street || ''}
                  onChange={e => {
                    setFormData(prev => ({
                      ...prev,
                      business_address: {
                        ...prev.business_address,
                        street: e.target.value,
                      },
                    }))
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='City'
                  value={formData.business_address?.city || ''}
                  onChange={e => {
                    setFormData(prev => ({
                      ...prev,
                      business_address: {
                        ...prev.business_address,
                        city: e.target.value,
                      },
                    }))
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='State'
                  value={formData.business_address?.state || ''}
                  onChange={e => {
                    setFormData(prev => ({
                      ...prev,
                      business_address: {
                        ...prev.business_address,
                        state: e.target.value,
                      },
                    }))
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='ZIP Code'
                  value={formData.business_address?.zipCode || ''}
                  onChange={e => {
                    setFormData(prev => ({
                      ...prev,
                      business_address: {
                        ...prev.business_address,
                        zipCode: e.target.value,
                      },
                    }))
                  }}
                />
              </Grid>
            </Grid>,
          )}

          {renderFormSection(
            'Financial Information',
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Last Year Revenue'
                  name='last_year_revenue'
                  type='number'
                  value={formData.last_year_revenue || ''}
                  onChange={e => handleChange('last_year_revenue', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Last Year Expenses'
                  name='last_year_expenses'
                  type='number'
                  value={formData.last_year_expenses || ''}
                  onChange={e => handleChange('last_year_expenses', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Current Year Projected Revenue'
                  name='current_year_projected_revenue'
                  type='number'
                  value={formData.current_year_projected_revenue || ''}
                  onChange={e => handleChange('current_year_projected_revenue', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Current Year Projected Expenses'
                  name='current_year_projected_expenses'
                  type='number'
                  value={formData.current_year_projected_expenses || ''}
                  onChange={e => handleChange('current_year_projected_expenses', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>,
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {renderFormSection(
            'Personal Information',
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='First Name'
                  name='personal_info.first_name'
                  value={formData.personal_info?.first_name || ''}
                  onChange={e => handlePersonalInfoChange('first_name')(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Last Name'
                  name='personal_info.last_name'
                  value={formData.personal_info?.last_name || ''}
                  onChange={e => handlePersonalInfoChange('last_name')(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Email'
                  name='personal_info.email'
                  type='email'
                  value={formData.personal_info?.email || ''}
                  onChange={e => handlePersonalInfoChange('email')(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Phone'
                  name='personal_info.phone'
                  value={formData.personal_info?.phone || ''}
                  onChange={e => handlePersonalInfoChange('phone')(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Social Security Number'
                  name='personal_info.ssn'
                  value={formData.personal_info?.ssn || ''}
                  onChange={e => handlePersonalInfoChange('ssn')(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Date of Birth'
                  name='personal_info.date_of_birth'
                  type='date'
                  value={formData.personal_info?.date_of_birth || ''}
                  onChange={e => handlePersonalInfoChange('date_of_birth')(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
            </Grid>,
          )}

          {/* Personal Address Section */}
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              Home Address
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Street Address'
                  name='personal_info.home_address.street'
                  value={formData.personal_info?.home_address?.street || ''}
                  onChange={e => handleNestedHomeAddressChange('street', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='City'
                  name='personal_info.home_address.city'
                  value={formData.personal_info?.home_address?.city || ''}
                  onChange={e => handleNestedHomeAddressChange('city', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='State'
                  name='personal_info.home_address.state'
                  value={formData.personal_info?.home_address?.state || ''}
                  onChange={e => handleNestedHomeAddressChange('state', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='ZIP Code'
                  name='personal_info.home_address.zipCode'
                  value={formData.personal_info?.home_address?.zipCode || ''}
                  onChange={e => handleNestedHomeAddressChange('zipCode', e.target.value)}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          {renderFormSection(
            'Assets',
            <Grid container spacing={2}>
              {renderAssets()}
            </Grid>,
          )}

          {renderFormSection(
            'Liabilities',
            <Grid container spacing={2}>
              {renderLiabilities()}
            </Grid>,
          )}
        </Grid>
      </Grid>
    </Box>
  )

  const renderAssets = () => {
    if (!formData.assets) return null
    return Object.entries(formData.assets).map(([key, value]) => (
      <Grid item xs={12} sm={6} key={key}>
        <TextField
          fullWidth
          type='number'
          label={key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
          value={value}
          onChange={handleAssetsChange(key as keyof FormDataType['assets'])}
        />
      </Grid>
    ))
  }

  const renderLiabilities = () => {
    if (!formData.liabilities) return null
    return Object.entries(formData.liabilities).map(([key, value]) => (
      <Grid item xs={12} sm={6} key={key}>
        <TextField
          fullWidth
          type='number'
          label={key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
          value={value}
          onChange={handleLiabilitiesChange(key as keyof FormDataType['liabilities'])}
        />
      </Grid>
    ))
  }

  if (financingProfile && !isEditing) {
    return (
      <Box sx={{ width: '100%', p: 3 }}>
        <Alert
          severity='info'
          sx={{
            mb: 3,
            backgroundColor: 'rgba(33, 150, 243, 0.1)',
            '& .MuiAlert-icon': { color: 'primary.main' },
          }}
        >
          You must contact admin@optictruckworks.com to update your financing profile
        </Alert>
        <Card sx={{ mb: 3 }}>
          <CardHeader
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6' component='div'>
                  Financing Profile
                </Typography>
              </Box>
            }
            // action={
            //   <Button
            //     size="small"
            //     variant="contained"
            //     color="primary"
            //     startIcon={<Edit />}
            //     onClick={handleStartEdit}
            //   >
            //     Edit Profile
            //   </Button>
            // }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              {/* Business Information Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Business Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Business Name</Typography>
                    <Typography variant='body1'>{financingProfile.business_name || 'Not provided'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Entity Structure</Typography>
                    <Typography variant='body1'>{financingProfile.entity_structure || 'Not provided'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Years in Business</Typography>
                    <Typography variant='body1'>
                      {(financingProfile as any).years_in_business || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Federal Tax ID</Typography>
                    <Typography variant='body1'>{financingProfile.federal_tax_id || 'Not provided'}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Business Address Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Business Address
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Street Address</Typography>
                    <Typography variant='body1'>
                      {financingProfile.business_address?.street || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>City</Typography>
                    <Typography variant='body1'>
                      {financingProfile.business_address?.city || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>State</Typography>
                    <Typography variant='body1'>
                      {financingProfile.business_address?.state || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>ZIP Code</Typography>
                    <Typography variant='body1'>
                      {financingProfile.business_address?.zipCode || 'Not provided'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Financial Information Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Financial Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Last Year Revenue</Typography>
                    <Typography variant='body1'>
                      {financingProfile.last_year_revenue
                        ? formatCurrency(financingProfile.last_year_revenue)
                        : 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Last Year Expenses</Typography>
                    <Typography variant='body1'>
                      {financingProfile.last_year_expenses
                        ? formatCurrency(financingProfile.last_year_expenses)
                        : 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Current Year Projected Revenue</Typography>
                    <Typography variant='body1'>
                      {(financingProfile as any).current_year_projected_revenue
                        ? formatCurrency((financingProfile as any).current_year_projected_revenue)
                        : 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Current Year Projected Expenses</Typography>
                    <Typography variant='body1'>
                      {(financingProfile as any).current_year_projected_expenses
                        ? formatCurrency((financingProfile as any).current_year_projected_expenses)
                        : 'Not provided'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Personal Information Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Personal Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>First Name</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.first_name || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Last Name</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.last_name || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Email</Typography>
                    <Typography variant='body1'>
                      {(financingProfile.personal_info as any)?.email || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Phone</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.phone || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Social Security Number</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.ssn
                        ? '••••••' + financingProfile.personal_info.ssn.slice(-4)
                        : 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Date of Birth</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.date_of_birth || 'Not provided'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Home Address Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Home Address
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>Street Address</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.home_address?.street || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>City</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.home_address?.city || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>State</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.home_address?.state || 'Not provided'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='subtitle1'>ZIP Code</Typography>
                    <Typography variant='body1'>
                      {financingProfile.personal_info?.home_address?.zipCode || 'Not provided'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Assets Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Assets
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  {Object.entries(financingProfile.assets || {}).map(([key, value]) => (
                    <Grid item xs={12} md={6} key={key}>
                      <Typography variant='subtitle1'>
                        {key
                          .split('_')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </Typography>
                      <Typography variant='body1'>{formatCurrency(value)}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Liabilities Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Liabilities
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  {Object.entries(financingProfile.liabilities || {}).map(([key, value]) => (
                    <Grid item xs={12} md={6} key={key}>
                      <Typography variant='subtitle1'>
                        {key
                          .split('_')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </Typography>
                      <Typography variant='body1'>{formatCurrency(value)}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    )
  }

  return (
    <Box sx={{ p: 3 }}>
      <Alert
        severity='info'
        sx={{
          mb: 3,
          backgroundColor: 'rgba(33, 150, 243, 0.1)',
          '& .MuiAlert-icon': { color: 'primary.main' },
        }}
      >
        You must contact admin@optictruckworks.com to update your financing profile
      </Alert>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h6' component='div'>
                {isEditing ? 'Edit Financing Profile' : 'Create Financing Profile'}
              </Typography>
            </Box>
          }
          action={
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isEditing && (
                <Button
                  onClick={handleCancelEdit}
                  variant='outlined'
                  color='secondary'
                  startIcon={<Cancel />}
                  size='small'
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={handleSubmit}
                variant='contained'
                color='primary'
                disabled={isCreating}
                startIcon={isCreating ? <CircularProgress size={20} color='inherit' /> : <Save />}
                size='small'
              >
                {isCreating ? 'Saving...' : financingProfile ? 'Save Profile' : 'Create Profile'}
              </Button>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          {error && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component='form' ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Business Information Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Business Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Business Name'
                      name='business_name'
                      value={formData.business_name || ''}
                      onChange={e => handleChange('business_name', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel id='entity-structure-label'>Entity Structure</InputLabel>
                      <Select
                        labelId='entity-structure-label'
                        id='entity-structure'
                        value={formData.entity_structure || ''}
                        label='Entity Structure'
                        onChange={e => handleChange('entity_structure', e.target.value)}
                      >
                        <MenuItem value='Sole Proprietorship'>Sole Proprietorship</MenuItem>
                        <MenuItem value='Partnership'>Partnership</MenuItem>
                        <MenuItem value='LLC'>LLC</MenuItem>
                        <MenuItem value='Corporation'>Corporation</MenuItem>
                        <MenuItem value='S-Corporation'>S-Corporation</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Years in Business'
                      name='years_in_business'
                      type='number'
                      value={formData.years_in_business || ''}
                      onChange={e => handleChange('years_in_business', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Federal Tax ID'
                      name='federal_tax_id'
                      value={formData.federal_tax_id || ''}
                      onChange={e => handleChange('federal_tax_id', e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Business Address Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Business Address
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Street Address'
                      value={formData.business_address?.street || ''}
                      onChange={e => {
                        setFormData(prev => ({
                          ...prev,
                          business_address: {
                            ...prev.business_address,
                            street: e.target.value,
                          },
                        }))
                      }}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='City'
                      value={formData.business_address?.city || ''}
                      onChange={e => {
                        setFormData(prev => ({
                          ...prev,
                          business_address: {
                            ...prev.business_address,
                            city: e.target.value,
                          },
                        }))
                      }}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='State'
                      value={formData.business_address?.state || ''}
                      onChange={e => {
                        setFormData(prev => ({
                          ...prev,
                          business_address: {
                            ...prev.business_address,
                            state: e.target.value,
                          },
                        }))
                      }}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='ZIP Code'
                      value={formData.business_address?.zipCode || ''}
                      onChange={e => {
                        setFormData(prev => ({
                          ...prev,
                          business_address: {
                            ...prev.business_address,
                            zipCode: e.target.value,
                          },
                        }))
                      }}
                      size='small'
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Financial Information Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Financial Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Last Year Revenue'
                      name='last_year_revenue'
                      type='number'
                      value={formData.last_year_revenue || ''}
                      onChange={e => handleChange('last_year_revenue', e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Last Year Expenses'
                      name='last_year_expenses'
                      type='number'
                      value={formData.last_year_expenses || ''}
                      onChange={e => handleChange('last_year_expenses', e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Current Year Projected Revenue'
                      name='current_year_projected_revenue'
                      type='number'
                      value={formData.current_year_projected_revenue || ''}
                      onChange={e => handleChange('current_year_projected_revenue', e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Current Year Projected Expenses'
                      name='current_year_projected_expenses'
                      type='number'
                      value={formData.current_year_projected_expenses || ''}
                      onChange={e => handleChange('current_year_projected_expenses', e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Personal Information Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Personal Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='First Name'
                      name='personal_info.first_name'
                      value={formData.personal_info?.first_name || ''}
                      onChange={e => handlePersonalInfoChange('first_name')(e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Last Name'
                      name='personal_info.last_name'
                      value={formData.personal_info?.last_name || ''}
                      onChange={e => handlePersonalInfoChange('last_name')(e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Email'
                      name='personal_info.email'
                      type='email'
                      value={formData.personal_info?.email || ''}
                      onChange={e => handlePersonalInfoChange('email')(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Phone'
                      name='personal_info.phone'
                      value={formData.personal_info?.phone || ''}
                      onChange={e => handlePersonalInfoChange('phone')(e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Social Security Number'
                      name='personal_info.ssn'
                      value={formData.personal_info?.ssn || ''}
                      onChange={e => handlePersonalInfoChange('ssn')(e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Date of Birth'
                      name='personal_info.date_of_birth'
                      type='date'
                      value={formData.personal_info?.date_of_birth || ''}
                      onChange={e => handlePersonalInfoChange('date_of_birth')(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Home Address Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Home Address
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Street Address'
                      name='personal_info.home_address.street'
                      value={formData.personal_info?.home_address?.street || ''}
                      onChange={e => handleNestedHomeAddressChange('street', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='City'
                      name='personal_info.home_address.city'
                      value={formData.personal_info?.home_address?.city || ''}
                      onChange={e => handleNestedHomeAddressChange('city', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='State'
                      name='personal_info.home_address.state'
                      value={formData.personal_info?.home_address?.state || ''}
                      onChange={e => handleNestedHomeAddressChange('state', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='ZIP Code'
                      name='personal_info.home_address.zipCode'
                      value={formData.personal_info?.home_address?.zipCode || ''}
                      onChange={e => handleNestedHomeAddressChange('zipCode', e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Assets Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Assets
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  {renderAssets()}
                </Grid>
              </Grid>

              {/* Liabilities Section */}
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Liabilities
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  {renderLiabilities()}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ClientFinancingProfile
