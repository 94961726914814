import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { Expense, isValidExpenseCategory } from "../expenses";

const expenseConverter: FirestoreDataConverter<Expense> = {
  toFirestore(expense: WithFieldValue<Expense>) {
    const { id, ...data } = expense;
    
    // Convert Date objects to ISO strings for Firestore storage
    const firestoreData = {
      ...data,
      date: data.date instanceof Date ? data.date.toISOString() : data.date,
      created_at: data.created_at instanceof Date ? data.created_at.toISOString() : data.created_at || new Date().toISOString(),
      updated_at: data.updated_at instanceof Date ? data.updated_at.toISOString() : data.updated_at || null,
      // Ensure vehicleId and vehicleStockNumber are properly stored
      vehicleId: data.vehicleId || null,
    };
    return firestoreData;
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Expense {
    const data = snapshot.data(options);
    
    if (!data)
      return {
        id: snapshot.id,
        category: "Other",
        amount: 0,
        description: "",
        date: new Date(),
        created_by: "",
        created_at: new Date(),
        updated_at: null,
        updated_by: null,
        customCategory: null,
        vehicleId: null,
      };
    
    // Convert numeric values safely
    const safeNumber = (value: any): number => {
      if (value === undefined || value === null) return 0;
      const num = Number(value);
      return isNaN(num) ? 0 : num;
    };
    
    // Parse date strings to Date objects
    const parseDate = (dateString: any): Date => {
      if (!dateString) return new Date();
      try {
        return new Date(dateString);
      } catch (e) {
        return new Date();
      }
    };
    
    // Validate the category
    const category = isValidExpenseCategory(data.category) 
      ? data.category 
      : "Other";
    
    return {
      id: snapshot.id,
      category: category,
      amount: safeNumber(data.amount),
      description: data.description || "",
      date: parseDate(data.date),
      created_by: data.created_by || "",
      created_at: parseDate(data.created_at),
      updated_at: data.updated_at ? parseDate(data.updated_at) : null,
      updated_by: data.updated_by || null,
      customCategory: category === "Other" ? (data.customCategory || null) : null,
      vehicleId: data.vehicleId || null,
    };
  },
};

export { expenseConverter }; 