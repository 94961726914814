import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { Bank } from '@otw/models'
import { CreateUserType, PrivateUserInfoType } from '@otw/models/user'
import { useAdminApplications } from 'hooks/admin/useAdminApplications'
import { useManageUsers } from 'hooks/admin/useManageUsers'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

interface CreateDisabledUserProps {
  onSuccess?: (userData: PrivateUserInfoType) => void
}

// Update the schema to make bank_id optional and add external_bank_affiliation_name
const createUserSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address'),
  phone: z.string()
    .optional()
    .refine(
      val => !val || /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(val),
      'Please enter a valid phone number'
    ),
  company_name: z.string().optional(),
  bank_id: z.string().optional(),
  external_bank_affiliation_name: z.string().optional(),
  notes: z.string().optional(),
})

type FormData = z.infer<typeof createUserSchema>

export const CreateDisabledUser: React.FC<CreateDisabledUserProps> = ({ onSuccess }) => {
  const { createDisabledUserMutation } = useManageUsers()
  const { banks } = useAdminApplications()
  const [isCustomBank, setIsCustomBank] = useState(false)
  const [customBankName, setCustomBankName] = useState('')
  const [open, setOpen] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
      bank_id: '',
      external_bank_affiliation_name: '',
      notes: '',
    },
  })

  const handleOpen = () => {
    setOpen(true)
    reset()
    setIsCustomBank(false)
    setCustomBankName('')
  }

  const handleClose = () => {
    setOpen(false)
    reset()
    setIsCustomBank(false)
    setCustomBankName('')
  }

  const onSubmit = (data: FormData) => {
    const userData: CreateUserType = {
      ...data,
      password: '', // Generated server-side
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      email: data.email || '',
      phone: data.phone || '',
      
      company_name: data.company_name || '',
      bank_id: isCustomBank ? '1' : data.bank_id, // Use optic bank as default when custom bank is chosen
      external_financing_source: isCustomBank ? customBankName : '',
      dealership_name: '',
      role: 'client',
      permissions: [],
      email_verified: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      needsOnboarding: true,
      disabled: true,
      is_active: false,
    }

    createDisabledUserMutation.mutate(userData, {
      onSuccess: async (response) => {
        try {
          // Parse the response JSON if it's a Response object
          const responseData = response instanceof Response ? await response.json() : response;
          
          // Create a properly formatted PrivateUserInfoType object
          const completeUserData: PrivateUserInfoType = {
            ...userData,
            id: responseData?.id || '',
            //firebase_id: responseData?.firebase_id || '',
            displayName: `${data.first_name} ${data.last_name}`, // Note: using camelCase displayName
            // Add any other required fields from PrivateUserInfoType that might be missing
            email_verified: userData.email_verified || false,
            createdAt: userData.createdAt || new Date().toISOString(),
            updatedAt: userData.updatedAt || new Date().toISOString(),
            role: userData.role || 'client',
            permissions: userData.permissions || [],
            disabled: true,
            is_active: false,
          };
          
          onSuccess?.(completeUserData);
          handleClose();
        } catch (error) {
          console.error('Error processing user data:', error);
        }
      },
      onError: error => {
        console.error('Error creating user', error);
      },
    });
  }

  return (
    <>
      <Button variant='outlined' onClick={handleOpen} size="small">
        Create New Client
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Client</DialogTitle>
        <DialogContent>
          <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Contact Information
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label='First Name'
                  {...register('first_name')}
                  error={!!errors.first_name}
                  helperText={errors.first_name?.message}
                  disabled={createDisabledUserMutation.isPending}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label='Last Name'
                  {...register('last_name')}
                  error={!!errors.last_name}
                  helperText={errors.last_name?.message}
                  disabled={createDisabledUserMutation.isPending}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label='Email Address'
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  disabled={createDisabledUserMutation.isPending}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  {...register('phone')}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  disabled={createDisabledUserMutation.isPending}
                  placeholder="(123) 456-7890"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary" sx={{ mt: 2 }} gutterBottom>
                  Company Details
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Company Name'
                  {...register('company_name')}
                  error={!!errors.company_name}
                  helperText={errors.company_name?.message}
                  disabled={createDisabledUserMutation.isPending}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.bank_id}>
                  <InputLabel>Bank Affiliation</InputLabel>
                  <Controller
                    name="bank_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Bank Affiliation"
                        onChange={(e) => {
                          if (e.target.value === 'other') {
                            setIsCustomBank(true)
                            setValue('bank_id', 'other')
                          } else {
                            setIsCustomBank(false)
                            field.onChange(e)
                          }
                        }}
                        disabled={createDisabledUserMutation.isPending}
                      >
                        <MenuItem value="">
                          <em>Select a bank</em>
                        </MenuItem>
                        {banks.data?.map((bank: Bank) => (
                          <MenuItem key={bank.id} value={bank.id}>
                            {bank.name}
                          </MenuItem>
                        ))}
                        <MenuItem value="other" sx={{ color: 'primary.main' }}>
                          Other
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText>
                    {isCustomBank 
                      ? "Please enter the bank name below"
                      : errors.bank_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {isCustomBank && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Bank Name'
                    value={customBankName}
                    onChange={(e) => setCustomBankName(e.target.value)}
                    disabled={createDisabledUserMutation.isPending}
                    error={!!errors.bank_id}
                    helperText={errors.bank_id?.message}
                    autoFocus
                    placeholder="Enter bank name"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary" sx={{ mt: 2 }} gutterBottom>
                  Additional Information
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label='Notes'
                  {...register('notes')}
                  error={!!errors.notes}
                  helperText={errors.notes?.message}
                  disabled={createDisabledUserMutation.isPending}
                  placeholder="Enter any additional notes about this client..."
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={createDisabledUserMutation.isPending}
                >
                  {createDisabledUserMutation.isPending ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Create Client'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
} 