import React from 'react'
import { Box, Typography, Grid, Paper, Chip } from '@mui/material'
import { formatCurrency } from 'utils/formatters'
import { PrivateClientFinancingProfile } from '@otw/models'

// Define the type for our form data
type FormDataType = Omit<PrivateClientFinancingProfile, 'id' | 'date_created' | 'date_modified' | 'user_id'> & {
  fleet_list_file?: string;
};

// Define the type for additional owners
type AdditionalOwnerType = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  ownership_percentage: number;
  title?: string;
  user_id?: string;
};

interface ReviewStepProps {
  formData: FormDataType;
}

export const ReviewStep: React.FC<ReviewStepProps> = ({ formData }) => {
  const renderSection = (title: string, content: React.ReactNode) => (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {content}
    </Paper>
  )

  const renderField = (label: string, value: string | number | undefined) => (
    <Grid item xs={12} sm={6}>
      <Box>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="body1">
          {value || 'Not provided'}
        </Typography>
      </Box>
    </Grid>
  )

  const renderAddress = (address: any, title: string) => (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {renderField('Street', address?.street)}
        {renderField('City', address?.city)}
        {renderField('State', address?.state)}
        {renderField('ZIP Code', address?.zipCode)}
      </Grid>
    </>
  )

  // Format date for display
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'Not provided';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    } catch (e) {
      return dateString;
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        Review Your Information
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Please review all information before submitting. You can go back to make changes if needed.
      </Typography>

      {renderSection('Personal Information',
        <Grid container spacing={3}>
          {renderField('First Name', formData.personal_info?.first_name)}
          {renderField('Last Name', formData.personal_info?.last_name)}
          {renderField('SSN', formData.personal_info?.ssn ? '***-**-' + formData.personal_info?.ssn?.slice(-4) : 'Not provided')}
          {renderField('Date of Birth', formatDate(formData.personal_info?.date_of_birth))}
          {renderField('Phone', formData.personal_info?.phone)}
          {renderField('Alternate Email', formData.personal_info?.alternate_email_address)}
          {renderField('Title', formData.personal_info?.title)}
          {renderField('Ownership Percentage', formData.personal_info?.ownership_percentage ? `${formData.personal_info?.ownership_percentage}%` : 'Not provided')}
          <Grid item xs={12}>
            {renderAddress(formData.personal_info?.home_address, 'Home Address')}
          </Grid>
        </Grid>
      )}

      {renderSection('Company Information',
        <Grid container spacing={3}>
          {renderField('Company Name', formData.business_name)}
          {renderField('Tax ID (EIN)', formData.federal_tax_id)}
          {renderField('Entity Structure', formData.entity_structure)}
          {renderField('Business Start Date', formatDate(formData.business_start_date))}
          {renderField('Business Phone', formData.business_phone)}
          {renderField('Business Email', formData.business_email)}
          <Grid item xs={12}>
            {renderAddress(formData.business_address, 'Business Address')}
          </Grid>
        </Grid>
      )}

      {renderSection('Financial Information',
        <Grid container spacing={3}>
          {renderField('Last Year Revenue', formatCurrency(formData.last_year_revenue))}
          {renderField('Last Year Expenses', formatCurrency(formData.last_year_expenses))}
          {renderField('Net Income', formatCurrency(formData.last_year_net_income))}
          {renderField('Has Fleet', formData.has_fleet ? 'Yes' : 'No')}
          {formData.has_fleet && (
            <>
              {renderField('Fleet Quantity', formData.fleet_quantity)}
              {renderField('Fleet Value', formatCurrency(formData.fleet_value))}
              {renderField('Fleet List File', formData.fleet_list_file || 'Not uploaded')}
            </>
          )}
        </Grid>
      )}

      {renderSection('Assets',
        <Grid container spacing={3}>
          {Object.entries(formData.assets || {}).map(([key, value]) => (
            renderField(
              key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
              formatCurrency(value as number)
            )
          ))}
          <Grid item xs={12}>
            <Box sx={{ mt: 2 }}>
              <Chip 
                label="Disclaimer" 
                color="primary" 
                size="small" 
                sx={{ mb: 1 }} 
              />
              <Typography variant="body2" color="text.secondary">
                None of the assets listed above will be pledged under any circumstances in order to obtain financing. 
                This simply helps the lenders understand the financial health of the clients they're extending financing terms to.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}

      {renderSection('Liabilities',
        <Grid container spacing={3}>
          {Object.entries(formData.liabilities || {}).map(([key, value]) => (
            renderField(
              key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
              formatCurrency(value as number)
            )
          ))}
        </Grid>
      )}

      {formData.additionalOwners && formData.additionalOwners.length > 0 && renderSection('Additional Owners',
        <Grid container spacing={3}>
          {formData.additionalOwners.map((owner, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="subtitle1" gutterBottom>
                Owner {index + 1}
              </Typography>
              <Grid container spacing={2}>
                {renderField('First Name', owner.first_name)}
                {renderField('Last Name', owner.last_name)}
                {renderField('Email', owner.email)}
                {renderField('Phone', owner.phone)}
                {renderField('Title', owner.title)}
                {renderField('Ownership Percentage', owner.ownership_percentage ? `${owner.ownership_percentage}%` : 'Not provided')}
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Note: Total ownership percentage across all owners must equal 100%. We only need ownership info for those with 20%+ ownership.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default ReviewStep 