import { Vehicle } from '@otw/models'
import { db, storage } from 'config/firebase'
import { addDoc, collection } from 'firebase/firestore'
import { getBytes, ref } from '@firebase/storage'
import Papa, { ParseResult } from 'papaparse'
import { ChangeEvent } from 'react'
import * as XLSX from 'xlsx'

type CsvRow = {
  WHO: string
  'STOCK NUMBER': string
  YEAR: string
  MAKE: string
  MODEL: string
  SIZE: string
  MILES: string
  FUEL: string
  Location: string
  'OUR LIST PRICE': string
  TYPE: string
  VIN: string
  'THEIR ASKING PRICE': string
  profit: string
  'SOLD DATE': string
  'TRUCK NOTES': string
  'PHONE NUMBER?': string
  LINK: string
  EMAIL: string
  '360 CAMERAS': string
  'FOLLOW UP NOTES': string
  'WHO ENTERED?': string
  'PAYOFF AMOUNT'?: string
  date_added: string
}

export const handleInventoryCsvUpload = (event: ChangeEvent<HTMLInputElement>, inventoryName: string) => {
  if (!event.target.files) return
  const file = event.target.files[0]
  Papa.parse(file, {
    header: true,
    complete: async (result: ParseResult<CsvRow>) => {
      const currentDate = new Date().toISOString()
      const vehicles: Omit<Vehicle, 'id'>[] = result.data.map(
        (item: CsvRow): Omit<Vehicle, 'id'> => ({
          who: item['WHO'],
          stock_number: item['STOCK NUMBER'],
          year: parseInt(item['YEAR']),
          make: item['MAKE'],
          model: item['MODEL'],
          size: item['SIZE'],
          miles: parseInt(item['MILES']),
          fuel: item['FUEL'] as 'Gas' | 'Diesel' | null,
          location: item['Location'],
          optic_list_price: parseInt(item['OUR LIST PRICE'].replace(/[^0-9.-]+/g, '')),
          type: item['TYPE'],
          vin: item['VIN'],
          seller_asking_price: parseInt(item['THEIR ASKING PRICE'].replace(/[^0-9.-]+/g, '')),
          profit: Number(item['OUR LIST PRICE'] || 0) - Number(item['THEIR ASKING PRICE'] || 0),
          sold_date: new Date(item['SOLD DATE']).toISOString(),
          truck_notes: item['TRUCK NOTES'],
          phone_number: item['PHONE NUMBER?'],
          link: item['LINK'],
          email: item['EMAIL'],
          follow_up_notes: item['FOLLOW UP NOTES'],
          entered_by: item['WHO ENTERED?'],
          payoff_amount: parseInt(item['PAYOFF AMOUNT'] || ''),
          show_on_website: false,
          date_added: new Date(currentDate).toISOString(),
          photo_storage_path: '',
          condition: null,
          sold_to_user: null,
          status: 'Available' as const,
          dealQuality: 'good' as const,
          updated_by: null,
          updated_at: null,
          cameras: 'unknown' as const,
          shelves: 'unknown' as const,
          size_confirmed: false,
          sold_by_user: null,
        }),
      )

      // TODO: Check if the vehicle already exists in the database

      const masterInventoryRef = collection(db, inventoryName)

      for (const vehicle of vehicles) {
        await addDoc(masterInventoryRef, vehicle)
      }
    },
    skipEmptyLines: true,
  })
}

export const readInventoryFromFirebaseCSV = async (filePath: string): Promise<Vehicle[] | undefined> => {
  const fileRef = ref(storage, filePath)

  const fileBuffer = await getBytes(fileRef)

  const csvString = new TextDecoder().decode(fileBuffer)
  return new Promise(() => {
    Papa.parse(csvString, {
      header: true,
      complete: async (result: ParseResult<CsvRow>) => {
        const vehicles: Omit<Vehicle, 'id'>[] = result.data.map(
          (item: CsvRow): Omit<Vehicle, 'id'> => ({
            who: item['WHO'],
            stock_number: item['STOCK NUMBER'],
            year: parseInt(item['YEAR']),
            make: item['MAKE'],
            model: item['MODEL'],
            size: item['SIZE'],
            miles: parseInt(item['MILES']),
            fuel: item['FUEL'] as 'Gas' | 'Diesel' | null,
            location: item['Location'],
            optic_list_price: parseInt(item['OUR LIST PRICE'].replace(/[^0-9.-]+/g, '')),
            type: item['TYPE'],
            vin: item['VIN'],
            seller_asking_price: parseInt(item['THEIR ASKING PRICE'].replace(/[^0-9.-]+/g, '')),
            profit: parseInt(item.profit),
            sold_date: new Date(item['SOLD DATE']).toISOString(),
            truck_notes: item['TRUCK NOTES'],
            phone_number: item['PHONE NUMBER?'],
            photo_storage_path: '',
            link: item['LINK'],
            email: item['EMAIL'],
            follow_up_notes: item['FOLLOW UP NOTES'],
            entered_by: item['WHO ENTERED?'],
            payoff_amount: parseInt(item['PAYOFF AMOUNT'] || ''),
            show_on_website: false,
            date_added: new Date(item.date_added).toISOString(),
            condition: null,
            sold_to_user: null,
            status: null,
            dealQuality: null,
            updated_by: null,
            updated_at: null,
            cameras: 'unknown' as const,
            shelves: 'unknown' as const,
            size_confirmed: false,
            sold_by_user: null,
          }),
        )
        return vehicles
      },
      skipEmptyLines: true,
    })
  })
}

type ExcelRow = {
  'PICKUP LOCATION': string
  YEAR: string
  MAKE: string
  MODEL: string
  'SIZE (P FOR STEP VANS, FEET FOR BOX TRUCKS AND CUTAWAYS)': string
  VIN: string
  MILES: string
  'DESIRED PRICE': string
  'LIEN (Yes/No) ': string
  'FINANCING BANK': string
}

export const readInventoryFromXLSX = async (fileBase64: string): Promise<Omit<Vehicle, 'id'>[]> => {
  const workbook = XLSX.read(fileBase64, { type: 'base64' })
  const sheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const data = XLSX.utils.sheet_to_json(worksheet, { blankrows: false, defval: '' })

  //someone deleted a row and it shifted everything so im just going to find the header row dynamically for now like this
  let headers: string[] = []
  let headerRowIndex = -1

  for (let i = 0; i < data.length; i++) {
    const row = Object.values(data[i] as string[])
    if (row.includes('PICKUP LOCATION')) {
      headers = row
      headerRowIndex = i
      break
    }
  }

  if (headerRowIndex === -1) {
    throw new Error('Could not find header row, please make sure the uploaded spreadsheet matches the template')
  }

  const rows = data.slice(headerRowIndex + 1) as string[][]

  const vehicles = rows.map(row => {
    const item = Object.fromEntries(headers.map((header, index) => [header, Object.values(row)[index]])) as ExcelRow
    return {
      year: Number(item['YEAR'] || 0),
      make: String(item['MAKE'] || ''),
      model: String(item['MODEL'] || ''),
      size: String(item['SIZE (P FOR STEP VANS, FEET FOR BOX TRUCKS AND CUTAWAYS)'] || ''),
      miles: Number(item['MILES'] || 0),
      location: String(item['PICKUP LOCATION'] || ''),
      vin: String(item['VIN'] || ''),
      seller_asking_price: Number(item['DESIRED PRICE']?.replace(/[^0-9.-]+/g, '') || 0),
      show_on_website: false,
      who: '',
      stock_number: '',
      fuel: null as 'Gas' | 'Diesel' | null,
      optic_list_price: 0,
      photo_storage_path: '',
      type: '',
      profit: 0,
      sold_date: new Date().toISOString(),
      truck_notes: '',
      phone_number: '',
      link: '',
      email: '',
      follow_up_notes: '',
      entered_by: '',
      payoff_amount: 0,
      date_added: new Date().toISOString(),
      condition: null,
      sold_to_user: null,
      status: 'Available' as const,
      dealQuality: 'good' as const,
      updated_by: null,
      updated_at: null,
      cameras: 'unknown' as const,
      shelves: 'unknown' as const,
      size_confirmed: false,
      sold_by_user: null,
    }
  })

  return vehicles
}
