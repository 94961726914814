import React from 'react'
import { Navigation } from 'types/navigation'
import { Home, NoteAdd, Handshake, Dashboard, AccountBalanceWallet, AccountBalance } from '@mui/icons-material'
import { ClientHome, ApplicationNew, BecomeAnAffiliate, AffiliateDashboard, DebtOverhaul, ClientFinancingProfile } from 'views/Client'

export const clientPages: Navigation = [
  { kind: 'header', title: 'Client' },
  {
    segment: 'client',
    title: 'Home',
    icon: <Home />,
    component: <ClientHome />,
  },
  // {
  //   segment: 'client/application',
  //   title: 'New Application',
  //   icon: <NoteAdd />,
  //   component: <ApplicationNew />,
  // },
  {
    segment: 'client/financing-profile',
    title: 'Financing Profile',
    icon: <AccountBalance />,
    component: <ClientFinancingProfile />,
  },
  // {
  //   segment: 'client/become-an-affiliate',
  //   title: 'Become an Affiliate',
  //   icon: <Handshake />,
  //   component: <BecomeAnAffiliate />,
  // },
  // {
  //   segment: 'client/affiliate-dashboard',
  //   title: 'Affiliate Dashboard',
  //   icon: <Dashboard />,
  //   component: <AffiliateDashboard />,
  // },
  // {
  //   segment: 'client/debt-overhaul',
  //   title: 'Debt Overhaul',
  //   icon: <AccountBalanceWallet />,
  //   component: <DebtOverhaul />,
  // },
]
