import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import { db } from 'config/firebase'
import {
  DocumentReference,
  DocumentData,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore'
import { BankUser, Application, Bank } from '@otw/models'
import { useState } from 'react'
import { useInventory } from 'hooks/inventory/useInventory'
import { pandaFunctions } from 'core/functions/pandaFunctions'

export const useAdminApplications = () => {
  const queryClient = useQueryClient()
  const { vehicles } = useInventory('master_inventory')

  const banks = useQuery({
    queryKey: ['banks'],
    queryFn: async (): Promise<Bank[]> => {
      const banksRef = collection(db, 'banks')
      const snapshot = await getDocs(banksRef)
      const banks = snapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data,
        }
      }) as Bank[]

      return banks
    },
  })

  const applications = useQuery({
    queryKey: ['applications'],
    queryFn: async (): Promise<Application[]> => {
      const applicationsRef = collection(db, 'applications')
      const snapshot = await getDocs(applicationsRef)
      const applications = snapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data,
        }
      }) as Application[]

      const sortedApplicationsByLatestDate = applications.sort((a, b) => {
        return new Date(b.time_created).getTime() - new Date(a.time_created).getTime()
      })
      console.log('applications', sortedApplicationsByLatestDate)
      return sortedApplicationsByLatestDate
    },
  })

  const getApplicationsForUser = useQuery({
    queryKey: ['applicationsForUser'],
    queryFn: async ({ queryKey }: { queryKey: string[] }): Promise<Application[]> => {
      const userId = queryKey[1]
      const applicationsRef = collection(db, 'applications')
      const snapshot = await getDocs(applicationsRef)
      const applications = snapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data,
        }
      }) as Application[]
      return applications
    },
  })

  const {
    data: bankAgents,
    isLoading: bankAgentsIsLoading,
    error: bankAgentsError,
  } = useQuery({
    queryKey: ['bankAgents'],
    queryFn: async (): Promise<BankUser[]> => {
      const q = query(collection(db, 'users'), where('role', '==', 'bankAgent'))

      const snapshot = await getDocs(q)
      const bankAgents = snapshot.docs.map(doc => doc.data()) as BankUser[]
      console.log('bankAgents', bankAgents)
      return bankAgents
    },
  })

  const editApplication = useMutation({
    mutationFn: async (application: Application) => {
      const applicationRef = doc(db, 'applications', application.id.toString())
      await updateDoc(applicationRef, application)
    },
  })

  const getInventoryForApplication = useQuery({
    queryKey: ['inventoryForApplication'],
    enabled: false,
    queryFn: async ({ queryKey }) => {
      const applicationId = queryKey[1]
      const application = applications.data?.find(app => app.id === applicationId)
      if (!vehicles) return []
      return vehicles.filter(vehicle => application?.inventory_ids?.includes(vehicle.id))
    },
  })

  const sendApplicationBackForCorrections = useMutation({
    mutationFn: async ({ applicationId, correctionNotes }: { applicationId: string; correctionNotes: string }) => {
      await pandaFunctions.admin.sendApplicationBackForCorrections(applicationId, correctionNotes)
    },
  })

  const assignAgentToApplication = useMutation({
    mutationFn: async ({ applicationId, agentId }: { applicationId: string; agentId: string }) => {
      const applicationRef = doc(db, 'applications', applicationId)
      await updateDoc(applicationRef, { bank_agent_id: agentId, status: 'pending' })
    },
  })

  const deleteApplication = useMutation({
    mutationFn: async (applicationId: string) => {
      const applicationRef = doc(db, 'applications', applicationId)
      await deleteDoc(applicationRef)
    },
    onSuccess: () => {
      // Invalidate and refetch applications queries
      queryClient.invalidateQueries({ queryKey: ['applications'] })
      queryClient.invalidateQueries({ queryKey: ['applicationsForUser'] })
    }
  })

  return {
    banks,
    applications: applications.data,
    applicationsError: applications.error,
    applicationsIsLoading: applications.isLoading,
    bankAgents,
    bankAgentsError,
    bankAgentsIsLoading,
    editApplication,
    getInventoryForApplication,
    sendApplicationBackForCorrections,
    assignAgentToApplication,
    getApplicationsForUser,
    applicationsForUserError: getApplicationsForUser.error,
    applicationsForUserIsLoading: getApplicationsForUser.isLoading,
    deleteApplication,
  }
}
