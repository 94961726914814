import React, { useState, useMemo } from 'react';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  Typography, 
  Divider, 
  Table, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  Paper, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField, 
  Chip, 
  Box, 
  IconButton, 
  Collapse, 
  CircularProgress,
  InputAdornment,
  Stack,
  Tooltip
} from '@mui/material';
import { Vehicle } from '@otw/models';
import { formatPrice } from 'core/utils/inventoryUtils';
import { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import { useExpenses } from 'hooks/useExpenses';
import { getFullNameFromPrefix } from '../../../../dashboardTools';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface SalesOverviewProps {
  vehicles: Vehicle[]
  dateRange: {
    start: Date
    end: Date
  }
}

// Define interface for stock number sales metrics
interface StockNumberMetrics {
  stockNumber: string
  count: number
  totalRevenue: number
  totalProfit: number
  vehicles: Vehicle[]
}


export const SalesOverview: React.FC<SalesOverviewProps> = ({ vehicles, dateRange }) => {
  const { totalExpenses, isLoading: expensesLoading } = useExpenses({ dateRange })
  const [salesPersonFilter, setSalesPersonFilter] = useState<string>('')
  const [clientFilter, setClientFilter] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false)
  const [filtersExpanded, setFiltersExpanded] = useState(false)
  const isMobile = useIsMobile()
  
  // For optimized rendering
  const [displayCount, setDisplayCount] = useState(10)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [showSalesPerStock, setShowSalesPerStock] = useState(false)
  const [stockFilter, setStockFilter] = useState<string>('')

  // Get sold vehicles and sort by sold_date in descending order
  const soldVehicles = useMemo(() => {
    return vehicles
      .filter(v => v.status?.toLowerCase() === 'sold' && v.sold_date)
      .sort((a, b) => {
        // Sort by sold_date in descending order (newest first)
        const dateA = a.sold_date ? new Date(a.sold_date).getTime() : 0
        const dateB = b.sold_date ? new Date(b.sold_date).getTime() : 0
        return dateB - dateA
      })
  }, [vehicles])

  // Extract unique sales persons and clients for filter dropdowns
  const salesPersons = useMemo(() => {
    const persons = soldVehicles
      .map(v => v.sold_by_user?.name)
      .filter((person): person is string => !!person)
    return Array.from(new Set(persons)).sort()
  }, [soldVehicles])

  const clients = useMemo(() => {
    const clientList = soldVehicles
      .map(v => v.sold_to_user?.name)
      .filter((client): client is string => !!client)
    return Array.from(new Set(clientList)).sort()
  }, [soldVehicles])

  // Update the stockPrefixes extraction to map prefix to name when displaying
  const stockPrefixes = useMemo(() => {
    const prefixes = new Set<string>()
    soldVehicles.forEach(vehicle => {
      if (vehicle.stock_number) {
        const match = vehicle.stock_number.match(/^([A-Za-z]+)/)
        if (match) {
          prefixes.add(match[1].toUpperCase())
        }
      }
    })
    return Array.from(prefixes).sort()
  }, [soldVehicles])

  // Simplify the filter logic to directly filter against soldVehicles
  const filteredSales = useMemo(() => {
    return soldVehicles.filter(vehicle => {
      // Filter by sales person (sold_by_user)
      if (salesPersonFilter && vehicle.sold_by_user?.name !== salesPersonFilter) {
        return false
      }

      // Filter by client (sold_to_user)
      if (clientFilter && vehicle.sold_to_user?.name !== clientFilter) {
        return false
      }

      // Filter by stock prefix
      if (stockFilter && vehicle.stock_number) {
        const stockPrefix = vehicle.stock_number.match(/^([A-Za-z]+)/)?.[1]?.toUpperCase()
        if (stockPrefix !== stockFilter) {
          return false
        }
      }

      // Filter by search term
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase()
        return (
          vehicle.make?.toLowerCase().includes(searchLower) ||
          vehicle.model?.toLowerCase().includes(searchLower) ||
          vehicle.vin?.toLowerCase().includes(searchLower) ||
          vehicle.stock_number?.toLowerCase().includes(searchLower) ||
          vehicle.sold_by_user?.name?.toLowerCase().includes(searchLower) ||
          vehicle.sold_to_user?.name?.toLowerCase().includes(searchLower)
        )
      }

      return true
    })
  }, [soldVehicles, salesPersonFilter, clientFilter, searchTerm, stockFilter])

  // Calculate sales metrics
  const totalSales = filteredSales.length
  const totalRevenue = filteredSales.reduce((sum, vehicle) => sum + (vehicle.optic_list_price || 0), 0)
  const totalProfit = filteredSales.reduce((sum, vehicle) => {
    const buyPrice = vehicle.seller_asking_price || 0
    const additionalCosts = (vehicle.repair_cost || 0) + 
                           (vehicle.shipping_cost || 0) + 
                           (vehicle.rental_cost || 0) + 
                           (vehicle.other_cost || 0)
    const profit = (vehicle.optic_list_price || 0) - buyPrice - additionalCosts
    return sum + profit
  }, 0) - totalExpenses
  const avgProfit = totalSales > 0 ? totalProfit / totalSales : 0

  // Calculate sales per stock number metrics
  const stockNumberMetrics = useMemo(() => {
    const metrics: Record<string, StockNumberMetrics> = {}
    
    filteredSales.forEach(vehicle => {
      const stockNumber = vehicle.stock_number || 'Unknown'
      
      if (!metrics[stockNumber]) {
        metrics[stockNumber] = {
          stockNumber,
          count: 0,
          totalRevenue: 0,
          totalProfit: 0,
          vehicles: []
        }
      }
      
      metrics[stockNumber].count += 1
      metrics[stockNumber].totalRevenue += (vehicle.optic_list_price || 0)
      
      const buyPrice = vehicle.seller_asking_price || 0
      const additionalCosts = (vehicle.repair_cost || 0) + 
                             (vehicle.shipping_cost || 0) + 
                             (vehicle.rental_cost || 0) + 
                             (vehicle.other_cost || 0)
      const profit = (vehicle.optic_list_price || 0) - buyPrice - additionalCosts
      
      metrics[stockNumber].totalProfit += profit
      metrics[stockNumber].vehicles.push(vehicle)
    })
    
    return Object.values(metrics)
      .sort((a, b) => b.totalProfit - a.totalProfit)
  }, [filteredSales])

  // Dialog handlers
  const handleOpenVehicleDialog = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleDialog(true)
  }

  const handleCloseVehicleDialog = () => {
    setOpenVehicleDialog(false)
    setSelectedVehicle(null)
  }

  // Handle vehicle click
  const handleVehicleClick = (vehicle: Vehicle) => {
    handleOpenVehicleDialog(vehicle)
  }

  // Handle filter changes
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target
    if (name === 'salesPerson') {
      setSalesPersonFilter(value)
    } else if (name === 'client') {
      setClientFilter(value)
    }
  }

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  // Clear all filters
  const handleClearFilters = () => {
    setSalesPersonFilter('')
    setClientFilter('')
    setSearchTerm('')
    setStockFilter('')
  }

  // Toggle filters visibility on mobile
  const toggleFilters = () => {
    setFiltersExpanded(!filtersExpanded)
  }

  // Toggle between regular sales view and sales per stock number view
  const toggleSalesView = () => {
    setShowSalesPerStock(!showSalesPerStock)
  }

  // Handle scroll event for lazy loading
  const handleScroll = React.useCallback(() => {
    if (!containerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    
    // Load more items when user scrolls to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 100 && displayCount < filteredSales.length) {
      setDisplayCount(prev => Math.min(prev + 10, filteredSales.length));
    }
  }, [displayCount, filteredSales.length]);

  // Reset display count when filters change
  React.useEffect(() => {
    setDisplayCount(10);
  }, [salesPersonFilter, clientFilter, searchTerm]);

  return (
    <Card sx={{ width: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
      <CardHeader
        title='Sales Overview'
        sx={{
          pb: 1,
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
      />
      <Divider />
      <CardContent sx={{ p: { xs: 1, sm: 2, md: 3 }, '&:last-child': { pb: { xs: 1, sm: 2, md: 3 } } }}>
        {/* Filters */}
        <Box sx={{ mb: 2 }}>
          {isMobile ? (
            <>
              <Button 
                variant="outlined" 
                onClick={toggleFilters}
                startIcon={filtersExpanded ? <ExpandLessIcon /> : <FilterListIcon />}
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              >
                {filtersExpanded ? 'Hide Filters' : 'Show Filters'}
              </Button>
              
              <Collapse in={filtersExpanded}>
                <Paper elevation={1} sx={{ p: 1.5, mb: 1.5 }}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Search sales..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          ),
                          endAdornment: searchTerm && (
                            <InputAdornment position="end">
                              <IconButton 
                                size="small" 
                                onClick={() => setSearchTerm('')}
                                edge="end"
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    
                    {/* Active Filters Display */}
                    {(salesPersonFilter || clientFilter || stockFilter) && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
                          {salesPersonFilter && (
                            <Chip 
                              size="small"
                              label={`Sales Rep: ${salesPersonFilter}`}
                              onDelete={() => setSalesPersonFilter('')}
                              color="primary"
                            />
                          )}
                          {clientFilter && (
                            <Chip 
                              size="small"
                              label={`Client: ${clientFilter}`}
                              onDelete={() => setClientFilter('')}
                              color="primary"
                            />
                          )}
                          {stockFilter && (
                            <Chip 
                              size="small"
                              label={`Stock #: ${stockFilter}`}
                              onDelete={() => setStockFilter('')}
                              color="primary"
                            />
                          )}
                        </Box>
                      </Grid>
                    )}
                    
                    <Grid item xs={12}>
                      <Typography variant="caption" color="text.secondary" gutterBottom>
                        Sales Person
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1, maxHeight: 100, overflow: 'auto' }}>
                        {salesPersons.map(person => (
                          <Chip
                            key={person}
                            label={person}
                            size="small"
                            variant={salesPersonFilter === person ? "filled" : "outlined"}
                            color={salesPersonFilter === person ? "primary" : "default"}
                            onClick={() => setSalesPersonFilter(salesPersonFilter === person ? '' : person)}
                          />
                        ))}
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Typography variant="caption" color="text.secondary" gutterBottom>
                        Client
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1, maxHeight: 100, overflow: 'auto' }}>
                        {clients.map(client => (
                          <Chip
                            key={client}
                            label={client}
                            size="small"
                            variant={clientFilter === client ? "filled" : "outlined"}
                            color={clientFilter === client ? "primary" : "default"}
                            onClick={() => setClientFilter(clientFilter === client ? '' : client)}
                          />
                        ))}
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Typography variant="caption" color="text.secondary" gutterBottom>
                        Stock Prefix
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1, maxHeight: 100, overflow: 'auto' }}>
                        {stockPrefixes.map(prefix => (
                          <Chip
                            key={prefix}
                            label={getFullNameFromPrefix(prefix)}
                            size="small"
                            variant={stockFilter === prefix ? "filled" : "outlined"}
                            color={stockFilter === prefix ? "primary" : "default"}
                            onClick={() => setStockFilter(stockFilter === prefix ? '' : prefix)}
                          />
                        ))}
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Button 
                        variant="outlined" 
                        size="small" 
                        onClick={handleClearFilters}
                        startIcon={<ClearAllIcon />}
                        disabled={!salesPersonFilter && !clientFilter && !searchTerm && !stockFilter}
                        fullWidth
                      >
                        Clear Filters
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Collapse>
            </>
          ) : (
            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Search sales..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <IconButton 
                            size="small" 
                            onClick={() => setSearchTerm('')}
                            edge="end"
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="sales-person-label">Sales Person</InputLabel>
                    <Select
                      labelId="sales-person-label"
                      name="salesPerson"
                      value={salesPersonFilter}
                      onChange={handleSelectChange}
                      label="Sales Person"
                    >
                      <MenuItem value="">All Sales Persons</MenuItem>
                      {salesPersons.map(person => (
                        <MenuItem key={person} value={person}>{person}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="client-label">Client</InputLabel>
                    <Select
                      labelId="client-label"
                      name="client"
                      value={clientFilter}
                      onChange={handleSelectChange}
                      label="Client"
                    >
                      <MenuItem value="">All Clients</MenuItem>
                      {clients.map(client => (
                        <MenuItem key={client} value={client}>{client}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={handleClearFilters}
                    startIcon={<ClearAllIcon />}
                    disabled={!salesPersonFilter && !clientFilter && !searchTerm && !stockFilter}
                    fullWidth
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
        
        {/* Sales Summary */}
        {expensesLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 3 }}>
            <CircularProgress size={30} />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Loading sales data...
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={6} sm={3}>
                  <Paper 
                    elevation={1} 
                    sx={{ 
                      p: 1.5, 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      borderLeft: '4px solid',
                      borderColor: 'primary.main'
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">Total Sales</Typography>
                    <Typography variant="h6" fontWeight="bold">{totalSales}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper 
                    elevation={1} 
                    sx={{ 
                      p: 1.5, 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      borderLeft: '4px solid',
                      borderColor: 'primary.main'
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">Revenue</Typography>
                    <Typography variant="h6" fontWeight="bold">{formatPrice(totalRevenue)}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper 
                    elevation={1} 
                    sx={{ 
                      p: 1.5, 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      borderLeft: '4px solid',
                      borderColor: totalProfit >= 0 ? 'success.main' : 'error.main'
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">Profit</Typography>
                    <Typography 
                      variant="h6" 
                      fontWeight="bold"
                      color={totalProfit >= 0 ? 'success.main' : 'error.main'}
                    >
                      {formatPrice(totalProfit)}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper 
                    elevation={1} 
                    sx={{ 
                      p: 1.5, 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      borderLeft: '4px solid',
                      borderColor: 'primary.main'
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">Expenses</Typography>
                    <Typography variant="h6" fontWeight="bold">{formatPrice(totalExpenses)}</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            
            {/* Sales Table/Cards */}
            {isMobile ? (
              <Box sx={{ mb: 2 }}>
                {filteredSales.length === 0 ? (
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="subtitle1">
                      No sales data found matching the current filters
                    </Typography>
                  </Paper>
                ) : (
                  <>
                    <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 1.5 }}>
                      Recent Sales ({filteredSales.length})
                    </Typography>
                    
                    <Box 
                      ref={containerRef}
                      sx={{ 
                        height: 450, 
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0,0,0,0.2)',
                          borderRadius: '3px',
                        }
                      }}
                      onScroll={handleScroll}
                    >
                      <Stack spacing={1.5} sx={{ p: 0.5 }}>
                        {filteredSales.slice(0, displayCount).map((vehicle) => (
                          <Paper 
                            key={vehicle.id} 
                            elevation={1} 
                            sx={{ 
                              p: 1.5, 
                              cursor: 'pointer',
                              position: 'relative',
                              '&:hover': { 
                                boxShadow: 3,
                                transform: 'translateY(-2px)',
                                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
                              },
                              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                              borderLeft: '4px solid',
                              borderColor: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main'
                            }}
                            onClick={() => handleVehicleClick(vehicle)}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0.5 }}>
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                    {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.size}
                                  </Typography>
                                  <Box sx={{ textAlign: 'right' }}>
                                    <Typography 
                                      variant="body2" 
                                      sx={{ 
                                        color: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      {formatPrice(vehicle.profit || 0)}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" display="block">
                                      Sale: {formatPrice(vehicle.optic_list_price || 0)}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" display="block">
                                      Buy: {formatPrice(vehicle.seller_asking_price || 0)}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" display="block">
                                      <Tooltip
                                        title={
                                          <div>
                                            <Typography variant="caption" display="block">
                                              Repair: {formatPrice(vehicle.repair_cost || 0)}
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                              Shipping: {formatPrice(vehicle.shipping_cost || 0)}
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                              Rental: {formatPrice(vehicle.rental_cost || 0)}
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                              Other: {formatPrice(vehicle.other_cost || 0)}
                                            </Typography>
                                          </div>
                                        }
                                      >
                                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                          Add. Costs: {formatPrice(
                                            (vehicle.repair_cost || 0) + 
                                            (vehicle.shipping_cost || 0) + 
                                            (vehicle.rental_cost || 0) + 
                                            (vehicle.other_cost || 0)
                                          )}
                                        </Box>
                                      </Tooltip>
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                                      <Typography variant="caption" sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 0.5,
                                        color: 'text.secondary',
                                        fontWeight: 'medium'
                                      }}>
                                        <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                        Client: {vehicle.sold_to_user?.name || 'N/A'}
                                      </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                      <Typography variant="caption" sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 0.5,
                                        color: 'text.secondary',
                                        fontWeight: 'medium'
                                      }}>
                                        <PersonOutlineIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                        Seller: {vehicle.who || 'N/A'}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box sx={{ textAlign: 'right' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, justifyContent: 'flex-end' }}>
                                      <CalendarTodayIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                      <Typography variant="caption" color="text.secondary">
                                        {vehicle.sold_date ? new Date(vehicle.sold_date).toLocaleDateString() : 'N/A'}
                                      </Typography>
                                    </Box>
                                    <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 0.5 }}>
                                      Stock #: {vehicle.stock_number || 'N/A'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                        {displayCount < filteredSales.length && (
                          <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                            <CircularProgress size={24} />
                          </Box>
                        )}
                      </Stack>
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <TableContainer 
                component={Paper} 
                elevation={0} 
                sx={{ 
                  maxHeight: 500,
                  '& .MuiTableCell-root': {
                    px: 2,
                    py: 1,
                    whiteSpace: 'normal',
                    fontSize: 'inherit'
                  }
                }}
              >
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Vehicle</TableCell>
                      <TableCell>Stock #</TableCell>
                      <TableCell>VIN</TableCell>
                      <TableCell>Sold By</TableCell>
                      <TableCell>Client</TableCell>
                      <TableCell align="right">Sale Price</TableCell>
                      <TableCell align="right">Buy Price</TableCell>
                      <TableCell align="right">Additional Costs</TableCell>
                      <TableCell align="right">Profit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSales.map((vehicle) => (
                      <TableRow 
                        key={vehicle.id} 
                        hover 
                        onClick={() => handleVehicleClick(vehicle)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>
                          {vehicle.sold_date ? new Date(vehicle.sold_date).toLocaleDateString() : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              maxWidth: 'none',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {vehicle.year} {vehicle.make} {vehicle.model}
                          </Typography>
                        </TableCell>
                        <TableCell>{vehicle.stock_number || 'N/A'}</TableCell>
                        <TableCell>{vehicle.vin}</TableCell>
                        <TableCell>{vehicle.sold_by_user?.name || 'N/A'}</TableCell>
                        <TableCell>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              maxWidth: 'none',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {vehicle.sold_to_user?.name || 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {formatPrice(vehicle.optic_list_price || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatPrice(vehicle.seller_asking_price || 0)}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip
                            title={
                              <div>
                                <Typography variant="caption" display="block">
                                  Repair: {formatPrice(vehicle.repair_cost || 0)}
                                </Typography>
                                <Typography variant="caption" display="block">
                                  Shipping: {formatPrice(vehicle.shipping_cost || 0)}
                                </Typography>
                                <Typography variant="caption" display="block">
                                  Rental: {formatPrice(vehicle.rental_cost || 0)}
                                </Typography>
                                <Typography variant="caption" display="block">
                                  Other: {formatPrice(vehicle.other_cost || 0)}
                                </Typography>
                              </div>
                            }
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                              {formatPrice(
                                (vehicle.repair_cost || 0) + 
                                (vehicle.shipping_cost || 0) + 
                                (vehicle.rental_cost || 0) + 
                                (vehicle.other_cost || 0)
                              )}
                              <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell 
                          align="right" 
                          sx={{ 
                            color: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}
                        >
                          {formatPrice(vehicle.profit || 0)}
                        </TableCell>
                      </TableRow>
                    ))}
                    {filteredSales.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <Typography variant="subtitle1" sx={{ py: 2 }}>
                            No sales data found matching the current filters
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </CardContent>

      {/* Vehicle Dialog */}
      <Dialog 
        open={openVehicleDialog} 
        onClose={handleCloseVehicleDialog} 
        maxWidth="md" 
        fullWidth
        fullScreen={isMobile}
      >
        {selectedVehicle ? (
          <>
            <DialogTitle sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              pb: 1,
              borderBottom: '4px solid',
              borderColor: (selectedVehicle.profit || 0) > 0 ? 'success.main' : 'error.main'
            }}>
              <Typography variant="h6">
                Vehicle Details: {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model}
              </Typography>
              {isMobile && (
                <IconButton edge="end" color="inherit" onClick={handleCloseVehicleDialog} aria-label="close">
                  <CloseIcon />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent dividers>
              {isMobile ? (
                <Stack spacing={2}>
                  <Paper elevation={1} sx={{ p: 1.5 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Vehicle Information
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <DirectionsCarIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Stock Number</Typography>
                        </Box>
                        <Typography variant="body2">{selectedVehicle.stock_number}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <VpnKeyIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">VIN</Typography>
                        </Box>
                        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>{selectedVehicle.vin}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <InfoIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Status</Typography>
                        </Box>
                        <Typography variant="body2">{selectedVehicle.status}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <CalendarTodayIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Sold Date</Typography>
                        </Box>
                        <Typography variant="body2">
                          {selectedVehicle.sold_date ? new Date(selectedVehicle.sold_date).toLocaleDateString() : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  
                  <Paper elevation={1} sx={{ p: 1.5 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Sale Information
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Seller</Typography>
                        </Box>
                        <Typography variant="body2">{selectedVehicle.who || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Sold By</Typography>
                        </Box>
                        <Typography variant="body2">{selectedVehicle.sold_by_user?.name || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <PersonIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Sold To</Typography>
                        </Box>
                        <Typography variant="body2">{selectedVehicle.sold_to_user?.name || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <AttachMoneyIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">List Price</Typography>
                        </Box>
                        <Typography variant="body2">{formatPrice(selectedVehicle.optic_list_price || 0)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <MoneyOffIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Buy Price</Typography>
                        </Box>
                        <Typography variant="body2">{formatPrice(selectedVehicle.seller_asking_price || 0)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <MoneyOffIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Additional Costs</Typography>
                        </Box>
                        <Tooltip
                          title={
                            <div>
                              <Typography variant="caption" display="block">
                                Repair: {formatPrice(selectedVehicle.repair_cost || 0)}
                              </Typography>
                              <Typography variant="caption" display="block">
                                Shipping: {formatPrice(selectedVehicle.shipping_cost || 0)}
                              </Typography>
                              <Typography variant="caption" display="block">
                                Rental: {formatPrice(selectedVehicle.rental_cost || 0)}
                              </Typography>
                              <Typography variant="caption" display="block">
                                Other: {formatPrice(selectedVehicle.other_cost || 0)}
                              </Typography>
                            </div>
                          }
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2">
                              {formatPrice(
                                (selectedVehicle.repair_cost || 0) + 
                                (selectedVehicle.shipping_cost || 0) + 
                                (selectedVehicle.rental_cost || 0) + 
                                (selectedVehicle.other_cost || 0)
                              )}
                            </Typography>
                            <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <TrendingUpIcon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">Profit</Typography>
                        </Box>
                        <Typography 
                          variant="body2" 
                          sx={{ color: (selectedVehicle.profit || 0) > 0 ? 'success.main' : 'error.main' }}
                        >
                          {formatPrice(selectedVehicle.profit || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  
                  {filteredSales.length > 1 && (
                    <Paper 
                      elevation={1} 
                      sx={{ 
                        p: 1.5,
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 10,
                        mt: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {filteredSales.length > 0 && (
                          <Button 
                            variant="outlined" 
                            size="small"
                            startIcon={<ArrowBackIcon />}
                            disabled={filteredSales.length <= 1}
                            onClick={() => {
                              const currentIndex = filteredSales.findIndex(v => v.id === selectedVehicle.id);
                              if (currentIndex > 0) {
                                setSelectedVehicle(filteredSales[currentIndex - 1]);
                              }
                            }}
                          >
                            Previous
                          </Button>
                        )}
                        {filteredSales.length > 0 && (
                          <Button 
                            variant="outlined" 
                            size="small"
                            endIcon={<ArrowForwardIcon />}
                            disabled={filteredSales.length <= 1}
                            onClick={() => {
                              const currentIndex = filteredSales.findIndex(v => v.id === selectedVehicle.id);
                              if (currentIndex < filteredSales.length - 1) {
                                setSelectedVehicle(filteredSales[currentIndex + 1]);
                              }
                            }}
                          >
                            Next
                          </Button>
                        )}
                      </Box>
                    </Paper>
                  )}
                </Stack>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Stock Number</Typography>
                    <Typography variant="body1">{selectedVehicle.stock_number}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">VIN</Typography>
                    <Typography variant="body1">{selectedVehicle.vin}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                    <Typography variant="body1">{selectedVehicle.status}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Sold Date</Typography>
                    <Typography variant="body1">
                      {selectedVehicle.sold_date ? new Date(selectedVehicle.sold_date).toLocaleDateString() : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Seller</Typography>
                    <Typography variant="body1">{selectedVehicle.who || 'N/A'}</Typography>  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Sold By</Typography>
                    <Typography variant="body1">{selectedVehicle.sold_by_user?.name || 'N/A'}</Typography>  
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Sold To</Typography>
                    <Typography variant="body1">{selectedVehicle.sold_to_user?.name || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">List Price</Typography>
                    <Typography variant="body1">{formatPrice(selectedVehicle.optic_list_price || 0)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Buy Price</Typography>
                    <Typography variant="body1">{formatPrice(selectedVehicle.seller_asking_price || 0)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Additional Costs</Typography>
                    <Tooltip
                      title={
                        <div>
                          <Typography variant="caption" display="block">
                            Repair: {formatPrice(selectedVehicle.repair_cost || 0)}
                          </Typography>
                          <Typography variant="caption" display="block">
                            Shipping: {formatPrice(selectedVehicle.shipping_cost || 0)}
                          </Typography>
                          <Typography variant="caption" display="block">
                            Rental: {formatPrice(selectedVehicle.rental_cost || 0)}
                          </Typography>
                          <Typography variant="caption" display="block">
                            Other: {formatPrice(selectedVehicle.other_cost || 0)}
                          </Typography>
                        </div>
                      }
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                          {formatPrice(
                            (selectedVehicle.repair_cost || 0) + 
                            (selectedVehicle.shipping_cost || 0) + 
                            (selectedVehicle.rental_cost || 0) + 
                            (selectedVehicle.other_cost || 0)
                          )}
                        </Typography>
                        <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">Profit</Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ color: (selectedVehicle.profit || 0) > 0 ? 'success.main' : 'error.main' }}
                    >
                      {formatPrice(selectedVehicle.profit || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions sx={{ display: isMobile ? 'none' : 'flex' }}>
              <Button onClick={handleCloseVehicleDialog}>Close</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              pb: 1
            }}>
              <Typography variant="h6">All Sales</Typography>
              {isMobile && (
                <IconButton edge="end" color="inherit" onClick={handleCloseVehicleDialog} aria-label="close">
                  <CloseIcon />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 2 }}>
                <TableContainer component={Paper} elevation={0} sx={{ maxHeight: '70vh' }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Vehicle</TableCell>
                        <TableCell>Stock #</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Seller</TableCell>
                        <TableCell align="right">Sale Price</TableCell>
                        <TableCell align="right">Profit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredSales.map((vehicle) => (
                        <TableRow 
                          key={vehicle.id} 
                          hover 
                          onClick={() => setSelectedVehicle(vehicle)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell>
                            {vehicle.sold_date ? new Date(vehicle.sold_date).toLocaleDateString() : 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                maxWidth: 'none',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {vehicle.year} {vehicle.make} {vehicle.model}
                            </Typography>
                          </TableCell>
                          <TableCell>{vehicle.stock_number || 'N/A'}</TableCell>
                          <TableCell>{vehicle.sold_to_user?.name || 'N/A'}</TableCell>
                          <TableCell>{vehicle.sold_by_user?.name || 'N/A'}</TableCell>
                          <TableCell align="right">
                            {formatPrice(vehicle.optic_list_price || 0)}
                          </TableCell>
                          <TableCell 
                            align="right" 
                            sx={{ 
                              color: (vehicle.profit || 0) > 0 ? 'success.main' : 'error.main',
                              fontWeight: 'bold'
                            }}
                          >
                            {formatPrice(vehicle.profit || 0)}
                          </TableCell>
                        </TableRow>
                      ))}
                      {filteredSales.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            <Typography variant="subtitle1" sx={{ py: 2 }}>
                              No sales data found matching the current filters
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </DialogContent>
            <DialogActions sx={{ display: isMobile ? 'none' : 'flex' }}>
              <Button onClick={handleCloseVehicleDialog}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Card>
  )
}

export default SalesOverview
