import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InventoryIcon from '@mui/icons-material/Inventory'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Box, Card, CardContent, CardHeader, Divider, Grid, Tooltip } from '@mui/material'
import { formatPrice } from 'core/utils/inventoryUtils'
import { useInventory } from 'hooks/inventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useExpenses } from 'hooks/useExpenses'
import React, { useMemo } from 'react'
import { MetricCard, MobileViewSelector } from '../../shared'
import { StockOverview } from './views'

interface InventoryTabProps {
  dateRange?: {
    start: Date
    end: Date
  }
}

export const InventoryTab: React.FC<InventoryTabProps> = ({ 
  dateRange
}) => {
  const isMobile = useIsMobile();
  const { vehicles } = useInventory('master_inventory')

  const stockVehicles = useMemo(() => {
    if (!vehicles) return []
    
    const today = new Date()
    const endDateToUse = dateRange?.end && dateRange.end < today ? dateRange.end : today
    
    return vehicles.filter(v => {
      // Filter out sold vehicles
      if (v.status === 'Sold' || v.status === 'Sold elsewhere') return false
      
      // Check if the vehicle was added before or on the end date
      const vehicleDate = v.date_added ? new Date(v.date_added) : null
      if (!vehicleDate) return true // Include if no date
      
      return vehicleDate <= endDateToUse
    })
  }, [vehicles, dateRange])

  
  // Calculate total vehicle costs for filtered vehicles
  const totalVehicleCosts = stockVehicles.reduce((sum, vehicle) => 
    sum + (vehicle.repair_cost || 0) + 
          (vehicle.shipping_cost || 0) + 
          (vehicle.rental_cost || 0) + 
          (vehicle.other_cost || 0), 0
  );
  
  // Calculate costs breakdown for tooltip
  const costsBreakdown = stockVehicles.reduce(
    (acc, vehicle) => {
      acc.repair += vehicle.repair_cost || 0;
      acc.shipping += vehicle.shipping_cost || 0;
      acc.rental += vehicle.rental_cost || 0;
      acc.other += vehicle.other_cost || 0;
      return acc;
    },
    { repair: 0, shipping: 0, rental: 0, other: 0 }
  );
  
  // Create the metrics card component to reuse in both views
  const InventoryMetricsCard = () => (
    <Card>
      <CardHeader title="Inventory Metrics" />
      <Divider />
      <CardContent>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={6} md={4}>
            <MetricCard 
              title="Total Inventory" 
              value={stockVehicles.length.toString()} 
              icon={<InventoryIcon sx={{ fontSize: isMobile ? 20 : 24 }} />} 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MetricCard 
              title="Vehicle Costs" 
              value={
                <Tooltip
                  title={
                    <div>
                      <div>Purchase: {formatPrice(stockVehicles.reduce((sum, v) => sum + (v.seller_asking_price || 0), 0))}</div>
                      <div>Repair: {formatPrice(costsBreakdown.repair)}</div>
                      <div>Shipping: {formatPrice(costsBreakdown.shipping)}</div>
                      <div>Rental: {formatPrice(costsBreakdown.rental)}</div>
                      <div>Other: {formatPrice(costsBreakdown.other)}</div>
                    </div>
                  }
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {formatPrice(totalVehicleCosts + stockVehicles.reduce((sum, v) => sum + (v.seller_asking_price || 0), 0))}
                    <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                  </Box>
                </Tooltip>
              }
              icon={<AttachMoneyIcon sx={{ fontSize: isMobile ? 20 : 24 }} />} 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MetricCard 
              title="Potential Profit" 
              value={formatPrice(stockVehicles
                .filter(v => v.status === 'Active Deal' || v.status === 'Pending Sale')
                .reduce((sum, v) => {
                  const listPrice = v.optic_list_price || 0;
                  const buyPrice = v.seller_asking_price || 0;
                  const additionalCosts = (v.repair_cost || 0) + (v.shipping_cost || 0) + 
                                         (v.rental_cost || 0) + (v.other_cost || 0);
                  return sum + (listPrice - buyPrice - additionalCosts);
                }, 0))} 
              icon={<TrendingUpIcon sx={{ fontSize: isMobile ? 20 : 24 }} />} 
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
  
  // For mobile, we'll use the view selector with dropdown
  if (isMobile) {
    return (
      <Box sx={{ 
        maxWidth: '100%',
        overflowX: 'hidden'
      }}>
        <MobileViewSelector
          titles={["Stock Overview", "Inventory Metrics"]}
          icons={[
            <InventoryIcon fontSize="small" />,
            <MonetizationOnIcon fontSize="small" />
          ]}
        >
          <StockOverview stockVehicles={stockVehicles} dateRange={dateRange} />
          <InventoryMetricsCard />
        </MobileViewSelector>
      </Box>
    );
  }
  
  // Desktop view remains unchanged
  return (
    <Box sx={{ 
      maxWidth: '100%',
      overflowX: 'hidden'
    }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StockOverview stockVehicles={stockVehicles} dateRange={dateRange} />
        </Grid>
        <Grid item xs={12}>
          <InventoryMetricsCard />
        </Grid>
      </Grid>
    </Box>
  )
}

export default InventoryTab 