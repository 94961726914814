import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Snackbar,
  Alert,
  Dialog,
  Box,
  useTheme,
  Tooltip,
  Typography,
  DialogContentText,
  InputAdornment,
  CircularProgress,
  Paper,
} from '@mui/material'
import {
  AddComment,
  NotificationImportant,
  Block,
  Error as ErrorIcon,
  CheckCircle,
  DeleteForever,
  Warning,
  Search,
  People,
} from '@mui/icons-material'
import { PageTitle } from 'components/PageTitle'
import { useManageUsers } from 'hooks/admin/useManageUsers'
import { CreateUser } from './components/CreateUser'
import { AgGridReact } from 'ag-grid-react'
import { 
  ColDef, 
  GridApi, 
  GridReadyEvent,
  ICellRendererParams,
  ValueGetterParams,
  CellClickedEvent,
  IDatasource,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { CombinedUserData } from '@otw/models/user'
import { portalFunctions } from 'core/functions'

const booleanOptions = ['true', 'false']
const roles = ['client', 'bank', 'admin']

export const UserManagement = () => {
  const theme = useTheme()
  const gridApiRef = useRef<GridApi | null>(null)
  const {
    authUsers,
    authUsersLoading,
    authUsersError,
    updateUserMutation,
    disableUserMutation,
    enableUserMutation,
    deleteUserMutation,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    searchTerm,
    setSearchTerm,
  } = useManageUsers()

  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<CombinedUserData | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState<CombinedUserData | null>(null)
  const notesRef = useRef('')
  const [snackbar, setSnackbar] = useState<{
    open: boolean
    message: string
    severity: 'success' | 'error'
  }>({
    open: false,
    message: '',
    severity: 'success',
  })

  const isStaging = window.location.host.includes('stg')

  const handleOpen = useCallback((user: CombinedUserData) => {
    setSelectedUser(prevState => ({ ...prevState, ...user }))
    notesRef.current = user.notes || ''
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleDisableUser = useCallback(
    async (userId: string) => {
      try {
        await disableUserMutation.mutate(userId)
        setSnackbar({
          open: true,
          message: 'User disabled successfully',
          severity: 'success',
        })
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to disable user',
          severity: 'error',
        })
      }
    },
    [disableUserMutation],
  )

  const handleEnableUser = useCallback(
    async (userId: string) => {
      try {
        await enableUserMutation.mutate(userId)
        setSnackbar({
          open: true,
          message: 'User enabled successfully',
          severity: 'success',
        })
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to enable user',
          severity: 'error',
        })
      }
    },
    [enableUserMutation],
  )

  const handleSaveNotes = useCallback(async () => {
    if (selectedUser) {
      const userUpdate: CombinedUserData = {
        ...selectedUser,
        notes: notesRef.current,
      }
      await updateUserMutation.mutate(userUpdate)
    }
    handleClose()
  }, [selectedUser, handleClose])

  const handleChangeNotes = (event: { target: { value: string } }) => {
    notesRef.current = event.target.value
  }

  const handleResendVerificationEmail = (row: CombinedUserData) => async () => {
    try {
      await portalFunctions.admin.resendVerificationEmail(row.id)
      setSnackbar({
        open: true,
        message: 'Verification email sent successfully',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error resending verification email:', error)
      setSnackbar({
        open: true,
        message: 'Failed to send verification email',
        severity: 'error',
      })
    }
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  const handleCellValueChanged = async (params: any) => {
    try {
      const updatedUser = { ...params.data }
      await updateUserMutation.mutate(updatedUser)
      setSnackbar({
        open: true,
        message: 'User updated successfully',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error updating user:', error)
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to update user',
        severity: 'error',
      })
      // Refresh the grid to revert changes
      gridApiRef.current?.refreshCells({ force: true })
    }
  }

  const handleDeleteUser = async (user: CombinedUserData) => {
    setUserToDelete(user)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = async () => {
    if (!userToDelete) return

    try {
      await deleteUserMutation.mutate(userToDelete.id)
      setSnackbar({
        open: true,
        message: 'User deleted successfully',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error deleting user:', error)
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to delete user',
        severity: 'error',
      })
    } finally {
      setDeleteDialogOpen(false)
      setUserToDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
    setUserToDelete(null)
  }

  const ActionsRenderer = (props: ICellRendererParams) => {
    const user = props.data as CombinedUserData
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        {user.disabled ? (
          <Tooltip title="Enable User">
            <Button
              size="small"
              onClick={() => handleEnableUser(user.id)}
              startIcon={<CheckCircle color="success" />}
            >
              Enable
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Disable User">
            <Button
              size="small"
              onClick={() => handleDisableUser(user.id)}
              startIcon={<Block />}
            >
              Disable
            </Button>
          </Tooltip>
        )}
        <Tooltip title="Delete User">
          <Button
            size="small"
            color="error"
            onClick={() => handleDeleteUser(user)}
            startIcon={<DeleteForever />}
          >
            Delete
          </Button>
        </Tooltip>
        <Tooltip title="Add Notes">
          <Button
            size="small"
            onClick={() => handleOpen(user)}
            startIcon={<AddComment />}
          >
            Notes
          </Button>
        </Tooltip>
        <Tooltip title="Resend Verification Email">
          <Button
            size="small"
            onClick={handleResendVerificationEmail(user)}
            startIcon={<NotificationImportant />}
            disabled={user.auth?.emailVerified === true}
          >
            Verify
          </Button>
        </Tooltip>
      </Box>
    )
  }

  const EmailRenderer = (props: ICellRendererParams) => {
    const user = props.data as CombinedUserData
    if (user.error) {
      return (
        <Tooltip title={user.error}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ErrorIcon color="error" />
            {user.email}
          </Box>
        </Tooltip>
      )
    }
    return user.email
  }

  const columns: ColDef[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
      editable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    { 
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
      editable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'role',
      headerName: 'User Type',
      width: 120,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: roles,
      },
      filter: 'agSetColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      cellRenderer: EmailRenderer,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    { 
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      editable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'lastLoginAt',
      headerName: 'Last Login',
      width: 180,
      editable: false,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.data.lastLoginAt) return 'Never'
        return params.data.lastLoginAt
      },
      filter: 'agDateColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'disabled',
      headerName: 'Status',
      width: 130,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.disabled ? 'Disabled' : 'Active'
      },
      filter: 'agSetColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'auth.emailVerified',
      headerName: 'Email Verified',
      width: 130,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.auth?.emailVerified ? 'true' : 'false'
      },
      filter: 'agSetColumnFilter',
      floatingFilter: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 450,
      cellRenderer: ActionsRenderer,
      sortable: false,
      filter: false,
      editable: false,
    },
  ]

  const defaultColDef = {
    sortable: true,
    resizable: true,
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
  }, []);

  const memoizedRowData = useMemo(() => authUsers?.users || [], [authUsers?.users]);

  // Add custom status bar component
  const createStatusBar = () => {
    const loadedCount = memoizedRowData.length;
    const totalCount = authUsers.total || 0;
    const percentLoaded = totalCount > 0 ? Math.floor((loadedCount / totalCount) * 100) : 0;
    const isCompletelyLoaded = totalCount > 0 && loadedCount >= totalCount;
    
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          p: 1.5, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: '1px solid #ddd',
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
          <People fontSize="small" color="primary" />
          <Typography variant="body2" fontWeight={500}>
            {totalCount > 0 
              ? `${loadedCount} of ${totalCount} users (${percentLoaded}%)` 
              : `${loadedCount} users loaded`
            }
          </Typography>
          {isFetchingNextPage && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <CircularProgress size={16} sx={{ mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                Loading more...
              </Typography>
            </Box>
          )}
        </Box>
        
        <Typography variant="caption" color="text.secondary">
          {isCompletelyLoaded 
            ? "All users loaded" 
            : hasNextPage 
              ? "Loading 20 users at a time" 
              : "Loading complete"
          }
        </Typography>
      </Paper>
    );
  };

  if (authUsersLoading && !isFetchingNextPage && !memoizedRowData.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading users...</Typography>
      </Box>
    );
  }

  if (authUsersError) {
    return <div>Error loading users</div>
  }

  console.log(authUsers?.users)

  return (
    <>
      <PageTitle
        title="User Management"
      />

      <CreateUser />

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <TextField
          placeholder="Search users..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          sx={{ width: 300 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent sx={{ width: '550px' }}>
          <TextField
            autoFocus
            margin="dense"
            id="notes"
            label="Notes"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={10}
            defaultValue={notesRef.current}
            onChange={handleChangeNotes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveNotes}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the user {userToDelete?.email}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ height: 'calc(100vh - 300px)', width: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <div
          className={theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
          style={{ height: '100%', width: '100%', flex: 1 }}
        >
          <AgGridReact
            rowData={memoizedRowData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onCellValueChanged={handleCellValueChanged}
            pagination={false}
            rowSelection="multiple"
            enableCellTextSelection={true}
            suppressRowClickSelection={true}
            animateRows={false}
            suppressScrollOnNewData={true}
            rowBuffer={50}
            getRowId={(params) => params.data.id}
            overlayNoRowsTemplate="No users found"
          />
        </div>
        
        {/* Status bar */}
        {createStatusBar()}
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}
