import { Lead, LeadStatus, StatusHistory } from '@otw/models/leads/lead'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { db } from 'config/firebase'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { addDoc, collection, deleteDoc, doc, getDocs, Timestamp, updateDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { leadConverter } from '@otw/models/converters'

interface UseLeadsReturn {
  leads: Lead[] | undefined
  isLoading: boolean
  error: Error | null
  updateLead: ({ id, updates, statusChangeNote }: { id: string; updates: Partial<Lead>; statusChangeNote?: string }) => Promise<{ id: string } & Partial<Lead>>
  createLead: (lead: Omit<Lead, 'id' | 'createdAt' | 'updatedAt' | 'statusHistory' | 'prevStatus'>) => Promise<Lead>
  deleteLead: (id: string) => Promise<string>
  getLeadsByAssignedRep: (repId: number) => Lead[] | undefined
  getLeadsByStatus: (status: LeadStatus) => Lead[] | undefined
  getLeadsByApplicationId: (applicationId: string) => Lead[] | undefined
}

export const useLeads = (): UseLeadsReturn => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()
  const queryKey = ['leads']

  const {
    data: leads,
    isLoading,
    error,
  } = useQuery<Lead[]>({
    queryKey,
    queryFn: async () => {
      const leadsRef = collection(db, 'leads').withConverter(leadConverter)
      const snapshot = await getDocs(leadsRef)
      return snapshot.docs.map(doc => doc.data())
    },
  })

  const updateLeadMutation = useMutation({
    mutationFn: async ({ id, updates, statusChangeNote }: { id: string; updates: Partial<Lead>; statusChangeNote?: string }) => {
      const leadRef = doc(db, 'leads', id).withConverter(leadConverter)
      
      // Get the current lead data to track status changes
      const currentLead = leads?.find(lead => lead.id === id)
      const now = new Date()
      
      // Create status history entry if status is changing
      let statusHistory = [...(currentLead?.statusHistory || [])]
      if (updates.status && currentLead && updates.status !== currentLead.status) {
        const historyEntry: StatusHistory = {
          id: uuidv4(),
          previousStatus: currentLead.status,
          newStatus: updates.status,
          changedBy: userInfo?.id || 'unknown',
          changedAt: now,
          notes: statusChangeNote,
        }
        statusHistory = [...statusHistory, historyEntry]
        
        // Update prevStatus
        updates.prevStatus = currentLead.status
      }
      
      // Ensure we properly handle the truck request data
      if (updates.truckRequest && currentLead?.truckRequest) {
        // Make sure we preserve the types array if it exists in the current lead
        if (currentLead.truckRequest.types && !updates.truckRequest.types) {
          updates.truckRequest.types = currentLead.truckRequest.types;
        }
        
        // If we have truck types in the update, ensure they're properly formatted
        if (updates.truckRequest.types) {
          updates.truckRequest.types = updates.truckRequest.types.map(type => ({
            ...type,
            // Convert string budget to number if needed with proper type checking
            budget: typeof type.budget === 'string' ? 
              Number(String(type.budget).replace(/,/g, '')) : 
              type.budget
          }));
        }
      }
      
      // Update lastActionDate when significant changes occur
      const significantChange = updates.status || updates.assignedRepId || updates.followUpNotes
      
      // Prepare the update data
      const updateData = {
        ...updates,
        statusHistory: statusHistory.length > 0 ? statusHistory : currentLead?.statusHistory,
        updatedAt: now,
        ...(significantChange ? { lastActionDate: now } : {})
      }
      
      await updateDoc(leadRef, updateData as any)
      return { id, ...updates }
    },
    onMutate: async ({ id, updates }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousLeads = queryClient.getQueryData<Lead[]>(queryKey)

      queryClient.setQueryData<Lead[]>(queryKey, old => {
        if (!old) return old
        return old.map(lead => (lead.id === id ? { ...lead, ...updates } : lead))
      })

      return { previousLeads }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousLeads)
      showSnackbar('Error updating lead', 'error')
    },
    onSuccess: () => {
      showSnackbar('Lead updated successfully', 'success')
    },
  })

  const createLeadMutation = useMutation({
    mutationFn: async (lead: Omit<Lead, 'id' | 'createdAt' | 'updatedAt' | 'statusHistory' | 'prevStatus'>) => {
      const leadsRef = collection(db, 'leads').withConverter(leadConverter)
      const now = new Date()
      
      // Create initial status history entry
      const initialStatusHistory: StatusHistory = {
        id: uuidv4(),
        previousStatus: null,
        newStatus: lead.status || 'new',
        changedBy: userInfo?.id || 'unknown',
        changedAt: now,
        notes: 'Lead created',
      }
      
      const newLead = {
        ...lead,
        createdAt: now,
        updatedAt: now,
        lastActionDate: now,
        status: lead.status || 'new',
        prevStatus: null,
        truckIds: lead.truckIds || [],
        followUpNotes: lead.followUpNotes || [],
        statusHistory: [initialStatusHistory],
        applicationIds: lead.applicationIds || [],
        tags: lead.tags || [],
        createdBy: userInfo?.id || 'unknown',
      } as Lead
      
      const docRef = await addDoc(leadsRef, newLead)
      
      return { 
        ...newLead, 
        id: docRef.id,
      } as Lead
    },
    onMutate: async (newLead) => {
      await queryClient.cancelQueries({ queryKey })
      const previousLeads = queryClient.getQueryData<Lead[]>(queryKey)

      const optimisticLead = {
        ...newLead,
        id: `temp-${Date.now()}`,
        createdAt: new Date(),
        updatedAt: new Date(),
        statusHistory: [{
          id: uuidv4(),
          previousStatus: null,
          newStatus: newLead.status || 'new',
          changedBy: userInfo?.id || 'unknown',
          changedAt: new Date(),
          notes: 'Lead created',
        }],
        prevStatus: null,
      } as Lead

      queryClient.setQueryData<Lead[]>(queryKey, old => [...(old || []), optimisticLead])

      return { previousLeads, optimisticLead }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousLeads)
      showSnackbar('Error creating lead', 'error')
    },
    onSuccess: () => {
      showSnackbar('Lead created successfully', 'success')
    },
  })

  const deleteLeadMutation = useMutation({
    mutationFn: async (id: string) => {
      const leadRef = doc(db, 'leads', id).withConverter(leadConverter)
      await deleteDoc(leadRef)
      return id
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey })
      const previousLeads = queryClient.getQueryData<Lead[]>(queryKey)

      queryClient.setQueryData<Lead[]>(queryKey, old => {
        if (!old) return old
        return old.filter(lead => lead.id !== id)
      })

      return { previousLeads }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousLeads)
      showSnackbar('Error deleting lead', 'error')
    },
    onSuccess: () => {
      showSnackbar('Lead deleted successfully', 'success')
    },
  })

  // Helper functions to filter leads
  const getLeadsByAssignedRep = (repId: number) => {
    return leads?.filter(lead => lead.assignedRepId === repId)
  }

  const getLeadsByStatus = (status: LeadStatus) => {
    return leads?.filter(lead => lead.status === status)
  }

  const getLeadsByApplicationId = (applicationId: string) => {
    return leads?.filter(lead => lead.applicationIds.includes(applicationId))
  }

  return {
    leads,
    isLoading,
    error: error as Error | null,
    updateLead: updateLeadMutation.mutateAsync,
    createLead: createLeadMutation.mutateAsync,
    deleteLead: deleteLeadMutation.mutateAsync,
    getLeadsByAssignedRep,
    getLeadsByStatus,
    getLeadsByApplicationId,
  }
}
