import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'config/firebase'
import { useAuth } from 'hooks/auth/useAuth'
import { ClientUser, PrivateClientFinancingProfile } from '@otw/models'
import { useClientPrivateFinancingProfile } from './useClientPrivateFinancingProfile'
import { useSessionStorageFormState } from 'hooks/useSessionStorage'

type FormDataType = Omit<PrivateClientFinancingProfile, 'id' | 'date_created' | 'date_modified' | 'user_id'>

const initialFormData: FormDataType = {
  // Company Information
  us_citizen: false,
  bankruptcy: false,
  liens: false,
  business_name: '',
  entity_structure: '',
  business_start_date: '',
  federal_tax_id: '',
  primary_business_contact: '',
  business_phone: '',
  business_email: '',
  business_address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'United States',
  },

  // Personal Information
  personal_info: {
    first_name: '',
    last_name: '',
    ssn: '',
    date_of_birth: '',
    home_address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'United States',
    },
    phone: '',
    title: '',
    ownership_percentage: 0,
    alternate_email_address: '',
  },

  last_year_revenue: 0,
  last_year_expenses: 0,
  last_year_net_income: 0,
  has_fleet: false,
  fleet_quantity: 0,
  fleet_value: 0,
  assets: {
    checking_savings: 0,
    investments: 0,
    vehicles: 0,
    real_estate: 0,
    company_equity: 0,
    other_assets: 0,
  },
  liabilities: {
    credit_cards: 0,
    line_of_credit: 0,
    vehicle_loans: 0,
    mortgages: 0,
    notes_payable: 0,
    other_liabilities: 0,
  },
  has_additional_owners: false,
  additionalOwners: [],
  fleet_list_file: '',
}

const ONBOARDING_STORAGE_KEY = 'otw-onboarding-progress'

export const useClientOnboarding = () => {
  const { userInfo } = useAuth()
  const navigate = useNavigate()
  const { financingProfile, createFinancingProfile } = useClientPrivateFinancingProfile()
  const [formData, setFormData, clearFormData] = useSessionStorageFormState<FormDataType>(
    ONBOARDING_STORAGE_KEY,
    initialFormData,
  )

  // useEffect(() => {
  //   clearFormData()
  //   if (financingProfile && !formData) {
  //     console.log('financingProfile', financingProfile)
  //     setFormData(financingProfile)
  //   }
  // }, [financingProfile])

  // const [formData, setFormData] = useState<ClientOnboardingData>(() => {
  //   const savedState = localStorage.getItem(STORAGE_KEY)

  //   if (financingProfile) {
  //     return {
  //       ...financingProfile,
  //     }
  //   }

  //   if (savedState) {
  //     return JSON.parse(savedState)
  //   }

  //   return {
  //     company_name: '',
  //     entity_structure: '',
  //     address: {
  //       street: '',
  //       city: '',
  //       state: '',
  //       zipCode: '',
  //       country: 'United States',
  //     },
  //   }
  // })

  const handleComplete = async () => {
    if (!userInfo?.id) return

    const formattedAddress = [
      formData.business_address.street,
      formData.business_address.city,
      formData.business_address.state,
      formData.business_address.zipCode,
      formData.business_address.country,
    ]
      .filter(Boolean)
      .join(', ')

    try {
      // Create financing profile first
      console.log('formData on submit', formData)
      await createFinancingProfile({
        ...formData, // This will include all required fields from PrivateClientFinancingProfile
        business_start_date: new Date().toISOString(),
        primary_business_contact: `${formData.personal_info.first_name} ${formData.personal_info.last_name}`,
      })

      // After financing profile is created, update user info
      const userRef = doc(db, 'users', userInfo.id)

      const privateUserInfoUpdate: Partial<ClientUser> = {
        company_name: formData.business_name,
        entity_structure: formData.entity_structure,
        address: {
          ...formData.business_address,
          formattedAddress,
        },
        needsOnboarding: false,
        onboardingCompletedAt: new Date().toISOString(),
      }

      await updateDoc(userRef, privateUserInfoUpdate)

      // Clear form data from session storage after successful completion
      // clearFormData()

      // Navigate to dashboard
      // window.location.href = '/client'
    } catch (error) {
      console.error('Error completing onboarding:', error)
      throw error
    }
  }

  return {
    formData,
    setFormData,
    clearFormData,
    handleComplete,
  }
}
