import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore'
// these were used previously and were changed to the ones below blindly by @adamsiwiec1
// import logo1 from '../../../assets/LogoVariation-OpticTruckWorks-02.png'
// import icon from '../../../assets/Icon-OpticTruckWorks-03.png'
// import {
//   logoNoBackground,
//   opticTruckBlue,
// } from '../../../assets'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import opticTruckBlue from '../../../assets/icon-otw.png'
import opticTruckRed from '../../../assets/red-icon-otw.png'
import { db } from 'config/firebase'
import { vehicleConverter } from '@otw/models/converters'

// Base inventory item interface with common properties
interface InventoryItem {
  stock_number: string
  make: string
  model: string
  size: string
  fuel: string
  miles: string
  year: string
  condition: string
  location: string
  type: string
  status: string
}

// Master inventory extends base with Optic pricing
interface MasterInventoryItem extends InventoryItem {
  optic_list_price: string
}

// Shield inventory extends base with seller information
interface ShieldInventoryItem extends InventoryItem {
  seller_asking_price: string
  who: string
  email: string
  phone_number: string
}

// Formatting helpers
const formatCurrency = (value: string | number | undefined): string => {
  if (value === undefined) return ''
  const numValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]+/g, '')) : value
  return isNaN(numValue) ? '' : numValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

const formatNumber = (value: number | string): string => {
  const numValue = typeof value === 'string' ? parseInt(value.replace(/[^0-9-]+/g, ''), 10) : value
  return numValue.toLocaleString('en-US')
}

// Column definitions for better readability
const COMMON_COLUMNS = [
  { header: 'Stock Number', key: 'stock_number', width: 16 },
  { header: 'Make', key: 'make', width: 15 },
  { header: 'Model', key: 'model', width: 20 },
  { header: 'Size', key: 'size', width: 15 },
  { header: 'Fuel', key: 'fuel', width: 10 },
  { header: 'Miles', key: 'miles', width: 15, align: 'left', format: formatNumber },
  { header: 'Year', key: 'year', width: 10, align: 'center' },
  { header: 'Condition', key: 'condition', width: 15 },
  { header: 'Location', key: 'location', width: 15 },
  { header: 'Type', key: 'type', width: 15 },
  { header: 'Status', key: 'status', width: 15 },
]

const MASTER_COLUMNS = [
  ...COMMON_COLUMNS,
  { header: 'Sale Price', key: 'optic_list_price', width: 20, align: 'left', format: formatCurrency },
]

const SHIELD_COLUMNS = [
  ...COMMON_COLUMNS,
  { header: 'Price', key: 'seller_asking_price', width: 20, align: 'left', format: formatCurrency },
  { header: 'Seller Name', key: 'who', width: 25 },
  { header: 'Email', key: 'email', width: 30 },
  { header: 'Phone', key: 'phone_number', width: 20 },
]

const convertToPDF = (items: (MasterInventoryItem | ShieldInventoryItem)[]): jsPDF => {
  const isMasterInventory = 'optic_list_price' in items[0]
  const columns = isMasterInventory ? MASTER_COLUMNS : SHIELD_COLUMNS

  // Extract headers from column definitions
  const headers = columns.map(col => col.header)

  // Create rows with proper formatting
  const rows = items.map(item => {
    return columns.map(col => {
      const value = item[col.key as keyof typeof item]
      if (col.format && value !== undefined) {
        return col.format(value)
      }
      return value || ''
    })
  })

  // Create PDF document
  const doc = new jsPDF({
    orientation: 'landscape', // Use landscape for more horizontal space
    unit: 'mm',
    format: 'a4',
    compress: true,
  })
  doc.setFontSize(10)

  // Get page dimensions
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  
  // Define logo sizes and positions
  const topLogoSize = 24
  const topLogoY = 5
  const topLogoX = (pageWidth - topLogoSize) / 2
  
  const bottomLogoSize = 12
  const bottomLogoY = pageHeight - 18
  const bottomLogoX = (pageWidth - bottomLogoSize) / 2
  
  // Define table margins to avoid overlap with logos
  const tableTopMargin = topLogoY + topLogoSize + 5
  const tableBottomMargin = 25
  
  // Calculate available width for the table (accounting for page margins)
  const availableWidth = pageWidth - 20 // 10mm margin on each side
  
  // Calculate relative widths based on column definitions
  const totalDefinedWidth = columns.reduce((sum, col) => sum + (col.width || 10), 0)
  
  // Create dynamic column styles with proportional widths
  const columnStyles: Record<number, any> = {}
  columns.forEach((col, index) => {
    // Calculate proportional width to fill the entire available space
    const proportionalWidth = ((col.width || 10) / totalDefinedWidth) * availableWidth
    
    columnStyles[index] = {
      cellWidth: proportionalWidth,
      halign: col.align || 'left',
      overflow: 'linebreak',
      cellPadding: 1,
      fontSize: 7, // Smaller font size to ensure everything fits
    }
  })

  // Add top logo to the page
  const addTopLogo = (doc: jsPDF) => {
    doc.addImage(opticTruckBlue, 'PNG', topLogoX, topLogoY, topLogoSize, topLogoSize)
  }
  
  // Add bottom logo to the page
  const addBottomLogo = (doc: jsPDF) => {
    doc.addImage(opticTruckRed, 'PNG', bottomLogoX, bottomLogoY, bottomLogoSize, bottomLogoSize)
  }

  let firstPage = true

  // Generate table
  doc.autoTable({
    head: [headers],
    body: rows,
    startY: tableTopMargin,
    theme: 'grid',
    headStyles: {
      fillColor: [52, 73, 94],
      textColor: 255,
      fontSize: 7, // Smaller font size for headers
      cellPadding: 1.5,
      lineWidth: 0.1,
      lineColor: [200, 200, 200],
      minCellHeight: 8,
      valign: 'middle',
    },
    styles: {
      fontSize: 7, // Smaller font size for all content
      cellPadding: 1.5,
      lineWidth: 0.1,
      lineColor: [200, 200, 200],
      minCellHeight: 7,
      overflow: 'linebreak',
      font: 'helvetica',
    },
    columnStyles: columnStyles,
    alternateRowStyles: { fillColor: [242, 242, 242] },
    margin: { left: 10, right: 10, bottom: tableBottomMargin },
    didDrawPage: (data: { pageNumber: number; settings: { startY: number }; pageCount: number }) => {
      // Add top logo only on first page
      if (data.pageNumber === 1) {
        addTopLogo(doc)
      }
      
      // Add bottom logo on all pages
      addBottomLogo(doc)
      
      // Adjust start position for content on subsequent pages
      if (firstPage) {
        firstPage = false
      } else {
        data.settings.startY = 15
      }
    },
    willDrawCell: (data: {
      column: { index: number }
      cell: { text: string[]; styles: { cellPadding: number; fontSize: number; overflow: string } }
      row: { index: number }
      section: string
    }) => {
      // Special handling for email column in Shield inventory
      if (isMasterInventory === false && data.column.index === columns.findIndex(col => col.key === 'email')) {
        data.cell.styles.fontSize = 6 // Even smaller font for email addresses
      }
      
      // Ensure headers don't break mid-word
      if (data.section === 'head') {
        data.cell.styles.overflow = 'ellipsize'
      }
    },
    tableWidth: 'auto', // Let the table calculate its width based on content and available space
    bodyStyles: {
      maxCellHeight: pageHeight - tableTopMargin - tableBottomMargin,
    },
    horizontalPageBreak: false, // Disable horizontal page breaks
  })

  return doc
}

export const downloadShareableInventoryPdf = async (
  collectionName: 'master_inventory' | 'shield_inventory',
  ids: string[],
) => {
  const inventoryCollection = collection(db, collectionName).withConverter(vehicleConverter)

  const batchSize = 30
  const inventoryItems: (MasterInventoryItem | ShieldInventoryItem)[] = []

  // Process in batches to avoid query limitations
  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize)
    const inventoryQuery = query(inventoryCollection, where('__name__', 'in', batchIds))
    const querySnapshot = await getDocs(inventoryQuery)

    querySnapshot.forEach(doc => {
      const vehicle = doc.data()
      // @ts-expect-error ignore for now until data cleaning
      const fuel = vehicle.fuel === 'Gasoline' ? 'Gas' : vehicle.fuel || ''

      // Common properties for both inventory types
      const baseItem: InventoryItem = {
        stock_number: vehicle.stock_number || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        size: vehicle.size || '',
        fuel: fuel,
        miles: String(vehicle.miles || ''),
        year: String(vehicle.year || ''),
        condition: vehicle.condition || '',
        location: vehicle.location || '',
        type: vehicle.type || '',
        status: vehicle.status || '',
      }

      // Add type-specific properties
      const item =
        collectionName === 'master_inventory'
          ? ({
              ...baseItem,
              optic_list_price: vehicle.optic_list_price || '',
            } as MasterInventoryItem)
          : ({
              ...baseItem,
              seller_asking_price: vehicle.seller_asking_price || '',
              who: vehicle.who || '',
              email: vehicle.email || '',
              phone_number: vehicle.phone_number || '',
            } as ShieldInventoryItem)
      inventoryItems.push(item)
    })
  }

  const doc = convertToPDF(inventoryItems)

  // Use a more descriptive filename based on inventory type
  const filename = collectionName === 'master_inventory' ? 'optic_inventory.pdf' : 'shield_inventory.pdf'

  doc.save(filename)
}
