import BuildIcon from '@mui/icons-material/Build'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Box, Card, CardContent, Chip, Typography, Button } from '@mui/material'
import { ApplicationStatus, Application } from '@otw/models'
import React from 'react'
import { STATUS_COLORS } from '../../constants'
import { useClientApplication } from 'hooks/client/useClientApplication'
import { format } from 'date-fns'

interface ApplicationCardProps {
  application: Application
  onClick?: () => void
}

const getStatusStyles = (status: ApplicationStatus) => {
  const color = STATUS_COLORS[status] || '#000'

  const icons = {
    draft: <EditIcon />,
    ready: <TaskAltIcon />,
    corrections_needed: <BuildIcon />,
    pending: <HourglassEmptyIcon />,
    viewed: <VisibilityIcon />,
    approved: <CheckCircleIcon />,
    denied: <ClearIcon />,
    signing: <HourglassEmptyIcon />,
  }

  const labels = {
    draft: 'Draft',
    ready: 'Ready',
    corrections_needed: 'Corrections Needed',
    pending: 'Pending',
    viewed: 'Viewed',
    approved: 'Approved',
    denied: 'Denied',
    signing: 'Signing',
  }

  return {
    color,
    icon: icons[status],
    label: labels[status] || 'Unknown',
  }
}

const formatDate = (dateString: string) => {
  try {
    return format(new Date(dateString), 'MMM d, yyyy h:mm a')
  } catch (e) {
    return 'Invalid Date'
  }
}
const ApplicationCard: React.FC<ApplicationCardProps> = ({ application, onClick }) => {
  const statusStyles = getStatusStyles(application.status)

  const { banks } = useClientApplication()

  const bank = banks.find(bank => bank.id === application.bank_id)

  return (
    <Card
      sx={{ marginBottom: 2, boxShadow: 2, borderLeft: 4, borderColor: statusStyles.color, cursor: 'pointer' }}
      onClick={onClick}
    >
      <CardContent>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <Typography variant='h5' gutterBottom>
                {bank?.name}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <Chip
                {...(statusStyles.icon ? { icon: statusStyles.icon } : {})}
                label={statusStyles.label}
                color='default'
                sx={{ color: statusStyles.color, fontWeight: 'bold' }}
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1'>
              <strong>Date:</strong> {formatDate(application.time_created)}
            </Typography>
          </Box>

          <Box display='flex' justifyContent='center' mt={1}>
            {application.status === 'signing' ? (
              <Button
                variant='contained'
                color='primary'
                startIcon={<PlayArrowIcon />}
                onClick={e => {
                  e.stopPropagation()
                  if (application.pandadoc_url) {
                    onClick?.()
                  }
                }}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  boxShadow: theme => theme.shadows[4],
                  '&:hover': {
                    boxShadow: theme => theme.shadows[8],
                  },
                }}
                disabled={!application.pandadoc_url}
              >
                Open Application
              </Button>
            ) : (
              <Button
                variant='outlined'
                color={application.status === 'corrections_needed' ? 'error' : 'primary'}
                startIcon={application.status === 'corrections_needed' ? <BuildIcon /> : <VisibilityIcon />}
                onClick={e => {
                  e.stopPropagation()
                  if (application.pandadoc_url) {
                    onClick?.()
                  }
                }}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor:
                      application.status === 'corrections_needed'
                        ? 'rgba(211, 47, 47, 0.04)'
                        : 'rgba(25, 118, 210, 0.04)',
                  },
                }}
                disabled={!application.pandadoc_url}
              >
                {application.status === 'corrections_needed' ? 'Make Corrections' : 'View Application'}
              </Button>
            )}
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Typography variant='caption' sx={{ opacity: 0.7 }}>
            Ref #: [{application.document_uuid}]
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ApplicationCard
