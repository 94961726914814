import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Grid,
  MenuItem,
  Alert,
  Paper,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import { useClientOnboarding } from 'hooks/client/useClientOnboarding'
import { useSessionStorageFormState } from 'hooks/useSessionStorage'
import { useClientPrivateFinancingProfile } from 'hooks/client/useClientPrivateFinancingProfile'
import ReviewStep from './components/ReviewStep'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'config/firebase'
import { useNavigate } from 'react-router-dom'
import { ClientUser, PrivateClientFinancingProfile } from '@otw/models'
import { useAuth } from 'hooks/auth/useAuth'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  InfoOutlined as InfoIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSessionStorageStringState } from 'hooks/useSessionStorage'

// Define the type for our form data
type FormDataType = Omit<PrivateClientFinancingProfile, 'id' | 'date_created' | 'date_modified' | 'user_id' | 'personal_info'> & {
  fleet_list_file?: string;
  personal_info?: {
    first_name: string;
    last_name: string;
    date_of_birth: string;
    ssn: string;
    phone: string;
    home_address?: {
      street: string;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
    title?: string; // Make title optional to match our schema
    ownership_percentage: number | string;
    alternate_email_address?: string;
  };
}

// Define the type for additional owners based on the model
type AdditionalOwnerType = {
  first_name: string
  last_name: string
  email: string
  phone: string
  ownership_percentage: number | string
  title?: string
  user_id?: string
}

// Define asset and liability fields
const assetFields = ['checking_savings', 'investments', 'vehicles', 'real_estate', 'company_equity', 'other_assets']

const liabilityFields = [
  'credit_cards',
  'line_of_credit',
  'vehicle_loans',
  'mortgages',
  'notes_payable',
  'other_liabilities',
]

const steps = ['Welcome', 'Personal Information', 'Company Information', 'Financing Profile', 'Review']

const entityStructures = ['Sole Proprietorship', 'LLC', 'Corporation', 'Partnership']

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

// Add validation schemas after the imports
const personalInfoSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  ssn: z.string().regex(/^\d{3}-?\d{2}-?\d{4}$/, 'SSN must be in format XXX-XX-XXXX'),
  date_of_birth: z.string().min(1, 'Date of birth is required'),
  home_address: z.object({
    street: z.string().min(1, 'Street address is required'),
    city: z.string().min(1, 'City is required'),
    state: z.string().min(2, 'State is required'),
    zipCode: z.string().regex(/^\d{5}(-\d{4})?$/, 'ZIP code must be in format XXXXX or XXXXX-XXXX'),
    country: z.string().default('US'),
  }),
  phone: z.string().min(10, 'Phone number must be at least 10 digits'),
  alternate_email_address: z.string().email('Invalid email address').optional().or(z.literal('')),
  title: z.string().optional().or(z.literal('')),
  ownership_percentage: z
    .string()
    .refine(val => !val || (Number(val) >= 0 && Number(val) <= 100), {
      message: 'Ownership percentage must be between 0 and 100'
    })
    .or(z.number().min(0).max(100)),
  us_citizen: z.boolean().default(true),
  bankruptcy: z.boolean().default(false),
  liens: z.boolean().default(false),
})

// Company Information validation schema
const companyInfoSchema = z.object({
  business_name: z.string().min(1, 'Company name is required'),
  entity_structure: z.string().min(1, 'Entity structure is required'),
  federal_tax_id: z
    .string()
    .refine(val => !val || /^\d{9}$/.test(val.replace(/\D/g, '')), {
      message: 'EIN must be 9 digits'
    })
    .optional(),
  business_address: z.object({
    street: z.string().min(1, 'Street address is required'),
    city: z.string().min(1, 'City is required'),
    state: z.string().min(2, 'State is required'),
    zipCode: z.string().regex(/^\d{5}(-\d{4})?$/, 'ZIP code must be in format XXXXX or XXXXX-XXXX'),
    country: z.string().default('US'),
  }),
  business_phone: z.string().min(10, 'Phone number must be at least 10 digits'),
  business_email: z.string().email('Invalid email address').optional().or(z.literal('')),
  business_start_date: z.string().default(''),
  has_additional_owners: z.boolean().default(false),
})

// Financing profile validation schema
const financingProfileSchema = z.object({
  last_year_revenue: z.string().default(''),
  last_year_expenses: z.string().default(''),
  last_year_net_income: z.string().default(''),
  assets: z.object({
    checking_savings: z.string().default(''),
    investments: z.string().default(''),
    vehicles: z.string().default(''),
    real_estate: z.string().default(''),
    company_equity: z.string().default(''),
    other_assets: z.string().default(''),
  }),
  liabilities: z.object({
    credit_cards: z.string().default(''),
    line_of_credit: z.string().default(''),
    vehicle_loans: z.string().default(''),
    mortgages: z.string().default(''),
    notes_payable: z.string().default(''),
    other_liabilities: z.string().default(''),
  }),
  has_fleet: z.boolean().default(false),
  fleet_quantity: z.string().default(''),
  fleet_value: z.string().default(''),
})

// Define form data types for validation
type PersonalInfoFormData = z.infer<typeof personalInfoSchema>
type CompanyInfoFormData = z.infer<typeof companyInfoSchema>
type FinancingProfileFormData = z.infer<typeof financingProfileSchema>

export const ClientOnboarding: React.FC = (): React.ReactNode => {
  const [activeStep, setActiveStep] = useState(0)
  const [hasAdditionalOwners, setHasAdditionalOwners] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [showSSN, setShowSSN] = useState(false)

  // Use session storage for form data
  const [formData, setFormData] = useSessionStorageFormState<FormDataType>(
    'client-onboarding-form',
    {} as FormDataType,
  )

  // Store active step in session storage
  const [storedStep, setStoredStep] = useSessionStorageStringState('client-onboarding-step', '0')

  const { handleComplete: handleOnboardingComplete } = useClientOnboarding()
  const { createFinancingProfile, isCreating, createError } = useClientPrivateFinancingProfile()
  const navigate = useNavigate()
  const { userInfo } = useAuth()

  // Sync activeStep with stored step
  useEffect(() => {
    setActiveStep(parseInt(storedStep))
  }, [storedStep])

  const handleStepChange = (step: number) => {
    const previousStep = activeStep;
    console.log(`Changing step from ${previousStep} to ${step}`);
    
    if (step === 2) {
      // When entering company info step, log current form data 
      console.log("Company info form values before entering step:", {
        business_name: formData.business_name,
        entity_structure: formData.entity_structure,
        business_address: formData.business_address,
        personal_info: formData.personal_info, // for comparison
      });
    }
    
    setActiveStep(step)
    setStoredStep(step.toString())
  }

  // Initialize personal info form validation
  const {
    register: registerPersonalInfo,
    handleSubmit: handleSubmitPersonalInfo,
    formState: { errors: personalInfoErrors },
    setValue: setPersonalInfoValue,
    trigger: triggerPersonalInfo,
    watch: watchPersonalInfo,
    setError: setPersonalInfoError,
    clearErrors: clearPersonalInfoErrors,
    reset: resetPersonalInfo,
  } = useForm<PersonalInfoFormData>({
    resolver: zodResolver(personalInfoSchema),
    mode: 'onChange',
    defaultValues: {
      first_name: formData.personal_info?.first_name || '',
      last_name: formData.personal_info?.last_name || '',
      ssn: formData.personal_info?.ssn || '',
      date_of_birth: formData.personal_info?.date_of_birth || '',
      home_address: {
        street: formData.personal_info?.home_address?.street || '',
        city: formData.personal_info?.home_address?.city || '',
        state: formData.personal_info?.home_address?.state || '',
        zipCode: formData.personal_info?.home_address?.zipCode || '',
        country: formData.personal_info?.home_address?.country || 'US',
      },
      phone: formData.personal_info?.phone || '',
      alternate_email_address: formData.personal_info?.alternate_email_address || '',
      title: formData.personal_info?.title || '',
      ownership_percentage: formData.personal_info?.ownership_percentage || '',
      us_citizen: formData.us_citizen ?? true,
      bankruptcy: formData.bankruptcy ?? false,
      liens: formData.liens ?? false,
    },
  })

  // Initialize company info form validation
  const {
    register: registerCompanyInfo,
    handleSubmit: handleSubmitCompanyInfo,
    formState: { errors: companyInfoErrors },
    setValue: setCompanyInfoValue,
    trigger: triggerCompanyInfo,
    watch: watchCompanyInfo,
    setError: setCompanyInfoError,
    clearErrors: clearCompanyInfoErrors,
    reset: resetCompanyInfo,
  } = useForm<CompanyInfoFormData>({
    resolver: zodResolver(companyInfoSchema),
    mode: 'onChange',
    defaultValues: {
      business_name: formData.business_name || '',
      entity_structure: formData.entity_structure || '',
      federal_tax_id: formData.federal_tax_id || '',
      business_address: {
        street: formData.business_address?.street || '',
        city: formData.business_address?.city || '',
        state: formData.business_address?.state || '',
        zipCode: formData.business_address?.zipCode || '',
        country: formData.business_address?.country || 'US',
      },
      business_phone: formData.business_phone || '',
      business_email: formData.business_email || '',
      business_start_date: formData.business_start_date || '',
      has_additional_owners: formData.has_additional_owners || false,
    },
  })

  // Initialize financing profile validation
  const {
    register: registerFinancingProfile,
    handleSubmit: handleSubmitFinancingProfile,
    formState: { errors: financingProfileErrors },
    setValue: setFinancingProfileValue,
    trigger: triggerFinancingProfile,
    watch: watchFinancingProfile,
    reset: resetFinancingProfile,
  } = useForm<FinancingProfileFormData>({
    resolver: zodResolver(financingProfileSchema),
    mode: 'onChange',
    defaultValues: {
      last_year_revenue:
        typeof formData.last_year_revenue === 'number'
          ? formData.last_year_revenue.toString()
          : formData.last_year_revenue || '',
      last_year_expenses:
        typeof formData.last_year_expenses === 'number'
          ? formData.last_year_expenses.toString()
          : formData.last_year_expenses || '',
      last_year_net_income:
        typeof formData.last_year_net_income === 'number'
          ? formData.last_year_net_income.toString()
          : formData.last_year_net_income || '',
      assets: {
        checking_savings:
          typeof formData.assets?.checking_savings === 'number'
            ? formData.assets.checking_savings.toString()
            : formData.assets?.checking_savings || '',
        investments:
          typeof formData.assets?.investments === 'number'
            ? formData.assets.investments.toString()
            : formData.assets?.investments || '',
        vehicles:
          typeof formData.assets?.vehicles === 'number'
            ? formData.assets.vehicles.toString()
            : formData.assets?.vehicles || '',
        real_estate:
          typeof formData.assets?.real_estate === 'number'
            ? formData.assets.real_estate.toString()
            : formData.assets?.real_estate || '',
        company_equity:
          typeof formData.assets?.company_equity === 'number'
            ? formData.assets.company_equity.toString()
            : formData.assets?.company_equity || '',
        other_assets:
          typeof formData.assets?.other_assets === 'number'
            ? formData.assets.other_assets.toString()
            : formData.assets?.other_assets || '',
      },
      liabilities: {
        credit_cards:
          typeof formData.liabilities?.credit_cards === 'number'
            ? formData.liabilities.credit_cards.toString()
            : formData.liabilities?.credit_cards || '',
        line_of_credit:
          typeof formData.liabilities?.line_of_credit === 'number'
            ? formData.liabilities.line_of_credit.toString()
            : formData.liabilities?.line_of_credit || '',
        vehicle_loans:
          typeof formData.liabilities?.vehicle_loans === 'number'
            ? formData.liabilities.vehicle_loans.toString()
            : formData.liabilities?.vehicle_loans || '',
        mortgages:
          typeof formData.liabilities?.mortgages === 'number'
            ? formData.liabilities.mortgages.toString()
            : formData.liabilities?.mortgages || '',
        notes_payable:
          typeof formData.liabilities?.notes_payable === 'number'
            ? formData.liabilities.notes_payable.toString()
            : formData.liabilities?.notes_payable || '',
        other_liabilities:
          typeof formData.liabilities?.other_liabilities === 'number'
            ? formData.liabilities.other_liabilities.toString()
            : formData.liabilities?.other_liabilities || '',
      },
      has_fleet: formData.has_fleet || false,
      fleet_quantity:
        typeof formData.fleet_quantity === 'number'
          ? formData.fleet_quantity.toString()
          : formData.fleet_quantity || '',
      fleet_value:
        typeof formData.fleet_value === 'number' ? formData.fleet_value.toString() : formData.fleet_value || '',
    },
  })

  // Sync forms with session storage when formData changes
  useEffect(() => {
    if (formData.personal_info) {
      resetPersonalInfo({
        first_name: formData.personal_info.first_name || '',
        last_name: formData.personal_info.last_name || '',
        ssn: formData.personal_info.ssn || '',
        date_of_birth: formData.personal_info.date_of_birth || '',
        home_address: {
          street: formData.personal_info.home_address?.street || '',
          city: formData.personal_info.home_address?.city || '',
          state: formData.personal_info.home_address?.state || '',
          zipCode: formData.personal_info.home_address?.zipCode || '',
          country: formData.personal_info.home_address?.country || 'US',
        },
        phone: formData.personal_info.phone || '',
        alternate_email_address: formData.personal_info.alternate_email_address || '',
        title: formData.personal_info.title || '',
        ownership_percentage: formData.personal_info.ownership_percentage || '',
        us_citizen: formData.us_citizen ?? true,
        bankruptcy: formData.bankruptcy ?? false,
        liens: formData.liens ?? false,
      })
    }
  }, [formData.personal_info, resetPersonalInfo])

  // Sync company info with session storage
  useEffect(() => {
    resetCompanyInfo({
      business_name: formData.business_name || '',
      entity_structure: formData.entity_structure || '',
      federal_tax_id: formData.federal_tax_id || '',
      business_address: {
        street: formData.business_address?.street || '',
        city: formData.business_address?.city || '',
        state: formData.business_address?.state || '',
        zipCode: formData.business_address?.zipCode || '',
        country: formData.business_address?.country || 'US',
      },
      business_phone: formData.business_phone || '',
      business_email: formData.business_email || '',
      business_start_date: formData.business_start_date || '',
      has_additional_owners: formData.has_additional_owners || false,
    })
  }, [
    formData.business_name,
    formData.entity_structure,
    formData.business_address,
    formData.business_phone,
    resetCompanyInfo,
  ])

  // Sync financing profile with session storage
  useEffect(() => {
    resetFinancingProfile({
      last_year_revenue:
        typeof formData.last_year_revenue === 'number'
          ? formData.last_year_revenue.toString()
          : formData.last_year_revenue || '',
      last_year_expenses:
        typeof formData.last_year_expenses === 'number'
          ? formData.last_year_expenses.toString()
          : formData.last_year_expenses || '',
      last_year_net_income:
        typeof formData.last_year_net_income === 'number'
          ? formData.last_year_net_income.toString()
          : formData.last_year_net_income || '',
      assets: {
        checking_savings:
          typeof formData.assets?.checking_savings === 'number'
            ? formData.assets.checking_savings.toString()
            : formData.assets?.checking_savings || '',
        investments:
          typeof formData.assets?.investments === 'number'
            ? formData.assets.investments.toString()
            : formData.assets?.investments || '',
        vehicles:
          typeof formData.assets?.vehicles === 'number'
            ? formData.assets.vehicles.toString()
            : formData.assets?.vehicles || '',
        real_estate:
          typeof formData.assets?.real_estate === 'number'
            ? formData.assets.real_estate.toString()
            : formData.assets?.real_estate || '',
        company_equity:
          typeof formData.assets?.company_equity === 'number'
            ? formData.assets.company_equity.toString()
            : formData.assets?.company_equity || '',
        other_assets:
          typeof formData.assets?.other_assets === 'number'
            ? formData.assets.other_assets.toString()
            : formData.assets?.other_assets || '',
      },
      liabilities: {
        credit_cards:
          typeof formData.liabilities?.credit_cards === 'number'
            ? formData.liabilities.credit_cards.toString()
            : formData.liabilities?.credit_cards || '',
        line_of_credit:
          typeof formData.liabilities?.line_of_credit === 'number'
            ? formData.liabilities.line_of_credit.toString()
            : formData.liabilities?.line_of_credit || '',
        vehicle_loans:
          typeof formData.liabilities?.vehicle_loans === 'number'
            ? formData.liabilities.vehicle_loans.toString()
            : formData.liabilities?.vehicle_loans || '',
        mortgages:
          typeof formData.liabilities?.mortgages === 'number'
            ? formData.liabilities.mortgages.toString()
            : formData.liabilities?.mortgages || '',
        notes_payable:
          typeof formData.liabilities?.notes_payable === 'number'
            ? formData.liabilities.notes_payable.toString()
            : formData.liabilities?.notes_payable || '',
        other_liabilities:
          typeof formData.liabilities?.other_liabilities === 'number'
            ? formData.liabilities.other_liabilities.toString()
            : formData.liabilities?.other_liabilities || '',
      },
      has_fleet: formData.has_fleet || false,
      fleet_quantity:
        typeof formData.fleet_quantity === 'number'
          ? formData.fleet_quantity.toString()
          : formData.fleet_quantity || '',
      fleet_value:
        typeof formData.fleet_value === 'number' ? formData.fleet_value.toString() : formData.fleet_value || '',
    })
  }, [formData.last_year_revenue, formData.assets, formData.liabilities, formData.has_fleet, resetFinancingProfile])

  // Type assertion to help TypeScript understand the structure
  const typedFormData = formData as unknown as FormDataType

  const updateFormData = (field: keyof FormDataType, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  // Generic form change handler for non-personal info fields
  const handleFormChange =
    (field: keyof FormDataType | `${keyof FormDataType & string}.${string}`) =>
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { value: unknown; name: string }>
        | { target: { value: any } },
    ) => {
      const value = event.target.value

      // Handle nested fields (e.g., 'assets.checking_savings')
      if (field.includes('.')) {
        const [parent, child] = field.split('.') as [keyof FormDataType, string]
        setFormData(prev => ({
          ...prev,
          [parent]: {
            ...((prev[parent] as Record<string, any>) || {}),
            [child]: value,
          },
        }))
      } else {
        // Handle top-level fields
        setFormData(prev => ({
          ...prev,
          [field as keyof FormDataType]: value,
        }))
      }
    }

  // Update handlePersonalInfoChange to use proper typing and add type assertion
  const handlePersonalInfoChange =
    (field: keyof PersonalInfoFormData) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      setPersonalInfoValue(field, value, { shouldValidate: true })

      // Update form data with the new value
      if (field === 'ownership_percentage') {
        setFormData(prev => ({
          ...prev,
          personal_info: {
            ...prev.personal_info,
            [field]: value,
          },
        } as FormDataType))
      } else {
        setFormData(prev => ({
          ...prev,
          personal_info: {
            ...prev.personal_info,
            [field]: value,
          },
        } as FormDataType))
      }
    }

  // Update handlePersonalAddressChange to use proper typing and add type assertion
  const handlePersonalAddressChange =
    (field: keyof PersonalInfoFormData['home_address']) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      setPersonalInfoValue(`home_address.${field}`, value, { shouldValidate: true })
      setFormData(prev => ({
        ...prev,
        personal_info: {
          ...prev.personal_info,
          home_address: {
            ...prev.personal_info?.home_address,
            [field]: value,
          },
        },
      } as FormDataType))
    }

  // Company info change handler
  const handleCompanyInfoChange =
    (field: keyof CompanyInfoFormData) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value
      
      // Special handling for EIN/Tax ID
      if (field === 'federal_tax_id') {
        // Only allow up to 9 digits total
        const digitsOnly = value.replace(/\D/g, '');
        if (digitsOnly.length > 9) {
          return; // Don't update if exceeding 9 digits
        }
        
        // Format the EIN
        value = formatEIN(value);
      }
      
      setCompanyInfoValue(field, value, { shouldValidate: true })
      
      // Directly update the formData with the company information
      // Use a specific update to avoid mixing with personal info
      setFormData(prev => ({
        ...prev,
        [field]: value,
      }))
    }

  // Company address change handler
  const handleCompanyAddressChange =
    (field: keyof CompanyInfoFormData['business_address']) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      setCompanyInfoValue(`business_address.${field}`, value, { shouldValidate: true })
      
      // Ensure we update just the business address, not personal address
      setFormData(prev => ({
        ...prev,
        business_address: {
          ...prev.business_address,
          [field]: value,
        },
      }))
    }

  // Financing profile change handler
  const handleFinancingProfileChange =
    (field: keyof FinancingProfileFormData) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      setFinancingProfileValue(field, value, { shouldValidate: true })
      setFormData(prev => ({
        ...prev,
        [field]: value,
      }))
    }

  // Assets change handler
  const handleAssetChange =
    (field: keyof FinancingProfileFormData['assets']) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      setFinancingProfileValue(`assets.${field}`, value, { shouldValidate: true })
      setFormData(prev => ({
        ...prev,
        assets: {
          ...prev.assets,
          [field]: value,
        } as any,
      }))
    }

  // Liabilities change handler
  const handleLiabilityChange =
    (field: keyof FinancingProfileFormData['liabilities']) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      setFinancingProfileValue(`liabilities.${field}`, value, { shouldValidate: true })
      setFormData(prev => ({
        ...prev,
        liabilities: {
          ...prev.liabilities,
          [field]: value,
        } as any,
      }))
    }

  // Fleet checkbox change handler
  const handleFleetCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    // ONLY update the React Hook Form state - nothing else
    setFinancingProfileValue('has_fleet', checked, { shouldValidate: true })
    // Do NOT update formData - that will happen when validateStep is called
  }

  // Collect form data for the current step without validation
  const validateStep = async (step: number) => {
    switch (step) {
      case 0:
        return true;
      case 1:
        // Get current form values
        const personalFormValues = watchPersonalInfo();
        console.log('Personal Form Values:', personalFormValues); // Debug log

        // Update formData with current form values
        const updatedPersonalInfo = {
          first_name: personalFormValues.first_name,
          last_name: personalFormValues.last_name,
          ssn: personalFormValues.ssn,
          date_of_birth: personalFormValues.date_of_birth,
          home_address: {
            ...personalFormValues.home_address,
            country: 'US'
          },
          phone: personalFormValues.phone,
          alternate_email_address: personalFormValues.alternate_email_address,
          title: personalFormValues.title,
          ownership_percentage: personalFormValues.ownership_percentage
        };

        setFormData(prev => ({
          ...prev,
          personal_info: {
            ...prev.personal_info,
            ...updatedPersonalInfo
          },
          us_citizen: personalFormValues.us_citizen,
          bankruptcy: personalFormValues.bankruptcy,
          liens: personalFormValues.liens
        }));

        return true;
      case 2:
        // Get current form values
        const companyFormValues = watchCompanyInfo();
        console.log('Company Form Values:', companyFormValues); // Debug log

        // Update formData with current form values - make sure to keep these separate from personal_info
        setFormData(prev => ({
          ...prev,
          // Explicitly set company fields to avoid confusion with personal info
          business_name: companyFormValues.business_name,
          entity_structure: companyFormValues.entity_structure,
          federal_tax_id: companyFormValues.federal_tax_id || '',
          business_address: {
            street: companyFormValues.business_address?.street || '',
            city: companyFormValues.business_address?.city || '',
            state: companyFormValues.business_address?.state || '',
            zipCode: companyFormValues.business_address?.zipCode || '',
            country: 'US'
          },
          business_phone: companyFormValues.business_phone,
          business_email: companyFormValues.business_email || '',
          business_start_date: companyFormValues.business_start_date || '',
          has_additional_owners: hasAdditionalOwners
        }));

        return true;
      case 3:
        // Get current form values
        const financingFormValues = watchFinancingProfile();
        console.log('Financing Form Values:', financingFormValues); // Debug log

        // Update formData with current form values - convert string values to numbers
        setFormData(prev => {
          // First convert to unknown to satisfy TypeScript
          const updated = {
            ...prev,
            last_year_revenue: financingFormValues.last_year_revenue !== '' ? parseFloat(financingFormValues.last_year_revenue) : '',
            last_year_expenses: financingFormValues.last_year_expenses !== '' ? parseFloat(financingFormValues.last_year_expenses) : '',
            last_year_net_income: financingFormValues.last_year_net_income !== '' ? parseFloat(financingFormValues.last_year_net_income) : '',
            assets: {
              checking_savings: financingFormValues.assets.checking_savings !== '' ? parseFloat(financingFormValues.assets.checking_savings) : '',
              investments: financingFormValues.assets.investments !== '' ? parseFloat(financingFormValues.assets.investments) : '',
              vehicles: financingFormValues.assets.vehicles !== '' ? parseFloat(financingFormValues.assets.vehicles) : '',
              real_estate: financingFormValues.assets.real_estate !== '' ? parseFloat(financingFormValues.assets.real_estate) : '',
              company_equity: financingFormValues.assets.company_equity !== '' ? parseFloat(financingFormValues.assets.company_equity) : '',
              other_assets: financingFormValues.assets.other_assets !== '' ? parseFloat(financingFormValues.assets.other_assets) : ''
            },
            liabilities: {
              credit_cards: financingFormValues.liabilities.credit_cards !== '' ? parseFloat(financingFormValues.liabilities.credit_cards) : '',
              line_of_credit: financingFormValues.liabilities.line_of_credit !== '' ? parseFloat(financingFormValues.liabilities.line_of_credit) : '',
              vehicle_loans: financingFormValues.liabilities.vehicle_loans !== '' ? parseFloat(financingFormValues.liabilities.vehicle_loans) : '',
              mortgages: financingFormValues.liabilities.mortgages !== '' ? parseFloat(financingFormValues.liabilities.mortgages) : '',
              notes_payable: financingFormValues.liabilities.notes_payable !== '' ? parseFloat(financingFormValues.liabilities.notes_payable) : '',
              other_liabilities: financingFormValues.liabilities.other_liabilities !== '' ? parseFloat(financingFormValues.liabilities.other_liabilities) : ''
            },
            has_fleet: financingFormValues.has_fleet,
            fleet_quantity: financingFormValues.fleet_quantity !== '' ? parseFloat(financingFormValues.fleet_quantity) : '',
            fleet_value: financingFormValues.fleet_value !== '' ? parseFloat(financingFormValues.fleet_value) : ''
          };
          return updated as unknown as FormDataType;
        });

        return true;
      case 4:
        return true;
      default:
        return true;
    }
  }

  // Handle form completion
  const handleComplete = async () => {
    try {
      setError(null);
      
      // Collect final form data without validation concerns
      await validateStep(activeStep);
      
      console.log("Final form data being submitted:", formData);
      
      // Create a promise that resolves when the mutation completes
      await new Promise<void>((resolve, reject) => {
        createFinancingProfile(
          formData as unknown as Omit<PrivateClientFinancingProfile, 'id' | 'date_created' | 'date_modified' | 'user_id'>,
          {
            onSuccess: (data) => {
              console.log('Financing profile created successfully:', data);
              resolve();
            },
            onError: (error) => {
              console.error('Error creating financing profile:', error);
              reject(error);
            }
          }
        );
      });
      
      // At this point, the profile was created successfully
      // Then pass the form data to the onboarding complete handler
      
      // Clear session storage
      window.sessionStorage.removeItem('client-onboarding-form');
      window.sessionStorage.removeItem('client-onboarding-step');
      
      // Use window.location to ensure full page reload 
      window.location.href = '/client';
    } catch (error) {
      console.error('Error completing onboarding:', error);
      setError('Failed to complete onboarding. Please try again.');
    }
  }

  // Update handleNext to handle validation with Zod and React Hook Form
  const handleNext = async () => {
    // Collect current form data without validation
    await validateStep(activeStep);
    
    // Check if we're moving to a step we've already completed before
    const alreadyVisitedNextStep = parseInt(storedStep) > activeStep;
    
    // Perform step-specific validation with React Hook Form
    let isValid = true;
    
    if (activeStep === 1) {
      // Check if we've visited company info step before
      if (alreadyVisitedNextStep) {
        console.log("Already visited company info before, skipping strict validation");
        // Just do a basic check for required fields
        if (!formData.personal_info?.first_name || !formData.personal_info?.last_name) {
          setError("First name and last name are required");
          return;
        }
      } else {
        // Personal info validation using React Hook Form
        isValid = await triggerPersonalInfo();
        if (!isValid) {
          console.log("Personal info validation failed");
          return;
        }
      }
    } else if (activeStep === 2) {
      // Company info validation using React Hook Form
      if (alreadyVisitedNextStep) {
        console.log("Already visited financing profile before, skipping strict validation");
        // Just do a basic check for required fields
        if (!formData.business_name) {
          setError("Company name is required");
          return;
        }
      } else {
        isValid = await triggerCompanyInfo();
        if (!isValid) {
          console.log("Company info validation failed");
          return;
        }
        
        // Additional validation for owners if needed
        if (hasAdditionalOwners && (!formData.additionalOwners || formData.additionalOwners.length === 0)) {
          setError("Please add at least one additional owner");
          return;
        }
      }
    } else if (activeStep === 3) {
      // Financing profile validation using React Hook Form
      if (alreadyVisitedNextStep) {
        console.log("Already visited review step before, skipping validation");
      } else {
        isValid = await triggerFinancingProfile();
        if (!isValid) {
          console.log("Financing profile validation failed");
          return;
        }
      }
      
      // Log the data for debugging
      console.log("Form data after validation (moving to Review):", {
        assets: formData.assets,
        liabilities: formData.liabilities,
        last_year_revenue: formData.last_year_revenue,
        last_year_expenses: formData.last_year_expenses,
        last_year_net_income: formData.last_year_net_income
      });
    }
    
    // Clear any previous errors
    setError(null);
    clearPersonalInfoErrors();
    
    // Move to next step or complete
    if (activeStep === steps.length - 1) {
      // If on the final review step, handle completion
      await handleComplete();
    } else {
      // Otherwise move to the next step
      handleStepChange(activeStep + 1);
    }
  }

  // Update handleBack to properly prepare form data when moving back
  const handleBack = () => {
    const targetStep = activeStep - 1;
    console.log(`Moving back from step ${activeStep} to step ${targetStep}`);
    
    // Clear any errors
    setError(null);
    clearPersonalInfoErrors();
    
    // Move to previous step
    handleStepChange(targetStep);
  }

  const hasExistingProgress = () => {
    // Check if any required fields have been filled out
    return Boolean(
      formData.personal_info?.first_name ||
        formData.personal_info?.last_name ||
        formData.personal_info?.ssn ||
        formData.personal_info?.date_of_birth ||
        formData.personal_info?.home_address?.street ||
        formData.personal_info?.home_address?.city ||
        formData.personal_info?.home_address?.state ||
        formData.personal_info?.home_address?.zipCode ||
        formData.personal_info?.phone ||
        formData.business_name ||
        formData.entity_structure ||
        formData.business_address?.street ||
        formData.business_address?.city ||
        formData.business_address?.state ||
        formData.business_address?.zipCode ||
        formData.business_phone,
    )
  }

  const getButtonText = () => {
    if (activeStep === steps.length - 1) {
      return isCreating ? 'Submitting...' : 'Complete'
    }
    if (activeStep === 0) return hasExistingProgress() ? 'Continue' : 'Begin'
    return 'Next'
  }

  const handleAddOwner = () => {
    setFormData((prev: any) => {
      // Create a new owner with all required fields
      const newOwner: AdditionalOwnerType = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        ownership_percentage: '',
        title: '',
      }

      return {
        ...prev,
        additionalOwners: [...(prev.additionalOwners || []), newOwner],
      }
    })
  }

  const handleRemoveOwner = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalOwners: prev.additionalOwners?.filter((_: any, i: number) => i !== index) || [],
    }))
  }

  const handleOwnerChange =
    (index: number, field: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData((prev: any) => ({
        ...prev,
        additionalOwners:
          prev.additionalOwners?.map((owner: any, i: number) =>
            i === index
              ? {
                  ...owner,
                  [field]: event.target.value,
                }
              : owner,
          ) || [],
      }))
    }

  // Helper function to format EIN
  const formatEIN = (ein: string): string => {
    // Remove all non-digits
    const digitsOnly = ein.replace(/\D/g, '');
    
    // Format as XX-XXXXXXX if we have at least 2 digits
    if (digitsOnly.length >= 2) {
      return `${digitsOnly.slice(0, 2)}-${digitsOnly.slice(2, 9)}`;
    }
    
    return digitsOnly;
  }

  // Helper component for field with tooltip
  const FieldWithTooltip = ({
    label,
    tooltip,
    required = false,
    ...props
  }: {
    label: string
    tooltip: string
    required?: boolean
    [key: string]: any
  }) => (
    <TextField
      fullWidth
      label={
        <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {tooltip && (
            <Tooltip title={tooltip} arrow placement='top'>
              <IconButton size='small' sx={{ ml: 0.5, p: 0 }}>
                <InfoIcon fontSize='small' color='action' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
      required={required}
      {...props}
    />
  )

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
            <Typography variant='h5' gutterBottom>
              Welcome to Optic Truck Works!
            </Typography>
            <Typography variant='body1' paragraph>
              We look forward to providing you a smooth and easy experience.
            </Typography>
            <Box sx={{ ml: 2 }}>
              <Typography variant='body1' paragraph>
                1. Verify your general company information and business details
              </Typography>
              <Typography variant='body1' paragraph>
                2. Complete an optional financing profile to help expedite future financing requests
              </Typography>
              <Typography variant='body1' paragraph>
                3. Invite additional business owners if applicable
              </Typography>
            </Box>
            <Typography variant='body1' sx={{ mt: 2 }}>
              This process typically takes 5-10 minutes to complete.
            </Typography>
          </Paper>
        )
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Personal Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='First Name'
                  {...registerPersonalInfo('first_name')}
                  error={!!personalInfoErrors.first_name}
                  helperText={personalInfoErrors.first_name?.message || 'Enter your legal first name'}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Last Name'
                  {...registerPersonalInfo('last_name')}
                  error={!!personalInfoErrors.last_name}
                  helperText={personalInfoErrors.last_name?.message || 'Enter your legal last name'}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Social Security Number'
                  {...registerPersonalInfo('ssn')}
                  type={showSSN ? 'text' : 'password'}
                  error={!!personalInfoErrors.ssn}
                  helperText={personalInfoErrors.ssn?.message || 'Format: XXX-XX-XXXX'}
                  placeholder='XXX-XX-XXXX'
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle ssn visibility'
                          onClick={() => setShowSSN(!showSSN)}
                          edge='end'
                        >
                          {showSSN ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Date of Birth'
                  {...registerPersonalInfo('date_of_birth')}
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={!!personalInfoErrors.date_of_birth}
                  helperText={personalInfoErrors.date_of_birth?.message || 'Required for financing applications'}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Home Street Address'
                  {...registerPersonalInfo('home_address.street')}
                  error={!!personalInfoErrors.home_address?.street}
                  helperText={personalInfoErrors.home_address?.street?.message || 'Your current residential address'}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='City'
                  {...registerPersonalInfo('home_address.city')}
                  error={!!personalInfoErrors.home_address?.city}
                  helperText={personalInfoErrors.home_address?.city?.message}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label='State'
                  {...registerPersonalInfo('home_address.state')}
                  error={!!personalInfoErrors.home_address?.state}
                  helperText={personalInfoErrors.home_address?.state?.message}
                  required
                >
                  {states.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='ZIP Code'
                  {...registerPersonalInfo('home_address.zipCode')}
                  error={!!personalInfoErrors.home_address?.zipCode}
                  helperText={personalInfoErrors.home_address?.zipCode?.message || 'Format: XXXXX or XXXXX-XXXX'}
                  placeholder='XXXXX'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  {...registerPersonalInfo('phone')}
                  error={!!personalInfoErrors.phone}
                  helperText={personalInfoErrors.phone?.message || 'Format: XXX-XXX-XXXX'}
                  placeholder='XXX-XXX-XXXX'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Alternate Email Address'
                  {...registerPersonalInfo('alternate_email_address')}
                  error={!!personalInfoErrors.alternate_email_address}
                  helperText={personalInfoErrors.alternate_email_address?.message}
                />
              </Grid>

              {/* New citizenship and financial disclosure fields */}
              <Grid item xs={12}>
                <Typography variant='subtitle1' gutterBottom sx={{ mt: 2 }}>
                  Citizenship & Financial Disclosure
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...registerPersonalInfo('us_citizen')}
                      checked={watchPersonalInfo('us_citizen')}
                      onChange={e => {
                        setPersonalInfoValue('us_citizen', e.target.checked, { shouldValidate: true })
                        setFormData(prev => ({
                          ...prev,
                          us_citizen: e.target.checked,
                        }))
                      }}
                    />
                  }
                  label='Are you a US citizen?'
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...registerPersonalInfo('bankruptcy')}
                      checked={watchPersonalInfo('bankruptcy')}
                      onChange={e => {
                        setPersonalInfoValue('bankruptcy', e.target.checked, { shouldValidate: true })
                        setFormData(prev => ({
                          ...prev,
                          bankruptcy: e.target.checked,
                        }))
                      }}
                    />
                  }
                  label='Have you filed for bankruptcy in the last 7 years?'
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...registerPersonalInfo('liens')}
                      checked={watchPersonalInfo('liens')}
                      onChange={e => {
                        setPersonalInfoValue('liens', e.target.checked, { shouldValidate: true })
                        setFormData(prev => ({
                          ...prev,
                          liens: e.target.checked,
                        }))
                      }}
                    />
                  }
                  label='Do you have any tax liens or judgments?'
                />
              </Grid>
            </Grid>
          </Box>
        )
      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Company Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  label='Company Name'
                  {...registerCompanyInfo('business_name')}
                  error={!!companyInfoErrors.business_name}
                  helperText={
                    companyInfoErrors.business_name?.message ||
                    "Enter your business's legal name as it appears on official documents"
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='Tax ID (EIN)'
                  {...registerCompanyInfo('federal_tax_id')}
                  error={!!companyInfoErrors.federal_tax_id}
                  helperText={companyInfoErrors.federal_tax_id?.message || 'Enter 9 digits (XX-XXXXXXX format)'}
                  inputProps={{ maxLength: 10 }} // Allow for hyphen
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label='Entity Structure'
                  {...registerCompanyInfo('entity_structure')}
                  error={!!companyInfoErrors.entity_structure}
                  helperText={companyInfoErrors.entity_structure?.message || 'Legal structure of your business'}
                  required
                >
                  {entityStructures.map(structure => (
                    <MenuItem key={structure} value={structure}>
                      {structure}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Street Address'
                  {...registerCompanyInfo('business_address.street')}
                  error={!!companyInfoErrors.business_address?.street}
                  helperText={
                    companyInfoErrors.business_address?.street?.message || "Your business's physical address"
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='City'
                  {...registerCompanyInfo('business_address.city')}
                  error={!!companyInfoErrors.business_address?.city}
                  helperText={companyInfoErrors.business_address?.city?.message || 'City of your business address'}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label='State'
                  {...registerCompanyInfo('business_address.state')}
                  error={!!companyInfoErrors.business_address?.state}
                  helperText={companyInfoErrors.business_address?.state?.message}
                  required
                >
                  {states.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='ZIP Code'
                  {...registerCompanyInfo('business_address.zipCode')}
                  error={!!companyInfoErrors.business_address?.zipCode}
                  helperText={
                    companyInfoErrors.business_address?.zipCode?.message || 'ZIP code of your business address'
                  }
                  placeholder='XXXXX'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  {...registerCompanyInfo('business_phone')}
                  error={!!companyInfoErrors.business_phone}
                  helperText={
                    companyInfoErrors.business_phone?.message || "We'll only use this for important communications"
                  }
                  placeholder='XXX-XXX-XXXX'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Business Email (optional)'
                  {...registerCompanyInfo('business_email')}
                  error={!!companyInfoErrors.business_email}
                  helperText={companyInfoErrors.business_email?.message || 'Optional business contact email'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='When did you start your business?'
                  type='date'
                  value={formData?.business_start_date ?? ''}
                  onChange={handleFormChange('business_start_date')}
                  InputLabelProps={{ shrink: true }}
                  helperText='This helps us understand your business history'
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1' gutterBottom sx={{ mt: 2 }}>
                  Company Affiliation
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Title'
                  value={formData.personal_info?.title ?? ''}
                  onChange={handlePersonalInfoChange('title')}
                  placeholder='e.g. CEO, President, Owner'
                  helperText='Your position in the company (optional)'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type='number'
                  label='Ownership Percentage'
                  value={formData.personal_info?.ownership_percentage ?? ''}
                  onChange={handlePersonalInfoChange('ownership_percentage')}
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                  }}
                  helperText='Your percentage of ownership in the company'
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasAdditionalOwners}
                      onChange={e => {
                        const checked = e.target.checked
                        setHasAdditionalOwners(checked)
                        updateFormData('has_additional_owners', checked)
                        if (!checked) {
                          updateFormData('additionalOwners', [])
                        }
                      }}
                    />
                  }
                  label='I have additional owners with 20% or more ownership'
                />
                {hasAdditionalOwners && (
                  <Typography variant='body2' color='text.secondary' sx={{ mt: 1, ml: 4 }}>
                    We need information for all owners with 20% or more ownership stake
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Additional Owners Section - Only show when checkbox is checked */}
            {hasAdditionalOwners && (
              <Box sx={{ mt: 4 }}>
                <Typography variant='h6' gutterBottom>
                  Additional Owners
                </Typography>
                <Typography variant='body2' color='text.secondary' sx={{ mb: 3 }}>
                  Please provide information for all owners with 20% or more ownership stake. Total ownership
                  percentage across all owners must equal 100%.
                </Typography>

                {formData.additionalOwners?.map((owner, index) => (
                  <Paper key={index} elevation={2} sx={{ p: 3, mb: 3, position: 'relative' }}>
                    <IconButton
                      onClick={() => handleRemoveOwner(index)}
                      sx={{ position: 'absolute', top: 10, right: 10 }}
                      color='error'
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Typography variant='h6' gutterBottom>
                      Owner {index + 1}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='First Name'
                          value={(owner as any).first_name}
                          onChange={handleOwnerChange(index, 'first_name')}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Last Name'
                          value={(owner as any).last_name}
                          onChange={handleOwnerChange(index, 'last_name')}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Email'
                          type='email'
                          value={(owner as any).email}
                          onChange={handleOwnerChange(index, 'email')}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Phone'
                          value={(owner as any).phone}
                          onChange={handleOwnerChange(index, 'phone')}
                          required
                          helperText="We'll only use this for important communications"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Title'
                          value={(owner as any).title}
                          onChange={handleOwnerChange(index, 'title')}
                          placeholder='e.g. CEO, President, Owner'
                          required
                          helperText='Position in the company'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type='number'
                          label='Ownership Percentage'
                          value={(owner as any).ownership_percentage}
                          onChange={handleOwnerChange(index, 'ownership_percentage')}
                          required
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                          }}
                          helperText='We only need ownership info for those with 20%+ ownership'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label='Home Address'
                          value={(owner as any).home_address}
                          onChange={handleOwnerChange(index, 'home_address')}
                          required
                          helperText='Current residential address'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Date of Birth'
                          type='date'
                          value={(owner as any).date_of_birth}
                          onChange={handleOwnerChange(index, 'date_of_birth')}
                          required
                          InputLabelProps={{ shrink: true }}
                          helperText='Required for financing applications'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='Social Security Number'
                          type='password'
                          value={(owner as any).ssn}
                          onChange={handleOwnerChange(index, 'ssn')}
                          required
                          helperText='Required for financing applications'
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ))}

                <Button variant='outlined' startIcon={<AddIcon />} onClick={handleAddOwner} sx={{ mt: 2 }}>
                  Add Owner
                </Button>
              </Box>
            )}
          </Box>
        )
      case 3:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' gutterBottom>
              Financing Profile
            </Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 3 }}>
              This information helps expedite future financing requests. None of the assets listed will be pledged
              under any circumstances to obtain financing. This simply helps the lenders understand the financial
              health of the clients they're extending financing terms to.
            </Typography>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Financial Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='Last Year Revenue'
                  {...registerFinancingProfile('last_year_revenue')}
                  error={!!financingProfileErrors.last_year_revenue}
                  helperText={
                    (financingProfileErrors.last_year_revenue?.message as string) ||
                    'Include all sources of business revenue'
                  }
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='Last Year Expenses'
                  {...registerFinancingProfile('last_year_expenses')}
                  error={!!financingProfileErrors.last_year_expenses}
                  helperText={
                    (financingProfileErrors.last_year_expenses?.message as string) ||
                    'Include all business operating expenses'
                  }
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='Net Income'
                  {...registerFinancingProfile('last_year_net_income')}
                  error={!!financingProfileErrors.last_year_net_income}
                  helperText={
                    (financingProfileErrors.last_year_net_income?.message as string) ||
                    'This is your total revenue minus total expenses'
                  }
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Assets
            </Typography>
            <Grid container spacing={2}>
              {assetFields.map(key => {
                let helperText = ''
                switch (key) {
                  case 'checking_savings':
                    helperText = 'Please tally all cash in both business and personal checking and savings account.'
                    break
                  case 'investments':
                    helperText = 'Please list any/all investment accounts, such as 401k, IRA, stock, CDs, etc.'
                    break
                  case 'vehicles':
                    helperText =
                      'We will do a fleet evaluation using your Schedule B fleet list to assist with the valuation here.'
                    break
                  case 'real_estate':
                    helperText =
                      'Please list the current value of any/all properties you own, regardless of whether they are residential/commercial. Please do not subtract any mortgage balance here.'
                    break
                  case 'company_equity':
                    helperText =
                      'Please list the current valuation for your business. If you do not have one, we can assist in determining the value with you.'
                    break
                  case 'other_assets':
                    helperText =
                      'Please list any other assets you may have here. These may be things we have not specifically asked for above. If this does not apply to you, leave it blank.'
                    break
                }

                return (
                  <Grid item xs={12} sm={6} md={4} key={key}>
                    <TextField
                      fullWidth
                      type='number'
                      label={key
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                      {...registerFinancingProfile(`assets.${key}` as any)}
                      error={!!financingProfileErrors.assets?.[key as keyof typeof financingProfileErrors.assets]}
                      helperText={helperText}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Liabilities
            </Typography>
            <Grid container spacing={2}>
              {liabilityFields.map(key => {
                let helperText = ''
                switch (key) {
                  case 'credit_cards':
                    helperText =
                      'Please list the combined balance of any credit cards that do not get paid off in full monthly.'
                    break
                  case 'line_of_credit':
                    helperText =
                      'Please list any used portions of lines of credit here. You do not need to list the amount of the line itself, just the portion that is currently used.'
                    break
                  case 'vehicle_loans':
                    helperText =
                      'Please list the combined total of outstanding loans for your business vehicles here.'
                    break
                  case 'mortgages':
                    helperText = 'Please list any/all mortgage balances here.'
                    break
                  case 'notes_payable':
                    helperText =
                      'Please list any notes you owe here that are separate from the above types of debt, i.e. seller financing that is not through a lender.'
                    break
                  case 'other_liabilities':
                    helperText =
                      'Please list any other liabilities here that have not been specifically asked for above.'
                    break
                }

                return (
                  <Grid item xs={12} sm={6} md={4} key={key}>
                    <TextField
                      fullWidth
                      type='number'
                      label={key
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                      {...registerFinancingProfile(`liabilities.${key}` as any)}
                      error={
                        !!financingProfileErrors.liabilities?.[
                          key as keyof typeof financingProfileErrors.liabilities
                        ]
                      }
                      helperText={helperText}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <Typography variant='subtitle1' gutterBottom sx={{ mt: 3 }}>
              Fleet Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...registerFinancingProfile('has_fleet')}
                      checked={watchFinancingProfile('has_fleet')}
                      onChange={handleFleetCheckboxChange}
                    />
                  }
                  label='Do you have any trucks in your fleet?'
                />
                {watchFinancingProfile('has_fleet') && (
                  <Typography variant='body2' color='text.secondary' sx={{ mt: 1, ml: 4 }}>
                    Include all commercial trucks
                  </Typography>
                )}
              </Grid>
              {watchFinancingProfile('has_fleet') && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type='number'
                      label='Fleet Quantity'
                      {...registerFinancingProfile('fleet_quantity')}
                      error={!!financingProfileErrors.fleet_quantity}
                      helperText={
                        (financingProfileErrors.fleet_quantity?.message as string) || 'Include all commercial trucks'
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type='number'
                      label='Fleet Value'
                      {...registerFinancingProfile('fleet_value')}
                      error={!!financingProfileErrors.fleet_value}
                      helperText={financingProfileErrors.fleet_value?.message as string}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant='outlined' component='label' sx={{ mt: 1 }}>
                      Upload Schedule B Fleet List (Optional)
                      <input
                        type='file'
                        hidden
                        onChange={e => {
                          if (e.target.files && e.target.files[0]) {
                            const file = e.target.files[0]
                            // Handle file upload logic here
                            updateFormData('fleet_list_file', file.name)
                          }
                        }}
                      />
                    </Button>
                    <Typography variant='body2' color='text.secondary' sx={{ mt: 1 }}>
                      Optional, but we may need this to assist with the approval so it's best to do it now.
                    </Typography>
                    {formData?.fleet_list_file && (
                      <Typography variant='body2' sx={{ mt: 1 }}>
                        File selected: {formData.fleet_list_file}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )
      case 4:
        return (
          <>
            <ReviewStep formData={typedFormData as any} />
            {isCreating && (
              <Alert severity="info" sx={{ mt: 3 }}>
                Your information is being securely processed. Please do not close this page or navigate away.
              </Alert>
            )}
          </>
        )
      default:
        return null
    }
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 1000, mx: 'auto', p: 3 }}>
      <Typography variant='h4' gutterBottom align='center'>
        Complete Your Company Profile
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {renderStepContent(activeStep)}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack} sx={{ mr: 1 }} disabled={isCreating}>
            Back
          </Button>
        )}
        <Button 
          variant='contained' 
          color='primary' 
          onClick={handleNext} 
          disabled={isCreating}
          startIcon={isCreating && <CircularProgress size={20} color="inherit" />}
        >
          {getButtonText()}
        </Button>
      </Box>
    </Box>
  )
}

export default ClientOnboarding
