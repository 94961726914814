import React, { useState } from 'react'
import { Stack, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box, Button, Menu, Divider, useTheme } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface DateRangeSelectorProps {
  startDate: Dayjs | null
  endDate: Dayjs | null
  onStartDateChange: (date: Dayjs | null) => void
  onEndDateChange: (date: Dayjs | null) => void
  displayText: string
  onDateRangeChange: (range: string) => void
  selectedDateRange: string
}

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  displayText,
  onDateRangeChange,
  selectedDateRange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const isMobile = useIsMobile()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRangeSelect = (range: string) => {
    onDateRangeChange(range)
    handleClose()
  }

  return (
    <Box>
      <Button
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<CalendarTodayIcon />}
        variant="outlined"
        size={isMobile ? "small" : "medium"}
        sx={{ 
          borderRadius: 2,
          textTransform: 'none',
          whiteSpace: isMobile ? 'nowrap' : 'normal',
          minWidth: isMobile ? 'auto' : '200px',
          px: isMobile ? 1 : 2,
          fontSize: isMobile ? '0.75rem' : 'inherit'
        }}
      >
        {displayText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: { 
            width: isMobile ? 280 : 320,
            maxWidth: '95vw',
            borderRadius: 2,
            mt: 1
          }
        }}
      >
        <Typography 
          variant="subtitle2" 
          sx={{ 
            px: 2, 
            py: 1, 
            color: theme.palette.text.secondary,
            fontWeight: 500
          }}
        >
          Select Date Range
        </Typography>
        <Divider />
        <MenuItem 
          onClick={() => handleRangeSelect('this_month')}
          selected={selectedDateRange === 'this_month'}
        >
          This Month
        </MenuItem>
        <MenuItem 
          onClick={() => handleRangeSelect('last_month')}
          selected={selectedDateRange === 'last_month'}
        >
          Last Month
        </MenuItem>
        <MenuItem 
          onClick={() => handleRangeSelect('this_year')}
          selected={selectedDateRange === 'this_year'}
        >
          This Year
        </MenuItem>
        <MenuItem 
          onClick={() => handleRangeSelect('custom')}
          selected={selectedDateRange === 'custom'}
        >
          Custom Range
        </MenuItem>
        
        {selectedDateRange === 'custom' && (
          <Box sx={{ p: 2 }}>
            <Divider sx={{ my: 1 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack 
                direction={isMobile ? "column" : "row"} 
                spacing={isMobile ? 1 : 2}
                sx={{ mt: 1 }}
              >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={onStartDateChange}
                  slotProps={{ 
                    textField: { 
                      size: "small",
                      fullWidth: true,
                      sx: { 
                        '& .MuiInputBase-input': { 
                          fontSize: isMobile ? '0.8rem' : 'inherit',
                          py: isMobile ? 1 : 'inherit'
                        } 
                      }
                    } 
                  }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={onEndDateChange}
                  slotProps={{ 
                    textField: { 
                      size: "small",
                      fullWidth: true,
                      sx: { 
                        '& .MuiInputBase-input': { 
                          fontSize: isMobile ? '0.8rem' : 'inherit',
                          py: isMobile ? 1 : 'inherit'
                        } 
                      }
                    } 
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        )}
      </Menu>
    </Box>
  )
}

export default DateRangeSelector
