import React from 'react'
import { useAuth } from 'contexts/AuthContext'
import { OwnerDashboard } from './OwnerDashboard'
import { AdminDashboard } from './AdminDashboard'
import { Box, CircularProgress } from '@mui/material'

export const SuperAdminHome: React.FC = () => {
  const { userInfo, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  // Check if the user is an owner
  const isOwner = userInfo?.isOwner || false

  // Render the appropriate dashboard based on user role
  return isOwner ? <OwnerDashboard /> : <AdminDashboard />
}

export default SuperAdminHome
