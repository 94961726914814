import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Lead } from '@otw/models/leads/lead';
import { LeadForm } from '../LeadForm';

interface LeadFormDialogProps {
  open: boolean;
  onClose: () => void;
  selectedLead: Lead | null;
  isCreating: boolean;
}

const LeadFormDialog: React.FC<LeadFormDialogProps> = ({
  open,
  onClose,
  selectedLead,
  isCreating
}) => {
  // Track if the dialog has been opened with this lead
  const [initializedWithLead, setInitializedWithLead] = useState<string | null>(null);
  
  // When the dialog opens with a lead, track its ID
  React.useEffect(() => {
    if (open && selectedLead && selectedLead.id !== initializedWithLead) {
      setInitializedWithLead(selectedLead.id);
    }
  }, [open, selectedLead, initializedWithLead]);
  
  // This function is called when the form is cleared/canceled
  const handleClearSelection = () => {
    // Just close the dialog without resetting the form
    onClose();
    
    // Reset the initialized state when dialog closes
    if (!open) {
      setInitializedWithLead(null);
    }
  };

  // Handle backdrop click to prevent closing
  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    // Prevent the default behavior
    event.stopPropagation();
  };

  return (
    <Dialog 
      open={open} 
      maxWidth="md" 
      fullWidth
      disableEscapeKeyDown
      // Completely disable closing on backdrop click
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      // Additional handler for backdrop clicks
      onClick={handleBackdropClick}
      PaperProps={{
        sx: { 
          maxHeight: '90vh',
          height: 'auto'
        }
      }}
    >
      <DialogTitle sx={{ pb: 1, fontSize: {xs: '1.2rem', md: '1.5rem'} }}>
        {isCreating ? 'Create New Lead' : 'Edit Lead'}
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <LeadForm 
          selectedLead={selectedLead} 
          onClearSelection={handleClearSelection} 
          isCreating={isCreating}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LeadFormDialog; 