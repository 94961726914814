import React from 'react'
import { Grid, Box } from '@mui/material'
import { Vehicle } from '@otw/models'
import { Application } from '@otw/models'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { 
  FinancialOverview, 
  SalesOverview, 
  MonthlyComparison,
  ExpenseList,
  RecentSales
} from './views'
import { MobileViewSelector } from '../../shared'
import { useExpenses } from 'hooks/useExpenses'

// Import icons for the mobile view selector
import AssessmentIcon from '@mui/icons-material/Assessment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ReceiptIcon from '@mui/icons-material/Receipt'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import SellIcon from '@mui/icons-material/Sell'

interface OverviewTabProps {
  filteredInventoryVehicles: Vehicle[]
  filteredSoldVehicles: Vehicle[]
  applications: Application[]
  dateRange: {
    start: Date
    end: Date
  }
}

export const OverviewTab: React.FC<OverviewTabProps> = ({ 
  filteredInventoryVehicles, 
  filteredSoldVehicles,
  applications,
  dateRange 
}) => {
  const isMobile = useIsMobile();
  
  // Fix the type error by passing the correct structure to useExpenses
  const { expenses, isLoading: expensesLoading, deleteExpense } = useExpenses({ 
    dateRange: dateRange 
  });
  
  // Create a wrapper function to handle the void return type
  const handleDeleteExpense = async (id: string): Promise<void> => {
    await deleteExpense(id);
    // Return void explicitly
    return;
  };
  
  // For mobile, we'll use the view selector with dropdown
  if (isMobile) {
    return (
      <Box sx={{ 
        maxWidth: '100%',
        overflowX: 'hidden'
      }}>
        <MobileViewSelector
          titles={[
            "Sales Overview", 
            "Financial Overview", 
            "Expenses", 
            "Recent Sales", 
            "Monthly Comparison"
          ]}
          icons={[
            <AssessmentIcon fontSize="small" />,
            <AttachMoneyIcon fontSize="small" />,
            <ReceiptIcon fontSize="small" />,
            <SellIcon fontSize="small" />,
            <TrendingUpIcon fontSize="small" />
          ]}
        >
          <SalesOverview 
            vehicles={filteredSoldVehicles} 
            dateRange={dateRange} 
          />
          <FinancialOverview 
            vehicles={filteredSoldVehicles} 
            dateRange={dateRange} 
          />
          <ExpenseList 
            expenses={expenses || []} 
            onDelete={handleDeleteExpense} 
          />
          <RecentSales 
            vehicles={filteredSoldVehicles} 
            dateRange={dateRange} 
          />
          <MonthlyComparison />
        </MobileViewSelector>
      </Box>
    );
  }
  
  // Desktop view remains unchanged but with added expense list
  return (
    <Box sx={{ 
      maxWidth: '100%',
      overflowX: 'hidden'
    }}>
      <Grid container spacing={3}>
        {/* Financial Overview - Full width */}
        <Grid item xs={12}>
          <FinancialOverview 
            vehicles={filteredSoldVehicles} 
            dateRange={dateRange} 
          />
        </Grid>
        
        {/* Sales Overview with filtering - Full width */}
        <Grid item xs={12}>
          <SalesOverview 
            vehicles={filteredSoldVehicles} 
            dateRange={dateRange} 
          />
        </Grid>
        
        {/* Monthly Comparison - Full width for consistency */}
        <Grid item xs={12}>
          <MonthlyComparison />
        </Grid>
      </Grid>
    </Box>
  )
}

export default OverviewTab
