import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from "firebase/firestore";
import {
  Lead,
  LeadStatus,
  StatusHistory,
  FollowUpNote,
  TruckRequest,
  TruckType,
} from "../leads/lead";

export const leadConverter: FirestoreDataConverter<Lead> = {
  toFirestore: (lead: Lead) => {
    const { id, ...data } = lead;

    // Helper function to convert undefined to null and dates to ISO strings
    const convertValue = (value: any) => {
      if (value === undefined) return null;
      if (value instanceof Date) return isNaN(value.getTime()) ? '' : value.toISOString();
      return value;
    };

    // Process truck request data
    let truckRequest = null;
    if (lead.truckRequest) {
      truckRequest = {
        notes: lead.truckRequest.notes,
        types: lead.truckRequest.types || [] // Initialize the types property
      };
      
      // Process truck types if they exist
      if (lead.truckRequest.types && Array.isArray(lead.truckRequest.types)) {
        truckRequest.types = lead.truckRequest.types.map(type => ({
          ...type,
          // Convert string values to appropriate types
          quantity: typeof type.quantity === 'string' ? parseInt(type.quantity) : type.quantity,
          minYear: typeof type.minYear === 'string' ? parseInt(type.minYear) : type.minYear,
          maxYear: typeof type.maxYear === 'string' ? parseInt(type.maxYear) : type.maxYear,
          budget: typeof type.budget === 'string' ? 
            Number(String(type.budget).replace(/,/g, '')) : 
            type.budget,
          // Ensure array fields are always arrays
          make: Array.isArray(type.make) ? type.make : (type.make ? [type.make] : []),
          model: Array.isArray(type.model) ? type.model : (type.model ? [type.model] : []),
          fuelType: Array.isArray(type.fuelType) ? type.fuelType : (type.fuelType ? [type.fuelType] : [])
        }));
      }
    }

    // Process status history to ensure no undefined values
    const statusHistory = lead.statusHistory ? lead.statusHistory.map(history => ({
      ...history,
      id: history.id || null,
      previousStatus: history.previousStatus || null,
      newStatus: history.newStatus,
      changedBy: history.changedBy,
      changedAt: convertValue(history.changedAt),
      notes: history.notes || null
    })) : [];

    // Return the processed data
    return {
      ...data,
      truckRequest,
      statusHistory,
      createdAt: convertValue(lead.createdAt),
      updatedAt: convertValue(lead.updatedAt),
      lastContactDate: convertValue(lead.lastContactDate),
      nextFollowUpDate: convertValue(lead.nextFollowUpDate),
      lastActionDate: convertValue(lead.lastActionDate),
      status: lead.status || null,
      prevStatus: lead.prevStatus || null,
      source: lead.source || null,
      truckIds: lead.truckIds || [],
      applicationIds: lead.applicationIds || [],
      followUpNotes: lead.followUpNotes || [],
      tags: lead.tags || []
    };
  },

  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): Lead => {
    const data = snapshot.data(options);

    // Convert ISO string dates or Firestore timestamps to JavaScript Date objects
    const convertTimestamp = (timestamp: any): Date | null => {
      if (!timestamp) return null;
      if (timestamp instanceof Timestamp) return timestamp.toDate();
      if (typeof timestamp === 'string') return new Date(timestamp);
      return null;
    };

    // Process truck request data
    let truckRequest: TruckRequest = {
      notes: data.truckRequest?.notes || "",
      types: undefined // Initialize the types property
    };
    
    if (data.truckRequest) {
      // If types already exists, use it
      if (data.truckRequest.types && Array.isArray(data.truckRequest.types)) {
        truckRequest.types = data.truckRequest.types.map((type: any) => ({
          type: type.type || "",
          size: type.size || "",
          condition: type.condition || "",
          quantity: type.quantity || 1,
          // Handle array fields properly
          make: Array.isArray(type.make) ? type.make : (type.make ? [type.make] : []),
          model: Array.isArray(type.model) ? type.model : (type.model ? [type.model] : []),
          minYear: type.minYear || undefined,
          maxYear: type.maxYear || undefined,
          fuelType: Array.isArray(type.fuelType) ? type.fuelType : (type.fuelType ? [type.fuelType] : []),
          maxMileage: type.maxMileage || undefined,
          budget: type.budget || undefined
        }));
      } 
    }

    // Convert ISO string dates to Date objects
    return {
      id: snapshot.id,
      customerName: data.customerName || "",
      email: data.email || "",
      phone: data.phone || "",
      status: (data.status || 'new') as LeadStatus,
      prevStatus: data.prevStatus as LeadStatus | null,
      assignedRepId: data.assignedRepId || 0,
      company: data.company || "",
      location: data.location || "",
      financingStatus: data.financingStatus || "",
      timeFrame: data.timeFrame || "",
      vehicleInterest: data.vehicleInterest || "",
      truckIds: Array.isArray(data.truckIds) ? data.truckIds : [],
      truckRequest,
      followUpNotes: Array.isArray(data.followUpNotes) ? data.followUpNotes : [],
      statusHistory: Array.isArray(data.statusHistory) ? data.statusHistory.map((history: any) => ({
        ...history,
        changedAt: convertTimestamp(history.changedAt) || new Date()
      })) : [],
      applicationIds: Array.isArray(data.applicationIds) ? data.applicationIds : [],
      createdAt: convertTimestamp(data.createdAt) || new Date(),
      updatedAt: convertTimestamp(data.updatedAt) || new Date(),
      lastContactDate: convertTimestamp(data.lastContactDate),
      nextFollowUpDate: convertTimestamp(data.nextFollowUpDate),
      source: data.source || null,
      priority: data.priority || "medium",
      tags: Array.isArray(data.tags) ? data.tags : [],
      createdBy: data.createdBy || "",
      lastActionDate: convertTimestamp(data.lastActionDate)
    };
  },
};
