import forge from 'node-forge'
import { opticPublicKey } from 'config/opticPublicKey'

export function encryptPayload(payload: any): string {
  try {
    // Generate a random AES key and IV
    const aesKey = forge.random.getBytesSync(32) // 256 bits
    const iv = forge.random.getBytesSync(16) // 128 bits

    // Create AES cipher
    const cipher = forge.cipher.createCipher('AES-CBC', aesKey)
    cipher.start({ iv: iv })

    // Convert payload to string and encrypt with AES
    const payloadString = JSON.stringify(payload)
    cipher.update(forge.util.createBuffer(payloadString, 'utf8'))
    cipher.finish()
    const encryptedData = cipher.output.getBytes()

    // Encrypt the AES key with RSA
    const publicKey = forge.pki.publicKeyFromPem(opticPublicKey)
    const encryptedKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    })

    // Combine everything into a single object
    const result = {
      key: forge.util.encode64(encryptedKey),
      iv: forge.util.encode64(iv),
      data: forge.util.encode64(encryptedData),
    }

    return JSON.stringify(result)
  } catch (error) {
    console.error('Failed to encrypt payload:', error)
    throw new Error('Failed to encrypt payload')
  }
}
