import { useQuery } from '@tanstack/react-query'
import { db } from 'config/firebase'
import { collection, getDocs } from 'firebase/firestore'
import { Application } from '@otw/models/application/application'
import { applicationConverter } from '@otw/models/converters'

export const useApplications = () => {
  const {
    data: applications,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['applications'],
    queryFn: async (): Promise<Application[]> => {
      const applicationsRef = collection(db, 'applications').withConverter(applicationConverter)
      const snapshot = await getDocs(applicationsRef)
      const applications = snapshot.docs.map(doc => doc.data())

      // Sort applications by latest date
      const sortedApplicationsByLatestDate = applications.sort((a, b) => {
        return new Date(b.time_created).getTime() - new Date(a.time_created).getTime()
      })

      return sortedApplicationsByLatestDate
    },
  })

  return {
    applications,
    isLoading,
    error,
  }
}
