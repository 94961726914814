import { useQuery } from '@tanstack/react-query'
import { portalFunctions, ClientFinancingProfileResponse } from 'core/functions/portalFunctions'
import { useAuth } from 'hooks/auth/useAuth'

export const useClientFinancingProfiles = () => {
  const { userInfo } = useAuth()

  const {
    data: clientFinancingProfiles,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['admin', 'clientFinancingProfiles'],
    queryFn: async (): Promise<ClientFinancingProfileResponse[]> => {
      if (!['admin', 'babyAdmin'].includes(userInfo?.role || '')) {
        throw new Error('Unauthorized: User does not have admin permissions')
      }

      return await portalFunctions.admin.getAllClientFinancingProfiles()
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    retry: 3,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    enabled: Boolean(['admin', 'babyAdmin'].includes(userInfo?.role || '')),
  })

  return {
    clientFinancingProfiles: clientFinancingProfiles || [],
    isLoading,
    isError,
    error,
    refetchClientFinancingProfiles: refetch,
  }
} 