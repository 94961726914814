import { useQuery } from '@tanstack/react-query';
import { db } from 'config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { BusinessInquiry } from '@otw/models';

export const useBusinessInquiries = () => {
  // Fetch business inquiries
  const businessInquiries = useQuery({
    queryKey: ['business_inquiries'],
    queryFn: async (): Promise<BusinessInquiry[]> => {
      try {
        const inquiriesRef = collection(db, 'business_inquiries');
        const snapshot = await getDocs(inquiriesRef);
        const inquiries = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as BusinessInquiry[];
        return inquiries;
      } catch (error) {
        console.error('Error fetching business inquiries:', error);
        throw error; // Ensure the error is propagated
      }
    },
  });

  return {
    businessInquiries: businessInquiries.data,
    businessInquiriesError: businessInquiries.error,
    businessInquiriesIsLoading: businessInquiries.isLoading,
  };
};
