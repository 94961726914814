import AssessmentIcon from '@mui/icons-material/Assessment'
import InventoryIcon from '@mui/icons-material/Inventory'
import PeopleIcon from '@mui/icons-material/People'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { Box, CircularProgress, Tab, Tabs, Typography, useTheme } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useApplications } from 'hooks/admin/useApplications'
import { useInventory } from 'hooks/inventory/useInventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { ApplicationsTab, DateRangeSelector, InventoryTab, OverviewTab, SalesTab } from './components'
import { useExpenses } from 'hooks/useExpenses'

// TabPanel component for dashboard tabs
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      style={{
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: { xs: 2, md: 3 },
            width: '100%',
            maxWidth: '100%',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

// Main component
export const OwnerDashboard: React.FC = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()
  const { vehicles, isLoading } = useInventory('master_inventory')
  const { applications, isLoading: applicationsLoading } = useApplications()
  const [dateRange, setDateRange] = useState<string>('this_month')
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(null)
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null)
  const { expenses } = useExpenses()

  // Use localStorage instead of sessionStorage to persist tab selection
  const [storedTabValue, setStoredTabValue] = useState<number>(0)

  // Load stored tab value from localStorage on component mount
  useEffect(() => {
    const savedTab = localStorage.getItem('ownerDashboardTab')
    if (savedTab !== null) {
      setStoredTabValue(Number(savedTab))
    }
  }, [])

  const tabValue = storedTabValue

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setStoredTabValue(newValue)
    localStorage.setItem('ownerDashboardTab', newValue.toString())
  }

  // Handle swipe change for SwipeableViews
  const handleSwipeChangeIndex = (index: number) => {
    setStoredTabValue(index)
    localStorage.setItem('ownerDashboardTab', index.toString())
  }

  // Handle date range change
  const handleDateRangeChange = (newRange: string) => {
    setDateRange(newRange)
  }

  // Helper function to get date range based on selection
  const getDateRange = () => {
    if (dateRange === 'this_month') {
      const startOfMonth = dayjs().startOf('month')
      return {
        start: startOfMonth.toDate(),
        end: dayjs().toDate(),
      }
    } else if (dateRange === 'last_month') {
      const lastMonth = dayjs().subtract(1, 'month')
      return {
        start: lastMonth.startOf('month').toDate(),
        end: lastMonth.endOf('month').toDate(),
      }
    } else if (dateRange === 'this_year') {
      return {
        start: dayjs().startOf('year').toDate(),
        end: dayjs().toDate(),
      }
    } else if (dateRange === 'custom' && customStartDate && customEndDate) {
      return {
        start: customStartDate.toDate(),
        end: customEndDate.toDate(),
      }
    } else {
      // Fallback
      return {
        start: dayjs().startOf('month').toDate(),
        end: dayjs().toDate(),
      }
    }
  }

  // Helper function to get display text for date range
  const getDateRangeDisplayText = () => {
    if (dateRange === 'this_month') {
      return `This Month (${dayjs().format('MMMM YYYY')})`
    } else if (dateRange === 'last_month') {
      return `Last Month (${dayjs().subtract(1, 'month').format('MMMM YYYY')})`
    } else if (dateRange === 'this_year') {
      return `This Year (${dayjs().format('YYYY')})`
    } else if (dateRange === 'custom' && customStartDate && customEndDate) {
      return `${customStartDate.format('MMM D, YYYY')} - ${customEndDate.format('MMM D, YYYY')}`
    } else {
      return `This Month (${dayjs().format('MMMM YYYY')})`
    }
  }

  // Filter vehicles based on date range - for inventory
  const filteredInventoryVehicles = useMemo(() => {
    if (!vehicles) return []

    const { start, end } = getDateRange()

    return vehicles.filter(vehicle => {
      const vehicleDate = vehicle.date_added ? new Date(vehicle.date_added) : null
      if (!vehicleDate) return true // Include if no date

      return vehicleDate >= start && vehicleDate <= end
    })
  }, [vehicles, dateRange, customStartDate, customEndDate])

  // Filter vehicles based on sold date - for sales metrics
  const filteredSoldVehicles = useMemo(() => {
    if (!vehicles) return []

    const { start, end } = getDateRange()

    return (
      vehicles
        .filter(vehicle => {
          // Only include sold vehicles
          if (vehicle.status !== 'Sold' || !vehicle.sold_date) return false

          const soldDate = new Date(vehicle.sold_date)
          return soldDate >= start && soldDate <= end
        })
        // Sort by sold_date in descending order (newest first)
        .sort((a, b) => {
          const dateA = a.sold_date ? new Date(a.sold_date).getTime() : 0
          const dateB = b.sold_date ? new Date(b.sold_date).getTime() : 0
          return dateB - dateA
        })
    )
  }, [vehicles, dateRange, customStartDate, customEndDate])

  // Calculate metrics

  if (isLoading || applicationsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        p: { xs: 1, sm: 1.5, md: 3 },
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          mb: { xs: 1, md: 3 },
          display: 'flex',
          flexDirection: { xs: 'row', md: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'center', md: 'flex-end' },
          gap: { xs: 1, md: 0 },
          width: '100%',
          flexWrap: { xs: 'wrap', md: 'nowrap' },
        }}
      >
        <Typography variant={isMobile ? 'h5' : 'h3'} sx={{ mb: { md: 0 } }}>
          Owner Dashboard
        </Typography>

        <Box
          sx={{
            flexShrink: 0,
            width: { xs: 'auto', md: 'auto' },
            ml: { xs: 'auto', md: 0 },
          }}
        >
          <DateRangeSelector
            startDate={customStartDate}
            endDate={customEndDate}
            onStartDateChange={setCustomStartDate}
            onEndDateChange={setCustomEndDate}
            displayText={getDateRangeDisplayText()}
            onDateRangeChange={handleDateRangeChange}
            selectedDateRange={dateRange}
          />
        </Box>
      </Box>

      {/* Dashboard Tabs */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant='fullWidth'
          aria-label='dashboard tabs'
          sx={{
            maxWidth: '100%',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          <Tab
            icon={<AssessmentIcon />}
            label={isMobile ? 'Overview' : 'Overview'}
            id='dashboard-tab-0'
            aria-controls='dashboard-tabpanel-0'
            iconPosition='top'
            sx={{ minWidth: 0, px: isMobile ? 1 : 2 }}
          />
          <Tab
            icon={<InventoryIcon />}
            label={isMobile ? 'Inventory' : 'Inventory'}
            id='dashboard-tab-1'
            aria-controls='dashboard-tabpanel-1'
            iconPosition='top'
            sx={{ minWidth: 0, px: isMobile ? 1 : 2 }}
          />
          <Tab
            icon={<ReceiptIcon />}
            label={isMobile ? 'Sales' : 'Sales & Deals'}
            id='dashboard-tab-2'
            aria-controls='dashboard-tabpanel-2'
            iconPosition='top'
            sx={{ minWidth: 0, px: isMobile ? 1 : 2 }}
          />
          <Tab
            icon={<PeopleIcon />}
            label={isMobile ? 'Apps' : 'Applications'}
            id='dashboard-tab-3'
            aria-controls='dashboard-tabpanel-3'
            iconPosition='top'
            sx={{ minWidth: 0, px: isMobile ? 1 : 2 }}
          />
        </Tabs>
      </Box>

      {/* Swipeable Views Container */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabValue}
        onChangeIndex={handleSwipeChangeIndex}
        resistance
        enableMouseEvents={!isMobile} // Enable mouse events only on desktop for testing
        containerStyle={{
          width: '100%',
          maxWidth: '100%',
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
        }}
        style={{
          padding: 0,
          overflowX: 'hidden',
        }}
      >
        {/* Overview Tab */}
        <div style={{ width: '100%', padding: 0 }}>
          <Box
            sx={{
              py: { xs: 2, md: 3 },
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <OverviewTab
              filteredInventoryVehicles={filteredInventoryVehicles}
              filteredSoldVehicles={filteredSoldVehicles}
              applications={applications || []}
              dateRange={getDateRange()}
            />
          </Box>
        </div>

        {/* Inventory Tab */}
        <div style={{ width: '100%', padding: 0 }}>
          <Box
            sx={{
              py: { xs: 2, md: 3 },
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <InventoryTab dateRange={getDateRange()} />
          </Box>
        </div>

        {/* Sales & Deals Tab */}
        <div style={{ width: '100%', padding: 0 }}>
          <Box
            sx={{
              py: { xs: 2, md: 3 },
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <SalesTab soldVehicles={filteredSoldVehicles} dateRange={getDateRange()} />
          </Box>
        </div>

        {/* Applications Tab */}
        <div style={{ width: '100%', padding: 0 }}>
          <Box
            sx={{
              py: { xs: 2, md: 3 },
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <ApplicationsTab applications={applications || []} dateRange={getDateRange()} />
          </Box>
        </div>
      </SwipeableViews>
    </Box>
  )
}

export default OwnerDashboard
