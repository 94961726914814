import React, { useState, useMemo } from 'react'
import {
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  IconButton,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import { Vehicle } from '@otw/models'
import { formatPrice, formatDateTime, formatPhoneNumber } from 'core/utils/inventoryUtils'
import PersonIcon from '@mui/icons-material/Person'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import SearchIcon from '@mui/icons-material/Search'
import { LineChart } from '@mui/x-charts/LineChart'
import dayjs from 'dayjs'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SellIcon from '@mui/icons-material/Sell'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { getDealQualityInfo } from 'hooks/graybook'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { alpha } from '@mui/material/styles'
import { getFullNameFromPrefix } from '../../../../dashboardTools'

interface SalesAndDealsOverviewProps {
  soldVehicles: Vehicle[]
  dateRange?: { start: Date; end: Date }
}

interface SaleDetail {
  vehicle: Vehicle
  buyer: string | null
  sales_rep: string | null
  seller: string | null
  price: number | null
  cost: number | null
  profit: number | null
  date: string | null
}

export const SalesAndDealsOverview: React.FC<SalesAndDealsOverviewProps> = ({
  soldVehicles,
  dateRange = {
    start: dayjs().startOf('month').toDate(),
    end: dayjs().toDate(),
  },
}) => {
  const [tabValue, setTabValue] = useState(0)
  const [selectedSale, setSelectedSale] = useState<SaleDetail | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedSalesRep, setSelectedSalesRep] = useState<string | null>(null)
  const [selectedStockPrefix, setSelectedStockPrefix] = useState<string | null>(null)
  const [stockPrefixDialogOpen, setStockPrefixDialogOpen] = useState(false)
  const isMobile = useIsMobile()
  const theme = useTheme()

  // Process sold vehicles
  const soldDeals = useMemo(() => {
    return soldVehicles
      .filter(v => v.status === 'Sold')
      .map(vehicle => ({
        vehicle,
        buyer: vehicle.sold_to_user?.name || null,
        sales_rep: vehicle.sold_by_user?.name || null,
        seller: vehicle.who,
        price: vehicle.optic_list_price,
        cost:
          (vehicle.seller_asking_price || 0) +
          (vehicle.repair_cost || 0) +
          (vehicle.shipping_cost || 0) +
          (vehicle.rental_cost || 0) +
          (vehicle.other_cost || 0),
        profit:
          (vehicle.optic_list_price || 0) -
          (vehicle.seller_asking_price || 0) -
          (vehicle.repair_cost || 0) -
          (vehicle.shipping_cost || 0) -
          (vehicle.rental_cost || 0) -
          (vehicle.other_cost || 0),
        date: vehicle.sold_date,
      }))
  }, [soldVehicles])

  // Get unique sales reps
  const salesReps = useMemo(() => {
    const reps = new Set<string>()

    // Add sales reps from sold deals
    soldDeals.forEach(deal => {
      if (deal.sales_rep) reps.add(deal.sales_rep)
    })

    return Array.from(reps).sort()
  }, [soldDeals])

  const filteredSoldDeals = useMemo(() => {
    let filtered = soldDeals

    // Filter by search term
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase()
      filtered = filtered.filter(
        deal =>
          (deal.buyer && deal.buyer.toLowerCase().includes(lowerSearchTerm)) ||
          (deal.seller && deal.seller.toLowerCase().includes(lowerSearchTerm)) ||
          (deal.vehicle.make && deal.vehicle.make.toLowerCase().includes(lowerSearchTerm)) ||
          (deal.vehicle.model && deal.vehicle.model.toLowerCase().includes(lowerSearchTerm)) ||
          (deal.vehicle.vin && deal.vehicle.vin.toLowerCase().includes(lowerSearchTerm)) ||
          (deal.vehicle.stock_number && deal.vehicle.stock_number.toLowerCase().includes(lowerSearchTerm)),
      )
    }

    // Filter by sales rep
    if (selectedSalesRep) {
      filtered = filtered.filter(deal => deal.seller === selectedSalesRep)
    }

    return filtered
  }, [soldDeals, searchTerm, selectedSalesRep])

  const soldDealsTotals = useMemo(() => {
    return filteredSoldDeals.reduce(
      (acc, deal) => ({
        sales: acc.sales + (deal.price || 0),
        profit: acc.profit + (deal.profit || 0),
      }),
      { sales: 0, profit: 0 },
    )
  }, [filteredSoldDeals])

  // Prepare chart data for sold vehicles over time
  const chartData = useMemo(() => {
    // Create a map of dates within the range
    const startDate = dateRange.start
    const endDate = dateRange.end
    const dayDiff = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))

    // Initialize data arrays
    const dates: string[] = []
    const unitsSold: number[] = []
    const revenue: number[] = []

    // Create a map to store cumulative data by date
    const cumulativeData: Record<string, { units: number; revenue: number }> = {}

    // Initialize all dates in range with zeros
    for (let i = 0; i <= dayDiff; i++) {
      const date = dayjs(startDate).add(i, 'day')
      const dateStr = date.format('YYYY-MM-DD')
      dates.push(date.format('MMM D'))
      cumulativeData[dateStr] = { units: 0, revenue: 0 }
    }

    // Populate with actual data
    soldDeals.forEach(deal => {
      if (deal.date) {
        const dealDate = dayjs(deal.date)
        // Check if the deal date is within our range
        if (
          dealDate.isAfter(dayjs(startDate).subtract(1, 'day')) &&
          dealDate.isBefore(dayjs(endDate).add(1, 'day'))
        ) {
          const dateStr = dealDate.format('YYYY-MM-DD')
          if (cumulativeData[dateStr]) {
            cumulativeData[dateStr].units += 1
            cumulativeData[dateStr].revenue += deal.price || 0
          }
        }
      }
    })

    // Convert the map to arrays for the chart
    Object.keys(cumulativeData)
      .sort()
      .forEach(date => {
        unitsSold.push(cumulativeData[date].units)
        revenue.push(cumulativeData[date].revenue)
      })

    return { dates, unitsSold, revenue }
  }, [soldDeals, dateRange])

  // Calculate stock number metrics
  const stockNumberMetrics = useMemo(() => {
    const metrics: Record<
      string,
      {
        sold: number
        soldThisMonth: number
        dealQuality: Record<string, number>
        soldProfit: number
        totalCost: number
        revenue: number
        buyPrices: number
        additionalCosts: number
        vehicles: Vehicle[]
      }
    > = {}

    // Initialize an "All" category
    metrics['ALL'] = {
      sold: 0,
      soldThisMonth: 0,
      dealQuality: { great: 0, good: 0, average: 0, belowAverage: 0, poor: 0 },
      soldProfit: 0,
      totalCost: 0,
      revenue: 0,
      buyPrices: 0,
      additionalCosts: 0,
      vehicles: [],
    }

    // Get current month for filtering
    const currentMonthStart = dayjs().startOf('month')

    // Process sold vehicles
    soldDeals.forEach(deal => {
      const stockPrefix = deal.vehicle.stock_number?.match(/^([A-Za-z]+)/)?.[1]?.toUpperCase() || 'UNKNOWN'

      if (!metrics[stockPrefix]) {
        metrics[stockPrefix] = {
          sold: 0,
          soldThisMonth: 0,
          dealQuality: { great: 0, good: 0, average: 0, belowAverage: 0, poor: 0 },
          soldProfit: 0,
          totalCost: 0,
          revenue: 0,
          buyPrices: 0,
          additionalCosts: 0,
          vehicles: [],
        }
      }

      // Calculate buy price and additional costs separately
      const buyPrice = deal.vehicle.seller_asking_price || 0
      const additionalCosts =
        (deal.vehicle.repair_cost || 0) +
        (deal.vehicle.shipping_cost || 0) +
        (deal.vehicle.rental_cost || 0) +
        (deal.vehicle.other_cost || 0)

      metrics[stockPrefix].sold += 1
      metrics['ALL'].sold += 1

      // Add to revenue (price field)
      metrics[stockPrefix].revenue += deal.price || 0
      metrics['ALL'].revenue += deal.price || 0

      // Add buy prices
      metrics[stockPrefix].buyPrices += buyPrice
      metrics['ALL'].buyPrices += buyPrice

      // Add additional costs
      metrics[stockPrefix].additionalCosts += additionalCosts
      metrics['ALL'].additionalCosts += additionalCosts

      // Count vehicles sold this month
      if (deal.date && dayjs(deal.date).isAfter(currentMonthStart)) {
        metrics[stockPrefix].soldThisMonth += 1
        metrics['ALL'].soldThisMonth += 1
      }

      metrics[stockPrefix].soldProfit += deal.profit || 0
      metrics['ALL'].soldProfit += deal.profit || 0
      metrics[stockPrefix].totalCost += deal.cost || 0
      metrics['ALL'].totalCost += deal.cost || 0

      // Add to vehicles list for display
      metrics[stockPrefix].vehicles.push(deal.vehicle)
      metrics['ALL'].vehicles.push(deal.vehicle)

      // Get deal quality from the vehicle's dealQuality property
      const quality = deal.vehicle.dealQuality || 'poor';

      // Increment the appropriate counter
      metrics[stockPrefix].dealQuality[quality] = (metrics[stockPrefix].dealQuality[quality] || 0) + 1
      metrics['ALL'].dealQuality[quality] = (metrics['ALL'].dealQuality[quality] || 0) + 1
    })

    return metrics
  }, [soldDeals])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleRowClick = (sale: SaleDetail) => {
    setSelectedSale(sale)
    setDialogOpen(true)
  }

  const handleSalesRepClick = (rep: string) => {
    setSelectedSalesRep(selectedSalesRep === rep ? null : rep)
  }

  const handleStockPrefixClick = (prefix: string) => {
    setSelectedStockPrefix(prefix)
    setStockPrefixDialogOpen(true)
  }

  // Get vehicles for selected stock prefix
  const selectedPrefixVehicles = useMemo(() => {
    if (!selectedStockPrefix) return []

    // If ALL is selected, return all vehicles
    if (selectedStockPrefix === 'ALL') {
      return [...soldDeals.map(deal => deal.vehicle)]
    }

    // Get all vehicles that match the prefix
    const prefixRegex = new RegExp(`^${selectedStockPrefix}`, 'i')
    return [
      ...soldDeals
        .filter(deal => deal.vehicle.stock_number && prefixRegex.test(deal.vehicle.stock_number))
        .map(deal => deal.vehicle),
    ]
  }, [selectedStockPrefix, soldDeals])

  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
      <Typography variant='h6' gutterBottom>
        {tabValue === 0 ? 'Active Deals' : 'Sales per Stock Number'}
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label='sales tabs'>
          <Tab label={`Recent Sales (${filteredSoldDeals.length})`} />
          <Tab label='Sales per Stock Number' />
        </Tabs>
      </Box>

      {tabValue === 1 ? (
        // Stock Number Overview Tab
        <Box>
          {isMobile ? (
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Stack spacing={1.5}>
                {Object.entries(stockNumberMetrics)
                  .sort((a, b) => {
                    // Keep ALL at top
                    if (a[0] === 'ALL') return -1
                    if (b[0] === 'ALL') return 1
                    return b[1].sold - a[1].sold
                  })
                  .map(([prefix, metrics]) => {
                    const isAllRow = prefix === 'ALL'
                    return (
                      <Paper
                        key={prefix}
                        elevation={isAllRow ? 2 : 1}
                        sx={{
                          p: 1.5,
                          cursor: 'pointer',
                          bgcolor: isAllRow ? alpha(theme.palette.primary.light, 0.1) : 'inherit',
                          '&:hover': { bgcolor: 'action.hover' },
                        }}
                        onClick={() => handleStockPrefixClick(prefix)}
                      >
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant={isAllRow ? 'subtitle1' : 'subtitle2'}
                            color='primary.main'
                            fontWeight={isAllRow ? 'bold' : 'normal'}
                            sx={{ textDecoration: 'underline' }}
                          >
                            {getFullNameFromPrefix(prefix)}
                          </Typography>
                        </Box>

                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Typography variant='caption' color='text.secondary'>
                              Total Sold
                            </Typography>
                            <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                              {metrics.sold}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant='caption' color='text.secondary'>
                              This Month
                            </Typography>
                            <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                              {metrics.soldThisMonth}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant='caption' color='text.secondary'>
                              Revenue
                            </Typography>
                            <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                              {formatPrice(metrics.revenue)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant='caption' color='text.secondary'>
                              Profit
                            </Typography>
                            <Typography
                              variant='body2'
                              fontWeight={isAllRow ? 'bold' : 'normal'}
                              sx={{
                                color: metrics.soldProfit > 0 ? 'success.main' : 'error.main',
                              }}
                            >
                              {formatPrice(metrics.soldProfit)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant='caption' color='text.secondary'>
                              Buy Prices
                            </Typography>
                            <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                              {formatPrice(metrics.buyPrices)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant='caption' color='text.secondary'>
                              Add. Costs
                            </Typography>
                            <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                              {formatPrice(metrics.additionalCosts)}
                            </Typography>
                          </Grid>
                          {Object.values(metrics.dealQuality).some(count => count > 0) ? (
                            <Grid item xs={12}>
                              <Typography variant='caption' color='text.secondary' display='block' sx={{ mb: 0.5 }}>
                                Deal Quality
                              </Typography>
                              <Stack direction='row' spacing={0.5}>
                                {Object.entries(metrics.dealQuality)
                                  .filter(([_, count]) => count > 0)
                                  .map(([quality, count]) => {
                                    const qualityInfo = getDealQualityInfo(quality as Vehicle['dealQuality'])
                                    return (
                                      <Chip
                                        key={quality}
                                        label={count}
                                        color={qualityInfo.color as any}
                                        size='small'
                                        variant='outlined'
                                      />
                                    )
                                  })}
                              </Stack>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Paper>
                    )
                  })}
                {Object.keys(stockNumberMetrics).length === 0 && (
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='body1'>No sales data available for the selected period</Typography>
                  </Paper>
                )}
              </Stack>
            </Box>
          ) : (
            <TableContainer sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Stock #</TableCell>
                    <TableCell align='center'>Total Vehicles Sold</TableCell>
                    <TableCell align='center'>Sold This Month</TableCell>
                    <TableCell align='right'>Total Revenue</TableCell>
                    <TableCell align='right'>Buy Prices</TableCell>
                    <TableCell align='right'>Additional Costs</TableCell>
                    <TableCell align='right'>Total Profit</TableCell>
                    <TableCell align='center'>Deal Quality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(stockNumberMetrics)
                    .sort((a, b) => {
                      // Keep ALL at top
                      if (a[0] === 'ALL') return -1
                      if (b[0] === 'ALL') return 1
                      return b[1].sold - a[1].sold
                    })
                    .map(([prefix, metrics]) => (
                      <TableRow
                        key={prefix}
                        hover
                        sx={{
                          fontWeight: prefix === 'ALL' ? 'bold' : 'normal',
                          bgcolor: prefix === 'ALL' ? alpha(theme.palette.primary.main, 0.1) : 'inherit',
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'action.hover' },
                        }}
                        onClick={() => handleStockPrefixClick(prefix)}
                      >
                        <TableCell>
                          <Typography
                            variant={prefix === 'ALL' ? 'subtitle2' : 'body2'}
                            sx={{
                              color: theme.palette.primary.main,
                              textDecoration: 'underline',
                            }}
                          >
                            {getFullNameFromPrefix(prefix)}
                          </Typography>
                        </TableCell>
                        <TableCell align='center'>{metrics.sold}</TableCell>
                        <TableCell align='center'>{metrics.soldThisMonth}</TableCell>
                        <TableCell align='right'>{formatPrice(metrics.revenue)}</TableCell>
                        <TableCell align='right'>{formatPrice(metrics.buyPrices)}</TableCell>
                        <TableCell align='right'>{formatPrice(metrics.additionalCosts)}</TableCell>
                        <TableCell align='right'>
                          {formatPrice(metrics.soldProfit)}
                        </TableCell>
                        <TableCell align='center'>
                          <Tooltip
                            title={
                              <>
                                {Object.entries(metrics.dealQuality)
                                  .filter(([_, count]) => count > 0)
                                  .map(([quality, count]) => {
                                    const qualityInfo = getDealQualityInfo(quality as Vehicle['dealQuality'])
                                    return (
                                      <Typography key={quality} variant='caption' component='div'>
                                        {qualityInfo.label}: {count}
                                      </Typography>
                                    )
                                  })}
                              </>
                            }
                          >
                            <Stack direction='row' spacing={0.5} justifyContent='center'>
                              {Object.entries(metrics.dealQuality)
                                .filter(([_, count]) => count > 0)
                                .map(([quality, count]) => {
                                  const qualityInfo = getDealQualityInfo(quality as Vehicle['dealQuality'])
                                  return (
                                    <Box
                                      key={quality}
                                      sx={{
                                        width: 12,
                                        height: 12,
                                        borderRadius: '50%',
                                        bgcolor: `${qualityInfo.color}.main`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'white',
                                        fontSize: '0.6rem',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {count}
                                    </Box>
                                  )
                                })}
                            </Stack>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  {Object.keys(stockNumberMetrics).length === 0 && (
                    <TableRow>
                      <TableCell colSpan={9} align='center'>
                        No sales data available for the selected period
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      ) : (
        // Original tabs content
        <>
          <Box sx={{ mb: 2 }}>
            <TextField
              placeholder='Search by buyer, seller, vehicle...'
              variant='outlined'
              size='small'
              fullWidth
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />

            <Stack direction='row' spacing={1} sx={{ mb: 2, flexWrap: 'wrap', gap: 1 }}>
              <Chip label={`Total Sales: ${filteredSoldDeals.length}`} color='primary' />
              <Chip label={`Total Revenue: ${formatPrice(soldDealsTotals.sales)}`} color='primary' />
              <Chip label={`Total Profit: ${formatPrice(soldDealsTotals.profit)}`} color='success' />
            </Stack>

            {/* Sales Rep Filter Chips */}
            <Typography variant='subtitle2' color='text.secondary' gutterBottom>
              Filter by Sales Rep:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {salesReps.map(rep => (
                <Chip
                  key={rep}
                  label={rep}
                  onClick={() => handleSalesRepClick(rep)}
                  color={selectedSalesRep === rep ? 'primary' : 'default'}
                  variant={selectedSalesRep === rep ? 'filled' : 'outlined'}
                  icon={<PersonIcon />}
                />
              ))}
              {selectedSalesRep && (
                <Chip label='Clear Filter' onClick={() => setSelectedSalesRep(null)} color='secondary' />
              )}
            </Box>
          </Box>

          {isMobile ? (
            <Box sx={{ mb: 2 }}>
              <Stack spacing={1.5}>
                {filteredSoldDeals.map(deal => (
                  <Paper
                    key={deal.vehicle.id}
                    elevation={1}
                    sx={{
                      p: 1.5,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: 'action.hover' },
                    }}
                    onClick={() => handleRowClick(deal)}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            mb: 0.5,
                          }}
                        >
                          <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                            {deal.vehicle.year} {deal.vehicle.make} {deal.vehicle.model}
                          </Typography>
                          <Box sx={{ textAlign: 'right' }}>
                            <Typography
                              variant='body2'
                              sx={{
                                color: (deal.profit || 0) > 0 ? 'success.main' : 'error.main',
                                fontWeight: 'bold',
                              }}
                            >
                              {formatPrice(deal.profit || 0)}
                            </Typography>
                            <Typography variant='caption' color='text.secondary'>
                              {formatPrice(deal.price || 0)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box>
                            <Typography variant='caption' color='text.secondary' display='block'>
                              Buyer: {deal.buyer || 'Unknown'}
                            </Typography>
                            <Typography variant='caption' color='text.secondary' display='block'>
                              Sales Rep: {deal.sales_rep || 'Unknown'}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: 'right' }}>
                            <Typography variant='caption' color='text.secondary' display='block'>
                              {deal.date ? new Date(deal.date).toLocaleDateString() : 'N/A'}
                            </Typography>
                            <Typography variant='caption' color='text.secondary' display='block'>
                              Costs: {formatPrice(deal.cost || 0)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
                {filteredSoldDeals.length === 0 && (
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='body1'>No sales found matching the current filters</Typography>
                  </Paper>
                )}
              </Stack>
            </Box>
          ) : (
            <TableContainer sx={{ maxHeight: 300, overflow: 'auto' }}>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Vehicle</TableCell>
                    <TableCell>Stock #</TableCell>
                    <TableCell>Buyer</TableCell>
                    <TableCell>Sales Rep</TableCell>
                    <TableCell align='right'>Price</TableCell>
                    <TableCell align='right'>Buy Price</TableCell>
                    <TableCell align='right'>Add. Costs</TableCell>
                    <TableCell align='right'>Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredSoldDeals.map(deal => (
                    <TableRow
                      key={deal.vehicle.id}
                      hover
                      onClick={() => handleRowClick(deal)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        {deal.vehicle.year} {deal.vehicle.make} {deal.vehicle.model}
                      </TableCell>
                      <TableCell>{deal.vehicle.stock_number || 'N/A'}</TableCell>
                      <TableCell>{deal.buyer || 'Unknown'}</TableCell>
                      <TableCell>{deal.sales_rep || 'Unknown'}</TableCell>
                      <TableCell align='right'>{formatPrice(deal.price || 0)}</TableCell>
                      <TableCell align='right'>{formatPrice(deal.vehicle.seller_asking_price || 0)}</TableCell>
                      <TableCell align='right'>
                        <Tooltip
                          title={
                            <div>
                              <Typography variant='caption' display='block'>
                                Repair: {formatPrice(deal.vehicle.repair_cost || 0)}
                              </Typography>
                              <Typography variant='caption' display='block'>
                                Shipping: {formatPrice(deal.vehicle.shipping_cost || 0)}
                              </Typography>
                              <Typography variant='caption' display='block'>
                                Rental: {formatPrice(deal.vehicle.rental_cost || 0)}
                              </Typography>
                              <Typography variant='caption' display='block'>
                                Other: {formatPrice(deal.vehicle.other_cost || 0)}
                              </Typography>
                            </div>
                          }
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {formatPrice(
                              (deal.vehicle.repair_cost || 0) +
                                (deal.vehicle.shipping_cost || 0) +
                                (deal.vehicle.rental_cost || 0) +
                                (deal.vehicle.other_cost || 0),
                            )}
                            <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='right'>{formatPrice(deal.profit || 0)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {/* Detail Dialog, update to include costs */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='md' fullWidth fullScreen={isMobile}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Vehicle Sale Details
          <IconButton onClick={() => setDialogOpen(false)} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedSale && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h6' gutterBottom>
                      Vehicle Information
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                      <DirectionsCarIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant='h6'>
                        {selectedSale.vehicle.year} {selectedSale.vehicle.make} {selectedSale.vehicle.model}
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Stock Number
                        </Typography>
                        <Typography variant='body1'>{selectedSale.vehicle.stock_number || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          VIN
                        </Typography>
                        <Typography variant='body1'>{selectedSale.vehicle.vin || 'N/A'}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Mileage
                        </Typography>
                        <Typography variant='body1'>
                          {selectedSale.vehicle.miles
                            ? `${selectedSale.vehicle.miles.toLocaleString()} miles`
                            : 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Type
                        </Typography>
                        <Typography variant='body1'>{selectedSale.vehicle.type || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body2' color='text.secondary'>
                          Deal Quality
                        </Typography>
                        <Typography variant='body1'>
                          <Chip
                            size='small'
                            label={selectedSale.vehicle.dealQuality 
                              ? getDealQualityInfo(selectedSale.vehicle.dealQuality).displayText 
                              : 'Unknown'}
                            color={selectedSale.vehicle.dealQuality 
                              ? getDealQualityInfo(selectedSale.vehicle.dealQuality).color 
                              : 'default'}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h6' gutterBottom>
                      Financial Details
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <AttachMoneyIcon sx={{ mr: 0.5, color: 'primary.main', fontSize: 20 }} />
                            <Typography variant='body2' color='text.secondary'>
                              Sale Price
                            </Typography>
                          </Box>
                          <Typography variant='h6' color='primary.main'>
                            {formatPrice(selectedSale.price || 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <AttachMoneyIcon sx={{ mr: 0.5, color: 'error.main', fontSize: 20 }} />
                            <Typography variant='body2' color='text.secondary'>
                              Cost
                            </Typography>
                          </Box>
                          <Typography variant='h6' color='error.main'>
                            {formatPrice(selectedSale.cost || 0)}
                            <Tooltip
                              title={
                                <div>
                                  <Typography variant='caption' display='block'>
                                    Buy Price: {formatPrice(selectedSale.vehicle.seller_asking_price || 0)}
                                  </Typography>
                                  <Typography variant='caption' display='block'>
                                    Repair: {formatPrice(selectedSale.vehicle.repair_cost || 0)}
                                  </Typography>
                                  <Typography variant='caption' display='block'>
                                    Shipping: {formatPrice(selectedSale.vehicle.shipping_cost || 0)}
                                  </Typography>
                                  <Typography variant='caption' display='block'>
                                    Rental: {formatPrice(selectedSale.vehicle.rental_cost || 0)}
                                  </Typography>
                                  <Typography variant='caption' display='block'>
                                    Other: {formatPrice(selectedSale.vehicle.other_cost || 0)}
                                  </Typography>
                                </div>
                              }
                            >
                              <IconButton size='small' color='inherit' sx={{ ml: 1, p: 0 }}>
                                <InfoOutlinedIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <AttachMoneyIcon
                              sx={{
                                mr: 0.5,
                                color: selectedSale.profit! > 0 ? 'success.main' : 'error.main',
                                fontSize: 20,
                              }}
                            />
                            <Typography variant='body2' color='text.secondary'>
                              Profit
                            </Typography>
                          </Box>
                          <Typography
                            variant='h6'
                            color={selectedSale.profit! > 0 ? 'success.main' : 'error.main'}
                            sx={{ fontWeight: 'bold' }}
                          >
                            {formatPrice(selectedSale.profit || 0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box>
                      <Typography variant='h6' gutterBottom>
                        Transaction Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <CalendarTodayIcon sx={{ mr: 0.5, color: 'text.secondary', fontSize: 20 }} />
                            <Typography variant='body2' color='text.secondary'>
                              Deal Date
                            </Typography>
                          </Box>
                          <Typography variant='body1'>
                            {selectedSale.date ? formatDateTime(selectedSale.date) : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <SellIcon sx={{ mr: 0.5, color: 'text.secondary', fontSize: 20 }} />
                            <Typography variant='body2' color='text.secondary'>
                              Sales Rep
                            </Typography>
                          </Box>
                          <Typography variant='body1'>{selectedSale.sales_rep || 'Unknown'}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant='h6' gutterBottom>
                      Customer Information
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                      <PersonIcon sx={{ mr: 1, color: 'text.secondary', mt: 0.5 }} />
                      <Box>
                        <Typography variant='h6'>{selectedSale.buyer || 'Unknown Buyer'}</Typography>
                        {selectedSale.vehicle.sold_to_user && (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                              <LocationOnIcon sx={{ mr: 0.5, color: 'text.secondary', fontSize: 20 }} />
                              <Typography variant='body2'>No address available</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                              <PhoneIcon sx={{ mr: 0.5, color: 'text.secondary', fontSize: 20 }} />
                              <Typography variant='body2'>No phone available</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                              <EmailIcon sx={{ mr: 0.5, color: 'text.secondary', fontSize: 20 }} />
                              <Typography variant='body2'>No email available</Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Detail Dialog for Stock Prefix vehicles */}
      <Dialog
        open={stockPrefixDialogOpen}
        onClose={() => setStockPrefixDialogOpen(false)}
        maxWidth='md'
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {selectedStockPrefix === 'ALL'
            ? 'All Vehicles'
            : `${getFullNameFromPrefix(selectedStockPrefix || '')} Stock Vehicles`}{' '}
          ({selectedPrefixVehicles.length})
          <IconButton onClick={() => setStockPrefixDialogOpen(false)} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {isMobile ? (
            <Box sx={{ mb: 2 }}>
              <Stack spacing={1.5}>
                {selectedPrefixVehicles.map(vehicle => {
                  const buyPrice = vehicle.seller_asking_price || 0
                  const additionalCosts =
                    (vehicle.repair_cost || 0) +
                    (vehicle.shipping_cost || 0) +
                    (vehicle.rental_cost || 0) +
                    (vehicle.other_cost || 0)
                  const profit = (vehicle.optic_list_price || 0) - buyPrice - additionalCosts

                  return (
                    <Paper key={vehicle.id} elevation={1} sx={{ p: 1.5 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                              mb: 0.5,
                            }}
                          >
                            <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                              {vehicle.stock_number}
                            </Typography>
                            <Chip
                              label={vehicle.dealQuality 
                                ? getDealQualityInfo(vehicle.dealQuality).displayText 
                                : 'Unknown'}
                              size='small'
                              color={vehicle.dealQuality 
                                ? getDealQualityInfo(vehicle.dealQuality).color 
                                : 'default'}
                            />
                          </Box>
                          <Typography variant='body2'>
                            {vehicle.year} {vehicle.make} {vehicle.model}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={{ my: 0.5 }} />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='caption' color='text.secondary'>
                            List Price
                          </Typography>
                          <Typography variant='body2'>{formatPrice(vehicle.optic_list_price || 0)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='caption' color='text.secondary'>
                            Buy Price
                          </Typography>
                          <Typography variant='body2'>{formatPrice(buyPrice)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='caption' color='text.secondary'>
                            Additional Costs
                          </Typography>
                          <Tooltip
                            title={
                              <div>
                                <Typography variant='caption' display='block'>
                                  Repair: {formatPrice(vehicle.repair_cost || 0)}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  Shipping: {formatPrice(vehicle.shipping_cost || 0)}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  Rental: {formatPrice(vehicle.rental_cost || 0)}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  Other: {formatPrice(vehicle.other_cost || 0)}
                                </Typography>
                              </div>
                            }
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant='body2'>
                                {formatPrice(
                                  (vehicle.repair_cost || 0) +
                                    (vehicle.shipping_cost || 0) +
                                    (vehicle.rental_cost || 0) +
                                    (vehicle.other_cost || 0),
                                )}
                              </Typography>
                              <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                            </Box>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='caption' color='text.secondary'>
                            Profit
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{ color: profit > 0 ? 'success.main' : 'error.main', fontWeight: 'bold' }}
                          >
                            {formatPrice(profit)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  )
                })}
                {selectedPrefixVehicles.length === 0 && (
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='body1'>No vehicles found for this stock prefix</Typography>
                  </Paper>
                )}
              </Stack>
            </Box>
          ) : (
            <TableContainer sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Stock #</TableCell>
                    <TableCell>Vehicle</TableCell>
                    <TableCell>Deal Quality</TableCell>
                    <TableCell align='right'>List Price</TableCell>
                    <TableCell align='right'>Buy Price</TableCell>
                    <TableCell align='right'>Additional Costs</TableCell>
                    <TableCell align='right'>Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPrefixVehicles.map(vehicle => {
                    const buyPrice = vehicle.seller_asking_price || 0
                    const additionalCosts =
                      (vehicle.repair_cost || 0) +
                      (vehicle.shipping_cost || 0) +
                      (vehicle.rental_cost || 0) +
                      (vehicle.other_cost || 0)
                    const profit = (vehicle.optic_list_price || 0) - buyPrice - additionalCosts

                    return (
                      <TableRow key={vehicle.id} hover>
                        <TableCell>{vehicle.stock_number}</TableCell>
                        <TableCell>
                          {vehicle.year} {vehicle.make} {vehicle.model}
                        </TableCell>
                        <TableCell>
                          <Chip
                            size='small'
                            label={vehicle.dealQuality 
                              ? getDealQualityInfo(vehicle.dealQuality).displayText 
                              : 'Unknown'}
                            color={vehicle.dealQuality 
                              ? getDealQualityInfo(vehicle.dealQuality).color 
                              : 'default'}
                          />
                        </TableCell>
                        <TableCell align='right'>{formatPrice(vehicle.optic_list_price || 0)}</TableCell>
                        <TableCell align='right'>{formatPrice(buyPrice)}</TableCell>
                        <TableCell align='right'>
                          <Tooltip
                            title={
                              <div>
                                <Typography variant='caption' display='block'>
                                  Repair: {formatPrice(vehicle.repair_cost || 0)}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  Shipping: {formatPrice(vehicle.shipping_cost || 0)}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  Rental: {formatPrice(vehicle.rental_cost || 0)}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  Other: {formatPrice(vehicle.other_cost || 0)}
                                </Typography>
                              </div>
                            }
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                              {formatPrice(additionalCosts)}
                              <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }} />
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align='right'
                          sx={{ color: profit > 0 ? 'success.main' : 'error.main', fontWeight: 'bold' }}
                        >
                          {formatPrice(profit)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {selectedPrefixVehicles.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align='center'>
                        No vehicles found for this stock prefix
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStockPrefixDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
