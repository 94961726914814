import { addDoc, collection, doc, setDoc } from '@firebase/firestore'
import FilterListIcon from '@mui/icons-material/FilterList'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { Box, MenuItem, Select, Typography, Container, useTheme, Paper } from '@mui/material'
import { PageTitle } from 'components/PageTitle'
import { useAuth } from 'contexts/AuthContext'
import { ApplicationStatus, PandaDocument } from '@otw/models'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useSnackbar } from 'contexts/snackBarContext'
import React, { useState, useEffect, useRef } from 'react'
import ApplicationBoard from './ApplicationBoard'
import { useNavigate } from 'react-router-dom'
import PandadocDialog from '../../../components/PandadocDialog'
import { useClientApplication } from 'hooks/client/useClientApplication'
import { animate, spring } from 'motion'
import { ApplicationStatusTimeline } from 'components/ApplicationStatusTimeline'

const ClientHome = (): React.ReactNode => {
  const { userInfo } = useAuth()
  const theme = useTheme()
  const isMobile = useIsMobile()
  const [statusFilter, setStatusFilter] = useState<ApplicationStatus | 'all'>('all')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [iframeUrl, setIframeUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [currentStatus, setCurrentStatus] = useState<ApplicationStatus>()
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const { submitApplication, applications } = useClientApplication()

  const headerRef = useRef<HTMLDivElement>(null)
  const subtitleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (headerRef.current && subtitleRef.current && contentRef.current) {
      // @ts-ignore - the types are wrong but this works
      animate(headerRef.current, { y: [20, 0], opacity: [0, 1] }, { duration: 0.5, easing: spring() })

      // @ts-ignore
      animate(subtitleRef.current, { y: [20, 0], opacity: [0, 1] }, { duration: 0.5, delay: 0.2, easing: spring() })

      // @ts-ignore
      animate(contentRef.current, { y: [20, 0], opacity: [0, 1] }, { duration: 0.5, delay: 0.4, easing: spring() })
    }
  }, [])

  const statuses = ['all', 'approved', 'draft', 'denied', 'pending', 'viewed', 'ready', 'corrections needed']

  const handleResumeApplication = (url: string, status: ApplicationStatus) => {
    setIframeUrl(url)
    setCurrentStatus(status)
    setDialogOpen(true)
  }

  const handleFormCompleted = async (pandaPayload: PandaDocument) => {
    try {
      await submitApplication(pandaPayload)
      showSnackbar('Application Submitted Successfully!', 'success')
      setDialogOpen(false)
    } catch (error) {
      showSnackbar('Failed to submit application. Please try again.', 'error')
    }
  }

  const hasApplications = applications && applications.length > 0

  return (
    <Container maxWidth='xl'>
      <Box sx={{ overflow: 'hidden' }}>
        <Box mb={4}>
          <Box
            ref={headerRef}
            sx={{
              opacity: 0,
              mb: 1,
            }}
          >
            <Typography
              variant='h3'
              sx={{
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              {hasApplications ? 'Welcome back' : 'Welcome'}, {userInfo?.first_name}
              {userInfo?.last_name ? ` ${userInfo.last_name}` : ''}!
            </Typography>
          </Box>

          <Box
            ref={subtitleRef}
            sx={{
              opacity: 0,
              mb: 3,
            }}
          >
            <Box>
              <Typography variant='body1' color='text.secondary'>
                {hasApplications 
                  ? "You can view and complete applications that have been sent to you by our team. Your applications will appear below. Need assistance? Contact us at admin@optictruckworks.com or call Mike at 908-922-0869."
                  : "If you don't see any applications, please contact us at admin@optictruckworks.com or call Mike at 908-922-0869 to get started."}
              </Typography>
            </Box>
          </Box>

          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 4,
              borderRadius: 2,
              bgcolor: 'background.default',
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                mb: 2,
                color: theme.palette.primary.main,
                fontWeight: 500,
              }}
            >
              Application Process
            </Typography>
            <ApplicationStatusTimeline role='client' />
          </Paper>
        </Box>

        <Box ref={contentRef} sx={{ opacity: 0 }}>
          {hasApplications && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <AssignmentIcon
                    sx={{
                      fontSize: 32,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Box>
                    <Typography variant='h5' sx={{ fontWeight: 500, color: theme.palette.primary.main }}>
                      My Applications
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      Track and manage your financing applications
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FilterListIcon color='action' />
                  <Select
                    value={statusFilter}
                    onChange={e => setStatusFilter(e.target.value as ApplicationStatus)}
                    size='small'
                    sx={{
                      minWidth: 150,
                      backgroundColor: 'background.paper',
                      borderRadius: 1,
                      '& .MuiSelect-select': {
                        py: 1,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.divider,
                        borderWidth: 1,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.light,
                        borderWidth: 1,
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main,
                        borderWidth: 2,
                      },
                    }}
                  >
                    {statuses.map(status => (
                      <MenuItem key={status} value={status} sx={{ textTransform: 'capitalize' }}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <ApplicationBoard
                statusFilter={statusFilter}
                view='card'
                onResumeApplication={(url, status) => handleResumeApplication(url, status)}
              />
            </>
          )}
        </Box>
      </Box>

      <PandadocDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        iframeUrl={iframeUrl}
        loading={loading}
        onFormCompleted={handleFormCompleted}
        onIframeLoad={() => setLoading(false)}
        status={currentStatus}
        application={applications.find(app => app.pandadoc_url === iframeUrl)}
      />
    </Container>
  )
}

export default ClientHome
