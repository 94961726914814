import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { CombinedUserData, CreateUserType, PrivateUserInfoType } from '@otw/models/user'
import { portalFunctions, GetAllUsersResponse } from 'core/functions/portalFunctions'
import { updateUser } from 'core/admin/users'
import { useAuth } from 'contexts/AuthContext'
import { useState, useEffect, useRef } from 'react'

interface CreateUserResponse {
  userId: string
  success: boolean
}

// Define the page param type
interface PageParam {
  page: number;
  lastDocId?: string | null;
}

// Smaller batch size to ensure more reliable loading
const USERS_PER_PAGE = 20;

export const useManageUsers = () => {
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingMoreUsers, setLoadingMoreUsers] = useState(false)
  const [autoLoadComplete, setAutoLoadComplete] = useState(false);
  
  // Track whether we're in the process of loading
  const isLoadingRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  // Store the current retry attempt when loading fails
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 5;
  
  // Infinite query to fetch users with pagination
  const {
    data,
    isLoading: authUsersLoading,
    isError: authUsersError,
    error: authUsersErrorDetails,
    refetch: refetchUsers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['admin', 'users', 'management', searchTerm],
    initialPageParam: { page: 1 } as PageParam,
    queryFn: async ({ pageParam }) => {
      try {
        // Check if user has admin permissions
        if (userInfo?.role !== 'admin' && userInfo?.role !== 'babyAdmin') {
          throw new Error('Unauthorized: User does not have admin permissions')
        }

        const typedPageParam = pageParam as PageParam;
        
        // Set loading state for better UX
        setLoadingMoreUsers(true)
        
        const options = {
          limit: USERS_PER_PAGE,
          page: typedPageParam.page,
          lastDocId: typedPageParam.lastDocId === null ? undefined : typedPageParam.lastDocId,
          search: searchTerm || undefined
        }

        console.log(`Fetching users (page ${typedPageParam.page}, lastDocId: ${typedPageParam.lastDocId})`);
        const result = await portalFunctions.admin.getAuthUsers(options)
        
        if (!result || !result.users) {
          throw new Error('No users data returned from API')
        }
        
        console.log(`Received ${result.users.length} users, total: ${result.total}`);
        setLoadingMoreUsers(false)
        return result
      } catch (error) {
        setLoadingMoreUsers(false)
        console.error('Error fetching users:', error)
        throw error
      }
    },
    getNextPageParam: (lastPage: GetAllUsersResponse) => {
      if (!lastPage.hasMore) {
        console.log('No more pages to fetch according to API');
        return undefined;
      }
      
      const nextParams = { 
        page: (lastPage.page || 1) + 1, 
        lastDocId: lastPage.lastDocId 
      } as PageParam;
      
      console.log(`Next page will be: page ${nextParams.page}, lastDocId: ${nextParams.lastDocId}`);
      return nextParams;
    },
    staleTime: 5 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    enabled: Boolean(userInfo?.role === 'admin' || userInfo?.role === 'babyAdmin'),
  })

  // Combine paginated results into a single array for ag-grid
  const authUsers = data ? {
    users: data.pages.flatMap((page: GetAllUsersResponse) => page.users || []),
    total: data.pages[0]?.total || 0
  } : { users: [], total: 0 }

  // Simple function to load the next page
  const continueLoading = useEffect(() => {
    // Skip if we're already loading, there's no next page, or auto-load is complete
    if (
      isLoadingRef.current || 
      isFetchingNextPage || 
      !data || 
      autoLoadComplete || 
      !hasNextPage
    ) {
      return;
    }
    
    const currentCount = data.pages.flatMap(p => p.users || []).length;
    const totalCount = data.pages[0]?.total || 0;
    
    // If we have all users or have reached the retry limit, stop auto-loading
    if (currentCount >= totalCount || retryCountRef.current >= MAX_RETRIES) {
      console.log(`Auto-loading complete. Loaded ${currentCount}/${totalCount} users.`);
      setAutoLoadComplete(true);
      return;
    }
    
    // Set loading state
    isLoadingRef.current = true;
    console.log(`Loading more users (${currentCount}/${totalCount} loaded)`);
    
    // Load the next page
    fetchNextPage()
      .then(() => {
        isLoadingRef.current = false;
        // Reset retry counter on success
        retryCountRef.current = 0;
        
        // Schedule next load after a delay
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        
        // Set a timeout to check again after a delay
        timeoutRef.current = setTimeout(() => {
          // This will trigger the effect again
          setAutoLoadComplete(false);
        }, 500);
      })
      .catch(error => {
        console.error("Error loading users:", error);
        isLoadingRef.current = false;
        
        // Increment retry counter
        retryCountRef.current++;
        
        // Try again after a delay
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        
        timeoutRef.current = setTimeout(() => {
          // This will trigger the effect again
          setAutoLoadComplete(false);
        }, 1000);
      });
  }, [data, hasNextPage, isFetchingNextPage, autoLoadComplete, fetchNextPage]);

  // Reset autoload state when search term changes
  useEffect(() => {
    // Reset auto-load state
    setAutoLoadComplete(false);
    retryCountRef.current = 0;
    
    // Clear any pending timeouts
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [searchTerm]);
  
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const createUserMutation = useMutation({
    mutationFn: async (user: CreateUserType) => {
      const response = await portalFunctions.admin.createFirebaseUser(user)
      if (!response.ok) {
        const text = await response.text()
        try {
          const data = JSON.parse(text)
          throw new Error(data.message || 'Failed to create user')
        } catch (e) {
          // If JSON parsing fails, use the raw text
          throw new Error(text || 'Failed to create user')
        }
      }

      const data = (await response.json()) as CreateUserResponse
      if (!data.userId) {
        throw new Error('Failed to create user')
      }
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const createDisabledUserMutation = useMutation({
    mutationFn: async (user: CreateUserType) => {
      return await portalFunctions.admin.createDisabledFirebaseUser(user)
    },
    onMutate: async newUser => {
      await queryClient.cancelQueries({ queryKey: ['users'] })

      // Snapshot the previous value
      const previousUsers = queryClient.getQueryData(['users'])

      queryClient.setQueryData(['users'], (old: PrivateUserInfoType[]) => {
        const optimisticUser = {
          ...newUser,
          uid: 'temp-' + Date.now(), // Temporary ID
          disabled: true,
          metadata: {
            creationTime: new Date().toISOString(),
            lastSignInTime: null,
          },
        }
        return [...old, optimisticUser]
        
      })

      return { previousUsers }
    },
    onError: (err, newUser, context) => {
      queryClient.setQueryData(['users'], context?.previousUsers)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    },
  })

  const updateUserMutation = useMutation({
    mutationFn: async (user: CombinedUserData) => {
      return await updateUser(user)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.deleteFirebaseUser(userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const disableUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.disableFirebaseUser(userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  const enableUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.enableFirebaseUser(userId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users', 'management'] })
    },
  })

  return {
    authUsers,
    authUsersLoading,
    authUsersError,
    authUsersErrorDetails,
    refetchUsers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    searchTerm,
    setSearchTerm,
    createUserMutation,
    createDisabledUserMutation,
    updateUserMutation,
    deleteUserMutation,
    disableUserMutation,
    enableUserMutation,
  }
}
