export const vehicleStatusOptions = [
  'Pending Available',
  'Available',
  'Pending Sale',
  'Sold',
  'Sold elsewhere',
  'Active Deal',
]

export const vehicleTypeOptions = ['Step van', 'Cargo van', 'Cutaway', 'Box truck']

export const vehicleFuelOptions = ['Gas', 'Diesel']

export const dealQualityOptions = ['great', 'good', 'average', 'belowAverage', 'poor']

export const getVehicleSizeOptions = (vehicleType: string) => {
  if (!vehicleType) return []

  switch (vehicleType.toLowerCase()) {
    case 'step van':
      return ['P500', 'P700', 'P900', 'P1000', 'P1100', 'P1200']
    case 'cargo van':
      return ['low-standard', 'medium-standard', 'high-standard', 'low-extended', 'medium-extended', 'high-extended']
    case 'cutaway':
      return ['10ft', '12ft', '14ft', '15ft', '16ft', '17ft', '18ft']
    case 'box truck':
      return ['12ft', '14ft', '16ft', '18ft', '20ft', '22ft', '24ft', '26ft']
    default:
      return []
  }
}

export const conditionOptions = ['New', 'Used']

export const makeOptions = ['Ford', 'Chevrolet', 'Freightliner', 'GMC', 'Mercedes-Benz']

export const getVehicleModelOptions = (vehicleMake: string) => {
  switch (vehicleMake) {
    case 'Ford':
      return ['E350', 'E450', 'F59', 'F650', 'F750', 'Transit']
    case 'Chevrolet':
      return ['Express 3500', 'Express 4500', '5500XD LCF']
    case 'Freightliner':
      return ['M2 106', 'MT45', 'MT55']
    case 'GMC':
      return ['Express 3500', 'Express 4500', 'Savana 3500', 'Savana 4500']
    case 'Mercedes-Benz':
      return ['Sprinter 2500', 'Sprinter 3500']
    default:
      return []
  }
}
