const opticPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAqW/g/2L9gl8AtWvdvrlS
uKjC0B/4hezgTHRP6VXmhBd23PYpxmzRZA73c4iXCtvLTaYaAd3uz4+R5s0ghL2X
86YBeFFHJ3u1p8LX4wwtdrXPegqSe9X5P2oeBIDXVVdGcuYWFJuoH7XIh2Vctwir
cnbRndH1ZbZ863CTY8XicHGH88xhRlgtJ91TZrC74duH98/H8vZ5d5qQTHmLnsTD
9slYrp/u1P/ty/2OXpI5UrAeEnCMTvkV4KjCd8TqntVtlrVjN5jtI5LfsEgGMd6l
DXDniXwFJcQE92pRcUk758rbbNsiMWLJ4ELTxXzkAzPeMgYriKsvX9WOc1FyC4je
uEiR66KxDkBuCy4679Iv9Sx1UpK4YVnftPAIhvaVNVwHWxwd3h/qMLRGJdcdqjw+
wUWDJZXXbgY/+iWuO90unakdhn73/ETxup2sSiqhN3N5TwP7O0YEmiVl2Vl9wpIB
po3waBeh2QwQt+WNugUZalItnGFceIxaAt01GRFvmz7jAgMBAAE=
-----END PUBLIC KEY-----
`;


export { opticPublicKey };
