import { Autocomplete, Box, MenuItem, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Calculator from './components/Calculator'
import { useFormulas } from 'hooks/graybook'
import { useIsMobile } from 'hooks/screen/useIsMobile'

const DepreciationCalculator = () => {
  const [selectedFormulaId, setSelectedFormulaId] = useState<string>('')
  const isMobile = useIsMobile()

  const { formulas } = useFormulas({
    formulasCollection: 'gray_book_formulas',
  })

  const selectedFormula = formulas?.find(f => f.id === selectedFormulaId)

  return (
    <Box sx={{ 
      p: isMobile ? 2 : 3, 
      maxWidth: 600, 
      margin: '0 auto',
      width: '100%'
    }}>
      <Typography variant='h4' gutterBottom sx={{ fontSize: isMobile ? '1.5rem' : '2.125rem' }}>
        Depreciation Calculator
      </Typography>

      <CalculatorForm
        selectedFormulaId={selectedFormulaId}
        setSelectedFormulaId={setSelectedFormulaId}
        formulas={formulas}
      />
      {selectedFormula && <Calculator formula={selectedFormula} />}
    </Box>
  )
}

const CalculatorForm = ({
  selectedFormulaId,
  setSelectedFormulaId,
  formulas,
}: {
  selectedFormulaId: string
  setSelectedFormulaId: (id: string) => void
  formulas?: { id: string; name: string }[]
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <Box sx={{ my: 2, position: 'relative' }}>
      <Autocomplete
        fullWidth
        options={formulas || []}
        getOptionLabel={(option) => option.name}
        value={formulas?.find(f => f.id === selectedFormulaId) || null}
        onChange={(_, newValue) => {
          if (newValue) {
            setSelectedFormulaId(newValue.id)
            setSearchTerm(newValue.name)
          } else {
            setSelectedFormulaId('')
            setSearchTerm('')
          }
        }}
        inputValue={searchTerm}
        onInputChange={(_, newInputValue) => {
          setSearchTerm(newInputValue)
          if (!newInputValue) {
            setSelectedFormulaId('')
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Formulas"
            sx={{
              mb: 1,
              width: '100%',
              '& .MuiInputBase-root': {
                width: '100%'
              }
            }}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.id}
            sx={{
              backgroundColor: selectedFormulaId === option.id ? 'action.selected' : 'inherit'
            }}
          >
            {option.name}
          </MenuItem>
        )}
      />
    </Box>
  )
}



export default DepreciationCalculator
