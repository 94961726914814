import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { IsLoading, AccountDeactivated, NeedVerification } from 'components'
import { NotFound } from 'views/Auth/NotFound'
import { useAuth } from 'contexts/AuthContext'
import { Login, ForgotPassword, ResetPassword, LoginMFACode } from 'views/Auth'
import { SupportRequest } from 'views/Shared'
import { getSuperUserPages, clientPages, dealerPages, bankPages } from 'router/pages'
import { 
  AuthRoute, 
  SuperUserRoute, 
  DealerRoute, 
  ClientRoute, 
  BankRoute, 
  ProtectedRoute,
  SalesAgentRoute
} from './routes'
import { mapPagesToReactRouter } from 'router/mapPagesToReactRouter'
import { AuthLayout } from './layouts'
import FleetListPage from 'views/SuperAdmin/Management/SubmissionManagement/components/FleetListPage'
import OnboardingLayout from './layouts/OnboardingLayout/OnboardingLayout'
import AccountSettings from 'views/Shared/AccountSettings/AccountSettings'
import { NeedsResetPassword } from 'views/Shared/NeedsResetPassword/NeedsResetPassword'
import { ClientOnboarding } from 'views/Client/ClientOnboarding/ClientOnboarding'
import { CoreLayout } from './layouts'
import { MainLoading } from 'components/MainLoading'
import { BankOnboarding } from 'views/Bank/BankOnboarding/BankOnboarding'
import { NotificationCenter } from 'views/Shared/NotificationCenter'

export const AppRoutes = () => {
  const { isLoading, userInfo, currentUser } = useAuth()

  if (isLoading) {
    return <MainLoading />
  }

  const superuserPages = getSuperUserPages()

  if (userInfo?.is_active === false) {
    return <AccountDeactivated />
  }

  // if (!userInfo?.isVerified && userInfo) {
  //   return <NeedVerification />
  // }

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path='/login'
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        {/* <Route
          path='/signup'
          element={
            <AuthRoute>
              <Signup />
            </AuthRoute>
          }
        /> */}
        <Route
          path='/forgot-password'
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />

        {/* <Route
          path='/reset-password'
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        /> */}

        <Route
          path='/reset-password'
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />

        <Route
          path='/login-mfa-code'
          element={
            <AuthRoute>
              <LoginMFACode />
            </AuthRoute>
          }
        />
      </>
    )
  }

  const RedirectRoutes = () => {
    return (
      <>
        {/* Not Found - other pages redirect here */}
        <Route path='/not-found' element={<NotFound />} />

        {/* Redirect logic to /login or /role occurs in AuthLayout */}
        <Route path='/' />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route path='*' element={<Navigate to='/not-found' replace />} />
      </>
    )
  }

  const SharedRoutes = () => {
    return (
      <>
        <Route
          path='/notification-center'
          element={
            <ProtectedRoute>
              <NotificationCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path='/support-request'
          element={
            <ProtectedRoute>
              <SupportRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path='/not-found'
          element={
            <ProtectedRoute>
              <NotFound />
            </ProtectedRoute>
          }
        />
        <Route
          path='/account-deactivated'
          element={
            <ProtectedRoute>
              <AccountDeactivated />
            </ProtectedRoute>
          }
        />

        <Route
          path='/account-settings'
          element={
            <ProtectedRoute>
              <AccountSettings />
            </ProtectedRoute>
          }
        />
      </>
    )
  }

  const SuperAdminRoutes = () => {
    return (
      <>
        <Route
          path='/fleet-list'
          element={
            <SuperUserRoute>
              <FleetListPage />
            </SuperUserRoute>
          }
        />
      </>
    )
  }

  const OnboardingRoutes = () => {
    return (
      <>
        <Route element={<OnboardingLayout />}>
          <Route path='/needs-reset-password' element={<NeedsResetPassword />} />
          <Route path='/client-onboarding' element={<ClientOnboarding />} />
          <Route path='/bank-onboarding' element={<BankOnboarding />} />
        </Route>{' '}
      </>
    )
  }

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          {AuthRoutes()} {RedirectRoutes()}
        </Route>

        {OnboardingRoutes()}

        <Route element={<CoreLayout />}>
          {SharedRoutes()}
          {SuperAdminRoutes()}
          {mapPagesToReactRouter(superuserPages, SuperUserRoute)}
          {mapPagesToReactRouter(clientPages, ClientRoute)}
          {mapPagesToReactRouter(clientPages, SalesAgentRoute)} // Just use client pages until scott needs to see different pages
          {mapPagesToReactRouter(dealerPages, DealerRoute)}
          {mapPagesToReactRouter(bankPages, BankRoute)}
        </Route>
      </Routes>
    </Router>
  )
}
