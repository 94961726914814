export type ExpenseCategory = 'Shipping' | 'Repairs' | 'RentalReimbursement' | 'Other';

export interface Expense {
  readonly id: string;
  category: ExpenseCategory;
  amount: number;
  description: string;
  date: Date; 
  created_by: string;
  created_at: Date; 
  updated_at: Date | null; 
  updated_by: string | null;
  
  // For "Other" category, we can provide a custom value
  customCategory: string | null;
  
  // Optional link to a vehicle
  vehicleId: string | null;
}

// Type guard to check if the category is valid
export function isValidExpenseCategory(category: string): category is ExpenseCategory {
  return ['Shipping', 'Repairs', 'RentalReimbursement', 'Other'].includes(category);
} 