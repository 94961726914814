import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  Button,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  CardHeader,
  Alert,
  TextField,
  MenuItem,
  Switch,
  FormControlLabel,
  IconButton,
  Snackbar,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAuth } from 'hooks/auth/useAuth';
import { portalFunctions, ClientFinancingProfileResponse } from 'core/functions/portalFunctions';
import { formatCurrency } from 'utils/formatters';
import { useUpdateClientFinancingProfile } from 'hooks/admin/useUpdateClientFinancingProfile';
import { PrivateClientFinancingProfile } from '@otw/models';
import { useGenerateApplicationForUser } from 'hooks/admin/useGenerateApplicationForUser';
import { useGetAllBanks } from 'hooks/admin/useGetAllBanks';
import { useAdminApplications } from 'hooks/admin/useAdminApplications';
import { useUsers } from 'hooks/useUsers';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Application } from '@otw/models';

export const ClientFinancingDetail = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [clientData, setClientData] = useState<ClientFinancingProfileResponse | null>(null);
  const { updateFinancingProfile, isUpdating, updateError, isUpdateSuccess } = useUpdateClientFinancingProfile();
  const { users, isLoading: isLoadingUsers } = useUsers();
  
  // Edit mode state
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showSSN, setShowSSN] = useState(false);

  // Application generation state
  const [isApplicationDialogOpen, setIsApplicationDialogOpen] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState('');
  const { generateApplicationForUser, isGenerating, error: applicationError, applicationUrl, isSuccess, reset } = useGenerateApplicationForUser();
  const { banks, isLoading: isLoadingBanks, error: banksError } = useGetAllBanks();
  const [closeCountdown, setCloseCountdown] = useState(5);
  
  // Applications for user
  const { banks: banksQuery, bankAgents, deleteApplication } = useAdminApplications();
  const { data: applications, isLoading: isLoadingApplications, error: applicationsError, refetch: refetchApplications } = useQuery<Application[]>({
    queryKey: ['applicationsForUser', clientId],
    queryFn: async () => {
      if (!clientId) return [];
      
      // Get applications from Firestore where client_id matches clientId
      const { db } = await import('config/firebase');
      const { collection, getDocs, query, where } = await import('firebase/firestore');
      
      const applicationsRef = collection(db, 'applications');
      const q = query(applicationsRef, where('client_id', '==', clientId));
      const snapshot = await getDocs(q);
      
      const applications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Application[];
      
      // Sort applications by creation date (newest first)
      return applications.sort((a, b) => {
        return new Date(b.time_created).getTime() - new Date(a.time_created).getTime();
      });
    },
    enabled: !!clientId,
  });

  // Add state for delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState<string | null>(null);

  // Invitation dialog state
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [ownerToInvite, setOwnerToInvite] = useState<any>(null);
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [inviteError, setInviteError] = useState<string | null>(null);

  // Loading skeleton for sections
  const SectionSkeleton = () => (
    <Card sx={{ mb: 3 }}>
      <CardHeader 
        title={<Skeleton width="40%" height={30} />}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Skeleton width="30%" height={24} sx={{ mb: 1 }} />
              <Skeleton width="100%" height={40} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );

  const fetchClientData = async () => {
    if (!clientId) return;
    
    try {
      // Only set isLoading if we're not already in the saving state
      if (!isSaving) {
        setIsLoading(true);
      }
      
      // Force a fresh fetch by invalidating the query cache first
      const allProfiles = await portalFunctions.admin.getAllClientFinancingProfiles();
      const client = allProfiles.find(profile => profile.id === clientId);
      
      if (!client) {
        setError('Client not found');
      } else {
        // Ensure we're setting completely new state objects to trigger re-renders
        setClientData({...client});
        if (client.financingProfile) {
          setEditedProfile(JSON.parse(JSON.stringify(client.financingProfile)));
        }
      }
    } catch (err) {
      setError('Failed to load client data');
      console.error(err);
    } finally {
      // Always turn off both loading states when done
      setIsLoading(false);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [clientId]);

  // Add effect to refresh data after successful update
  useEffect(() => {
    if (isUpdateSuccess) {
      // Add a small delay to ensure the backend has processed the update
      const timer = setTimeout(() => {
        fetchClientData();
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [isUpdateSuccess]);

  const handleBack = () => {
    navigate(`/${userInfo?.role}/financing-profiles`);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    // Reset edited profile to original data
    if (clientData?.financingProfile) {
      setEditedProfile(JSON.parse(JSON.stringify(clientData.financingProfile)));
    }
    setIsEditMode(false);
  };

  const handleSaveProfile = async () => {
    if (!clientId || !editedProfile) return;
    
    try {
      setIsSaving(true);
      
      // Store the updated profile before sending to ensure we have the latest data
      const updatedProfileData = JSON.parse(JSON.stringify(editedProfile));
      
      await updateFinancingProfile({
        clientId,
        financingProfile: updatedProfileData
      });
      
      // We'll rely on the useEffect hook that watches isUpdateSuccess to refresh the data
      // This ensures we don't exit the loading state until the data is actually refreshed
      
      setSuccessMessage('Profile updated successfully');
      setIsEditMode(false);
      
      // Keep the saving state active for a moment to ensure the UI shows loading
      // The fetchClientData call from the useEffect will set isSaving to false when complete
    } catch (err) {
      console.error('Failed to update profile:', err);
      setError('Failed to update profile');
      setIsSaving(false);
    }
  };

  const handleInputChange = (section: string, field: string, value: any) => {
    if (!editedProfile) return;
    
    // Create a deep copy of the profile to avoid mutation issues
    const updatedProfile = JSON.parse(JSON.stringify(editedProfile)) as any;
    
    if (section === 'root') {
      updatedProfile[field] = value;
    } else if (section === 'assets') {
      if (!updatedProfile.assets) updatedProfile.assets = {};
      updatedProfile.assets[field] = value;
    } else if (section === 'liabilities') {
      if (!updatedProfile.liabilities) updatedProfile.liabilities = {};
      updatedProfile.liabilities[field] = value;
    } else if (section === 'personal_info') {
      if (!updatedProfile.personal_info) updatedProfile.personal_info = {};
      updatedProfile.personal_info[field] = value;
    } else if (section === 'business_address') {
      if (!updatedProfile.business_address) updatedProfile.business_address = {};
      updatedProfile.business_address[field] = value;
    } else if (section === 'personal_address') {
      if (!updatedProfile.personal_info) updatedProfile.personal_info = {};
      if (!updatedProfile.personal_info.home_address) updatedProfile.personal_info.home_address = {};
      updatedProfile.personal_info.home_address[field] = value;
    } else if (section.startsWith('additionalOwner_')) {
      // Handle additional owner changes
      const ownerIndex = parseInt(section.split('_')[1]);
      if (!updatedProfile.additionalOwners) updatedProfile.additionalOwners = [];
      
      if (updatedProfile.additionalOwners[ownerIndex]) {
        updatedProfile.additionalOwners[ownerIndex] = {
          ...updatedProfile.additionalOwners[ownerIndex],
          [field]: field === 'ownership_percentage' ? Number(value) : value
        };
      }
    }
    
    setEditedProfile(updatedProfile);
  };

  const getFinancingStatus = () => {
    if (!clientData?.financingProfile) {
      return {
        status: 'missing',
        icon: <ErrorIcon color="error" />,
        text: 'No Financing Profile',
      };
    }

    // Check if the profile has all required fields
    const profile = clientData.financingProfile;
    const hasBusinessInfo = profile.business_name && profile.entity_structure;
    const hasFinancialInfo = profile.last_year_revenue !== undefined && profile.last_year_expenses !== undefined;
    const hasPersonalInfo = profile.personal_info?.first_name && profile.personal_info?.last_name;

    if (!hasBusinessInfo || !hasFinancialInfo || !hasPersonalInfo) {
      return {
        status: 'incomplete',
        icon: <PendingIcon color="warning" />,
        text: 'Incomplete Profile',
      };
    }

    return {
      status: 'complete',
      icon: <CheckCircleIcon color="success" />,
      text: 'Complete Profile',
    };
  };

  // Get application counts by status
  const getApplicationStatusCounts = () => {
    if (!applications || applications.length === 0) return null;
    
    const counts: Record<string, number> = {};
    applications.forEach(app => {
      const status = app.status.toLowerCase();
      counts[status] = (counts[status] || 0) + 1;
    });
    
    return counts;
  };

  const applicationStatusCounts = getApplicationStatusCounts();

  // Handle application generation
  const handleOpenApplicationDialog = () => {
    setIsApplicationDialogOpen(true);
    
    // Log banks for debugging
    console.log('Available banks for selection:', banks);
    
    // Set the first bank as default if available
    if (banks.length > 0 && !selectedBankId) {
      console.log('Setting default bank:', banks[0]);
      setSelectedBankId(banks[0].id);
    }
  };

  // Reset countdown when dialog is closed
  const handleCloseApplicationDialog = useCallback(() => {
    setIsApplicationDialogOpen(false);
    reset();
    setCloseCountdown(5);
  }, [reset]);

  const handleGenerateApplication = async () => {
    if (!clientId || !selectedBankId) {
      console.error('Cannot generate application: missing clientId or selectedBankId', { clientId, selectedBankId });
      return;
    }
    
    console.log('Generating application for user', { userId: clientId, bankId: selectedBankId });
    
    try {
      await generateApplicationForUser({
        userId: clientId,
        bankId: selectedBankId
      });
      console.log('Application generated successfully');
    } catch (error) {
      console.error('Error generating application:', error);
    }
  };
  
  // Refresh applications when a new application is successfully generated
  useEffect(() => {
    if (isSuccess) {
      // Add a small delay to ensure the application is saved in Firestore
      const timer = setTimeout(() => {
        refetchApplications();
      }, 1000);
      
      // Close the dialog after a delay with countdown
      setCloseCountdown(5);
      
      const countdownInterval = setInterval(() => {
        setCloseCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      
      const closeTimer = setTimeout(() => {
        handleCloseApplicationDialog();
      }, 5000);
      
      return () => {
        clearTimeout(timer);
        clearTimeout(closeTimer);
        clearInterval(countdownInterval);
      };
    }
  }, [isSuccess, refetchApplications, handleCloseApplicationDialog]);

  // Get bank name by ID
  const getBankName = (bankId: string) => {
    const bank = banksQuery.data?.find(bank => bank.id === bankId);
    return bank?.name || 'Unknown Bank';
  };

  // Get bank agent name by ID
  const getBankAgentName = (agentId: string) => {
    if (!agentId) return 'Not assigned';
    const agent = bankAgents?.find(agent => agent.id === agentId);
    return agent ? `${agent.first_name} ${agent.last_name}` : 'Unknown Agent';
  };

  // Format date
  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy h:mm a');
    } catch (e) {
      return 'Invalid Date';
    }
  };

  // Get status chip
  const getStatusChip = (status: string) => {
    switch (status.toLowerCase()) {
      case 'signing':
        return <Chip size="small" label="Signing" color="primary" />;
      case 'pending':
        return <Chip size="small" label="Pending" color="warning" />;
      case 'approved':
        return <Chip size="small" label="Approved" color="success" />;
      case 'denied':
        return <Chip size="small" label="Denied" color="error" />;
      case 'corrections needed':
        return <Chip size="small" label="Corrections Needed" color="warning" />;
      default:
        return <Chip size="small" label={status} />;
    }
  };

  // Handle refresh applications
  const handleRefreshApplications = () => {
    refetchApplications();
  };

  // Handle delete application
  const handleDeleteApplication = (applicationId: string) => {
    // Reset the mutation state before opening the dialog
    deleteApplication.reset();
    setApplicationToDelete(applicationId);
    setDeleteDialogOpen(true);
  };

  // Add effect to close the dialog when the mutation is complete
  useEffect(() => {
    // Only run this effect if the dialog is open and the mutation was successful
    if (deleteDialogOpen && deleteApplication.isSuccess && applicationToDelete) {
      setDeleteDialogOpen(false);
      setApplicationToDelete(null);
      setSuccessMessage('Application deleted successfully');
      refetchApplications();
      
      // Reset the success state after handling the deletion
      // This is important to allow deleting multiple applications without refreshing
      const timer = setTimeout(() => {
        deleteApplication.reset();
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [deleteApplication.isSuccess, applicationToDelete, deleteApplication, refetchApplications, deleteDialogOpen]);

  // Confirm delete application
  const confirmDeleteApplication = async () => {
    if (applicationToDelete) {
      try {
        await deleteApplication.mutateAsync(applicationToDelete);
        // Dialog will be closed and application to delete will be reset in the useEffect
      } catch (error) {
        setError('Failed to delete application');
        console.error('Error deleting application:', error);
        // Close the dialog even if there's an error
        setDeleteDialogOpen(false);
        setApplicationToDelete(null);
      }
    }
  };

  // Cancel delete application
  const cancelDeleteApplication = () => {
    setDeleteDialogOpen(false);
    setApplicationToDelete(null);
    // Reset the mutation state when canceling
    deleteApplication.reset();
  };

  // Check if an email already exists in the system
  const checkEmailExists = (email: string) => {
    if (!users || isLoadingUsers) return false;
    return users.some(user => user.email?.toLowerCase() === email.toLowerCase());
  };

  // Function to open the invitation dialog
  const openInviteDialog = (owner: any) => {
    setOwnerToInvite(owner);
    setInviteDialogOpen(true);
    setInviteSuccess(false);
    setInviteError(null);
  };

  // Function to close the invitation dialog
  const closeInviteDialog = () => {
    setInviteDialogOpen(false);
    setOwnerToInvite(null);
  };

  // Function to send invitation to an additional owner
  const handleSendInvitation = async () => {
    if (!ownerToInvite) return;
    
    setIsSendingInvite(true);
    setInviteError(null);
    
    try {
      // This would typically call an API endpoint to send an invitation
      // For now, we'll just simulate a successful invitation
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setInviteSuccess(true);
      setTimeout(() => {
        closeInviteDialog();
      }, 2000);
    } catch (error) {
      console.error('Error sending invitation:', error);
      setInviteError('Failed to send invitation. Please try again.');
    } finally {
      setIsSendingInvite(false);
    }
  };

  // Function to add a new additional owner
  const handleAddOwner = () => {
    if (!editedProfile) return;
    
    const updatedProfile = JSON.parse(JSON.stringify(editedProfile)) as any;
    
    if (!updatedProfile.additionalOwners) {
      updatedProfile.additionalOwners = [];
    }
    
    // Add a new empty owner
    updatedProfile.additionalOwners.push({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      ownership_percentage: 0,
      title: ''
    });
    
    setEditedProfile(updatedProfile);
  };
  
  // Function to remove an additional owner
  const handleRemoveOwner = (index: number) => {
    if (!editedProfile || !editedProfile.additionalOwners) return;
    
    const updatedProfile = JSON.parse(JSON.stringify(editedProfile)) as any;
    
    // Remove the owner at the specified index
    updatedProfile.additionalOwners = updatedProfile.additionalOwners.filter((_: any, i: number) => i !== index);
    
    setEditedProfile(updatedProfile);
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Button startIcon={<ArrowBackIcon />} disabled sx={{ mb: 2 }}>
          Back to Financing Profiles
        </Button>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Skeleton width="70%" height={40} />
              <Skeleton width="50%" height={24} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Skeleton width={120} height={40} />
            </Grid>
          </Grid>
        </Paper>
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton />
      </Box>
    );
  }

  if (error || !clientData) {
    return (
      <Box sx={{ p: 3 }}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 2 }}>
          Back to Financing Profiles
        </Button>
        <Alert severity="error">{error || 'Client data not found'}</Alert>
      </Box>
    );
  }

  const status = getFinancingStatus();
  const profile = clientData.financingProfile;
  
  // Use the edited profile in edit mode, otherwise use the original profile
  const displayProfile = isEditMode ? editedProfile : profile;

  return (
    <Box sx={{ p: 3 }}>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
      />
      
      <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 2 }}>
        Back to Financing Profiles
      </Button>

      {/* Client Header */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5">
              {clientData.first_name} {clientData.last_name}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {clientData.email}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Company: {clientData.company_name || clientData.dealership_name || 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {profile ? (
                  <Chip
                    icon={<CheckCircleIcon color="success" />}
                    label="Financing Profile"
                    color="success"
                  />
                ) : (
                  <Chip
                    icon={<ErrorIcon color="error" />}
                    label="No Financing Profile"
                    color="error"
                  />
                )}
              </Box>
              {applications && (
                <Typography variant="body2">
                  Applications: <strong>{applications.length}</strong>
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Typography variant="body2">
                <strong>User ID:</strong> {clientId}
              </Typography>
              {(clientData as any)?.phone && (
                <Typography variant="body2">
                  <strong>Phone:</strong> {(clientData as any).phone}
                </Typography>
              )}
              {(clientData as any)?.created_at && (
                <Typography variant="body2">
                  <strong>Created:</strong> {formatDate((clientData as any).created_at)}
                </Typography>
              )}
              {(clientData as any)?.last_login && (
                <Typography variant="body2">
                  <strong>Last Login:</strong> {formatDate((clientData as any).last_login)}
                </Typography>
              )}
              {(clientData as any)?.role && (
                <Typography variant="body2">
                  <strong>Role:</strong> {(clientData as any).role}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Applications Section */}
      <Card sx={{ mb: 3 }}>
        <CardHeader 
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div">
                Financing Applications
              </Typography>
              {applications && applications.length > 0 && (
                <Chip 
                  label={applications.length} 
                  color="primary" 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
          }
          action={
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton 
                size="small" 
                onClick={handleRefreshApplications} 
                disabled={isLoadingApplications}
                title="Refresh Applications"
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DescriptionIcon />}
                onClick={handleOpenApplicationDialog}
                disabled={isUpdating}
                size="small"
              >
                Generate New Application
              </Button>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          {isLoadingApplications ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : applicationsError ? (
            <Alert severity="error">
              Error loading applications: {applicationsError instanceof Error ? applicationsError.message : 'Unknown error'}
            </Alert>
          ) : applications && applications.length > 0 ? (
            <>
              {applicationStatusCounts && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {Object.entries(applicationStatusCounts).map(([status, count]) => (
                    <Chip
                      key={status}
                      label={`${status}: ${count}`}
                      size="small"
                      color={
                        status === 'approved' ? 'success' :
                        status === 'denied' ? 'error' :
                        status === 'signing' ? 'primary' :
                        status === 'corrections needed' ? 'warning' :
                        'default'
                      }
                      sx={{ textTransform: 'capitalize' }}
                    />
                  ))}
                </Box>
              )}
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date Created</TableCell>
                      <TableCell>Bank</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Agent</TableCell>
                      <TableCell>Last Updated</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {applications.map((application) => (
                      <TableRow key={application.id} hover>
                        <TableCell>{formatDate(application.time_created)}</TableCell>
                        <TableCell>{getBankName(application.bank_id)}</TableCell>
                        <TableCell>{getStatusChip(application.status)}</TableCell>
                        <TableCell>{getBankAgentName(application.bank_agent_id)}</TableCell>
                        <TableCell>{formatDate(application.status_updated_at)}</TableCell>
                        <TableCell>
                          {application.notes || application.correction_notes || application.bank_decision_notes ? (
                            <Tooltip 
                              title={
                                <Box>
                                  {application.notes && (
                                    <Box mb={1}>
                                      <Typography variant="subtitle2">Notes:</Typography>
                                      <Typography variant="body2">{application.notes}</Typography>
                                    </Box>
                                  )}
                                  {application.correction_notes && (
                                    <Box mb={1}>
                                      <Typography variant="subtitle2">Correction Notes:</Typography>
                                      <Typography variant="body2">{application.correction_notes}</Typography>
                                    </Box>
                                  )}
                                  {application.bank_decision_notes && (
                                    <Box>
                                      <Typography variant="subtitle2">Bank Decision Notes:</Typography>
                                      <Typography variant="body2">{application.bank_decision_notes}</Typography>
                                    </Box>
                                  )}
                                </Box>
                              }
                            >
                              <Typography variant="body2" sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                View Notes
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              No notes
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton 
                            color="error" 
                            size="small" 
                            onClick={() => handleDeleteApplication(application.id)}
                            title="Delete Application"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                No applications found for this client.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DescriptionIcon />}
                onClick={handleOpenApplicationDialog}
                sx={{ mt: 2 }}
              >
                Generate First Application
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Application Generation Dialog */}
      <Dialog open={isApplicationDialogOpen} onClose={handleCloseApplicationDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Generate Financing Application</DialogTitle>
        <DialogContent>
          {isSuccess ? (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Application Generated Successfully!
              </Typography>
              <Typography variant="body1" paragraph>
                The application has been sent to the client.
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 2, mt: 1 }}>
                Generate a financing application for {clientData.first_name} {clientData.last_name}.
              </Typography>
              
              {applicationError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {applicationError}
                </Alert>
              )}
              
              {banksError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Error loading banks: {banksError}
                </Alert>
              )}
              
              <TextField
                select
                label="Select Bank"
                value={selectedBankId}
                onChange={(e) => {
                  console.log('Bank selected:', e.target.value);
                  setSelectedBankId(e.target.value);
                }}
                fullWidth
                disabled={isLoadingBanks || isGenerating}
                sx={{ mb: 2 }}
              >
                {isLoadingBanks ? (
                  <MenuItem disabled>Loading banks...</MenuItem>
                ) : banks.length === 0 ? (
                  <MenuItem disabled>No banks available</MenuItem>
                ) : banks.map((bank) => (
                  <MenuItem key={bank.id} value={bank.id}>
                    {bank.name || `Bank ${bank.id}`}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {isSuccess ? (
            <Button onClick={handleCloseApplicationDialog}>Close</Button>
          ) : (
            <>
              <Button onClick={handleCloseApplicationDialog} disabled={isGenerating}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateApplication}
                disabled={!selectedBankId || isGenerating || isLoadingBanks}
                startIcon={isGenerating && <CircularProgress size={20} color="inherit" />}
              >
                {isGenerating ? 'Generating...' : 'Generate Application'}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={cancelDeleteApplication}>
        <DialogTitle>Delete Application</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this application? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteApplication}>Cancel</Button>
          <Button 
            onClick={confirmDeleteApplication} 
            color="error" 
            variant="contained"
            disabled={deleteApplication.isPending}
            startIcon={deleteApplication.isPending ? <CircularProgress size={20} color="inherit" /> : <DeleteIcon />}
          >
            {deleteApplication.isPending ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Invitation Dialog */}
      <Dialog open={inviteDialogOpen} onClose={closeInviteDialog}>
        <DialogTitle>Invite Additional Owner (placeholder - not implemented)</DialogTitle>
        <DialogContent>
          {inviteSuccess ? (
            <Alert severity="success" sx={{ mt: 2 }}>
              Invitation sent successfully!
            </Alert>
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 2, mt: 1 }}>
                Send an invitation to join the platform to:
              </Typography>
              {ownerToInvite && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    {`${ownerToInvite.first_name} ${ownerToInvite.last_name}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {ownerToInvite.email}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${ownerToInvite.title || 'Owner'} (${ownerToInvite.ownership_percentage}% ownership)`}
                  </Typography>
                </Box>
              )}
              {inviteError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {inviteError}
                </Alert>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {inviteSuccess ? (
            <Button onClick={closeInviteDialog}>Close</Button>
          ) : (
            <>
              <Button onClick={closeInviteDialog} disabled={isSendingInvite}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendInvitation}
                disabled={isSendingInvite}
                startIcon={isSendingInvite && <CircularProgress size={20} color="inherit" />}
              >
                {isSendingInvite ? 'Sending...' : 'Send Invitation'}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {!profile ? (
        <Box sx={{ textAlign: 'center', py: 4, bgcolor: '#f5f5f5', borderRadius: 1, mb: 3 }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No Financing Profile
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            This client has not submitted a financing profile yet.
          </Typography>
        </Box>
      ) : isSaving ? (
        // Show skeleton loading while saving
        <>
          <SectionSkeleton />
        </>
      ) : (
        <Card sx={{ mb: 3 }}>
          <CardHeader 
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="div">
                  Financing Profile
                </Typography>
                {status.status === 'complete' && (
                  <Chip 
                    icon={<CheckCircleIcon />}
                    label="Complete" 
                    color="success" 
                    size="small" 
                    sx={{ ml: 1 }}
                  />
                )}
                {status.status === 'incomplete' && (
                  <Chip 
                    icon={<PendingIcon />}
                    label="Incomplete" 
                    color="warning" 
                    size="small" 
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
            }
            action={
              isEditMode ? (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={handleCancelEdit}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                    onClick={handleSaveProfile}
                    disabled={isSaving}
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </Button>
                </Box>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={handleEditClick}
                  disabled={isUpdating}
                >
                  Edit
                </Button>
              )
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={4}>
              {/* Personal Information Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Personal Information</Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">First Name</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.first_name || ''}
                        onChange={(e) => handleInputChange('personal_info', 'first_name', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.first_name || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Last Name</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.last_name || ''}
                        onChange={(e) => handleInputChange('personal_info', 'last_name', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.last_name || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">SSN</Typography>
                    {isEditMode ? (
                      <Box sx={{ position: 'relative' }}>
                        <TextField
                          fullWidth
                          size="small"
                          type={showSSN ? "text" : "password"}
                          value={editedProfile?.personal_info?.ssn || ''}
                          onChange={(e) => handleInputChange('personal_info', 'ssn', e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                size="small"
                                onClick={() => setShowSSN(!showSSN)}
                                edge="end"
                                aria-label="toggle SSN visibility"
                                sx={{ mr: -0.5 }}
                              >
                                {showSSN ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                              </IconButton>
                            )
                          }}
                        />
                        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                          {showSSN ? "Showing full SSN" : "Masked for security"}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.personal_info?.ssn ? '••••••' + displayProfile.personal_info.ssn.slice(-4) : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Date of Birth</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        value={editedProfile?.personal_info?.date_of_birth?.split('T')[0] || ''}
                        onChange={(e) => handleInputChange('personal_info', 'date_of_birth', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.personal_info?.date_of_birth ? format(new Date(displayProfile.personal_info.date_of_birth), 'MMMM d, yyyy') : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Phone</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.phone || ''}
                        onChange={(e) => handleInputChange('personal_info', 'phone', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.phone || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Title</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.title || ''}
                        onChange={(e) => handleInputChange('personal_info', 'title', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.title || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Ownership Percentage</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        value={editedProfile?.personal_info?.ownership_percentage || ''}
                        onChange={(e) => handleInputChange('personal_info', 'ownership_percentage', Number(e.target.value))}
                        InputProps={{
                          endAdornment: '%',
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.personal_info?.ownership_percentage ? `${displayProfile.personal_info.ownership_percentage}%` : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                
                {/* Personal Address */}
                <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>Home Address</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle2">Street</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.home_address?.street || ''}
                        onChange={(e) => handleInputChange('personal_address', 'street', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.home_address?.street || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">City</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.home_address?.city || ''}
                        onChange={(e) => handleInputChange('personal_address', 'city', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.home_address?.city || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">State</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.home_address?.state || ''}
                        onChange={(e) => handleInputChange('personal_address', 'state', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.home_address?.state || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">ZIP Code</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.personal_info?.home_address?.zipCode || ''}
                        onChange={(e) => handleInputChange('personal_address', 'zipCode', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.personal_info?.home_address?.zipCode || 'Not provided'}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Business Information Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Business Information</Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Business Name</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.business_name || ''}
                        onChange={(e) => handleInputChange('root', 'business_name', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.business_name || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Entity Structure</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.entity_structure || ''}
                        onChange={(e) => handleInputChange('root', 'entity_structure', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.entity_structure || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Federal Tax ID</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedProfile?.federal_tax_id || ''}
                        onChange={(e) => handleInputChange('root', 'federal_tax_id', e.target.value)}
                      />
                    ) : (
                      <Typography variant="body1">{displayProfile?.federal_tax_id || 'Not provided'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Business Start Date</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        value={editedProfile?.business_start_date?.split('T')[0] || ''}
                        onChange={(e) => handleInputChange('root', 'business_start_date', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.business_start_date ? format(new Date(displayProfile.business_start_date), 'MMMM d, yyyy') : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Additional Owners Section - Moved above Financing Profile */}
              {displayProfile?.additionalOwners && displayProfile.additionalOwners.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>Additional Owners</Typography>
                  <Divider sx={{ mb: 2 }} />
                  
                  {/* Summary of registered vs unregistered owners */}
                  {!isLoadingUsers && (
                    <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                      {(() => {
                        const totalOwners = displayProfile.additionalOwners.length;
                        const registeredOwners = displayProfile.additionalOwners.filter(
                          (owner: any) => checkEmailExists(owner.email)
                        ).length;
                        const unregisteredOwners = totalOwners - registeredOwners;
                        
                        return (
                          <>
                            <Typography variant="body2">
                              Total Owners: <strong>{totalOwners}</strong>
                            </Typography>
                            <Chip 
                              label={`${registeredOwners} Registered`} 
                              color="success" 
                              size="small" 
                              icon={<CheckCircleIcon />} 
                            />
                            {unregisteredOwners > 0 && (
                              <Chip 
                                label={`${unregisteredOwners} Need Invitation`} 
                                color="warning" 
                                size="small" 
                                icon={<PendingIcon />} 
                              />
                            )}
                          </>
                        );
                      })()}
                    </Box>
                  )}
                  
                  <TableContainer component={Paper} sx={{ mb: 3 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Ownership %</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {displayProfile.additionalOwners.map((owner: any, index: number) => {
                          const emailExists = checkEmailExists(owner.email);
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                {isEditMode ? (
                                  <Box sx={{ display: 'flex', gap: 1 }}>
                                    <TextField
                                      size="small"
                                      value={owner.first_name || ''}
                                      onChange={(e) => handleInputChange(`additionalOwner_${index}`, 'first_name', e.target.value)}
                                      sx={{ width: '100px' }}
                                      placeholder="First"
                                    />
                                    <TextField
                                      size="small"
                                      value={owner.last_name || ''}
                                      onChange={(e) => handleInputChange(`additionalOwner_${index}`, 'last_name', e.target.value)}
                                      sx={{ width: '100px' }}
                                      placeholder="Last"
                                    />
                                  </Box>
                                ) : (
                                  `${owner.first_name} ${owner.last_name}`
                                )}
                              </TableCell>
                              <TableCell>
                                {isEditMode ? (
                                  <TextField
                                    size="small"
                                    value={owner.email || ''}
                                    onChange={(e) => handleInputChange(`additionalOwner_${index}`, 'email', e.target.value)}
                                    disabled={emailExists}
                                    fullWidth
                                    helperText={emailExists ? "Email cannot be changed for registered users" : ""}
                                  />
                                ) : (
                                  owner.email
                                )}
                              </TableCell>
                              <TableCell>
                                {isEditMode ? (
                                  <TextField
                                    size="small"
                                    value={owner.phone || ''}
                                    onChange={(e) => handleInputChange(`additionalOwner_${index}`, 'phone', e.target.value)}
                                    fullWidth
                                  />
                                ) : (
                                  owner.phone
                                )}
                              </TableCell>
                              <TableCell>
                                {isEditMode ? (
                                  <TextField
                                    size="small"
                                    value={owner.title || ''}
                                    onChange={(e) => handleInputChange(`additionalOwner_${index}`, 'title', e.target.value)}
                                    fullWidth
                                  />
                                ) : (
                                  owner.title || 'Not provided'
                                )}
                              </TableCell>
                              <TableCell>
                                {isEditMode ? (
                                  <TextField
                                    size="small"
                                    type="number"
                                    value={owner.ownership_percentage || 0}
                                    onChange={(e) => handleInputChange(`additionalOwner_${index}`, 'ownership_percentage', e.target.value)}
                                    InputProps={{
                                      endAdornment: '%',
                                    }}
                                    sx={{ width: '80px' }}
                                  />
                                ) : (
                                  `${owner.ownership_percentage}%`
                                )}
                              </TableCell>
                              <TableCell>
                                {emailExists ? (
                                  <Chip 
                                    label="Registered" 
                                    color="success" 
                                    size="small" 
                                    icon={<CheckCircleIcon />} 
                                  />
                                ) : (
                                  <Chip 
                                    label="Not Registered" 
                                    color="warning" 
                                    size="small" 
                                    icon={<PendingIcon />} 
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                {!emailExists && !isEditMode && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<PersonAddIcon />}
                                    onClick={() => openInviteDialog(owner)}
                                  >
                                    Invite
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              {/* Financial Information Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Financial Information</Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Last Year Revenue</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        value={editedProfile?.last_year_revenue || ''}
                        onChange={(e) => handleInputChange('root', 'last_year_revenue', Number(e.target.value))}
                        InputProps={{
                          startAdornment: <span>$</span>,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.last_year_revenue ? formatCurrency(displayProfile.last_year_revenue) : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Last Year Expenses</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        value={editedProfile?.last_year_expenses || ''}
                        onChange={(e) => handleInputChange('root', 'last_year_expenses', Number(e.target.value))}
                        InputProps={{
                          startAdornment: <span>$</span>,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.last_year_expenses ? formatCurrency(displayProfile.last_year_expenses) : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Current Year Projected Revenue</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        value={editedProfile?.current_year_projected_revenue || ''}
                        onChange={(e) => handleInputChange('root', 'current_year_projected_revenue', Number(e.target.value))}
                        InputProps={{
                          startAdornment: <span>$</span>,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.current_year_projected_revenue ? formatCurrency(displayProfile.current_year_projected_revenue) : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Current Year Projected Expenses</Typography>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        value={editedProfile?.current_year_projected_expenses || ''}
                        onChange={(e) => handleInputChange('root', 'current_year_projected_expenses', Number(e.target.value))}
                        InputProps={{
                          startAdornment: <span>$</span>,
                        }}
                      />
                    ) : (
                      <Typography variant="body1">
                        {displayProfile?.current_year_projected_expenses ? formatCurrency(displayProfile.current_year_projected_expenses) : 'Not provided'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Assets Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Assets</Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  {isEditMode ? (
                    // Edit mode - show text fields for each asset
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Checking & Savings</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.assets?.checking_savings || ''}
                          onChange={(e) => handleInputChange('assets', 'checking_savings', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Investments</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.assets?.investments || ''}
                          onChange={(e) => handleInputChange('assets', 'investments', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Vehicles</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.assets?.vehicles || ''}
                          onChange={(e) => handleInputChange('assets', 'vehicles', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Real Estate</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.assets?.real_estate || ''}
                          onChange={(e) => handleInputChange('assets', 'real_estate', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Company Equity</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.assets?.company_equity || ''}
                          onChange={(e) => handleInputChange('assets', 'company_equity', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Other Assets</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.assets?.other_assets || ''}
                          onChange={(e) => handleInputChange('assets', 'other_assets', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    // View mode - show formatted values
                    displayProfile?.assets ? (
                      Object.entries(displayProfile.assets).map(([key, value]) => (
                        <Grid item xs={12} md={6} key={key}>
                          <Typography variant="subtitle1">
                            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </Typography>
                          <Typography variant="body1">{formatCurrency(value as number)}</Typography>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body1">No assets information provided</Typography>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>

              {/* Liabilities Section */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Liabilities</Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3}>
                  {isEditMode ? (
                    // Edit mode - show text fields for each liability
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Credit Cards</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.liabilities?.credit_cards || ''}
                          onChange={(e) => handleInputChange('liabilities', 'credit_cards', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Line of Credit</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.liabilities?.line_of_credit || ''}
                          onChange={(e) => handleInputChange('liabilities', 'line_of_credit', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Vehicle Loans</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.liabilities?.vehicle_loans || ''}
                          onChange={(e) => handleInputChange('liabilities', 'vehicle_loans', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Mortgages</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.liabilities?.mortgages || ''}
                          onChange={(e) => handleInputChange('liabilities', 'mortgages', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Notes Payable</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.liabilities?.notes_payable || ''}
                          onChange={(e) => handleInputChange('liabilities', 'notes_payable', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Other Liabilities</Typography>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          value={editedProfile?.liabilities?.other_liabilities || ''}
                          onChange={(e) => handleInputChange('liabilities', 'other_liabilities', Number(e.target.value))}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    // View mode - show formatted values
                    displayProfile?.liabilities ? (
                      Object.entries(displayProfile.liabilities).map(([key, value]) => (
                        <Grid item xs={12} md={6} key={key}>
                          <Typography variant="subtitle1">
                            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </Typography>
                          <Typography variant="body1">{formatCurrency(value as number)}</Typography>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body1">No liabilities information provided</Typography>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}; 