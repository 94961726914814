import { Box, Grid, Typography, Alert } from '@mui/material'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useState, useEffect } from 'react'
import BusinessSubmissionCard from './components/Cards/BusinessSubmissionCard'
import BusinessInquiryCard from './components/Cards/BusinessInquiryCard'
import { useBusinessSubmissions } from 'hooks/admin/useBusinessSubmissions'
import { useBusinessInquiries } from 'hooks/admin/useBusinessInquiries'
import { BusinessSubmission, BusinessInquiry } from '@otw/models'

const BusinessSubmissions: React.FC = () => {
  const [filteredSubmissions, setFilteredSubmissions] = useState<BusinessSubmission[]>([])
  const isMobile = useIsMobile()
  const { businessSubmissions, businessSubmissionsIsLoading, toggleShowOnWebsite } = useBusinessSubmissions()
  const { businessInquiries, businessInquiriesError, businessInquiriesIsLoading } = useBusinessInquiries()

  useEffect(() => {
    if (businessSubmissions) {
      setFilteredSubmissions(businessSubmissions)
    }
  }, [businessSubmissions])

  const handleToggleShow = (id: string, show: boolean) => {
    toggleShowOnWebsite.mutate({ id, show })
  }

  return (
    <Box>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='flex-start'
        alignItems='center'
      >
        <Typography variant='h4'>Business Submissions</Typography>
      </Box>

      {businessInquiriesError && (
        <Alert severity="error">
          Error fetching business inquiries: {businessInquiriesError.message}
        </Alert>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Grid container spacing={2}>
          {!businessSubmissionsIsLoading &&
            filteredSubmissions.map(submission => (
              <Grid key={submission.id} item xs={12} sm={12} md={6} lg={12}>
                <Box display='flex' flexDirection='column'>
                  <BusinessSubmissionCard submission={submission} onToggleShow={handleToggleShow} type="submission" />
                </Box>
              </Grid>
            ))}
          {!businessInquiriesIsLoading &&
            businessInquiries?.map((inquiry: BusinessInquiry) => (
              <Grid key={inquiry.id} item xs={12} sm={12} md={6} lg={12}>
                <Box display='flex' flexDirection='column'>
                  <BusinessInquiryCard inquiry={inquiry} />
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default BusinessSubmissions
