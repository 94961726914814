// web/app/src/views/SuperAdmin/Management/LeadManagement/components/LeadList.tsx
import React from 'react';
import { Box, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import { Droppable } from '@hello-pangea/dnd';
import { Lead, LeadStatus } from '@otw/models/leads/lead';
import LeadCard from './LeadCard';
import { Delete } from '@mui/icons-material';
//import { LeadCard } from './LeadCard';

export interface LeadListProps {
  title: string;
  leads: Lead[];
  status: LeadStatus;
  color: string;
  onLeadSelect: (lead: Lead) => void;
  onViewDetails: (lead: Lead) => void;
  onViewSuggestedTrucks: (lead: Lead) => void;
  onDeleteColumn?: (status: string) => void;
  isDefaultColumn?: boolean;
  columnName?: string;
}

export const LeadList: React.FC<LeadListProps> = ({
  title,
  leads,
  status,
  color,
  onLeadSelect,
  onViewDetails,
  onViewSuggestedTrucks,
  onDeleteColumn,
  isDefaultColumn = false,
  columnName
}) => {
  // Function to create a lighter version of the color for the background
  const getLighterColor = (color: string, opacity: number = 0.15): string => {
    // For hex colors
    if (color.startsWith('#')) {
      return `${color}${Math.round(opacity * 255).toString(16).padStart(2, '0')}`;
    }
    // For rgb colors
    if (color.startsWith('rgb')) {
      return color.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
    }
    // Default fallback
    return `rgba(0, 0, 0, ${opacity})`;
  };

  const backgroundColor = getLighterColor(color);
  
  return (
    <Box sx={{ height: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          height: '100%',
          minHeight: '600px',
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: backgroundColor,
          transition: 'background-color 0.3s ease'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            pb: 1,
            borderBottom: '2px solid',
            borderColor: color
          }}
        >
          <Typography variant="h6" component="h2" fontWeight="500">
            {title}
          </Typography>
          
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
              {leads.length} {leads.length === 1 ? 'lead' : 'leads'}
            </Typography>
            
            {!isDefaultColumn && onDeleteColumn && (
              <Tooltip title="Delete Column">
                <IconButton
                  size="small"
                  onClick={() => onDeleteColumn(status)}
                  color="error"
                  sx={{ 
                    ml: 0.5,
                    '&:hover': { 
                      backgroundColor: 'rgba(244, 67, 54, 0.08)' 
                    } 
                  }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Droppable droppableId={status}>
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                flexGrow: 1,
                minHeight: '1000px',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                ...(leads.length === 0 ? {
                  border: '2px dashed',
                  borderColor: 'rgba(0,0,0,0.1)',
                  borderRadius: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&::after': {
                    content: '"Drop leads here"',
                    color: 'text.disabled',
                    fontSize: '0.875rem',
                    fontStyle: 'italic'
                  }
                } : {})
              }}
            >
              {leads.map((lead, index) => (
                <LeadCard
                  key={lead.id}
                  lead={lead}
                  index={index}
                  status={status}
                  onLeadSelect={onLeadSelect}
                  onViewDetails={onViewDetails}
                  onViewSuggestedTrucks={onViewSuggestedTrucks}
                  columnName={columnName || title}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Paper>
    </Box>
  );
};