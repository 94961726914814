import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface FieldResetConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  parentField: string;
  parentValue: string;
  dependentField: string;
  dependentValue: string;
}

/**
 * A reusable dialog component for confirming field resets when changing parent fields
 * that have dependent fields (e.g., make/model, type/size).
 */
const FieldResetConfirmationDialog: React.FC<FieldResetConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  parentField,
  parentValue,
  dependentField,
  dependentValue,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <WarningIcon color="warning" />
        <Typography variant="h6">Confirm Field Change</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Changing the <strong>{parentField}</strong> from <strong>{dependentValue ? 'current value' : ''}</strong> to <strong>{parentValue}</strong> will reset the <strong>{dependentField}</strong> field
          {dependentValue ? ` (currently set to "${dependentValue}")` : ''}.
        </DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>
          Are you sure you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="warning" variant="contained" autoFocus>
          Reset {dependentField}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldResetConfirmationDialog; 