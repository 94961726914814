import { useMutation, useQuery } from '@tanstack/react-query'
import { portalFunctions } from 'core/functions'
import { PrivateClientFinancingProfile } from '@otw/models'
import { useAuth } from 'hooks/auth/useAuth'

export const useClientPrivateFinancingProfile = () => {
  const { currentUser } = useAuth()

  // Create financing profile mutation
  const createFinancingProfile = useMutation({
    mutationFn: async (
      profile: Omit<PrivateClientFinancingProfile, 'id' | 'date_created' | 'date_modified' | 'user_id'>,
    ) => {
      if (!currentUser?.uid) {
        throw new Error('User not authenticated')
      }

      const now = new Date().toISOString()
      const completeProfile: Omit<PrivateClientFinancingProfile, 'id'> = {
        date_created: now,
        date_modified: now,
        user_id: currentUser.uid,
        ...profile,
      }

      const response = await portalFunctions.client.createClientPrivateFinancingProfile(completeProfile)
      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message || 'Failed to create financing profile')
      }
      return response.json()
    },
  })

  // Get financing profile query
  const {
    data: financingProfile,
    isLoading: isLoadingProfile,
    error: profileError,
    refetch: refetchProfile,
  } = useQuery({
    queryKey: ['clientFinancingProfile', currentUser?.uid],
    queryFn: async (): Promise<PrivateClientFinancingProfile | null> => {
      if (!currentUser?.uid) {
        throw new Error('User not authenticated')
      }

      const response = await portalFunctions.client.getClientPrivateFinancingProfile()
      if (!response.ok) {
        if (response.status === 404) {
          return null
        }
        throw new Error(response.statusText || 'Failed to fetch financing profile')
      }
      const data = (await response.json()).data
      console.log(data)
      return data as PrivateClientFinancingProfile
    },
    enabled: !!currentUser?.uid,
  })

  return {
    // Create profile
    createFinancingProfile: createFinancingProfile.mutate,
    isCreating: createFinancingProfile.isPending,
    createError: createFinancingProfile.error,

    // Get profile
    financingProfile,
    isLoadingProfile,
    profileError,
    refetchProfile,
  }
}
