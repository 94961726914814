import React, { useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate, useLocation } from 'react-router-dom'
import { Navigation, NavigationPageItem } from 'types/navigation'
import { mapPagesToDrawer } from 'router/mapPagesToDrawer'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'

const DRAWER_WIDTH = 300

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: 64, // Match AppBar height
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    ...(open && {
      ...openedMixin(theme),
      position: 'fixed',
      height: '100%',
    }),
    ...(!open && {
      ...closedMixin(theme),
      position: 'fixed',
      height: '100%',
    }),
  },
}))

interface CoreDrawerProps {
  open: boolean
  onDrawerClose: () => void
  navigationItems: Navigation
  setOpen: (open: boolean) => void
}

export const CoreDrawer: React.FC<CoreDrawerProps> = ({ open, onDrawerClose, navigationItems, setOpen }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // const { updateBreadcrumb } = useBreadcrumb();
  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: string]: boolean
  }>({})

  // Helper function to find navigation item by path
  const findNavItem = (items: Navigation, path: string): NavigationPageItem | null => {
    for (const item of items) {
      if ('segment' in item && item.segment === path) {
        return item as NavigationPageItem
      }
      if ('children' in item && item.children) {
        const found = findNavItem(item.children, path)
        if (found) return found
      }
    }
    return null
  }

  const handleNavigation = (path?: string) => {
    if (!path) {
      throw new Error('Something weird happened.. path is undefined')
    }

    // Find the navigation item for this path
    const navItem = findNavItem(navigationItems, path)
    if (navItem) {
      // Update breadcrumb with the navigation item's title
      // updateBreadcrumb([{ label: navItem.title, path: `/${path}` }])
    }

    navigate(`/${path}`)
    
    // Close drawer automatically on mobile after navigation
    if (isMobile && open) {
      setOpen(false)
    }
  }

  const handleDropdownToggle = (segment: string) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [segment]: !prev[segment],
    }))
  }

  return (
    <Drawer
      variant='permanent'
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          [theme.breakpoints.down('sm')]: {
            width: open ? DRAWER_WIDTH : 0,
            visibility: open ? 'visible' : 'hidden',
          },
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={onDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon sx={{ color: theme.palette.text.primary }} />
          ) : (
            <ChevronLeftIcon sx={{ color: theme.palette.text.primary }} />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        {mapPagesToDrawer(navigationItems, {
          theme,
          open,
          setOpen,
          location,
          openDropdowns,
          handleNavigation,
          handleDropdownToggle,
        })}
      </List>
    </Drawer>
  )
}
