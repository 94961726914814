import React, { useState, ReactNode, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  useTheme, 
  Menu, 
  MenuItem, 
  Button, 
  Chip,
  Divider,
  IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import { alpha } from '@mui/material/styles';

interface MobileViewSelectorProps {
  children: ReactNode[];
  titles: string[];
  icons?: React.ReactNode[];
  initialView?: number;
}

export const MobileViewSelector: React.FC<MobileViewSelectorProps> = ({ 
  children, 
  titles,
  icons = [],
  initialView = 0
}) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(initialView);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  
  // Update content height when active index changes
  useEffect(() => {
    if (contentRef.current) {
      // Add a small delay to ensure content is rendered
      const timer = setTimeout(() => {
        if (contentRef.current) {
          setContentHeight(contentRef.current.scrollHeight);
        }
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [activeIndex, children]);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleViewChange = (index: number) => {
    setActiveIndex(index);
    handleClose();
    // Reset content height when view changes
    setContentHeight(null);
  };
  
  return (
    <Box sx={{ 
      position: 'relative', 
      width: '100%',
      minHeight: contentHeight ? `${contentHeight}px` : 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* View selector header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2,
        mt: 1
      }}>
        <Button
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
          color="primary"
          size="small"
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            px: 2,
            py: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderColor: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.12),
              borderColor: theme.palette.primary.main,
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {icons[activeIndex] && (
              <Box sx={{ 
                mr: 1, 
                display: 'flex', 
                alignItems: 'center',
                color: theme.palette.primary.main
              }}>
                {icons[activeIndex]}
              </Box>
            )}
            <Typography 
              variant="body1" 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.primary.main
              }}
            >
              {titles[activeIndex]}
            </Typography>
          </Box>
        </Button>
      </Box>
      
      {/* View selection menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: { 
            width: 240,
            maxWidth: '90vw',
            borderRadius: 2,
            mt: 1
          }
        }}
      >
        <Typography 
          variant="subtitle2" 
          sx={{ 
            px: 2, 
            py: 1, 
            color: theme.palette.text.secondary,
            fontWeight: 500
          }}
        >
          Select View
        </Typography>
        <Divider />
        {titles.map((title, index) => (
          <MenuItem 
            key={index} 
            onClick={() => handleViewChange(index)}
            selected={index === activeIndex}
            sx={{ 
              py: 1.5,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: index === activeIndex ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.12),
              }
            }}
          >
            {icons[index] && (
              <Box sx={{ 
                mr: 2, 
                color: index === activeIndex ? theme.palette.primary.main : theme.palette.text.primary,
                display: 'flex',
                alignItems: 'center'
              }}>
                {icons[index]}
              </Box>
            )}
            <Typography 
              variant="body2"
              sx={{
                color: index === activeIndex ? theme.palette.primary.main : theme.palette.text.primary,
                fontWeight: index === activeIndex ? 500 : 400
              }}
            >
              {title}
            </Typography>
            {index === activeIndex && (
              <Chip 
                label="Current" 
                size="small" 
                color="primary" 
                sx={{ 
                  ml: 'auto', 
                  height: 24,
                  fontWeight: 500,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText
                }}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
      
      {/* Current content */}
      <Box 
        ref={contentRef}
        sx={{ 
          flex: 1,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {children[activeIndex]}
      </Box>
    </Box>
  );
};

export default MobileViewSelector; 