import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingIcon from '@mui/icons-material/Pending';
import {
  Box,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { Application } from '@otw/models/application/application';
import dayjs from 'dayjs';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import React, { useMemo } from 'react';

interface ApplicationStatsProps {
  applications: Application[];
  dateRange: {
    start: Date;
    end: Date;
  };
}

export const ApplicationStats: React.FC<ApplicationStatsProps> = ({
  applications = [],
  dateRange
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  // Get all applications
  const allApplications = useMemo(() => {
    return applications;
  }, [applications]);

  // Get total applications count
  const totalApplications = allApplications.length;

  // Get active applications (not approved or denied)
  const activeApplications = useMemo(() => {
    return allApplications.filter(app => app.status !== 'approved' && app.status !== 'denied');
  }, [allApplications]);

  // Get applications that need attention
  const needsAttentionApplications = useMemo(() => {
    return allApplications.filter(app => app.status === 'corrections_needed' || app.status === 'pending');
  }, [allApplications]);

  // Get new applications within the date range
  const newApplicationsInRange = useMemo(() => {
    return allApplications.filter(app => {
      const creationDate = new Date(app.time_created);
      return creationDate >= dateRange.start && creationDate <= dateRange.end;
    });
  }, [allApplications, dateRange]);

  // Calculate applications by day for the selected period
  const applicationsByDay = useMemo(() => {
    const dayMap: Record<string, number> = {};
    const days = Math.ceil((dateRange.end.getTime() - dateRange.start.getTime()) / (1000 * 60 * 60 * 24));

    // Initialize all days with 0
    for (let i = 0; i < days; i++) {
      const day = dayjs(dateRange.start).add(i, 'day').format('YYYY-MM-DD');
      dayMap[day] = 0;
    }

    // Count applications by day
    newApplicationsInRange.forEach(app => {
      const day = dayjs(app.time_created).format('YYYY-MM-DD');
      if (dayMap[day] !== undefined) {
        dayMap[day]++;
      }
    });

    return dayMap;
  }, [newApplicationsInRange, dateRange]);

  // Get the day with most applications
  const maxApplicationsPerDay = useMemo(() => {
    return Math.max(...Object.values(applicationsByDay), 1);
  }, [applicationsByDay]);

  // Calculate applications by status
  const applicationsByStatus = useMemo(() => {
    const statusMap: Record<string, number> = {
      ready: 0,
      corrections_needed: 0,
      pending: 0,
      viewed: 0,
      approved: 0,
      denied: 0,
      signing: 0,
      draft: 0,
    };

    allApplications.forEach(app => {
      if (statusMap[app.status] !== undefined) {
        statusMap[app.status]++;
      }
    });

    return statusMap;
  }, [allApplications]);

  return (
    <Paper elevation={3} sx={{ 
      p: { xs: 1.5, sm: 2, md: 3 }, 
      height: '100%',
      overflow: 'hidden'
    }}>
      <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
        Application Statistics
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={isMobile ? 1 : 2}>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<AssignmentIcon />} 
              label={`Total: ${totalApplications}`} 
              color='primary' 
              sx={{ 
                width: '100%', 
                justifyContent: 'flex-start',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '& .MuiChip-icon': {
                  color: theme.palette.primary.contrastText
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<AssignmentTurnedInIcon />} 
              label={`Active: ${activeApplications.length}`} 
              color='success' 
              sx={{ 
                width: '100%', 
                justifyContent: 'flex-start',
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
                '& .MuiChip-icon': {
                  color: theme.palette.success.contrastText
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<AssignmentLateIcon />} 
              label={`Needs Attention: ${needsAttentionApplications.length}`} 
              color='warning' 
              sx={{ 
                width: '100%', 
                justifyContent: 'flex-start',
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
                '& .MuiChip-icon': {
                  color: theme.palette.warning.contrastText
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Chip 
              icon={<PendingIcon />} 
              label={`New: ${newApplicationsInRange.length}`} 
              color='info' 
              sx={{ 
                width: '100%', 
                justifyContent: 'flex-start',
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
                '& .MuiChip-icon': {
                  color: theme.palette.info.contrastText
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Applications by Day Chart */}
      <Box sx={{ mb: 3, mt: 3 }}>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Applications by Day
        </Typography>
        
        {Object.keys(applicationsByDay).length > 0 ? (
          <Box sx={{ height: 200, width: '100%' }}>
            <BarChart
              series={[
                {
                  data: Object.values(applicationsByDay),
                  label: 'Applications',
                  color: theme.palette.primary.main,
                },
              ]}
              xAxis={[
                {
                  data: Object.keys(applicationsByDay).map(day => 
                    dayjs(day).format('MMM D')
                  ),
                  scaleType: 'band',
                },
              ]}
              height={200}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
          </Box>
        ) : (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              No application data available for the selected period
            </Typography>
          </Box>
        )}
      </Box>

      {/* Applications by Status */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Applications by Status
        </Typography>
        
        <Grid container spacing={1}>
          {Object.entries(applicationsByStatus).map(([status, count]) => (
            count > 0 && (
              <Grid item xs={6} key={status}>
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography variant="caption" color="text.secondary">
                      {status.replace('_', ' ')}
                    </Typography>
                    <Typography variant="caption" fontWeight="bold">
                      {count}
                    </Typography>
                  </Box>
                  <LinearProgress 
                    variant="determinate" 
                    value={(count / totalApplications) * 100} 
                    sx={{ 
                      height: 8, 
                      borderRadius: 1,
                      backgroundColor: `${theme.palette.grey[200]}`,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: getStatusColor(status)
                      }
                    }}
                  />
                </Box>
              </Grid>
            )
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

// Status color mapping
const getStatusColor = (status: string) => {
  switch (status) {
    case 'ready':
      return '#2196F3' // Blue
    case 'corrections_needed':
      return '#F44336' // Red
    case 'pending':
      return '#FFA000' // Amber
    case 'viewed':
      return '#9C27B0' // Purple
    case 'approved':
      return '#4CAF50' // Green
    case 'denied':
      return '#D32F2F' // Dark Red
    case 'signing':
      return '#FF9800' // Orange
    case 'draft':
      return '#757575' // Grey
    default:
      return '#757575' // Grey
  }
}

export default ApplicationStats; 