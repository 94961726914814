import AssessmentIcon from '@mui/icons-material/Assessment'
import InventoryIcon from '@mui/icons-material/Inventory'
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import dayjs, { Dayjs } from 'dayjs'
import { useInventory } from 'hooks/inventory/useInventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import {
  DateRangeSelector,
  InventoryTab
} from './components'

// TabPanel component for dashboard tabs
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      style={{ 
        width: '100%', 
        maxWidth: '100%',
        overflowX: 'hidden'
      }}
      {...other}
    >
      {value === index && (
        <Box sx={{ 
          py: { xs: 2, md: 3 },
          width: '100%',
          maxWidth: '100%'
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Main component
export const AdminDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { userInfo } = useAuth();
  const { vehicles, isLoading } = useInventory('master_inventory')
  const [dateRange, setDateRange] = useState<string>('this_month')
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(null)
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null)

  // Use localStorage instead of sessionStorage to persist tab selection
  const [storedTabValue, setStoredTabValue] = useState<number>(0)
  
  // Load stored tab value from localStorage on component mount
  useEffect(() => {
    const savedTab = localStorage.getItem('adminDashboardTab')
    if (savedTab !== null) {
      setStoredTabValue(Number(savedTab))
    }
  }, [])
  
  const tabValue = storedTabValue
  
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setStoredTabValue(newValue);
    localStorage.setItem('adminDashboardTab', newValue.toString());
  };

  // Handle swipe change for SwipeableViews
  const handleSwipeChangeIndex = (index: number) => {
    setStoredTabValue(index);
    localStorage.setItem('adminDashboardTab', index.toString());
  };

  // Handle date range change
  const handleDateRangeChange = (newRange: string) => {
    setDateRange(newRange);
  };

  // Helper function to get date range based on selection
  const getDateRange = () => {
    if (dateRange === 'this_month') {
      const startOfMonth = dayjs().startOf('month')
      return {
        start: startOfMonth.toDate(),
        end: dayjs().toDate()
      }
    } else if (dateRange === 'last_month') {
      const lastMonth = dayjs().subtract(1, 'month')
      return {
        start: lastMonth.startOf('month').toDate(),
        end: lastMonth.endOf('month').toDate()
      }
    } else if (dateRange === 'this_year') {
      return {
        start: dayjs().startOf('year').toDate(),
        end: dayjs().toDate()
      }
    } else if (dateRange === 'custom' && customStartDate && customEndDate) {
      return {
        start: customStartDate.toDate(),
        end: customEndDate.toDate()
      }
    } else {
      // Fallback
      return {
        start: dayjs().startOf('month').toDate(),
        end: dayjs().toDate()
      }
    }
  }
  
  // Helper function to get display text for date range
  const getDateRangeDisplayText = () => {
    if (dateRange === 'this_month') {
      return `This Month (${dayjs().format('MMMM YYYY')})`
    } else if (dateRange === 'last_month') {
      return `Last Month (${dayjs().subtract(1, 'month').format('MMMM YYYY')})`
    } else if (dateRange === 'this_year') {
      return `This Year (${dayjs().format('YYYY')})`
    } else if (dateRange === 'custom' && customStartDate && customEndDate) {
      return `${customStartDate.format('MMM D, YYYY')} - ${customEndDate.format('MMM D, YYYY')}`
    } else {
      return `This Month (${dayjs().format('MMMM YYYY')})`
    }
  }
  
  // Filter vehicles based on date range and admin's inventory
  const filteredInventoryVehicles = useMemo(() => {
    if (!vehicles || !userInfo) return []
    
    const { start, end } = getDateRange()
    
    // Filter vehicles by date range and admin's ID
    return vehicles.filter(vehicle => {
      // Filter by admin's ID
      if (vehicle.entered_by !== userInfo.email) return false
      
      // Filter by date range
      const vehicleDate = vehicle.date_added ? new Date(vehicle.date_added) : null
      if (!vehicleDate) return true // Include if no date
      
      return vehicleDate >= start && vehicleDate <= end
    })
  }, [vehicles, userInfo, dateRange, customStartDate, customEndDate])

  // Calculate metrics for admin's inventory
  const calculatedMetrics = useMemo(() => {
    // For inventory metrics, use filteredInventoryVehicles
    const activeInventory = filteredInventoryVehicles.filter(v => 
      v.status === 'Available' || v.status === 'Pending Available'
    )
    
    // Calculate sold vehicles (only those entered by this admin)
    const soldVehicles = filteredInventoryVehicles.filter(v => v.status === 'Sold')
    
    // Calculate vehicles sold this month
    const soldThisMonth = soldVehicles.filter(v => 
      v.sold_date && dayjs(v.sold_date).isAfter(dayjs().startOf('month'))
    ).length
    
    // Calculate revenue
    const revenueThisMonth = soldVehicles.reduce((sum, v) => sum + (v.optic_list_price || 0), 0)
    
    // Calculate profit
    const totalProfit = soldVehicles.reduce((sum, v) => sum + (v.profit || 0), 0)
    
    // Calculate average profit
    const averageProfit = soldVehicles.length > 0 ? totalProfit / soldVehicles.length : 0
    
    // Calculate average days to sell
    const avgDaysToSell = soldVehicles
      .filter(v => v.date_added && v.sold_date)
      .reduce((sum, v) => {
        const addedDate = dayjs(v.date_added)
        const soldDate = dayjs(v.sold_date)
        const days = soldDate.diff(addedDate, 'day')
        return sum + days
      }, 0) / (soldVehicles.length || 1)
    
    // Calculate top models
    const modelCounts: Record<string, number> = {}
    soldVehicles.forEach(v => {
      const model = v.model || 'Unknown'
      modelCounts[model] = (modelCounts[model] || 0) + 1
    })
    
    const topModels = Object.entries(modelCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([model, count]) => ({ model, count }))
    
    return {
      activeInventory: activeInventory.length,
      totalInventory: filteredInventoryVehicles.length,
      soldVehicles: soldVehicles.length,
      soldThisMonth,
      revenueThisMonth,
      totalProfit,
      averageProfit,
      avgDaysToSell,
      topModels
    }
  }, [filteredInventoryVehicles])
  

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box 
      sx={{ 
        p: { xs: 1, sm: 1.5, md: 3 },
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden'
      }}
    >
      <Box sx={{ 
        mb: { xs: 1, md: 3 }, 
        display: 'flex', 
        flexDirection: { xs: 'row', md: 'row' }, 
        justifyContent: 'space-between', 
        alignItems: { xs: 'center', md: 'flex-end' },
        gap: { xs: 1, md: 0 },
        width: '100%',
        flexWrap: { xs: 'wrap', md: 'nowrap' }
      }}>
        <Typography variant={isMobile ? "h5" : "h3"} sx={{ mb: { md: 0 } }}>
          Admin Dashboard
        </Typography>
        
        <Box sx={{ 
          flexShrink: 0, 
          width: { xs: 'auto', md: 'auto' },
          ml: { xs: 'auto', md: 0 }
        }}>
          <DateRangeSelector 
            startDate={customStartDate} 
            endDate={customEndDate} 
            onStartDateChange={setCustomStartDate} 
            onEndDateChange={setCustomEndDate}
            displayText={getDateRangeDisplayText()}
            onDateRangeChange={handleDateRangeChange}
            selectedDateRange={dateRange}
          />
        </Box>
      </Box>

      {/* Dashboard Tabs */}
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        width: '100%',
        maxWidth: '100%'
      }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="fullWidth"
          aria-label="dashboard tabs"
          sx={{ 
            maxWidth: '100%',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center'
            }
          }}
        >
          <Tab 
            icon={<AssessmentIcon />} 
            label={isMobile ? "Overview" : "Overview"} 
            id="dashboard-tab-0" 
            aria-controls="dashboard-tabpanel-0" 
            iconPosition="top"
            sx={{ minWidth: 0, px: isMobile ? 1 : 2 }}
          />
          <Tab 
            icon={<InventoryIcon />} 
            label={isMobile ? "Inventory" : "Inventory"} 
            id="dashboard-tab-1" 
            aria-controls="dashboard-tabpanel-1" 
            iconPosition="top"
            sx={{ minWidth: 0, px: isMobile ? 1 : 2 }}
          />
        </Tabs>
      </Box>

      {/* Swipeable Views Container */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabValue}
        onChangeIndex={handleSwipeChangeIndex}
        resistance
        enableMouseEvents={!isMobile} // Enable mouse events only on desktop for testing
        containerStyle={{
          width: '100%',
          maxWidth: '100%',
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
        }}
        style={{
          padding: 0,
          overflowX: 'hidden'
        }}
      >
        {/* Overview Tab */}
        <div style={{ width: '100%', padding: 0 }}>
          <Box sx={{ 
            py: { xs: 2, md: 3 },
            width: '100%',
            maxWidth: '100%'
          }}>
            <Typography variant="h5" gutterBottom>
              Your Inventory Overview
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                Total vehicles in your inventory: <strong>{filteredInventoryVehicles.length}</strong>
              </Typography>
              <Typography variant="body1">
                Active vehicles: <strong>{calculatedMetrics.activeInventory}</strong>
              </Typography>
            </Box>
          </Box>
        </div>

        {/* Inventory Tab */}
        <div style={{ width: '100%', padding: 0 }}>
          <Box sx={{ 
            py: { xs: 2, md: 3 },
            width: '100%',
            maxWidth: '100%'
          }}>
            <InventoryTab 
              dateRange={getDateRange()}
            />
          </Box>
        </div>
      </SwipeableViews>
    </Box>
  )
}

export default AdminDashboard 