import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
// import truckLogo from 'assets/Icon-OpticTruckWorks-02.png'
import InfoIcon from '@mui/icons-material/Info'
import ReplayIcon from '@mui/icons-material/Replay'
import { Vehicle } from '@otw/models/inventory'
import { opticTruckBlue as truckLogo } from 'assets'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { fetchVinData } from 'core/api/inventory/fetchVinData'
import {
  getVehicleModelOptions,
  getVehicleSizeOptions,
  makeOptions,
  vehicleFuelOptions,
  vehicleStatusOptions,
  vehicleTypeOptions,
} from 'core/constants/inventory'
import { useCalculateDeal } from 'hooks/graybook/useCalculateDeal'
import { useInventory } from 'hooks/inventory/useInventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import states from 'states-us'
import { z } from 'zod'
import FieldResetConfirmationDialog from '../Dialogs/FieldResetConfirmationDialog'
import SoldDialog from '../Dialogs/SoldDialog'

// Map VIN decoder make values to our allowed make values
const mapVinMakeToAllowedMake = (vinMake: string | undefined | null): string => {
  if (!vinMake) return ''

  // Normalize the VIN make value (lowercase and trim)
  const normalizedVinMake = vinMake.toLowerCase().trim()

  // Map common VIN decoder values to our allowed values
  const makeMapping: Record<string, string> = {
    ford: 'Ford',
    chevrolet: 'Chevrolet',
    chevy: 'Chevrolet',
    gmc: 'GMC',
    freightliner: 'Freightliner',
    'general motors': 'GMC',
    'gm': 'GMC',
    mercedes: 'Mercedes-Benz',
    'mercedes benz': 'Mercedes-Benz',
    'mercedes-benz': 'Mercedes-Benz',
  }

  // Return the mapped value if it exists, otherwise empty string
  return makeMapping[normalizedVinMake] || ''
}

// Validate if a year is valid
const isValidYear = (year: string | undefined | null): boolean => {
  if (!year) return false

  const parsedYear = parseInt(year, 10)
  const currentYear = new Date().getFullYear()
  const minYear = 1990 // Minimum valid year

  return !isNaN(parsedYear) && parsedYear >= minYear && parsedYear <= currentYear
}

interface VehicleFormProps {
  open: boolean
  vehicle: Vehicle | null
  onClose: () => void
  onSubmit: (data: { [key: string]: any }) => void
  inventoryCollection: 'master_inventory' | 'shield_inventory'
  generateStockNumber: () => string
  isEdit?: boolean
}

function isValidURL(url: string) {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

// FIXME: double declaration edit and isnew

const VehicleForm: React.FC<VehicleFormProps> = ({
  open,
  onClose,
  onSubmit,
  vehicle,
  inventoryCollection,
  generateStockNumber,
  isEdit = false,
}) => {
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const isNew = vehicle === null
  const isAdmin = userInfo?.role === 'admin'
  const isOwner = userInfo?.isOwner || false
  const isBabyAdmin = userInfo?.role === 'babyAdmin'
  const [soldPopupOpen, setSoldPopupOpen] = useState(false)
  const [pendingFormData, setPendingFormData] = useState<{ [key: string]: string | number | boolean } | null>(null)
  const { vehicles, pauseSnapshotListener, resumeSnapshotListener } = useInventory(inventoryCollection)
  const minYear = inventoryCollection === 'shield_inventory' ? 1990 : 2000
  const { showSnackbar } = useSnackbar()

  const [vinAlreadyExistsError, setVinAlreadyExistsError] = useState(false)
  const [previousVehicle, setPreviousVehicle] = useState<Vehicle | null>(null)
  const [isResell, setIsResell] = useState(false)
  const sizeSelectRef = useRef<HTMLDivElement>(null)

  // State for field reset confirmation dialogs
  const [resetConfirmationOpen, setResetConfirmationOpen] = useState(false)
  const [pendingFieldChange, setPendingFieldChange] = useState<{
    parentField: string
    parentValue: string
    dependentField: string
    dependentValue: string
    onConfirm: () => void
  } | null>(null)

  const formSchema = z
    .object({
      stock_number: z.string().min(1, 'Stock Number is required'),
      vin: isAdmin
        ? z
            .string()
            .length(17, 'VIN must be exactly 17 characters long')
            .refine(val => {
              // Only validate uniqueness if this is a new vehicle (not editing)
              if (isEdit) return true
              
              // Get all vehicles with the same VIN
              const existingVehicles = vehicles?.filter(v => v.vin === val) || []
              
              // Check if any of the vehicles with this VIN are active (not sold)
              return !existingVehicles.some(v => 
                v.status?.toLowerCase() !== 'sold' && 
                v.status?.toLowerCase() !== 'sold elsewhere'
              )
            }, 'Vehicle with this VIN already exists in active inventory')
        : z.string().optional(),
      make: z.string().min(1, 'Make is required'),
      model: z.string().min(1, 'Model is required'),
      type: z.string().min(1, 'Type is required') as z.ZodType<Vehicle['type']>,
      size: z.string().min(1, 'Size is required') as z.ZodType<Vehicle['size']>,
      fuel: z.string().min(1, 'Fuel is required') as z.ZodType<Vehicle['fuel']>,
      miles: z
        .number()
        .min(0, 'Mileage must be a positive number')
        .refine(val => !isNaN(val) && val >= 0, 'Mileage must be a positive number')
        .or(z.undefined()) as z.ZodType<Vehicle['miles']>,
      year: z
        .number()
        .min(minYear, 'Year is required')
        .max(new Date().getFullYear(), `Year must be between ${minYear} and current year`)
        .refine(val => !isNaN(val), 'Year must be a valid number')
        .refine(val => {
          const num = Number(val)
          return !isNaN(num) && num >= minYear && num <= new Date().getFullYear()
        }, `Year must be between ${minYear} and current year`)
        .or(z.undefined()) as z.ZodType<Vehicle['year']>,
      location: z
        .string()
        .min(1, 'Location is required')
        .refine(val => {
          // Skip validation if empty (the min(1) above will catch this)
          if (!val) return true
          // Check if it's a valid US state abbreviation
          return states.some(state => state.abbreviation === val)
        }, 'Please select a valid US state') as z.ZodType<Vehicle['location']>,
      condition: z.string().min(1, 'Condition is required') as z.ZodType<Vehicle['condition']>,
      status: z.string().min(1, 'Status is required') as z.ZodType<Vehicle['status']>,
      who: z.string().optional() as z.ZodType<Vehicle['who']>,
      phone_number: z
        .string()
        .optional()
        .refine(val => {
          if (!val) return true // Allow empty values
          // Remove all non-digit characters to count actual digits
          const digitsOnly = val.replace(/\D/g, '')
          // Ensure exactly 10 digits (standard US number)
          return digitsOnly.length === 10
        }, 'Phone number must be exactly 10 digits') as z.ZodType<Vehicle['phone_number']>,
      link: z
        .string()
        .optional()
        .refine(val => !val || isValidURL(val), 'URL is not valid') as z.ZodType<Vehicle['link']>,
      optic_list_price: z
        .number()
        .optional()
        .refine(
          val => !val || (!isNaN(Number(val)) && Number(val) >= 0),
          'Optic List Price must be a positive number',
        ) as z.ZodType<Vehicle['optic_list_price']>,
      seller_asking_price: isAdmin
        ? z
            .number()
            .refine(
              val => !val || (!isNaN(Number(val)) && Number(val) >= 0),
              'Seller Asking Price must be a positive number',
            )
        : (z.number().optional() as z.ZodType<Vehicle['seller_asking_price']>),
      sold_date: z.string().nullable().optional(),
      truck_notes: z.string().optional() as z.ZodType<Vehicle['truck_notes']>,
      dealQuality: z.string().optional() as z.ZodType<Vehicle['dealQuality']>,
      cameras: z.enum(['yes', 'no', 'unknown']).default('unknown'),
      shelves: z.enum(['yes', 'no', 'unknown']).default('unknown'),
      size_confirmed: z.boolean().default(false),
    })
    .refine(
      data => {
        // Skip validation if model or make is empty
        if (!data.model || !data.make) return true

        const validModels = getVehicleModelOptions(data.make)
        return validModels.includes(data.model) || data.model === 'Unknown'
      },
      {
        message: 'Model is not valid for the selected make',
        path: ['model'],
      },
    )
    .refine(
      data => {
        // Skip validation if size or type is empty
        if (!data.size || !data.type) return true

        const validSizes = getVehicleSizeOptions(data.type)
        return validSizes.includes(data.size) || data.size === 'Unknown'
      },
      {
        message: 'Size is not valid for the selected type',
        path: ['size'],
      },
    )

  // Define the form type
  type VehicleFormValues = z.infer<typeof formSchema>

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange', // Validate on change to provide immediate feedback
  })

  // Watch make and type to reset dependent fields when they change
  const watchedMake = watch('make')
  const watchedType = watch('type')

  // Handle make change
  const handleMakeChange = (e: any) => {
    const newMake = e.target.value
    const currentModel = watch('model')

    // Check if model needs to be reset
    if (currentModel) {
      const validModels = getVehicleModelOptions(newMake)
      if (!validModels.includes(currentModel) && currentModel !== 'Unknown') {
        // Show confirmation dialog
        setPendingFieldChange({
          parentField: 'Make',
          parentValue: newMake,
          dependentField: 'Model',
          dependentValue: currentModel,
          onConfirm: () => {
            // Apply the changes after confirmation
            setValue('make', newMake)
            setValue('model', '')
            trigger(['make', 'model'])
          },
        })
        setResetConfirmationOpen(true)
        return
      }
    }

    // If no reset needed, just set the value
    setValue('make', newMake)
    trigger(['make'])
  }

  // Handle type change
  const handleTypeChange = (e: any) => {
    const newType = e.target.value
    const currentSize = watch('size')

    // Check if size needs to be reset
    if (currentSize) {
      const validSizes = getVehicleSizeOptions(newType)
      if (!validSizes.includes(currentSize) && currentSize !== 'Unknown') {
        // Show confirmation dialog
        setPendingFieldChange({
          parentField: 'Type',
          parentValue: newType,
          dependentField: 'Size',
          dependentValue: currentSize,
          onConfirm: () => {
            // Apply the changes after confirmation
            setValue('type', newType)
            setValue('size', '')
            trigger(['type', 'size'])
          },
        })
        setResetConfirmationOpen(true)
        return
      }
    }

    // If no reset needed, just set the value
    setValue('type', newType)
    trigger(['type'])
  }

  // Handle confirmation dialog close
  const handleConfirmationClose = () => {
    setResetConfirmationOpen(false)
    setPendingFieldChange(null)
  }

  // Handle confirmation dialog confirm
  const handleConfirmationConfirm = () => {
    if (pendingFieldChange) {
      pendingFieldChange.onConfirm()
    }
    setResetConfirmationOpen(false)
    setPendingFieldChange(null)
  }

  // Add a function to format phone number input
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = e.target.value.replace(/\D/g, '') // Remove non-digits
    let formatted = ''

    if (input.length <= 3) {
      formatted = input
    } else if (input.length <= 6) {
      formatted = `(${input.slice(0, 3)}) ${input.slice(3)}`
    } else {
      formatted = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`
    }

    // Update the field value
    setValue('phone_number', formatted, { shouldValidate: true })
  }

  // Set initial values when component mounts or when vehicle/isNew changes
  useEffect(() => {
    if (vehicle) {
      reset({
        stock_number: vehicle.stock_number || '',
        vin: vehicle.vin || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        type: vehicle.type || '',
        size: vehicle.size || '',
        fuel: vehicle.fuel || '',
        miles: vehicle.miles || 0,
        year: vehicle.year || 0,
        location: vehicle.location || '',
        condition: vehicle.condition || '',
        status: vehicle.status || '',
        who: vehicle.who || '',
        phone_number: vehicle.phone_number || '',
        link: vehicle.link || '',
        optic_list_price: vehicle.optic_list_price || 0,
        seller_asking_price: vehicle.seller_asking_price || 0,
        sold_date: vehicle.sold_date || null,
        truck_notes: vehicle.truck_notes || '',
        dealQuality: vehicle.dealQuality || '',
        cameras: vehicle.cameras || 'unknown',
        shelves: vehicle.shelves || 'unknown',
        size_confirmed: Boolean(vehicle.size_confirmed),
      })
    } else if (isNew && userInfo) {
      reset({
        stock_number: generateStockNumber(),
        vin: '',
        make: '',
        model: '',
        type: '',
        size: '',
        fuel: '',
        miles: undefined,
        year: undefined,
        location: '',
        condition: '',
        status: '',
        who: '',
        phone_number: '',
        link: '',
        optic_list_price: undefined,
        seller_asking_price: undefined,
        sold_date: null,
        truck_notes: '',
        dealQuality: '',
        cameras: 'unknown',
        shelves: 'unknown',
        size_confirmed: false,
      })
    }
  }, [vehicle, isNew, userInfo, generateStockNumber, reset])

  const { grayBookRetailPrice, grayBookWholeSalePrice, dealQuality, dealQualityInfo, matchedFormula } =
    useCalculateDeal({
      make: watch('make'),
      model: watch('model'),
      type: watch('type'),
      size: watch('size'),
      miles: watch('miles'),
      year: watch('year'),
      sellerAskingPrice: watch('seller_asking_price'),
      fuel: watch('fuel'),
    })

  const vin = watch('vin') || ''

  useEffect(() => {
    setVinAlreadyExistsError(false)
    setPreviousVehicle(null)
    setIsResell(false)
    
    if (vin.length === 17 && !isEdit) {
      // Find if there are any vehicles with the same VIN
      const existingVehicles = vehicles?.filter(v => v.vin === vin) || []
      
      if (existingVehicles.length > 0) {
        // Check if any of the vehicles with this VIN are not sold (active inventory)
        const activeVehicles = existingVehicles.filter(v => 
          v.status?.toLowerCase() !== 'sold' && 
          v.status?.toLowerCase() !== 'sold elsewhere'
        )
        
        if (activeVehicles.length > 0) {
          // There are active vehicles with this VIN - show error
          setVinAlreadyExistsError(true)
        } else {
          // All vehicles with this VIN are sold - this is a resell
          // Sort by sold date to get the most recent one
          const sortedVehicles = [...existingVehicles].sort((a, b) => {
            if (!a.sold_date && !b.sold_date) return 0
            if (!a.sold_date) return 1
            if (!b.sold_date) return -1
            return new Date(b.sold_date).getTime() - new Date(a.sold_date).getTime()
          })
          
          const latestVehicle = sortedVehicles[0]
          setIsResell(true)
          setPreviousVehicle(latestVehicle)
        }
      } else {
        // No existing vehicle with this VIN, fetch VIN data as usual
        fetchVinData(vin)
          .then(vinData => {
            const vinMake = vinData.find(item => item.Variable === 'Make')?.Value
            const vinYear = vinData.find(item => item.Variable === 'Model Year')?.Value

            // Map the VIN make to our allowed make values
            const mappedMake = mapVinMakeToAllowedMake(vinMake)

            // Only set the make if it's in our allowed values
            if (mappedMake) {
              setValue('make', mappedMake)
            }

            // Only set the year if it's valid
            if (isValidYear(vinYear)) {
              setValue('year', parseInt(vinYear || '0'))
            }
          })
          .catch(error => {
            console.error('Error fetching VIN data:', error)
          })
      }
    }
  }, [vin, isEdit, setValue, vehicles])

  // Function to populate the form with data from the previous vehicle
  const populateFromPreviousVehicle = () => {
    if (!previousVehicle) return
    
    // Update all relevant fields from the previous vehicle
    setValue('make', previousVehicle.make || '')
    setValue('model', previousVehicle.model || '')
    setValue('type', previousVehicle.type || '')
    setValue('size', previousVehicle.size || '')
    setValue('fuel', previousVehicle.fuel || '')
    setValue('year', previousVehicle.year || undefined)
    setValue('cameras', previousVehicle.cameras || 'unknown')
    setValue('shelves', previousVehicle.shelves || 'unknown')
    setValue('size_confirmed', Boolean(previousVehicle.size_confirmed))
    
    // Only set location if not already set
    if (!watch('location') && previousVehicle.location) {
      setValue('location', previousVehicle.location)
    }
    
    // Update miles only if the vehicle is in "Used" condition
    setValue('condition', 'Used')
    
    // Populate the optic list price from the previous vehicle
    if (previousVehicle.optic_list_price) {
      setValue('optic_list_price', previousVehicle.optic_list_price)
    }
    
    // Add note about resell
    const existingNotes = watch('truck_notes') || ''
    const resellNote = `[RESELL] This vehicle was previously sold (Stock #${previousVehicle.stock_number}).`
    setValue('truck_notes', existingNotes ? `${existingNotes}\n${resellNote}` : resellNote)
    
    trigger() // Trigger validation
  }

  const onSubmitForm = (data: any) => {
    // Validate cameras and shelves fields
    if (!data.cameras || data.cameras === '') {
      setValue('cameras', 'unknown')
      showSnackbar('Cameras field is required. Setting to "unknown".', 'warning')
    }

    if (!data.shelves || data.shelves === '') {
      setValue('shelves', 'unknown')
      showSnackbar('Shelves field is required. Setting to "unknown".', 'warning')
    }

    const wasNotSold = (vehicle?.status || '').slice(0, 4).toLowerCase() !== 'sold'
    const isSoldNow = data.status?.toString().toLowerCase() === 'sold'

    // If marking as sold and no sold date is provided, set it to current date
    if (isSoldNow && !data.sold_date && (!vehicle || !vehicle.sold_date)) {
      data.sold_date = new Date().toISOString()
    }

    // If marking as sold, ensure show_on_website is false
    if (isSoldNow) {
      data.show_on_website = false
    }

    const finalData = {
      ...data,
      ...(wasNotSold && {
        dealQuality: dealQuality || undefined,
        grayBookWholeSalePrice: grayBookWholeSalePrice || undefined,
        grayBookRetailPrice: grayBookRetailPrice || undefined,
      }),
    }

    const needsSoldDialog =
      finalData.status?.toString().toLowerCase() === 'sold' ||
      finalData.status?.toString().toLowerCase() === 'active deal'

    if (needsSoldDialog && !vehicle?.sold_to_user) {
      setPendingFormData(finalData)
      setSoldPopupOpen(true)
      return
    }
    onSubmit(finalData)
    resumeSnapshotListener()
  }

  const handleSoldSubmit = async (
    soldToUser: { id: string; name: string },
    soldByUser: { id: string; name: string },
  ) => {
    if (!pendingFormData) return

    const isNewSold = pendingFormData.status?.toString().toLowerCase() === 'sold' && !pendingFormData.sold_date

    const finalData = {
      ...pendingFormData,
      sold_to_user: soldToUser,
      sold_by_user: soldByUser,
      ...(isNewSold
        ? {
            sold_date: new Date().toISOString(),
            show_on_website: false,
          }
        : {}),
    }
    onSubmit(finalData)
    setSoldPopupOpen(false)
    setPendingFormData(null)
  }

  const handleClose = () => {
    // Don't close the form if the reset confirmation dialog is open
    if (resetConfirmationOpen) {
      return
    }
    reset()
    onClose()
  }

  if (!vehicle && isNew && !userInfo) {
    return null
  }

  // When opening the form
  useEffect(() => {
    pauseSnapshotListener()
  }, [])

  // Check if the vehicle is already sold
  const isVehicleSold = vehicle?.status?.toLowerCase() === 'sold'

  // Determine if the user can edit the sold date
  const canEditSoldDate = isAdmin || isOwner || !isVehicleSold

  return (
    <>
      <Dialog fullScreen={isMobile} open={open} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle
          p={0}
          justifyContent='center'
          alignContent='center'
          display='flex'
          sx={{ position: 'relative' }}
        >
          <Typography variant='h1'> {isNew ? 'Add' : 'Edit'} Truck</Typography>
          <Box component='img' src={truckLogo} height={48} width={48} alt='' />
          <Box sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
            <Tooltip
              title="Real-time updates are paused. Changes by others won't be visible and may cause conflicts."
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'warning.light',
                    color: 'warning.contrastText',
                    '& .MuiTooltip-arrow': {
                      color: 'warning.light',
                    },
                  },
                },
              }}
            >
              <InfoIcon sx={{ color: 'warning.main', cursor: 'help' }} />
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', height: '100%', paddingBottom: 0 }}>
          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit(onSubmitForm)}
            height={{ xs: '100%', md: 'auto' }}
            width={{ xs: '100%', md: 'auto' }}
            p={0}
            overflow='hidden'
          >
            <Box flex={1} height={`calc(100% - ${isMobile ? '96px' : '64px'})`} sx={{ overflowY: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h4' fontWeight='bold'>
                    Basic Information
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('stock_number')}
                    label='Stock Number'
                    InputProps={{
                      readOnly: true,
                    }}
                    error={!!errors.stock_number}
                    helperText={errors.stock_number?.message?.toString() || ''}
                    defaultValue=''
                  />
                </Grid>
                {isAdmin && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      {...register('vin')}
                      label='VIN'
                      error={!!errors.vin || vinAlreadyExistsError}
                      helperText={vinAlreadyExistsError ? 'Vehicle already exists in active inventory' : `${vin.length}/17`}
                      defaultValue=''
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {vinAlreadyExistsError && (
                              <Tooltip title='This VIN already exists in active inventory' arrow>
                                <InfoIcon color='warning' sx={{ fontSize: 20 }} />
                              </Tooltip>
                            )}
                            {isResell && (
                              <Tooltip title='This is a resell. The vehicle was previously sold. Click to populate vehicle information.' arrow>
                                <Button
                                  onClick={populateFromPreviousVehicle}
                                  sx={{ minWidth: 'auto', p: 0.5 }}
                                  color="primary"
                                  startIcon={<ReplayIcon />}
                                >
                                  Resell
                                </Button>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}

                {isResell && (
                  <Grid item xs={12}>
                    <Box sx={{ 
                      backgroundColor: 'info.light', 
                      color: 'info.contrastText', 
                      borderRadius: 1, 
                      px: 2, 
                      py: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <InfoIcon fontSize="small" />
                      <Typography variant="body2">
                        This is a resell of a previously sold vehicle (Stock #{previousVehicle?.stock_number}). 
                        Click the "Resell" button to auto-fill details from the previous listing.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                
                <Grid item xs={12} mt={2}>
                  <Typography variant='h4' fontWeight='bold'>
                    Truck Information
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.make}>
                    <InputLabel id='make-label'>Make</InputLabel>
                    <Controller
                      name='make'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='make-label' label='Make' required onChange={handleMakeChange}>
                          <MenuItem value=''> </MenuItem>
                          {makeOptions.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                          <MenuItem value='Unknown'>Unknown</MenuItem>
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.make?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.model}>
                    <InputLabel id='model-label'>Model</InputLabel>
                    <Controller
                      name='model'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='model-label' label='Model' required>
                          <MenuItem value=''> </MenuItem>
                          {getVehicleModelOptions(watch('make')).map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                          <MenuItem value='Unknown'>Unknown</MenuItem>
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.model?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.type}>
                    <InputLabel id='type-label'>Type</InputLabel>
                    <Controller
                      name='type'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='type-label' label='Type' required onChange={handleTypeChange}>
                          <MenuItem value=''> </MenuItem>
                          {vehicleTypeOptions.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                          <MenuItem value='Unknown'>Unknown</MenuItem>
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.type?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.size}>
                    <InputLabel id='size-label'>Size</InputLabel>
                    <Controller
                      name='size'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='size-label' label='Size' required ref={sizeSelectRef}>
                          <MenuItem value=''> </MenuItem>
                          {getVehicleSizeOptions(watch('type')).map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.size?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.fuel}>
                    <InputLabel id='fuel-label'>Fuel</InputLabel>
                    <Controller
                      name='fuel'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='fuel-label' label='Fuel' required>
                          <MenuItem value=''> </MenuItem>
                          {vehicleFuelOptions.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.fuel?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('miles', {
                      setValueAs: value => (value === '' ? undefined : parseInt(value, 10)),
                      valueAsNumber: true,
                    })}
                    label='Mileage'
                    error={!!errors.miles}
                    helperText={errors.miles?.message?.toString() || ''}
                    required
                    type='number'
                    defaultValue={''}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('year', {
                      setValueAs: value => (value === '' ? undefined : parseInt(value, 10)),
                      valueAsNumber: true,
                    })}
                    label='Year'
                    error={!!errors.year}
                    helperText={errors.year?.message?.toString() || ''}
                    required
                    type='number'
                    defaultValue={''}
                    inputProps={{ min: minYear, max: new Date().getFullYear() }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.location}>
                    <Controller
                      name='location'
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={states}
                          getOptionLabel={option => {
                            // Handle both string values and state objects
                            if (typeof option === 'string') {
                              // Find the state object by abbreviation or name
                              const stateObj = states.find(
                                state => state.abbreviation === option || state.name === option,
                              )
                              return stateObj ? `${stateObj.name} (${stateObj.abbreviation})` : option
                            }
                            return `${option.name} (${option.abbreviation})`
                          }}
                          onChange={(_, newValue) => {
                            // Set the value to the abbreviation if an object is selected, otherwise use the string value
                            field.onChange(newValue ? newValue.abbreviation : '')
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Location (State)'
                              error={!!errors.location}
                              helperText={errors.location?.message?.toString() || ''}
                              required
                            />
                          )}
                          isOptionEqualToValue={(option, value) => {
                            if (typeof value === 'string') {
                              return option.abbreviation === value || option.name === value
                            }
                            return option.abbreviation === value.abbreviation
                          }}
                          value={
                            field.value
                              ? states.find(state => state.abbreviation === field.value) || field.value
                              : null
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.condition}>
                    <InputLabel id='condition-label'>Condition</InputLabel>
                    <Controller
                      name='condition'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='condition-label' label='Condition' required>
                          <MenuItem value=''> </MenuItem>
                          {['Used', 'New'].map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.condition?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.status}>
                    <InputLabel id='status-label'>Status</InputLabel>
                    <Controller
                      name='status'
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId='status-label' label='Status' required>
                          <MenuItem value=''> </MenuItem>
                          {vehicleStatusOptions.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>{errors.status?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Typography variant='h5' fontWeight='bold'>
                    Equipment Details
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.cameras}>
                    <Typography variant='subtitle2' gutterBottom>
                      Has Cameras <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Controller
                      name='cameras'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                          <FormControlLabel value='no' control={<Radio />} label='No' />
                          <FormControlLabel value='unknown' control={<Radio />} label='Unknown' />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText>{errors.cameras?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.shelves}>
                    <Typography variant='subtitle2' gutterBottom>
                      Has Shelves <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Controller
                      name='shelves'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                          <FormControlLabel value='no' control={<Radio />} label='No' />
                          <FormControlLabel value='unknown' control={<Radio />} label='Unknown' />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText>{errors.shelves?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.size_confirmed}>
                    <FormControlLabel
                      control={
                        <Controller
                          name='size_confirmed'
                          control={control}
                          render={({ field }) => (
                            <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                          )}
                        />
                      }
                      label='Length/size is confirmed'
                    />
                    <FormHelperText>{errors.size_confirmed?.message?.toString() || ''}</FormHelperText>
                  </FormControl>
                </Grid>

                {isAdmin && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant='h4' fontWeight='bold'>
                      Contact Information
                    </Typography>
                  </Grid>
                )}
                {!isBabyAdmin && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        {...register('who')}
                        label='Who'
                        error={!!errors.who}
                        helperText={errors.who?.message?.toString() || ''}
                        defaultValue=''
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        {...register('phone_number')}
                        label='Phone Number'
                        placeholder='(123) 456-7890'
                        inputProps={{
                          maxLength: 14, // Allow for formatting characters
                        }}
                        onChange={e => handlePhoneNumberChange(e)}
                        error={!!errors.phone_number}
                        helperText={errors.phone_number?.message?.toString() || 'Format: (123) 456-7890'}
                        defaultValue=''
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    {...register('link')}
                    label='Web Link'
                    error={!!errors.link}
                    helperText={errors.link?.message?.toString() || ''}
                    defaultValue=''
                    InputProps={{
                      endAdornment: watch('link') ? (
                        <InputAdornment position='end'>
                          <Button onClick={() => window.open(watch('link'), '_blank')}>View</Button>
                        </InputAdornment>
                      ) : undefined,
                    }}
                  />
                </Grid>

                {isAdmin && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant='h4' fontWeight='bold'>
                      Finance Information
                    </Typography>
                  </Grid>
                )}
                {!isBabyAdmin && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        {...register('optic_list_price', {
                          setValueAs: value => (value === '' ? null : parseFloat(value)),
                          valueAsNumber: true,
                        })}
                        label='Optic List Price'
                        error={!!errors.optic_list_price}
                        helperText={errors.optic_list_price?.message?.toString() || ''}
                        type='number'
                        defaultValue={null}
                        inputProps={{ min: 0, step: '0.01' }}
                        InputProps={{
                          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        {...register('seller_asking_price', {
                          setValueAs: value => (value === '' ? null : parseFloat(value)),
                          valueAsNumber: true,
                        })}
                        label='Seller Asking Price'
                        error={!!errors.seller_asking_price}
                        helperText={errors.seller_asking_price?.message?.toString() || ''}
                        required
                        type='number'
                        defaultValue={null}
                        inputProps={{ min: 0, step: '0.01' }}
                        InputProps={{
                          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    {matchedFormula && grayBookWholeSalePrice && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography variant='body1' sx={{ color: 'text.primary' }}>
                            Gray Book Price:{' '}
                            <span style={{ fontWeight: 500 }}>${grayBookWholeSalePrice.toLocaleString()}</span>
                          </Typography>
                          {userInfo?.isOwner && grayBookRetailPrice && (
                            <Typography variant='body1' sx={{ color: 'black' }}>
                              (Retail:{' '}
                              <span style={{ fontWeight: 500 }}>${grayBookRetailPrice.toLocaleString()}</span>)
                            </Typography>
                          )}
                        </Box>

                        {dealQualityInfo && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                            <Typography variant='body1'>Deal Quality:</Typography>
                            <Chip label={dealQualityInfo.label} color={dealQualityInfo.color} size='small' />
                          </Box>
                        )}
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name='sold_date'
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Sold Date'
                          value={value ? dayjs(value) : null}
                          onChange={newValue => {
                            onChange(newValue ? dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null)
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.sold_date,
                              helperText: errors.sold_date?.message?.toString() || '',
                              disabled: !canEditSoldDate,
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {isVehicleSold && !canEditSoldDate && (
                    <FormHelperText error>Only the owner can modify the sold date of a sold vehicle</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...register('truck_notes')}
                    label='Notes'
                    multiline
                    rows={4}
                    error={!!errors.truck_notes}
                    helperText={errors.truck_notes?.message?.toString() || ''}
                    defaultValue=''
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              display='flex'
              flexDirection={isMobile ? 'column-reverse' : 'row'}
              justifyContent='space-between'
              gap={1}
              m={isMobile ? 0 : 2}
              p={isMobile ? 1 : 2}
              alignItems='stretch'
              sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                width: '100%',
                height: 'fit-content',
              }}
            >
              <Button type='button' color='secondary' fullWidth onClick={handleClose}>
                Cancel
              </Button>
              <Button type='submit' variant='contained' fullWidth>
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>

        {/* Sold Dialog */}
        <SoldDialog
          open={soldPopupOpen}
          onClose={() => {
            setSoldPopupOpen(false)
            setPendingFormData(null)
          }}
          onSubmit={handleSoldSubmit}
          itemName={vehicle?.stock_number}
        />
      </Dialog>

      {/* Field Reset Confirmation Dialog - moved outside main Dialog */}
      {pendingFieldChange && (
        <FieldResetConfirmationDialog
          open={resetConfirmationOpen}
          onClose={handleConfirmationClose}
          onConfirm={handleConfirmationConfirm}
          parentField={pendingFieldChange.parentField}
          parentValue={pendingFieldChange.parentValue}
          dependentField={pendingFieldChange.dependentField}
          dependentValue={pendingFieldChange.dependentValue}
        />
      )}
    </>
  )
}

export default VehicleForm
