import { Box, Button, Typography, TextField, MenuItem, Chip, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, InputAdornment } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "hooks/auth/useAuth";
import { useClientFinancingProfiles } from "hooks/admin/useClientFinancingProfiles";
import { ClientFinancingProfileResponse } from "core/functions/portalFunctions";
import { formatCurrency } from "utils/formatters";

// For more readable display in the autocomplete
type ClientOption = {
  id: string;
  label: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
};

export const AdminFinancingHub = () => {
  const [filterStatus, setFilterStatus] = useState<"all" | "hasProfile" | "noProfile">("all");
  const { clientFinancingProfiles, isLoading } = useClientFinancingProfiles();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  // Format client data for the autocomplete
  const clientOptions: ClientOption[] = React.useMemo(() => {
    if (!clientFinancingProfiles) return [];
    return clientFinancingProfiles.map(client => ({
      id: client.id,
      label: `${client.first_name} ${client.last_name} - ${client.company_name || client.dealership_name || ""}`,
      firstName: client.first_name,
      lastName: client.last_name,
      company: client.company_name || client.dealership_name || "",
      email: client.email
    }));
  }, [clientFinancingProfiles]);

  const handleClientClick = (clientId: string) => {
    navigate(`/admin/financing-profiles/${clientId}`);
  };

  const filteredClients = clientFinancingProfiles?.filter((client: ClientFinancingProfileResponse) => {
    if (filterStatus === "all") return true;
    if (filterStatus === "hasProfile") return client.financingProfile !== null;
    if (filterStatus === "noProfile") return client.financingProfile === null;
    return true;
  });

  const getFinancingStatus = (client: ClientFinancingProfileResponse) => {
    if (!client.financingProfile) {
      return {
        status: "missing",
        icon: <ErrorIcon color="error" />,
        text: "No Financing Profile",
      };
    }

    // Check if the profile has all required fields
    const profile = client.financingProfile;
    const hasBusinessInfo = profile.business_name && profile.entity_structure;
    const hasFinancialInfo = profile.last_year_revenue !== undefined && profile.last_year_expenses !== undefined;
    const hasPersonalInfo = profile.personal_info?.first_name && profile.personal_info?.last_name;

    if (!hasBusinessInfo || !hasFinancialInfo || !hasPersonalInfo) {
      return {
        status: "incomplete",
        icon: <PendingIcon color="warning" />,
        text: "Incomplete Profile",
      };
    }

    return {
      status: "complete",
      icon: <CheckCircleIcon color="success" />,
      text: "Complete Profile",
    };
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Typography variant="h5" component="h1" sx={{ mr: 2 }}>
          Client Financing Profiles
        </Typography>
      </Box>

      {/* Controls Section */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 3 }}>
        <Autocomplete
          id="client-search"
          options={clientOptions}
          sx={{ width: 350 }}
          inputValue={searchText}
          onInputChange={(_, newValue) => {
            setSearchText(newValue);
          }}
          onChange={(_, option) => {
            if (option) {
              handleClientClick(option.id);
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PersonIcon sx={{ mr: 1, fontSize: 20, color: "primary.main" }} />
                  <Typography variant="body2" fontWeight="medium">
                    {option.firstName} {option.lastName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 3.5 }}>
                  {option.company && (
                    <Typography variant="caption" color="text.secondary">
                      {option.company}
                    </Typography>
                  )}
                  <Typography variant="caption" color="text.secondary">
                    {option.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search clients"
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              placeholder="Search by name, company, or email"
            />
          )}
          loading={isLoading}
          loadingText="Loading clients..."
          noOptionsText="No clients found"
        />

        <TextField
          select
          value={filterStatus}
          onChange={(e) =>
            setFilterStatus(e.target.value as "all" | "hasProfile" | "noProfile")
          }
          label="Filter by status"
          size="small"
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="all">All Clients</MenuItem>
          <MenuItem value="hasProfile">Has Profile</MenuItem>
          <MenuItem value="noProfile">No Profile</MenuItem>
        </TextField>

        <Box sx={{ display: "flex", gap: 2, ml: { xs: 0, md: "auto" } }}>
          <Button variant="outlined" color="primary">
            Export Profiles
          </Button>
          <Button variant="contained" color="primary">
            Send Profile Reminders
          </Button>
        </Box>
      </Box>

      {/* Loading State */}
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Table View */}
      {!isLoading && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Business Info</TableCell>
                <TableCell>Financial Info</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients?.map((client: ClientFinancingProfileResponse) => {
                const status = getFinancingStatus(client);
                const profile = client.financingProfile;
                
                return (
                  <TableRow 
                    key={client.id}
                    hover
                    onClick={() => handleClientClick(client.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon sx={{ mr: 1, color: "primary.main" }} />
                        <Box>
                          <Typography variant="body1">
                            {client.first_name} {client.last_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {client.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <BusinessIcon sx={{ mr: 1, color: "primary.main" }} />
                        <Typography variant="body2">
                          {client.company_name || client.dealership_name || "N/A"}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={status.icon}
                        label={status.text}
                        color={
                          status.status === "complete"
                            ? "success"
                            : status.status === "incomplete"
                            ? "warning"
                            : "error"
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {profile ? (
                        <Box>
                          <Typography variant="body2">
                            {profile.business_name || "Not provided"}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {profile.entity_structure || "Not specified"}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No data
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {profile ? (
                        <Box>
                          <Typography variant="body2">
                            Revenue: {formatCurrency(profile.last_year_revenue) || "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            Net Income: {formatCurrency(profile.last_year_net_income) || "N/A"}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No data
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {profile ? (
                        <Typography variant="body2">
                          {new Date(profile.date_modified).toLocaleDateString()}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          N/A
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClientClick(client.id);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* No Results */}
      {!isLoading && filteredClients?.length === 0 && (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="h6">No clients match the selected filter</Typography>
        </Box>
      )}
    </Box>
  );
}; 