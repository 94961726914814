import { useMutation, useQueryClient } from '@tanstack/react-query'
import { portalFunctions } from 'core/functions/portalFunctions'
import { PrivateClientFinancingProfile } from '@otw/models'
import { useAuth } from 'hooks/auth/useAuth'

export const useUpdateClientFinancingProfile = () => {
  const { userInfo } = useAuth()
  const queryClient = useQueryClient()

  const updateFinancingProfile = useMutation({
    mutationFn: async ({
      clientId,
      financingProfile,
    }: {
      clientId: string
      financingProfile: Partial<PrivateClientFinancingProfile>
    }) => {
      if (!userInfo || !['admin', 'babyAdmin'].includes(userInfo.role)) {
        throw new Error('Unauthorized: User does not have admin permissions')
      }

      return await portalFunctions.admin.updateClientFinancingProfile(clientId, financingProfile)
    },
    onSuccess: () => {
      // Invalidate the client financing profiles query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['admin', 'clientFinancingProfiles'] })
    },
  })

  return {
    updateFinancingProfile: updateFinancingProfile.mutate,
    isUpdating: updateFinancingProfile.isPending,
    updateError: updateFinancingProfile.error,
    isUpdateSuccess: updateFinancingProfile.isSuccess,
  }
} 