import React, { useEffect, useState } from 'react'
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, Collapse, Switch } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { BusinessSubmission } from '@otw/models'
import { useTheme } from '@mui/material/styles'
import { calculateDeal } from 'hooks/graybook/calculateDeal'
import { useFormulas } from 'hooks/graybook/useFormulas'
import { useFormulaRules } from 'hooks/graybook/useFormulaRules'
import CompletedVehicleCard from './CompletedVehicleCard'

//bugs
interface BusinessSubmissionCardProps {
  submission: BusinessSubmission
  onToggleShow: (id: string, show: boolean) => void
  type: 'submission' | 'inquiry'
}

const BusinessSubmissionCard: React.FC<BusinessSubmissionCardProps> = ({ submission, onToggleShow, type }) => {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  const { formulas } = useFormulas({ formulasCollection: 'gray_book_formulas' })
  const { rules } = useFormulaRules({
    rulesCollection: 'gray_book_formulas', // No specific subcollection needed for this use case
  })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggleShow(submission.id, event.target.checked)
  }

  // Formatter for currency
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" color={theme.palette.primary.main}>
          Business Submission
        </Typography>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h5" component="div">
            {submission.firstName} {submission.lastName}
          </Typography>
          <Grid item>
            <Grid container alignItems="center">
              <Typography variant="body2" sx={{ mr: 1 }}>
                Show on Website
              </Typography>
              <Switch
                checked={submission.show}
                onChange={handleToggleChange}
                name="showOnWebsite"
                color="primary"
              />
              <IconButton onClick={handleExpandClick}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="body2">
              Email: {submission.email}
            </Typography>
            <Typography variant="body2">
              Location: {submission.city}, {submission.state}
            </Typography>
            <Typography variant="body2">
              Phone: {submission.phoneNumber}
            </Typography>
            <Typography variant="body2">
              Weekly Mileage: {submission.weeklyMileage}
            </Typography>
            <Typography variant="body2">
              Years Owned: {submission.yearsOwned}
            </Typography>
            {submission.selectedDays?.length > 0 && (
              <Typography variant="body2">
                Operating Days: {submission.selectedDays.join(', ')}
              </Typography>
            )}
            <Typography variant="body2">
              Routes: {submission.routes}
            </Typography>
          </CardContent>
        </Card>

        <Collapse in={expanded}>
          {/* Financial Information Section */}
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            Financial Information:
          </Typography>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="body2">
                Annual Revenue: {currencyFormatter.format(submission.annualRevenue)}
              </Typography>
              <Typography variant="body2">
                Operating Expenses: {currencyFormatter.format(submission.operatingExpenses)}
              </Typography>
              <Typography variant="body2">
                Depreciation: {currencyFormatter.format(submission.depreciation)}
              </Typography>
              <Typography variant="body2">
                Amortization: {currencyFormatter.format(submission.amortization)}
              </Typography>
              <Typography variant="body2">
                Taxes: {currencyFormatter.format(submission.taxes)}
              </Typography>
              <Typography variant="body2">
                EBITDA: {currencyFormatter.format(submission.ebitda)}
              </Typography>
              <Typography variant="body2">
                Valuation Multiplier: {submission.valuationMultiplier}
              </Typography>
              <Typography variant="body2">
                Business Valuation: {currencyFormatter.format(submission.businessValuation)}
              </Typography>
              <Typography variant="body2">
                Seller Financing: {submission.sellerFinancing ? 'Yes' : 'No'}
              </Typography>
              {submission.sellerFinancing && (
                <Typography variant="body2">
                  Seller Financing Amount: {currencyFormatter.format(submission.sellerFinancingAmount)}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Fleet Information Section */}
          <Grid container alignItems="center" sx={{ mb: 2, mt: 2 }}>
            <Typography variant="h5" component="div">
              Fleet Information:
            </Typography>
            <Typography variant="h5" sx={{ ml: 1, color: 'red' }}>
              {submission.withFleet ? 'Included' : 'Not Included'}
            </Typography>
          </Grid>
          {submission.completedVehicles ? (
            <Grid container spacing={3}>
              {submission.completedVehicles.map((vehicle, index) => (
                <CompletedVehicleCard key={index} vehicle={vehicle} />
              ))}
            </Grid>
          ) : (
            submission.simpleFleetDetails && (
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="body2">
                    Number of Trucks: {submission.simpleFleetDetails.numberOfTrucks}
                  </Typography>
                  <Typography variant="body2">
                    Estimated Fleet Value: {submission.simpleFleetDetails.estimatedFleetValue}
                  </Typography>
                </CardContent>
              </Card>
            )
          )}

          {/* Staff Information Section */}
          <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
            Staff Information:
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Daily Rate</TableCell>
                  <TableCell>Weekly Rate</TableCell>
                  <TableCell>Annual Rate</TableCell>
                  <TableCell>Hiring Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submission.staff.map((staffMember, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2">
                        {staffMember.type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {currencyFormatter.format(staffMember.dailyRate)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {currencyFormatter.format(staffMember.weeklyRate)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {currencyFormatter.format(staffMember.totalAnnualPay)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {staffMember.hireDate}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Territories Section */}
          <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
            Territories:
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>Zip Codes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submission.territories.map((territory, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2">
                        {territory.state}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {territory.zipCodes.join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </CardContent>
    </Card>
  )
}

export default BusinessSubmissionCard