import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Chip,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { Application, ApplicationStatus } from '@otw/models/application/application';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ApplicationListProps {
  applications: Application[];
  initialView?: 'recent' | 'top';
}

// Status display names
const statusDisplayNames: Record<ApplicationStatus, string> = {
  signing: 'Signing',
  ready: 'Ready',
  draft: 'Draft',
  pending: 'Pending',
  viewed: 'Viewed',
  corrections_needed: 'Needs Corrections',
  approved: 'Approved',
  denied: 'Denied',
};

export const ApplicationList: React.FC<ApplicationListProps> = ({
  applications = [],
  initialView = 'recent'
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [topApplicantsSearchTerm, setTopApplicantsSearchTerm] = useState('');
  const [mobileView, setMobileView] = useState<'recent' | 'top'>(initialView);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const theme = useTheme();

  // Get all applications
  const allApplications = useMemo(() => {
    return applications;
  }, [applications]);

  // Group applications by user
  const applicationsByUser = useMemo(() => {
    const userMap: Record<string, { count: number; applications: Application[] }> = {};

    allApplications.forEach(app => {
      const userName = app.user_name || app.user_email || 'Unknown';
      if (!userMap[userName]) {
        userMap[userName] = { count: 0, applications: [] };
      }
      userMap[userName].count++;
      userMap[userName].applications.push(app);
    });

    return userMap;
  }, [allApplications]);

  // Filter applications based on search term
  const filteredApplications = useMemo(() => {
    if (!searchTerm) {
      return allApplications.sort((a, b) => {
        return new Date(b.time_created).getTime() - new Date(a.time_created).getTime();
      });
    }

    const lowerSearchTerm = searchTerm.toLowerCase();
    return allApplications
      .filter(app => {
        const userName = app.user_name || '';
        const userEmail = app.user_email || '';
        return (
          userName.toLowerCase().includes(lowerSearchTerm) ||
          userEmail.toLowerCase().includes(lowerSearchTerm)
        );
      })
      .sort((a, b) => {
        return new Date(b.time_created).getTime() - new Date(a.time_created).getTime();
      });
  }, [allApplications, searchTerm]);

  // Filter top applicants based on search term
  const filteredTopApplicants = useMemo(() => {
    if (!topApplicantsSearchTerm) {
      return Object.entries(applicationsByUser).sort(([_, a], [__, b]) => b.count - a.count);
    }

    const lowerSearchTerm = topApplicantsSearchTerm.toLowerCase();
    return Object.entries(applicationsByUser)
      .filter(([user]) => user.toLowerCase().includes(lowerSearchTerm))
      .sort(([_, a], [__, b]) => b.count - a.count);
  }, [applicationsByUser, topApplicantsSearchTerm]);

  // Get active applications (not approved or denied)
  const activeApplications = useMemo(() => {
    return filteredApplications.filter(app => app.status !== 'approved' && app.status !== 'denied');
  }, [filteredApplications]);

  // Handle mobile view toggle
  const handleMobileViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'recent' | 'top' | null,
  ) => {
    if (newView !== null) {
      setMobileView(newView);
    }
  };

  // Custom styled chip to match ApplicationManagement.tsx
  const StatusChip = styled(Chip)<{ statuscolor: string }>(({ statuscolor, theme }) => ({
    backgroundColor: `${statuscolor}20`, // 20% opacity background
    color: statuscolor,
    borderColor: statuscolor,
    fontWeight: 500,
    '& .MuiChip-label': {
      color: statuscolor,
    },
  }));

  return (
    <Paper elevation={3} sx={{ 
      p: { xs: 1.5, sm: 2, md: 3 }, 
      height: '100%',
      overflow: 'hidden'
    }}>
      <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
        Application List
      </Typography>

      {/* Mobile View Toggle */}
      <Box sx={{ mb: 2 }}>
        <ToggleButtonGroup
          value={mobileView}
          exclusive
          onChange={handleMobileViewChange}
          aria-label="application view"
          size="small"
          sx={{
            mb: 2,
            width: '100%',
            '& .MuiToggleButton-root': {
              color: theme.palette.text.primary,
              borderColor: theme.palette.divider,
              flex: 1,
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                }
              },
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              }
            }
          }}
        >
          <ToggleButton value="recent">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AssignmentIcon sx={{ mr: 1 }} />
              Recent Applications
            </Box>
          </ToggleButton>
          <ToggleButton value="top">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PeopleIcon sx={{ mr: 1 }} />
              Top Applicants
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      
      {/* Recent Applications */}
      {mobileView === 'recent' && (
        <Box sx={{ flex: 1 }}>
          <Box sx={{ mb: 2 }}>
            <TextField
              placeholder='Search applications...'
              variant='outlined'
              size='small'
              fullWidth
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
          </Box>
          {activeApplications.length > 0 ? (
            <List dense sx={{ maxHeight: 300, overflow: 'auto' }}>
              {activeApplications.map(application => (
                <React.Fragment key={application.id}>
                  <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          width: 40,
                          height: 40,
                        }}
                      >
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={application.user_name || application.user_email || 'Unknown'}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component='span'
                            variant='body2'
                            color='text.primary'
                            sx={{ fontSize: '0.75rem' }}
                          >
                            {new Date(application.time_created).toLocaleDateString()}
                          </Typography>
                        </React.Fragment>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                    <StatusChip
                      label={statusDisplayNames[application.status]}
                      size="small"
                      variant="outlined"
                      statuscolor={getStatusColor(application.status)}
                      sx={{ fontWeight: 500 }}
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant='body2' color='text.secondary'>
                No active applications found
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {/* Top Applicants */}
      {mobileView === 'top' && (
        <Box sx={{ flex: 1 }}>
          <Box sx={{ mb: 2 }}>
            <TextField
              placeholder='Search applicants...'
              variant='outlined'
              size='small'
              fullWidth
              value={topApplicantsSearchTerm}
              onChange={e => setTopApplicantsSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
          </Box>
          {filteredTopApplicants.length > 0 ? (
            <List dense sx={{ maxHeight: 300, overflow: 'auto' }}>
              {filteredTopApplicants.map(([user, data]) => (
                <ListItem key={user}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        width: 40,
                        height: 40,
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user}
                    secondary={`${data.count} application${data.count !== 1 ? 's' : ''}`}
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant='body2' color='text.secondary'>
                No applicants found
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

// Status color mapping
const getStatusColor = (status: string) => {
  switch (status) {
    case 'ready':
      return '#2196F3' // Blue
    case 'corrections_needed':
      return '#F44336' // Red
    case 'pending':
      return '#FFA000' // Amber
    case 'viewed':
      return '#9C27B0' // Purple
    case 'approved':
      return '#4CAF50' // Green
    case 'denied':
      return '#D32F2F' // Dark Red
    case 'signing':
      return '#FF9800' // Orange
    case 'draft':
      return '#757575' // Grey
    default:
      return '#757575' // Grey
  }
}

export default ApplicationList; 