import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { BusinessInquiry } from '@otw/models';
import { useTheme } from '@mui/material/styles';
interface BusinessInquiryCardProps {
  inquiry: BusinessInquiry;
}

const BusinessInquiryCard: React.FC<BusinessInquiryCardProps> = ({ inquiry }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const theme = useTheme()

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" color={theme.palette.secondary.main}>
          Business Inquiry
        </Typography>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h5" component="div">
            {inquiry.formFirstName} {inquiry.formLastName}
          </Typography>
          <Grid item>
            <IconButton onClick={handleExpandClick}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="body2">
              Inquirer Email: {inquiry.formEmail}
            </Typography>
            <Typography variant="body2">
              Inquirer Phone: {inquiry.formPhoneNumber}
            </Typography>
            <Typography variant="body2">
              Comments: {inquiry.comments}
            </Typography>
          </CardContent>
        </Card>
        <Collapse in={expanded}>
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            Business Information
          </Typography>
          <Card>
            <CardContent>
            <Typography variant="body2">
                Business Owner Name: {inquiry.businessFirstName} {inquiry.businessLastName}
              </Typography>
              <Typography variant="body2">
                Business Owner Email: {inquiry.email}
              </Typography>
              <Typography variant="body2">
                Business Owner Phone: {inquiry.phoneNumber}
              </Typography>
              <Typography variant="body2">
                Business Location: {inquiry.city}, {inquiry.state}
              </Typography>
              <Typography variant="body2">
                Business Owner Annual Revenue: {inquiry.annualRevenue}
              </Typography>
            </CardContent>
          </Card>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default BusinessInquiryCard; 