import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { useIsMobile } from 'hooks/screen/useIsMobile'

interface MetricCardProps {
  title: string
  value: React.ReactNode
  icon: React.ReactNode
  trend?: string
  color?: string
}

export const MetricCard: React.FC<MetricCardProps> = ({ 
  title, 
  value, 
  icon, 
  trend,
  color = 'primary'
}) => {
  const isMobile = useIsMobile();
  
  return (
    <Paper 
      elevation={2} 
      sx={{ 
        p: { xs: 1.5, sm: 2, md: 3 }, 
        height: '100%',
        borderRadius: 2
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start', 
        mb: isMobile ? 1 : 2 
      }}>
        <Typography 
          color="text.secondary" 
          variant={isMobile ? "body2" : "subtitle2"} 
          gutterBottom
        >
          {title}
        </Typography>
        <Box sx={{ color: `${color}.main` }}>{icon}</Box>
      </Box>
      <Box 
        sx={{ 
          fontSize: { 
            xs: '1.25rem', 
            sm: '1.5rem', 
            md: '2rem' 
          },
          fontWeight: 'bold'
        }}
      >
        {typeof value === 'string' ? (
          <Typography 
            variant="h4" 
            sx={{ 
              fontSize: 'inherit',
              fontWeight: 'inherit'
            }}
          >
            {value}
          </Typography>
        ) : (
          value
        )}
      </Box>
      {trend && (
        <Typography 
          variant="caption" 
          color="success.main" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5, 
            mt: isMobile ? 0.5 : 1 
          }}
        >
          <TrendingUpIcon fontSize="small" />
          {trend}
        </Typography>
      )}
    </Paper>
  );
}

export default MetricCard 