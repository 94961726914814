/**
 * Formats a number as currency (USD)
 * @param value - The number to format
 * @returns Formatted currency string
 */
export const formatCurrency = (value: string | number | undefined): string => {
  if (!value) return '$0.00'
  
  const numValue = typeof value === 'string' ? parseFloat(value) : value
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numValue)
} 