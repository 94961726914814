import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { Add, ViewColumn, Delete, MoreVert, FilterList } from '@mui/icons-material';
import { Box, Button, Container, Grid, Paper, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip, Menu, Chip } from '@mui/material';
import { Lead, LeadStatus } from '@otw/models/leads/lead';
import { useLeads } from 'hooks/admin/useLeads';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { LeadList } from './components/LeadList';
import LeadFormDialog from './components/dialogs/LeadFormDialog';
import LeadDetailDialog from './components/dialogs/LeadDetailDialog';
import { generateRandomColor } from 'core/utils/colorUtils';
import { collection, doc, getDoc, getDocs, setDoc, query, where, deleteDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
import { useAuth } from 'contexts/AuthContext';
import { useMediaQuery } from '@mui/material';

// Add these imports for month selection
import { SelectChangeEvent } from '@mui/material/Select';

interface LocalColumn {
  id: string;
  leads: Lead[];
  leadOrder: string[];
  name: string;
  color: string;
}

interface CustomColumn {
  id: string;
  name: string;
  color: string;
  position: number;
  createdBy: string;
}

interface LocalState {
  columns: { [key: string]: LocalColumn };
  columnOrder: string[];
}

// Background colors for swimlanes - lighter versions of the border colors
const STATUS_COLORS = {
  new: '#e3f2fd',         // Light blue background - New/fresh leads
  inProgress: '#fff3e0',  // Light orange background - Active work/in progress
  waitingFinance: '#fffde7', // Light yellow background - Waiting/on hold
  closed: '#e8f5e9',      // Light green background - Success/completed
  lost: '#ffebee'         // Light red background - Lost/negative outcome
};

// Border colors for swimlanes - matching the LeadCard border colors
const BORDER_COLORS = {
  new: '#2196f3',         // Blue - New/fresh leads
  inProgress: '#ff9800',  // Orange - Active work/in progress
  waitingFinance: '#ffc107', // Yellow/Amber - Waiting/on hold
  closed: '#4caf50',      // Green - Success/completed
  lost: '#f44336'         // Red - Lost/negative outcome
};

// User-friendly status names
const STATUS_NAMES = {
  new: 'New Leads',
  inProgress: 'In Progress',
  waitingFinance: 'Waiting Finance',
  closed: 'Closed',
  lost: 'Lost'
};

// Default columns that cannot be deleted
const DEFAULT_COLUMNS = ['new', 'inProgress', 'waitingFinance', 'closed', 'lost'];

// Create initial state for the board
const createInitialState = (leads: Lead[], customColumns: CustomColumn[] = []): LocalState => {
  // Group leads by status
  const leadsByStatus: Record<string, Lead[]> = {};
  
  // Initialize with default columns
  const columns: Record<string, LocalColumn> = {
    new: {
      id: 'new',
      leads: [],
      leadOrder: [],
      name: STATUS_NAMES.new,
      color: BORDER_COLORS.new
    },
    inProgress: {
      id: 'inProgress',
      leads: [],
      leadOrder: [],
      name: STATUS_NAMES.inProgress,
      color: BORDER_COLORS.inProgress
    },
    waitingFinance: {
      id: 'waitingFinance',
      leads: [],
      leadOrder: [],
      name: STATUS_NAMES.waitingFinance,
      color: BORDER_COLORS.waitingFinance
    },
    closed: {
      id: 'closed',
      leads: [],
      leadOrder: [],
      name: STATUS_NAMES.closed,
      color: BORDER_COLORS.closed
    },
    lost: {
      id: 'lost',
      leads: [],
      leadOrder: [],
      name: STATUS_NAMES.lost,
      color: BORDER_COLORS.lost
    }
  };
  
  // Add custom columns
  customColumns.forEach(customColumn => {
    columns[customColumn.id] = {
      id: customColumn.id,
      leads: [],
      leadOrder: [],
      name: customColumn.name,
      color: customColumn.color
    };
  });
  
  // Add leads to their respective columns
  leads.forEach(lead => {
    const status = lead.status;
    if (!status) {
      // Handle null status - add to "new" as a default
      columns["new"].leads.push(lead);
      columns["new"].leadOrder.push(lead.id);
      return;
    }
    
    if (!columns[status]) {
      // If status doesn't exist as a column, create it
      columns[status] = {
        id: status,
        leads: [],
        leadOrder: [],
        name: status.charAt(0).toUpperCase() + status.slice(1),
        color: generateRandomColor()
      };
    }
    
    columns[status].leads.push(lead);
    columns[status].leadOrder.push(lead.id);
  });
  
  // Create column order based on default columns and custom columns
  const columnOrder = [...DEFAULT_COLUMNS];
  
  // Add custom columns in their specified positions
  customColumns.sort((a, b) => a.position - b.position);
  customColumns.forEach(customColumn => {
    // Make sure we don't add duplicates
    if (!columnOrder.includes(customColumn.id)) {
      // Insert at the specified position, or at the end if position is invalid
      const position = Math.min(customColumn.position, columnOrder.length);
      columnOrder.splice(position, 0, customColumn.id);
    }
  });
  
  // Add any other columns that might exist in the data but aren't in the default or custom columns
  Object.keys(columns).forEach(columnId => {
    if (!columnOrder.includes(columnId)) {
      columnOrder.push(columnId);
    }
  });
  
  return {
    columns,
    columnOrder
  };
};

const LeadManagement: React.FC = () => {
  const { leads, isLoading, updateLead } = useLeads();
  const { userInfo } = useAuth();
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [state, setState] = useState<LocalState>({ columns: {}, columnOrder: [] });
  const [customColumns, setCustomColumns] = useState<CustomColumn[]>([]);
  const [isCreatingLead, setIsCreatingLead] = useState(false);
  const isMobile = useIsMobile();
  const [isSuggestedTrucksOpen, setIsSuggestedTrucksOpen] = useState(false);
  const [suggestedTrucksLead, setSuggestedTrucksLead] = useState<Lead | null>(null);
  const [isColumnDialogOpen, setIsColumnDialogOpen] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState('');
  const [newColumnPosition, setNewColumnPosition] = useState(0);
  const [isDeleteColumnDialogOpen, setIsDeleteColumnDialogOpen] = useState(false);
  const [columnToDelete, setColumnToDelete] = useState<string | null>(null);
  const [targetColumnForLeads, setTargetColumnForLeads] = useState<string>('');
  const [isLoadingColumns, setIsLoadingColumns] = useState(true);
  
  // Filter states
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [filterCompany, setFilterCompany] = useState<string>('');
  const [filterYear, setFilterYear] = useState<string>('');
  const [filterMonth, setFilterMonth] = useState<string>('');
  const [availableCompanies, setAvailableCompanies] = useState<string[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<Lead[] | null>(null);
  const [activeFilters, setActiveFilters] = useState<{company?: string, month?: string, year?: string}>({});

  // Generate years for filter (current year and 5 years back)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 6 }, (_, i) => (currentYear - i).toString());
  
  // Month names for filter
  const months = [
    { value: '0', label: 'January' },
    { value: '1', label: 'February' },
    { value: '2', label: 'March' },
    { value: '3', label: 'April' },
    { value: '4', label: 'May' },
    { value: '5', label: 'June' },
    { value: '6', label: 'July' },
    { value: '7', label: 'August' },
    { value: '8', label: 'September' },
    { value: '9', label: 'October' },
    { value: '10', label: 'November' },
    { value: '11', label: 'December' }
  ];

  // Load custom columns from Firestore
  const loadCustomColumns = async () => {
    try {
      setIsLoadingColumns(true);
      const columnsCollection = collection(db, 'leadColumns');
      const columnsQuery = query(columnsCollection);
      const querySnapshot = await getDocs(columnsQuery);
      
      const loadedColumns: CustomColumn[] = [];
      querySnapshot.forEach(doc => {
        loadedColumns.push(doc.data() as CustomColumn);
      });
      
      setCustomColumns(loadedColumns);
      return loadedColumns;
    } catch (error) {
      console.error('Error loading custom columns:', error);
      return [];
    } finally {
      setIsLoadingColumns(false);
    }
  };

  // Save a custom column to Firestore
  const saveCustomColumn = async (column: CustomColumn) => {
    try {
      const columnRef = doc(db, 'leadColumns', column.id);
      await setDoc(columnRef, column);
      return true;
    } catch (error) {
      console.error('Error saving custom column:', error);
      return false;
    }
  };

  // Delete a custom column from Firestore
  const deleteCustomColumn = async (columnId: string) => {
    try {
      const columnRef = doc(db, 'leadColumns', columnId);
      await deleteDoc(columnRef);
      return true;
    } catch (error) {
      console.error('Error deleting custom column:', error);
      return false;
    }
  };

  // Extract unique companies from leads
  useEffect(() => {
    if (leads) {
      const companies = Array.from(new Set(
        leads
          .map(lead => lead.company)
          .filter(company => company && company.trim() !== '') as string[]
      )).sort();
      
      setAvailableCompanies(companies);
    }
  }, [leads]);

  // Load leads and custom columns when component mounts
  useEffect(() => {
    const initializeBoard = async () => {
      const loadedColumns = await loadCustomColumns();
      const leadsToUse = filteredLeads || leads;
      if (leadsToUse) {
        setState(createInitialState(leadsToUse, loadedColumns));
      }
    };
    
    initializeBoard();
  }, [leads, filteredLeads]);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;

    // If there's no destination or the item was dropped back in its original position
    if (!destination || 
        (destination.droppableId === source.droppableId && 
         destination.index === source.index)) {
      return;
    }

    // Find the lead that was dragged
    const draggedLead = leads?.find(lead => lead.id === draggableId);
    if (!draggedLead) return;

    try {
      // Update local state first for immediate UI feedback
      const newState = { ...state };
      
      // Remove from source column
      const sourceColumn = { ...newState.columns[source.droppableId] };
      sourceColumn.leads = [...sourceColumn.leads];
      sourceColumn.leadOrder = [...sourceColumn.leadOrder];
      sourceColumn.leads.splice(source.index, 1);
      sourceColumn.leadOrder.splice(source.index, 1);
      
      // Add to destination column
      const destColumn = { ...newState.columns[destination.droppableId] };
      destColumn.leads = [...destColumn.leads];
      destColumn.leadOrder = [...destColumn.leadOrder];
      
      // Create a copy of the lead with updated status
      const updatedLead = { 
        ...draggedLead, 
        status: destination.droppableId as LeadStatus,
        prevStatus: draggedLead.status
      };
      
      // Insert at the destination index
      destColumn.leads.splice(destination.index, 0, updatedLead);
      destColumn.leadOrder.splice(destination.index, 0, updatedLead.id);
      
      // Update the columns in state
      newState.columns[source.droppableId] = sourceColumn;
      newState.columns[destination.droppableId] = destColumn;
      
      // Update state
      setState(newState);
      
      // Update the lead in the database
      await updateLead({
        id: draggableId,
        updates: {
          status: destination.droppableId as LeadStatus,
          prevStatus: draggedLead.status
        },
        statusChangeNote: `Moved from ${draggedLead.status ? (STATUS_NAMES[draggedLead.status] || draggedLead.status) : 'Unknown'} to ${STATUS_NAMES[destination.droppableId as LeadStatus] || destination.droppableId}`
      });
      
    } catch (error) {
      console.error('Error updating lead status:', error);
      // Revert to original state on error
      // You could implement this by keeping a backup of the state before changes
    }
  };

  const handleLeadSelect = useCallback((lead: Lead) => {
    setSelectedLead(lead);
    setIsCreatingLead(false);
    setIsDialogOpen(true);
  }, []);

  const handleCreateLead = useCallback(() => {
    setSelectedLead(null);
    setIsCreatingLead(true);
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setSelectedLead(null);
  }, []);

  const handleViewDetails = useCallback((lead: Lead) => {
    setSelectedLead(lead);
    setIsDetailDialogOpen(true);
  }, []);

  const handleCloseDetailDialog = useCallback(() => {
    setIsDetailDialogOpen(false);
  }, []);

  const handleEditFromDetails = (lead: Lead) => {
    setSelectedLead(lead);
    setIsCreatingLead(false);
    setIsDetailDialogOpen(false);
    setIsDialogOpen(true);
  };

  const handleViewSuggestedTrucks = useCallback((lead: Lead) => {
    setSuggestedTrucksLead(lead);
    setIsSuggestedTrucksOpen(true);
  }, []);

  const handleOpenColumnDialog = useCallback(() => {
    setNewColumnTitle('');
    setNewColumnPosition(state.columnOrder.length); // Default to last position
    setIsColumnDialogOpen(true);
  }, [state.columnOrder.length]);

  const handleCreateColumn = async () => {
    if (!newColumnTitle.trim() || !userInfo?.id) return;
    
    // Generate a unique ID for the column
    const columnId = `custom_${Date.now()}`;
    const color = generateRandomColor();
    
    // Create the custom column object
    const newCustomColumn: CustomColumn = {
      id: columnId,
      name: newColumnTitle.trim(),
      color,
      position: newColumnPosition,
      createdBy: userInfo.id
    };
    
    // Save to Firestore
    const saved = await saveCustomColumn(newCustomColumn);
    
    if (saved) {
      // Update local state
      setCustomColumns(prev => [...prev, newCustomColumn]);
      
      // Update the board state
      const newColumns = { ...state.columns };
      newColumns[columnId] = {
        id: columnId,
        name: newCustomColumn.name,
        color: newCustomColumn.color,
        leads: [],
        leadOrder: []
      };
      
      // Insert the new column at the specified position
      const newColumnOrder = [...state.columnOrder];
      newColumnOrder.splice(newColumnPosition, 0, columnId);
      
      setState({
        columns: newColumns,
        columnOrder: newColumnOrder
      });
    }
    
    // Reset form and close dialog
    setNewColumnTitle('');
    setNewColumnPosition(0);
    setIsColumnDialogOpen(false);
  };

  const handleDeleteColumn = (columnId: string) => {
    setColumnToDelete(columnId);
    // Set default target column (first column that's not the one being deleted)
    const firstAvailableColumn = state.columnOrder.find(id => id !== columnId);
    if (firstAvailableColumn) {
      setTargetColumnForLeads(firstAvailableColumn);
    }
    setIsDeleteColumnDialogOpen(true);
  };

  const confirmDeleteColumn = async () => {
    if (!columnToDelete || !targetColumnForLeads) return;

    // Get the column being deleted and its leads
    const columnToRemove = state.columns[columnToDelete];
    const leadsToMove = columnToRemove.leads;

    // Delete from Firestore if it's a custom column
    if (!DEFAULT_COLUMNS.includes(columnToDelete)) {
      await deleteCustomColumn(columnToDelete);
      
      // Update local custom columns state
      setCustomColumns(prev => prev.filter(col => col.id !== columnToDelete));
    }

    // Update the state to remove the column
    const newColumnOrder = state.columnOrder.filter(id => id !== columnToDelete);
    const newColumns = { ...state.columns };
    delete newColumns[columnToDelete];

    // Move leads to the target column and update their status in the database
    if (leadsToMove.length > 0) {
      // Update leads in the database
      for (const lead of leadsToMove) {
        await updateLead({
          id: lead.id,
          updates: { status: targetColumnForLeads as LeadStatus },
          statusChangeNote: `Moved from deleted column: ${columnToRemove.name}`
        });
      }

      // Add the leads to the target column
      newColumns[targetColumnForLeads].leads = [
        ...newColumns[targetColumnForLeads].leads,
        ...leadsToMove
      ];
      newColumns[targetColumnForLeads].leadOrder = [
        ...newColumns[targetColumnForLeads].leadOrder,
        ...leadsToMove.map(lead => lead.id)
      ];
    }

    // Update the state
    setState({
      columns: newColumns,
      columnOrder: newColumnOrder
    });

    // Close the dialog
    setIsDeleteColumnDialogOpen(false);
    setColumnToDelete(null);
    setTargetColumnForLeads('');
  };

  // Handle opening the filter dialog
  const handleOpenFilterDialog = useCallback(() => {
    setIsFilterDialogOpen(true);
  }, []);

  // Apply filters to leads
  const applyFilters = useCallback(() => {
    if (!leads) return;
    
    const newActiveFilters: {company?: string, month?: string, year?: string} = {};
    let filtered = [...leads];
    
    // Apply company filter if selected
    if (filterCompany) {
      filtered = filtered.filter(lead => lead.company === filterCompany);
      newActiveFilters.company = filterCompany;
    }
    
    // Apply year and month filters if selected
    if (filterYear) {
      newActiveFilters.year = filterYear;
      
      filtered = filtered.filter(lead => {
        if (!lead.createdAt) return false;
        
        const leadDate = new Date(lead.createdAt);
        return leadDate.getFullYear().toString() === filterYear;
      });
      
      // Apply month filter only if year is selected
      if (filterMonth) {
        newActiveFilters.month = filterMonth;
        
        filtered = filtered.filter(lead => {
          if (!lead.createdAt) return false;
          
          const leadDate = new Date(lead.createdAt);
          return leadDate.getMonth().toString() === filterMonth;
        });
      }
    }
    
    // Update filtered leads and active filters
    setFilteredLeads(filtered.length === leads.length ? null : filtered);
    setActiveFilters(newActiveFilters);
    setIsFilterDialogOpen(false);
  }, [leads, filterCompany, filterYear, filterMonth]);

  // Clear all filters
  const clearFilters = useCallback(() => {
    setFilterCompany('');
    setFilterYear('');
    setFilterMonth('');
    setFilteredLeads(null);
    setActiveFilters({});
    setIsFilterDialogOpen(false);
  }, []);

  // Remove a specific filter
  const removeFilter = useCallback((filterType: 'company' | 'month' | 'year') => {
    const newActiveFilters = {...activeFilters};
    
    if (filterType === 'company') {
      delete newActiveFilters.company;
      setFilterCompany('');
    } else if (filterType === 'year') {
      delete newActiveFilters.year;
      delete newActiveFilters.month; // Also remove month when year is removed
      setFilterYear('');
      setFilterMonth('');
    } else if (filterType === 'month') {
      delete newActiveFilters.month;
      setFilterMonth('');
    }
    
    if (Object.keys(newActiveFilters).length === 0) {
      setFilteredLeads(null);
      setActiveFilters({});
    } else {
      setActiveFilters(newActiveFilters);
      // Re-apply remaining filters
      let filtered = [...(leads || [])];
      
      if (newActiveFilters.company) {
        filtered = filtered.filter(lead => lead.company === newActiveFilters.company);
      }
      
      if (newActiveFilters.year) {
        filtered = filtered.filter(lead => {
          if (!lead.createdAt) return false;
          
          const leadDate = new Date(lead.createdAt);
          return leadDate.getFullYear().toString() === newActiveFilters.year;
        });
        
        if (newActiveFilters.month) {
          filtered = filtered.filter(lead => {
            if (!lead.createdAt) return false;
            
            const leadDate = new Date(lead.createdAt);
            return leadDate.getMonth().toString() === newActiveFilters.month;
          });
        }
      }
      
      setFilteredLeads(filtered);
    }
  }, [activeFilters, leads]);

  // Handle year change in filter
  const handleYearChange = (event: SelectChangeEvent) => {
    const year = event.target.value;
    setFilterYear(year);
    
    // Reset month if year is cleared
    if (!year) {
      setFilterMonth('');
    }
  };

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4, px: { xs: 1, sm: 2, md: 3 } }}>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'flex-start', md: 'center' }, 
          mb: 3 
        }}
      >
        <Typography variant="h4" component="h1" sx={{ mb: { xs: 2, md: 0 } }}>
          Lead Management
        </Typography>
        <Box display="flex" flexDirection="row">
          <Button
            variant="outlined"
            startIcon={<FilterList />}
            onClick={handleOpenFilterDialog}
            sx={{ mr: 2, fontSize: { xs: '0.8rem', md: '1rem' } }}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            startIcon={<ViewColumn />}
            onClick={handleOpenColumnDialog}
            sx={{ mr: 2, fontSize: { xs: '0.8rem', md: '1rem' } }}
          >
            Create Column
          </Button>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleCreateLead}
            sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
          >
            Create Lead
          </Button>
        </Box>
      </Box>

      {/* Display active filters */}
      {Object.keys(activeFilters).length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {activeFilters.company && (
            <Chip 
              label={`Company: ${activeFilters.company}`} 
              onDelete={() => removeFilter('company')}
              color="primary"
              variant="outlined"
            />
          )}
          {activeFilters.year && (
            <Chip 
              label={`Year: ${activeFilters.year}`} 
              onDelete={() => removeFilter('year')}
              color="primary"
              variant="outlined"
            />
          )}
          {activeFilters.month && (
            <Chip 
              label={`Month: ${months.find(m => m.value === activeFilters.month)?.label}`} 
              onDelete={() => removeFilter('month')}
              color="primary"
              variant="outlined"
            />
          )}
          <Button 
            size="small" 
            onClick={clearFilters}
            sx={{ ml: 1 }}
          >
            Clear All
          </Button>
        </Box>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            overflowX: 'auto',
            pb: 2,
            gap: isMobile ? 1 : 2,
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.3)',
              },
            },
            minHeight: '700px'
          }}
        >
          {state.columnOrder.map(columnId => {
            const column = state.columns[columnId];
            const isDefaultColumn = DEFAULT_COLUMNS.includes(columnId);
            
            return (
              <Box 
                key={columnId}
                sx={{ 
                  width: { xs: 250, sm: 280, md: 300 },
                  minWidth: { xs: 250, sm: 280, md: 300 },
                  position: 'relative',
                  flexShrink: 0,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <LeadList
                  title={column.name}
                  leads={column.leads}
                  status={columnId as LeadStatus}
                  color={column.color}
                  onLeadSelect={handleLeadSelect}
                  onViewDetails={handleViewDetails}
                  onViewSuggestedTrucks={handleViewSuggestedTrucks}
                  onDeleteColumn={!isDefaultColumn ? handleDeleteColumn : undefined}
                  isDefaultColumn={isDefaultColumn}
                  columnName={column.name}
                />
              </Box>
            );
          })}
        </Box>
      </DragDropContext>

      <LeadFormDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        selectedLead={selectedLead}
        isCreating={isCreatingLead}
      />

      {selectedLead && (
        <LeadDetailDialog
          open={isDetailDialogOpen}
          onClose={handleCloseDetailDialog}
          lead={selectedLead}
          onEdit={handleEditFromDetails}
        />
      )}

      {/* Create Column Dialog */}
      <Dialog
        open={isColumnDialogOpen}
        onClose={() => setIsColumnDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Column</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Column Title"
              fullWidth
              value={newColumnTitle}
              onChange={(e) => setNewColumnTitle(e.target.value)}
              margin="normal"
              required
            />
            
            <FormControl fullWidth margin="normal">
              <InputLabel>Position</InputLabel>
              <Select
                value={newColumnPosition}
                onChange={(e) => setNewColumnPosition(Number(e.target.value))}
                label="Position"
              >
                {state.columnOrder.map((_, index) => (
                  <MenuItem key={index} value={index}>
                    {index === 0 ? 'First' : `After ${state.columns[state.columnOrder[index-1]].name}`}
                  </MenuItem>
                ))}
                <MenuItem value={state.columnOrder.length}>Last</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsColumnDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateColumn} 
            variant="contained" 
            disabled={!newColumnTitle.trim()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Column Dialog */}
      <Dialog
        open={isDeleteColumnDialogOpen}
        onClose={() => setIsDeleteColumnDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Column</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to delete the column "{columnToDelete ? state.columns[columnToDelete]?.name : ''}"?
            </Typography>
            
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              All leads in this column will be moved to another column.
            </Typography>
            
            <FormControl fullWidth margin="normal">
              <InputLabel>Move leads to</InputLabel>
              <Select
                value={targetColumnForLeads}
                onChange={(e) => setTargetColumnForLeads(e.target.value)}
                label="Move leads to"
              >
                {state.columnOrder
                  .filter(id => id !== columnToDelete)
                  .map((columnId) => (
                    <MenuItem key={columnId} value={columnId}>
                      {state.columns[columnId].name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteColumnDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={confirmDeleteColumn} 
            variant="contained" 
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Filter Dialog */}
      <Dialog
        open={isFilterDialogOpen}
        onClose={() => setIsFilterDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Filter Leads</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth margin="normal">
              <Select
                value={filterCompany}
                onChange={(e) => setFilterCompany(e.target.value)}
                //label="Company"
                displayEmpty
              >
                <MenuItem value="">All Companies</MenuItem>
                {availableCompanies.map((company) => (
                  <MenuItem key={company} value={company}>
                    {company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth margin="normal">
              <InputLabel>Year</InputLabel>
              <Select
                value={filterYear}
                onChange={handleYearChange}
                label="Year"
              >
                <MenuItem value="">All Years</MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth margin="normal">
              <InputLabel>Month</InputLabel>
              <Select
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
                label="Month"
              >
                <MenuItem value="">All Months</MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearFilters}>Clear Filters</Button>
          <Button onClick={() => setIsFilterDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={applyFilters} 
            variant="contained"
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default memo(LeadManagement);
