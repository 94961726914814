import { Paper, Typography, Box, useTheme } from '@mui/material'
import { LineChart } from '@mui/x-charts'
import { useMemo } from 'react'
import { formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'
import { Vehicle } from '@otw/models'

interface SalesTrendChartProps {
  soldVehicles: Vehicle[]
  dateRange: { start: Date; end: Date }
}

export const SalesTrendChart = ({ soldVehicles, dateRange }: SalesTrendChartProps) => {
  const theme = useTheme()

  const parseDate = (dateValue: any): Date | null => {
    try {
      if (!dateValue) return null

      // Handle Firestore Timestamp
      if (dateValue?.seconds) {
        return new Date(dateValue.seconds * 1000)
      }

      // Handle string dates with "at" format
      if (typeof dateValue === 'string' && dateValue.includes(' at ')) {
        const cleanedDate = dateValue.replace(' at ', ' ')
        const date = new Date(cleanedDate)
        if (!isNaN(date.getTime())) {
          return date
        }
      }

      // Handle string dates
      if (typeof dateValue === 'string') {
        const date = new Date(dateValue)
        return isNaN(date.getTime()) ? null : date
      }

      // Handle Date objects
      if (dateValue instanceof Date) {
        return isNaN(dateValue.getTime()) ? null : dateValue
      }

      return null
    } catch (error) {
      console.error('Error parsing date:', dateValue, error)
      return null
    }
  }

  const chartData = useMemo(() => {
    // Filter sold vehicles within date range

    if (soldVehicles.length === 0) {
      return { xAxis: [], revenue: [], profit: [], maxValue: 0 }
    }

    // Create a date range array with all days between start and end
    const dayDiff = Math.ceil((dateRange.end.getTime() - dateRange.start.getTime()) / (1000 * 60 * 60 * 24))
    const allDates: string[] = []

    for (let i = 0; i <= dayDiff; i++) {
      const date = new Date(dateRange.start)
      date.setDate(date.getDate() + i)
      allDates.push(date.toISOString().split('T')[0])
    }

    // Initialize data for all dates
    const salesByDay: Record<string, { revenue: number; profit: number }> = {}
    allDates.forEach(date => {
      salesByDay[date] = { revenue: 0, profit: 0 }
    })

    // Populate with actual sales data
    soldVehicles.forEach(vehicle => {
      const date = parseDate(vehicle.sold_date)
      if (!date) return

      const dayKey = date.toISOString().split('T')[0]
      if (salesByDay[dayKey]) {
        salesByDay[dayKey].revenue += parseFloat(vehicle.optic_list_price?.toString() || '0') || 0
        salesByDay[dayKey].profit += parseFloat(vehicle.profit?.toString() || '0') || 0
      }
    })

    // Calculate cumulative data
    const revenue: number[] = []
    const profit: number[] = []
    let totalRevenue = 0
    let totalProfit = 0

    // Format dates for display
    const formatDate = (dateStr: string) => {
      const date = new Date(dateStr)
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      })
    }

    const formattedDates = allDates.map(formatDate)

    // Calculate cumulative values
    allDates.forEach(date => {
      totalRevenue += salesByDay[date].revenue
      totalProfit += salesByDay[date].profit
      revenue.push(totalRevenue)
      profit.push(totalProfit)
    })

    // Calculate max value for y-axis scaling
    const maxValue = Math.max(...revenue) * 1.2 // Add 20% margin to the top

    return {
      xAxis: formattedDates,
      revenue,
      profit,
      maxValue,
    }
  }, [soldVehicles, dateRange])

  if (chartData.xAxis.length === 0) {
    return (
      <Paper sx={{ p: 3, height: 400 }}>
        <Typography variant='h6' gutterBottom>
          Monthly Sales Performance
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Typography color='text.secondary'>No sales data available for selected date range</Typography>
        </Box>
      </Paper>
    )
  }

  return (
    <Paper sx={{ p: 3, height: 400 }}>
      <Typography variant='h6' gutterBottom>
        Monthly Sales Performance
      </Typography>
      <Box sx={{ width: '100%', height: 300 }}>
        <LineChart
          series={[
            {
              data: chartData.revenue,
              label: 'Total Revenue',
              color: theme.palette.primary.main,
              valueFormatter: (value: number | null) => (value !== null ? formatPrice(value.toString()) : '$0'),
              connectNulls: true,
              area: true,
              showMark: false,
            },
            {
              data: chartData.profit,
              label: 'Profit (included in Revenue)',
              color: theme.palette.success.main,
              valueFormatter: (value: number | null) => (value !== null ? formatPrice(value.toString()) : '$0'),
              connectNulls: true,
              area: true,
              showMark: false,
            },
          ]}
          xAxis={[
            {
              data: chartData.xAxis,
              scaleType: 'band',
              tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12,
              },
            },
          ]}
          yAxis={[
            {
              max: chartData.maxValue,
            },
          ]}
          height={300}
          margin={{ left: 70, right: 20, top: 20, bottom: 50 }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'right' },
              padding: 0,
            },
          }}
        />
      </Box>
      <Typography variant='caption' color='text.secondary' sx={{ display: 'block', textAlign: 'center', mt: 1 }}>
        The green area shows profit as a portion of total revenue
      </Typography>
    </Paper>
  )
}
