import ApartmentIcon from '@mui/icons-material/Apartment';
import BuildIcon from '@mui/icons-material/Build';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SortIcon from '@mui/icons-material/Sort';
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { Expense, ExpenseCategory } from '@otw/models';
import { formatPrice } from 'core/utils/inventoryUtils';
import dayjs from 'dayjs';
import { useIsMobile } from 'hooks/screen/useIsMobile';
import React, { useState } from 'react';

interface ExpenseListProps {
  expenses: Expense[];
  onDelete: (id: string) => Promise<void>;
}

export const ExpenseList: React.FC<ExpenseListProps> = ({ expenses, onDelete }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState<string | null>(null);
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [sortBy, setSortBy] = useState<'date' | 'amount'>('date');
  const [filterCategory, setFilterCategory] = useState<ExpenseCategory | 'All'>('All');
  const [expandedExpenseId, setExpandedExpenseId] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const theme = useTheme();

  const handleDeleteClick = (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedExpenseId(id);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedExpenseId) {
      await onDelete(selectedExpenseId);
      setDeleteDialogOpen(false);
      setSelectedExpenseId(null);
      if (detailDialogOpen && selectedExpense?.id === selectedExpenseId) {
        setDetailDialogOpen(false);
        setSelectedExpense(null);
      }
      if (expandedExpenseId === selectedExpenseId) {
        setExpandedExpenseId(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedExpenseId(null);
  };

  const handleExpenseClick = (expense: Expense, event: React.MouseEvent) => {
    event.stopPropagation();
    if (isMobile) {
      setExpandedExpenseId(expandedExpenseId === expense.id ? null : expense.id);
    } else {
      setSelectedExpense(expense);
      setDetailDialogOpen(true);
    }
  };

  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
    setSelectedExpense(null);
  };

  const getCategoryIcon = (category: ExpenseCategory) => {
    switch (category) {
      case 'Shipping':
        return <LocalShippingIcon fontSize="small" />;
      case 'Repairs':
        return <BuildIcon fontSize="small" />;
      case 'RentalReimbursement':
        return <DirectionsCarIcon fontSize="small" />;
      case 'Other':
        return <ApartmentIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const getCategoryColor = (category: ExpenseCategory) => {
    switch (category) {
      case 'Shipping':
        return 'primary';
      case 'Repairs':
        return 'error';
      case 'RentalReimbursement':
        return 'success';
      case 'Other':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getCategoryBgColor = (category: ExpenseCategory) => {
    switch (category) {
      case 'Shipping':
        return theme.palette.primary.light;
      case 'Repairs':
        return theme.palette.error.light;
      case 'RentalReimbursement':
        return theme.palette.success.light;
      case 'Other':
        return theme.palette.warning.light;
      default:
        return theme.palette.grey[200];
    }
  };

  // Sort and filter expenses
  const filteredAndSortedExpenses = expenses
    .filter(expense => filterCategory === 'All' || expense.category === filterCategory)
    .sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(b.date).getTime() - new Date(a.date).getTime(); // Newest first
      } else {
        return b.amount - a.amount; // Highest amount first
      }
    });

  // Group expenses by date for mobile view
  const groupedByDate = filteredAndSortedExpenses.reduce((groups, expense) => {
    const date = dayjs(expense.date).format('MMMM D, YYYY');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(expense);
    return groups;
  }, {} as Record<string, Expense[]>);

  // Calculate total amount
  const totalAmount = filteredAndSortedExpenses.reduce((sum, expense) => sum + expense.amount, 0);

  if (expenses.length === 0) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body1">No expenses found</Typography>
      </Box>
    );
  }

  return (
    <>
      {isMobile ? (
        <Box>
          {/* Mobile Filter and Sort Controls */}
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle2">Filter:</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                <Chip 
                  label="All" 
                  size="small" 
                  color={filterCategory === 'All' ? 'primary' : 'default'}
                  variant={filterCategory === 'All' ? 'filled' : 'outlined'}
                  onClick={() => setFilterCategory('All')}
                />
                <Chip 
                  icon={<LocalShippingIcon fontSize="small" />}
                  label="Shipping" 
                  size="small" 
                  color={filterCategory === 'Shipping' ? 'primary' : 'default'}
                  variant={filterCategory === 'Shipping' ? 'filled' : 'outlined'}
                  onClick={() => setFilterCategory('Shipping')}
                />
                <Chip 
                  icon={<BuildIcon fontSize="small" />}
                  label="Repairs" 
                  size="small" 
                  color={filterCategory === 'Repairs' ? 'error' : 'default'}
                  variant={filterCategory === 'Repairs' ? 'filled' : 'outlined'}
                  onClick={() => setFilterCategory('Repairs')}
                />
              </Box>
            </Box>
            <Box>
              <Tooltip title={`Sort by ${sortBy === 'date' ? 'amount' : 'date'}`}>
                <IconButton 
                  size="small" 
                  onClick={() => setSortBy(sortBy === 'date' ? 'amount' : 'date')}
                  color="primary"
                >
                  <SortIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Total Amount Display */}
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle1">
              {filteredAndSortedExpenses.length} {filteredAndSortedExpenses.length === 1 ? 'expense' : 'expenses'}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Total: {formatPrice(totalAmount)}
            </Typography>
          </Box>

          {/* Expense Cards Grouped by Date */}
          <Stack spacing={2}>
            {Object.entries(groupedByDate).map(([date, dateExpenses]) => (
              <Box key={date}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1, 
                    pb: 0.5, 
                    borderBottom: '1px solid', 
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5
                  }}
                >
                  <CalendarTodayIcon fontSize="small" color="action" />
                  {date}
                </Typography>
                <Stack spacing={1.5}>
                  {dateExpenses.map((expense) => (
                    <Card 
                      key={expense.id} 
                      elevation={1} 
                      sx={{ 
                        overflow: 'visible',
                        position: 'relative',
                        '&:hover': { 
                          boxShadow: 3,
                          transform: 'translateY(-2px)',
                          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
                        },
                        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
                      }}
                    >
                      <Box 
                        sx={{ 
                          position: 'absolute', 
                          top: 0, 
                          left: 0, 
                          width: '4px', 
                          height: '100%', 
                          bgcolor: getCategoryBgColor(expense.category),
                          borderTopLeftRadius: theme.shape.borderRadius,
                          borderBottomLeftRadius: theme.shape.borderRadius
                        }} 
                      />
                      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
                        <Grid container spacing={1}>
                          <Grid item xs={8}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                              <Avatar 
                                sx={{ 
                                  width: 28, 
                                  height: 28, 
                                  bgcolor: getCategoryBgColor(expense.category),
                                  color: theme.palette.getContrastText(getCategoryBgColor(expense.category))
                                }}
                              >
                                {getCategoryIcon(expense.category)}
                              </Avatar>
                              <Typography variant="subtitle2">{expense.category}</Typography>
                              {expense.vehicleId && (
                                <Tooltip title="Linked to vehicle">
                                  <Badge color="primary" variant="dot">
                                    <LinkIcon fontSize="small" sx={{ opacity: 0.7 }} />
                                  </Badge>
                                </Tooltip>
                              )}
                            </Box>
                            {expense.description && (
                              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5 }}>
                                <DescriptionIcon sx={{ color: 'text.secondary', opacity: 0.7, fontSize: '0.875rem', mt: 0.2 }} />
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  {expense.description}
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%', justifyContent: 'space-between' }}>
                              <Box>
                                <Typography variant="body1" fontWeight="bold">
                                  {formatPrice(expense.amount)}
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <IconButton 
                                  size="small" 
                                  color="primary" 
                                  onClick={(e) => handleExpenseClick(expense, e)}
                                  sx={{ 
                                    width: 30,
                                    height: 30
                                  }}
                                >
                                  {expandedExpenseId === expense.id ? 
                                    <ExpandLessIcon fontSize="small" /> : 
                                    <ExpandMoreIcon fontSize="small" />
                                  }
                                </IconButton>
                                <IconButton 
                                  size="small" 
                                  color="error" 
                                  onClick={(e) => handleDeleteClick(expense.id, e)}
                                  sx={{ 
                                    width: 30,
                                    height: 30,
                                    '&:hover': {
                                      bgcolor: theme.palette.error.light
                                    }
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        
                        {/* Expandable Details Section */}
                        <Collapse in={expandedExpenseId === expense.id} timeout="auto" unmountOnExit>
                          <Divider sx={{ my: 1.5 }} />
                          <Stack spacing={1.5}>
                            {expense.description && (
                              <Box>
                                <Typography variant="caption" color="text.secondary">
                                  Description
                                </Typography>
                                <Typography variant="body2">
                                  {expense.description}
                                </Typography>
                              </Box>
                            )}
                            
                            {expense.vehicleId && (
                              <Box>
                                <Typography variant="caption" color="text.secondary">
                                  Linked Vehicle
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <DirectionsCarIcon fontSize="small" color="primary" />
                                  <Typography variant="body2">{expense.vehicleId}</Typography>
                                </Box>
                              </Box>
                            )}
                            
                            <Box>
                              <Typography variant="caption" color="text.secondary">
                                Created By
                              </Typography>
                              <Typography variant="body2">
                                {expense.created_by} on {dayjs(expense.created_at).format('MMM D, YYYY [at] h:mm A')}
                              </Typography>
                            </Box>
                          </Stack>
                        </Collapse>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      ) : (
        <TableContainer component={Paper} elevation={0} sx={{ maxHeight: 300 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedExpenses.map((expense) => (
                <TableRow 
                  key={expense.id}
                  hover
                  onClick={(e) => handleExpenseClick(expense, e)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {getCategoryIcon(expense.category)}
                      <span>{expense.category}</span>
                      {expense.vehicleId && (
                        <Tooltip title="Linked to vehicle">
                          <LinkIcon fontSize="small" color="primary" sx={{ opacity: 0.7 }} />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{expense.description || '-'}</TableCell>
                  <TableCell>{dayjs(expense.date).format('MMM D, YYYY')}</TableCell>
                  <TableCell align="right">{formatPrice(expense.amount)}</TableCell>
                  <TableCell align="center">
                    <IconButton 
                      size="small" 
                      color="error" 
                      onClick={(e) => handleDeleteClick(expense.id, e)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Expense Detail Dialog - Desktop Version Only */}
      {!isMobile && (
        <Dialog
          open={detailDialogOpen}
          onClose={handleCloseDetailDialog}
          maxWidth="sm"
          fullWidth
        >
          {selectedExpense && (
            <>
              <DialogTitle sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                bgcolor: getCategoryBgColor(selectedExpense.category),
                color: theme.palette.getContrastText(getCategoryBgColor(selectedExpense.category))
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {getCategoryIcon(selectedExpense.category)}
                  <Typography variant="h6">{selectedExpense.category} Expense</Typography>
                </Box>
                <Typography variant="h6">{formatPrice(selectedExpense.amount)}</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <CalendarTodayIcon color="action" />
                      <Typography variant="body1">
                        {dayjs(selectedExpense.date).format('MMMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Grid>
                  
                  {selectedExpense.description && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Description
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 1.5 }}>
                        <Typography variant="body1">{selectedExpense.description}</Typography>
                      </Paper>
                    </Grid>
                  )}
                  
                  {selectedExpense.vehicleId && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Linked Vehicle
                      </Typography>
                      <Paper variant="outlined" sx={{ p: 1.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <DirectionsCarIcon color="primary" />
                          <Typography variant="body1">{selectedExpense.vehicleId}</Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                  
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Created By
                    </Typography>
                    <Paper variant="outlined" sx={{ p: 1.5 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body1">{selectedExpense.created_by}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          on {dayjs(selectedExpense.created_at).format('MMM D, YYYY [at] h:mm A')}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button 
                  onClick={handleCloseDetailDialog}
                  color="inherit"
                >
                  Close
                </Button>
                <Button 
                  onClick={(e) => handleDeleteClick(selectedExpense.id, e as React.MouseEvent)}
                  color="error"
                  variant="contained"
                  startIcon={<DeleteIcon />}
                >
                  Delete Expense
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        fullWidth={isMobile}
        maxWidth="xs"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this expense? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}; 