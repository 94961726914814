import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/auth/useAuth'
import { MFAWarning } from 'components/MFAWarning'
import Footer from 'components/Footer'
import { getSuperUserPages, clientPages, dealerPages, bankPages } from 'router/pages'
import { Navigation } from 'types/navigation'
import { Outlet } from 'react-router-dom'
import { CoreAppBar } from './components/CoreAppBar'
import { CoreDrawer } from './components/CoreDrawer'
import { MainLoading } from 'components/MainLoading'
import { useIsMobile } from 'hooks/screen/useIsMobile'

const DRAWER_WIDTH = 0
const CLOSED_DRAWER_WIDTH = -240
const CLOSED_DRAWER_WIDTH_MOBILE = CLOSED_DRAWER_WIDTH - 60

export const CoreLayout: React.FC = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { userInfo, currentUser } = useAuth()
  const isMobile = useIsMobile()
  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
      return
    }
  }, [currentUser, navigate])

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  const superuserPages = getSuperUserPages()

  const getNavigationItems = (): Navigation => {
    switch (userInfo?.role) {
      case 'admin':
        return superuserPages
      case 'babyAdmin':
        return superuserPages
      case 'client':
        return clientPages
      case 'dealer':
        return dealerPages
      case 'bankAgent':
        return bankPages
      default:
        return []
    }
  }

  if (!currentUser) {
    return <MainLoading />
  }

  if (userInfo?.role === 'client' && userInfo.needsOnboarding === true) {
    // if (userInfo?.role === 'client' && userInfo?.needsInitialPasswordSet) {
    return <Navigate to='/client-onboarding' />
  }

  if (userInfo?.role === 'bankAgent' && userInfo.needsOnboarding === true) {
    // if (userInfo?.role === 'bankAgent' && userInfo?.needsInitialPasswordSet) {
    return <Navigate to='/bank-onboarding' />
  }

  const navigationItems: Navigation = getNavigationItems()
  const isFreeloBoard = location.pathname.startsWith('/freelo/') && location.pathname !== '/freelo'
  const isKnowledgeBase = location.pathname.startsWith('/knowledge-base')

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <CoreAppBar open={open} onDrawerToggle={handleDrawerToggle} />
      <CoreDrawer
        open={open}
        onDrawerClose={handleDrawerToggle}
        navigationItems={navigationItems}
        setOpen={setOpen}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          marginLeft: `${open ? DRAWER_WIDTH : isMobile ? CLOSED_DRAWER_WIDTH_MOBILE : CLOSED_DRAWER_WIDTH}px`,
          transition: theme =>
            theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),

          marginTop: '64px', // Height of AppBar
          padding: isFreeloBoard || isKnowledgeBase ? 0 : { xs: 1, sm: 2, md: 3 },
          minHeight: 'calc(100vh - 64px)',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '100vw',
          overflowX: 'hidden',
        }}
      >
        <MFAWarning />
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
        {isFreeloBoard || isKnowledgeBase ? null : <Footer />}
        {/* <ChatBubble /> */}
      </Box>
    </Box>
  )
}

export default CoreLayout
