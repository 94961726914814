// TODO: Later convert this to a dynamic mapper that uses the useUsers hook to map 
// the entered_by property's initials (first letter of firstName + second letter of lastName)
// to the corresponding user's full name
const INITIALS_TO_NAME_MAP: Record<string, string> = {
    'All': 'All',
    'AC': 'Anthony Carracino',
    'SR': 'Sara Rivera',
    'CG': 'Colton Gray',
    'MD': 'Michael Dermek',
    'NH': 'Nick Hendriks',
    'AS': 'Adam Siweic',
    'JB': 'Jack Benedickson'
  }
  
  // Function to get full name from stock prefix
export const getFullNameFromPrefix = (prefix: string) => {
    return INITIALS_TO_NAME_MAP[prefix] || prefix;
  }