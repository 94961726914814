import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material'
import { Vehicle } from '@otw/models/inventory'
import React, { useEffect, useMemo, useState } from 'react'

interface VehicleSaleSelectionDialogProps {
  open: boolean
  onClose: () => void
  vehicles: Vehicle[]
  onConfirm: (selectedVehicles: Vehicle[]) => void
  remainingCount?: number
}

const VehicleSaleSelectionDialog: React.FC<VehicleSaleSelectionDialogProps> = ({
  open,
  onClose,
  vehicles,
  onConfirm,
  remainingCount: propRemainingCount,
}) => {
  const [selectedVehicles, setSelectedVehicles] = useState<{ [key: string]: boolean }>({})
  const [selectAll, setSelectAll] = useState(true)
  
  // Initialize all vehicles as selected
  useEffect(() => {
    if (open && vehicles.length > 0) {
      const initialSelection = vehicles.reduce((acc, vehicle) => {
        acc[vehicle.id] = true
        return acc
      }, {} as { [key: string]: boolean })
      
      setSelectedVehicles(initialSelection)
      setSelectAll(true)
    }
  }, [open, vehicles])
  
  const handleToggleVehicle = (id: string) => {
    setSelectedVehicles(prev => ({
      ...prev,
      [id]: !prev[id]
    }))
    
    // Update select all state based on new selection
    const updatedSelection = {
      ...selectedVehicles,
      [id]: !selectedVehicles[id]
    }
    
    const allSelected = vehicles.every(v => updatedSelection[v.id])
    setSelectAll(allSelected)
  }
  
  const handleToggleSelectAll = () => {
    const newSelectAll = !selectAll
    setSelectAll(newSelectAll)
    
    // Select or deselect all vehicles
    const newSelection = vehicles.reduce((acc, vehicle) => {
      acc[vehicle.id] = newSelectAll
      return acc
    }, {} as { [key: string]: boolean })
    
    setSelectedVehicles(newSelection)
  }
  
  const handleConfirm = () => {
    const selected = vehicles.filter(v => selectedVehicles[v.id])
    onConfirm(selected)
  }
  
  const selectedCount = Object.values(selectedVehicles).filter(Boolean).length
  
  // Calculate remaining vehicles count
  const remainingCount = useMemo(() => {
    return vehicles.length - selectedCount;
  }, [vehicles.length, selectedCount]);
  
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      // Improve accessibility by setting keepMounted to false
      keepMounted={false}
      // Restore focus properly when dialog closes
      disableRestoreFocus
    >
      <DialogTitle>
        Select Vehicles to Mark as Sold
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Select which vehicles you want to mark as sold with the same client and sales representative.
            {remainingCount > 0 && (
              <Box component="span" sx={{ fontWeight: 'bold', display: 'block', mt: 1 }}>
                {remainingCount} vehicle{remainingCount !== 1 ? 's' : ''} will remain after this selection.
              </Box>
            )}
          </Typography>
          
          <FormControlLabel
            control={
              <Checkbox 
                checked={selectAll} 
                onChange={handleToggleSelectAll}
                indeterminate={selectedCount > 0 && selectedCount < vehicles.length}
              />
            }
            label={`Select All (${selectedCount}/${vehicles.length})`}
            sx={{ mt: 1 }}
          />
        </Box>
        
        <Paper variant="outlined" sx={{ maxHeight: 400, overflow: 'auto' }}>
          <List disablePadding>
            {vehicles.map((vehicle, index) => (
              <React.Fragment key={vehicle.id}>
                {index > 0 && <Divider />}
                <ListItem disablePadding>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={!!selectedVehicles[vehicle.id]} 
                        onChange={() => handleToggleVehicle(vehicle.id)}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body1">
                          {vehicle.stock_number} - {vehicle.year} {vehicle.make} {vehicle.model}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Type: {vehicle.type}, Size: {vehicle.size}, Miles: {vehicle.miles?.toLocaleString() || 'N/A'}
                        </Typography>
                      </Box>
                    }
                    sx={{ py: 1, px: 2, width: '100%' }}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">Cancel</Button>
        <Button 
          onClick={handleConfirm} 
          variant="contained" 
          color="primary"
          disabled={selectedCount === 0}
        >
          Continue with {selectedCount} Vehicle{selectedCount !== 1 ? 's' : ''}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VehicleSaleSelectionDialog 