import { getHeaders } from 'core/getHeaders'
import { CombinedUserData, CreateUserType, ApplicationStatus, PrivateClientFinancingProfile } from '@otw/models'
import { functionsBaseUrl } from 'config/functionsBaseUrl'
import { sharedFunctions } from './sharedFunctions'
import { getApplicationDocuments } from 'core/api/application/bank'
import { encryptPayload } from 'core/security/encryptPayload'

export interface GetAllUsersResponse {
  users: CombinedUserData[]
  total: number
  page?: number
  limit?: number
  hasMore?: boolean
  lastDocId?: string | null
}

export interface ClientFinancingProfileResponse {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  dealership_name: string;
  financingProfile: PrivateClientFinancingProfile | null;
  error?: string;
}

export const portalFunctions = {
  client: {
    /** @encrypted This method encrypts the financing profile before transmission */
    createClientPrivateFinancingProfile: async (financingProfile: Omit<PrivateClientFinancingProfile, 'id'>) => {
      const encryptedPayload = await encryptPayload(financingProfile)
      const url = `${functionsBaseUrl}/portal/client/createClientPrivateFinancingProfile`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ encryptedPayload: encryptedPayload }),
      })
      return response
    },

    /** @encrypted This method receives and decrypts the financing profile */
    getClientPrivateFinancingProfile: async () => {
      const url = `${functionsBaseUrl}/portal/client/getClientPrivateFinancingProfile`
      const method = 'GET'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
      })
      return response
    },
  },

  admin: {
    getAuthUsers: async (options?: { limit?: number; page?: number; lastDocId?: string | null; search?: string }) => {
      const params = new URLSearchParams();
      if (options?.limit) params.append('limit', options.limit.toString());
      if (options?.page) params.append('page', options.page.toString());
      if (options?.lastDocId && options.lastDocId !== null) params.append('lastDocId', options.lastDocId);
      if (options?.search) params.append('search', options.search);

      const queryString = params.toString() ? `?${params.toString()}` : '';
      const url = `${functionsBaseUrl}/portal/admin/getAuthUsers${queryString}`;
      const method = 'GET';
      const headers = await getHeaders();
      const response = await fetch(url, {
        method: method,
        headers,
      });
      if (!response.ok) {
        throw new Error('Failed to get auth users');
      }
      const data: GetAllUsersResponse = await response.json();
      return data;
    },
    createFirebaseUser: async (userToCreate: CreateUserType) => {
      const url = `${functionsBaseUrl}/portal/admin/createFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify(userToCreate),
      })
      return response
    },
    createDisabledFirebaseUser: async (userToCreate: CreateUserType) => {
      const url = `${functionsBaseUrl}/portal/admin/createDisabledFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify(userToCreate),
      })
      return response
    },
    deleteFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/deleteFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    disableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/disableFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    enableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/enableFirebaseUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    resendVerificationEmail: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/resendVerificationEmail`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      })
      return response
    },
    generatePhotoUploadLink: async (email: string, vehicleData: any) => {
      const url = `${functionsBaseUrl}/portal/admin/generatePhotoUploadLink`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ email, vehicleData }),
      })
      return response
    },
    getAllClientFinancingProfiles: async () => {
      const url = `${functionsBaseUrl}/portal/admin/getAllClientFinancingProfiles`
      const method = 'GET'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
      })
      if (!response.ok) {
        throw new Error('Failed to get client financing profiles')
      }
      const result = await response.json()
      return result.data as ClientFinancingProfileResponse[]
    },
    updateClientFinancingProfile: async (clientId: string, financingProfile: Partial<PrivateClientFinancingProfile>) => {
      const url = `${functionsBaseUrl}/portal/admin/updateClientFinancingProfile`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ clientId, financingProfile }),
      })
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.error || 'Failed to update client financing profile')
      }
      return response.json()
    },
    generateApplicationForUser: async (userId: string, bankId: string) => {
      const url = `${functionsBaseUrl}/panda/admin/generateApplicationForUser`
      const method = 'POST'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId, bankId }),
      })
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.detail || 'Failed to generate application for user')
      }
      return response.json()
    },
    getAllBanks: async () => {
      const url = `${functionsBaseUrl}/portal/admin/getAllBanks`
      const method = 'GET'
      const headers = await getHeaders()
      const response = await fetch(url, {
        method: method,
        headers,
      })
      if (!response.ok) {
        throw new Error('Failed to get banks')
      }
      return response.json()
    },
  },
  public: {
    generatePasswordReset: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generatePasswordReset`
      const method = 'POST'
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log(response)

      if (response.status === 429) {
        throw new Error('Too many requests')
      }

      if (!response.ok) {
        throw new Error('Failed to send password reset email')
      }
    },
    resendVerificationEmail: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/resendVerificationEmail`
      const method = 'POST'
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    generateSignInLink: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generateSignInLink`
      const method = 'POST'
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: { 'Content-Type': 'application/json' },
      })
    },
  },
  shared: {
    setInitialPassword: async (password: string) => {
      const url = `${functionsBaseUrl}/portal/shared/setInitialPassword`
      const method = 'POST'
      const headers = await getHeaders()
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ password }),
      })
      return response
    },
  },
}
