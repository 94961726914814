import React from 'react'
import { Grid, Box } from '@mui/material'
import { Vehicle } from '@otw/models'
import { Application } from '@otw/models'
import { useIsMobile } from 'hooks/screen/useIsMobile'

// Import icons for the mobile view selector
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleIcon from '@mui/icons-material/People'
import { MobileViewSelector } from '../../shared'
import { ApplicationStats, ApplicationList, ApplicationsMonitor } from './views'

interface ApplicationsTabProps {
  applications: Application[]
  dateRange: {
    start: Date
    end: Date
  }
}

export const ApplicationsTab: React.FC<ApplicationsTabProps> = ({ applications, dateRange }) => {
  const isMobile = useIsMobile()

  // For mobile, we'll use the view selector with dropdown to split the views
  if (isMobile) {
    return (
      <Box
        sx={{
          maxWidth: '100%',
          overflowX: 'hidden',
        }}
      >
        <MobileViewSelector
          titles={['Application Stats', 'Application List']}
          icons={[<AssessmentIcon fontSize='small' />, <PeopleIcon fontSize='small' />]}
        >
          {/* Application Statistics View */}
          <ApplicationStats applications={applications} dateRange={dateRange} />

          {/* Application List View */}
          <ApplicationList applications={applications} />
        </MobileViewSelector>
      </Box>
    )
  }

  // Desktop view remains unchanged
  return (
    <Box
      sx={{
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ApplicationsMonitor applications={applications} dateRange={dateRange} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ApplicationsTab
