import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { BusinessSaleTruck, BusinessSubmission, Vehicle } from '@otw/models'
import { useCalculateDeal } from 'hooks/graybook/useCalculateDeal'

interface CompletedVehicleCardProps {
  vehicle: BusinessSaleTruck
}

const CompletedVehicleCard: React.FC<CompletedVehicleCardProps> = ({ vehicle }) => {
  const { grayBookRetailPrice } = useCalculateDeal({
    make: vehicle.make || '',
    model: vehicle.model || '',
    type: vehicle.type || '',
    size: vehicle.size || '',
    miles: vehicle.miles || '',
    year: vehicle.year || '',
    fuel: vehicle.fuel === "Gasoline" ? "Gas" : vehicle.fuel === "Diesel" ? "Diesel" : null,
    sellerAskingPrice: "0", // Assuming no asking price is needed
  })

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex' }}>
      <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">VIN: {vehicle.vin}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Make: {vehicle.make}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Model: {vehicle.model}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Year: {vehicle.year}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Size: {vehicle.size}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Fuel: {vehicle.fuel}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Condition: {vehicle.user_condition}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Miles: {vehicle.miles}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Damage: {vehicle.damage}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                {grayBookRetailPrice !== null
                  ? `Estimated Value: ${currencyFormatter.format(grayBookRetailPrice)}`
                  : 'Unable to calculate value.'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CompletedVehicleCard 