import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { Application } from "../application/application";

const applicationConverter: FirestoreDataConverter<Application> = {
  toFirestore(application: WithFieldValue<Application>) {
    const { id, ...data } = application;
    return data;
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): Application {
    const data = snapshot.data(options);

    if (!data)
      return {
        id: snapshot.id,
        status: "draft",
        status_updated_at: new Date().toISOString(),
        status_updated_by_user_id: "",
        completed: false,
        time_created: new Date().toISOString(),
        notes: "",
        correction_notes: "",
        bank_decision_notes: "",
        bank_id: "",
        bank_agent_id: "",
        client_id: "",
        user_id: "",
        user_email: "",
        user_name: "",
        document_uuid: "",
        template_uuid: "",
        inventory_ids: [],
      };

    return {
      id: snapshot.id,
      status: data.status || "draft",
      status_updated_at: data.status_updated_at || new Date().toISOString(),
      status_updated_by_user_id: data.status_updated_by_user_id || "",
      completed: data.completed || false,
      time_created: data.time_created || new Date().toISOString(),
      notes: data.notes || "",
      correction_notes: data.correction_notes || "",
      corrections_requested_by_user_id: data.corrections_requested_by_user_id,
      bank_decision_notes: data.bank_decision_notes || "",
      bank_id: data.bank_id || "",
      bank_agent_id: data.bank_agent_id || "",
      client_id: data.client_id || "",
      user_id: data.user_id || "",
      user_email: data.user_email || "",
      user_name: data.user_name || "",
      document_uuid: data.document_uuid || "",
      template_uuid: data.template_uuid || "",
      pandadoc_url: data.pandadoc_url,
      inventory_ids: data.inventory_ids || [],
      sql_app_id: data.sql_app_id,
      sql_user_id: data.sql_user_id,
    };
  },
};

export { applicationConverter };
export const toApplication = applicationConverter;
