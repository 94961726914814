/* eslint-disable */

import React, { useState, useEffect } from 'react'
import {
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
} from '@mui/material'
import AddNotesDialog from './components/AddNotesDialog'
import AssignAgentDialog from './components/AssignAgentDialog'
import ViewApprovalTerms from './components/ViewApprovalTerms'
import AssociateVehiclesDialog from './components/AssociateVehiclesDialog'
import ViewDocumentsDialog from './components/ViewDocumentsDialog'
import AdminPandadocDialog from 'components/AdminPandadocDialog'
import { useTheme, useMediaQuery } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { PageTitle } from 'components/PageTitle'
import { formatDateToLocalTime } from '../../../../core/utils/formatDateToLocalTime'
import { Application } from '@otw/models'
import { useManageUsers } from 'hooks/admin/useManageUsers'
import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'
import ApplicationCard from './components/ApplicationCard'
import { useAdminApplications } from 'hooks/admin/useAdminApplications'
import { FreeTechLogoLoading } from 'components/FreeTechLogoLoading'
import { useSnackbar } from 'contexts/snackBarContext'
import { ApplicationStatusTimeline } from 'components/ApplicationStatusTimeline'
import { pandaFunctions } from 'core/functions/pandaFunctions'

const TabPanel = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  padding: '24px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '16px',
  marginTop: '16px',
}))

const StyledTabs = styled(Tabs)<{ theme?: Theme }>(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '8px',
  marginBottom: '16px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    minHeight: 48,
    borderRadius: '8px',
    margin: '0 4px',
    transition: 'all 0.3s ease',
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: theme?.palette.primary.main,
      color: '#fff',
      boxShadow: theme?.shadows[2],
    },
    '&:hover': {
      opacity: 0.8,
      transform: 'translateY(-1px)',
    },
  },
}))

const ApplicationManagement = () => {
  const [filteredApps, setFilteredApps] = useState<Application[]>([])
  const [tabIndex, setTabIndex] = useState(0)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [documentUrl, setDocumentUrl] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAssignAgentDialogOpen, setAssignAgentDialog] = useState(false)
  const [selectedBankId, setSelectedBankId] = useState<string>('')
  const [selectedBankName, setSelectedBankName] = useState<string>('')
  const [selectedAgentId, setSelectedAgentId] = useState<string>('')
  const [selectedApplicationId, setSelectedApplicationId] = useState<string | null>(null)
  const [isAddNotesDialogOpen, setAddNotesDialogOpen] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState<Application | null>(null)
  const [correctioNotes, setCorrectionNotes] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { showSnackbar } = useSnackbar()

  const { editApplication, applications, applicationsIsLoading } = useAdminApplications()

  const { authUsers } = useManageUsers()

  useEffect(() => {
    if (!applications) return

    const filtered = applications.filter((app: Application) => {
      const query = searchQuery.toLowerCase()
      const matchesSearchQuery =
        (app.status?.toLowerCase() || '').includes(query) ||
        (app.bank_id?.toLowerCase() || '').includes(query) ||
        (app.user_name?.toLowerCase() || '').includes(query) ||
        (app.time_created?.toLowerCase() || '').includes(query) ||
        (app.notes?.toLowerCase() || '').includes(query) ||
        (app.bank_decision_notes?.toLowerCase() || '').includes(query) ||
        (app.id?.toString() || '').includes(query) ||
        (app.bank_agent_id?.toLowerCase() || '').includes(query)

      const matchesStatus = () => {
        switch (tabIndex) {
          case 0:
            return app.status === 'signing'
          case 1:
            return app.status === 'ready'
          case 2:
            return app.status === 'corrections_needed'
          case 3:
            return app.status === 'pending'
          case 4:
            return app.status === 'viewed'
          case 5:
            return app.status === 'approved' && app.completed !== true
          case 6:
            return app.status === 'denied' && !app.completed
          case 7:
            return app.completed === true
          default:
            return false
        }
      }

      return matchesSearchQuery && matchesStatus()
    })

    setFilteredApps(filtered)
  }, [tabIndex, applications, searchQuery])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleSelectTabChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number
    setTabIndex(value)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setDocumentUrl(null)
    setSelectedApplication(null)
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  const handleAssignAgent = () => {
    if (!selectedApplicationId) return
    assignAgent(selectedApplicationId, selectedAgentId)
    setAssignAgentDialog(false)
  }

  const assignAgent = async (application_id: string, agent_id: string) => {
    try {
      const appToUpdate = applications?.find(app => app.id === application_id)
      if (!appToUpdate) return

      await editApplication.mutateAsync({
        ...appToUpdate,
        bank_agent_id: agent_id,
        status: 'pending',
      })
    } catch (error) {
      console.error('Error during agent assignment:', error)
    }
  }

  const openAssignAgentDialog = (application: Application) => {
    setSelectedApplicationId(application.id)
    setSelectedBankId(application.bank_id)
    setSelectedBankName(application.bank_id)
    setAssignAgentDialog(true)
  }

  const markApplicationCompleted = async (application_id: string) => {
    const appToUpdate = applications?.find(app => app.id === application_id)
    if (!appToUpdate) return

    await editApplication.mutateAsync({
      ...appToUpdate,
      completed: true,
    })
  }

  const handleOpenAddNotesDialog = (application: Application) => {
    setSelectedApplication(application)
    setAddNotesDialogOpen(true)
  }

  const handleCloseAddNotesDialog = () => {
    setAddNotesDialogOpen(false)
  }

  const handleViewDocument = async (application: Application) => {
    if (application.status === 'signing') {
      showSnackbar('Document is currently being signed and cannot be viewed', 'warning')
      return
    }

    setLoadingDialogOpen(true)
    try {
      const url = await pandaFunctions.shared.getViewableDocument(application.id)
      console.log('url', url)

      setSelectedApplication(application)
      console.log('application', application)

      if (!url) {
        throw new Error('No document URL found')
      }
      setDocumentUrl(url)
      setLoadingDialogOpen(false)
      setIsDialogOpen(true)
    } catch (error) {
      console.error('Error fetching document:', error)
      setErrorDialogOpen(true)
      setLoadingDialogOpen(false)
    }
  }

  const handleSendDocumentBackForCorrections = async (application_id: string, correction_notes: string) => {
    try {
      const appToUpdate = applications?.find(app => app.id === application_id)
      if (!appToUpdate) return

      await editApplication.mutateAsync({
        ...appToUpdate,
        status: 'corrections_needed',
        correction_notes: correction_notes,
      })
      setDocumentUrl(null)
    } catch (error) {
      setErrorDialogOpen(true)
      setLoadingDialogOpen(false)
    }
  }

  const LoadingDialog = () => (
    <Dialog open={loadingDialogOpen} onClose={handleCloseLoadingDialog}>
      <DialogTitle>Loading Document..</DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )

  const handleCloseLoadingDialog = () => {
    setErrorDialogOpen(false)
  }

  const [viewApprovalNotesDialogOpen, setViewApprovalNotesDialogOpen] = useState(false)

  const handleOpenViewApprovalNotesDialog = (application: Application) => {
    setSelectedApplication(application)
    setViewApprovalNotesDialogOpen(true)
  }

  const handleCloseViewApprovalNotesDialog = () => {
    setViewApprovalNotesDialogOpen(false)
  }

  const ErrorDialog = () => (
    <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error loading the document. Please try again or submit a support request.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href='/support-request'>Submit Support Request</Button>
        <Button onClick={handleCloseErrorDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )

  const [isAssociateVehiclesDialogOpen, setAssociateVehiclesDialogOpen] = useState(false)

  const handleOpenAssociateVehiclesDialog = (application: Application) => {
    setSelectedApplication(application)
    setAssociateVehiclesDialogOpen(true)
  }

  const handleCloseAssociateVehiclesDialog = () => {
    setAssociateVehiclesDialogOpen(false)
  }

  const [viewDocumentsDialog, setViewDocumentsDialog] = useState(false)

  const handleOpenViewDocumentsDialog = (application: Application) => {
    setSelectedApplication(application)

    setViewDocumentsDialog(true)
  }

  const handleCloseViewDocumentsDialog = () => {
    setViewDocumentsDialog(false)
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Ready':
        return '#2196F3' // Blue
      case 'Corrections Needed':
        return '#F44336' // Red
      case 'Pending':
        return '#FFA000' // Amber
      case 'Viewed':
        return '#9C27B0' // Purple
      case 'Approved':
        return '#4CAF50' // Green
      case 'Denied':
        return '#D32F2F' // Dark Red
      case 'Completed':
        return '#757575' // Grey
      default:
        return 'text.primary'
    }
  }

  if (applicationsIsLoading) {
    return <FreeTechLogoLoading />
  }

  return (
    <>
      <ViewApprovalTerms
        application={selectedApplication}
        isOpen={viewApprovalNotesDialogOpen}
        onClose={handleCloseViewApprovalNotesDialog}
      />

      <AssignAgentDialog isOpen={isAssignAgentDialogOpen} onClose={() => setAssignAgentDialog(false)} />

      <AddNotesDialog
        application={selectedApplication}
        isOpen={isAddNotesDialogOpen}
        onClose={handleCloseAddNotesDialog}
      />

      <AssociateVehiclesDialog
        application={selectedApplication}
        isOpen={isAssociateVehiclesDialogOpen}
        onClose={handleCloseAssociateVehiclesDialog}
      />

      <ViewDocumentsDialog
        application={selectedApplication}
        isOpen={viewDocumentsDialog}
        onClose={handleCloseViewDocumentsDialog}
      />

      <ErrorDialog />
      <LoadingDialog />

      <Box display='flex' flexDirection='column'>
        <PageTitle
          title='Application Management'
          subtitle='Manage applications and their statuses.'
          bulletPoints={[
            'Use the tabs to filter applications by status.',
            "Click 'View' to view the application document.",
            "Click 'Download Documents' to download all documents associated with the application.",
            "Click 'Assign to Agent' to assign the application to a bank agent.",
            "Click 'Add Bank Notes' to add notes to the application.",
            "Click 'View Approval Terms' to view the approval terms.",
            "Click 'Associate Vehicles' to associate vehicles with the application.",
            "Click 'Mark Completed' to mark the application as completed.",
          ]}
        />
        <ApplicationStatusTimeline role='admin' />
        <TextField
          // fullWidth
          label='Keyword Search (name, bank, email, notes, etc...)'
          variant='outlined'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />
      </Box>

      {isMobile ? (
        <FormControl fullWidth>
          <InputLabel id='tab-select-label'>Status</InputLabel>
          <Select
            labelId='tab-select-label'
            id='tab-select'
            value={tabIndex}
            onChange={handleSelectTabChange}
            label='Status'
          >
            <MenuItem value={0}>signing</MenuItem>
            <MenuItem value={1}>ready</MenuItem>
            <MenuItem value={2}>corrections needed</MenuItem>
            <MenuItem value={3}>pending</MenuItem>
            <MenuItem value={4}>viewed</MenuItem>
            <MenuItem value={5}>approved</MenuItem>
            <MenuItem value={6}>denied</MenuItem>
            <MenuItem value={7}>completed</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <StyledTabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label='signing' />
          <Tab label='ready' />
          <Tab label='corrections needed' />
          <Tab label='pending' />
          <Tab label='viewed' />
          <Tab label='approved' />
          <Tab label='denied' />
          <Tab label='completed' />
        </StyledTabs>
      )}

      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={2}>
        {filteredApps.map((application: Application) => (
          <Grid item lg={4} md={4} sm={6} xs={12} key={application.id}>
            <ApplicationCard
              application={application}
              tabIndex={tabIndex}
              users={authUsers?.users || []}
              onViewDocument={handleViewDocument}
              onAssignAgent={openAssignAgentDialog}
              onMarkCompleted={markApplicationCompleted}
              onAddNotes={handleOpenAddNotesDialog}
              onViewDocuments={handleOpenViewDocumentsDialog}
              onViewApprovalNotes={handleOpenViewApprovalNotesDialog}
              onAssociateVehicles={handleOpenAssociateVehiclesDialog}
            />
          </Grid>
        ))}
      </Grid>

      <AdminPandadocDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        iframeUrl={documentUrl || ''}
        loading={loadingDialogOpen}
        onFormCompleted={() => {}}
        status={selectedApplication?.status}
        onIframeLoad={() => setLoadingDialogOpen(false)}
        applicationId={selectedApplication?.id}
      />
    </>
  )
}

export default ApplicationManagement
