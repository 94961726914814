import { useState, useEffect } from 'react'
import { portalFunctions } from 'core/functions/portalFunctions'
import { collection, getDocs } from '@firebase/firestore'
import { db } from 'config/firebase'

interface BankData {
  name: string
  template: string
  // Add other bank properties as needed
}

interface Bank extends BankData {
  id: string
}

export const useGetAllBanks = () => {
  const [banks, setBanks] = useState<Bank[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const fetchBanks = async () => {
    try {
      setIsLoading(true)
      setError(null)

      const response = await getDocs(collection(db, 'banks'))
      const fetchedBanks = response.docs.map(doc => {
        const data = doc.data() as BankData;
        return {
          id: doc.id,
          ...data
        };
      });
      
      console.log('Fetched banks:', fetchedBanks);
      setBanks(fetchedBanks)
    } catch (err) {
      console.error('Error fetching banks:', err)
      setError(err instanceof Error ? err.message : 'An unexpected error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchBanks()
  }, [])

  const refetch = () => {
    fetchBanks()
  }

  return {
    banks,
    isLoading,
    error,
    refetch,
  }
}
