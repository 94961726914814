// web/app/src/views/SuperAdmin/Management/LeadManagement/components/LeadForm.tsx
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Menu,
  useMediaQuery,
  Theme
} from '@mui/material'
import { Lead, LeadStatus, TruckRequest } from '@otw/models/leads/lead'
import { PrivateUserInfoType } from '@otw/models'
import { Vehicle } from '@otw/models/inventory/inventory'
import { useAuth } from 'contexts/AuthContext'
import { useLeads } from 'hooks/admin/useLeads'
import { useInventory } from 'hooks/inventory/useInventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useManageUsers } from 'hooks/useManageUsers'
import React, { useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { TruckRequestForm } from './TruckRequestForm'
import { UserSelector } from 'views/Shared/UserSelector'
import ClearIcon from '@mui/icons-material/Clear'
import MenuIcon from '@mui/icons-material/Menu'

interface LeadFormProps {
  selectedLead: Lead | null
  onClearSelection: () => void
  isCreating: boolean
  hideClientSearch?: boolean
  hideCancel?: boolean
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lead-tabpanel-${index}`}
      aria-labelledby={`lead-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ 
          p: isSmallScreen ? 1 : 3,
          px: isSmallScreen ? 0 : 3 
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const LeadForm: React.FC<LeadFormProps> = ({ 
  selectedLead, 
  onClearSelection, 
  isCreating,
  hideClientSearch = false,
  hideCancel = false
}) => {
  const { createLead, updateLead } = useLeads()
  const { vehicles } = useInventory("master_inventory")
  const { users } = useManageUsers()
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [tabValue, setTabValue] = useState(0);
  const [selectedUser, setSelectedUser] = useState<PrivateUserInfoType | null>(null);
  const [userApplications, setUserApplications] = useState<string[]>([]);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
    getValues,
  } = useForm<Lead>({
    defaultValues: selectedLead || {
      status: 'new' as LeadStatus,
      prevStatus: null,
      truckIds: [],
      applicationIds: [],
      followUpNotes: [],
      statusHistory: [],
      tags: [],
    },
  })

  // Get available sales reps (users with appropriate role)
  const salesReps = users?.filter(user => 
    user.role === 'admin' || user.role === 'babyAdmin'
  ) || []

  // Filter for active inventory only
  const activeVehicles = vehicles?.filter(vehicle => 
    vehicle.status === 'Available' || 
    vehicle.status === 'Pending Available' || 
    vehicle.status === 'Active Deal'
  ) || []

  const financingStatuses = [
    'No Credit',
    'Bad Credit',
    'Good Credit',
    'Excellent Credit',
    'Not Applicable'
  ]

  const leadStatuses: LeadStatus[] = [
    'new',
    'inProgress',
    'waitingFinance',
    'closed',
    'lost'
  ]

  // Add US states array
  const usStates = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setMobileMenuAnchor(null); // Close menu after selection
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleMobileMenuSelect = (index: number) => {
    setTabValue(index);
    setMobileMenuAnchor(null);
  };

  const handleUserSelect = (user: PrivateUserInfoType) => {
    setSelectedUser(user);
    setValue('customerName', `${user.first_name} ${user.last_name}`);
    setValue('email', user.email || '');
    setValue('phone', user.phone || '');
    if (user.company_name) {
      setValue('company', user.company_name);
    }
    if (user.address?.state) {
      setValue('location', user.address.state);
    }
    
    // Check if user has applications
    // @ts-ignore - applications property may exist on extended user types
    if (user.applications && Array.isArray(user.applications) && user.applications.length > 0) {
      // @ts-ignore - applications property may exist on extended user types
      const applicationIds = user.applications.map((app: any) => app.id);
      setUserApplications(applicationIds);
      
      // If creating a new lead, auto-populate application IDs
      if (isCreating) {
        setValue('applicationIds', applicationIds);
      }
    } else {
      setUserApplications([]);
    }
  };

  const handleClearUser = () => {
    setSelectedUser(null);
    setUserApplications([]);
    // Don't clear form values if editing an existing lead
    if (isCreating) {
      setValue('customerName', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('company', '');
      setValue('location', '');
      setValue('applicationIds', []);
    }
  };

  const onSubmit = async (data: Lead) => {
    // Create a copy of the data to modify
    const leadData = { ...data };
    
    // Ensure we preserve the truck types
    if (leadData.truckRequest && leadData.truckRequest.types) {
      console.log('Saving truck types:', leadData.truckRequest.types);
    }
    
    if (selectedLead) {
      await updateLead({ 
        id: selectedLead.id, 
        updates: leadData,
        statusChangeNote: data.status !== selectedLead.status 
          ? `Status updated from ${selectedLead.status} to ${data.status}`
          : undefined
      });
    } else {
      // Set the creator ID for new leads
      leadData.createdBy = userInfo?.id || '';
      await createLead(leadData);
    }
    
    onClearSelection();
  }

  // Format vehicle display for selection
  const formatVehicleDisplay = (vehicle: Vehicle) => {
    return `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''} - ${vehicle.stock_number || ''}`;
  };

  // Initialize form with default values
  useEffect(() => {
    if (selectedLead) {
      // Create a copy of the lead to modify
      const leadData = { ...selectedLead };
      
      // Check if we have truck types in the truckRequest
      if (leadData.truckRequest) {
        // Log the truck request data for debugging
        console.log('Lead truck request data:', leadData.truckRequest);
        
        // Ensure the types array exists
        if (!leadData.truckRequest.types) {
          leadData.truckRequest = {
            types: [],
            notes: ''
          };
        }
      }
          

      
      // Reset the form with the modified lead data
      reset(leadData);
      console.log('Reset form with lead data:', leadData);
      
      // If there's a selected user from the lead, set it
      if (selectedLead.email) {
        const matchingUser = users?.find(user => user.email === selectedLead.email);
        if (matchingUser) {
          setSelectedUser(matchingUser);
        }
      }
    } else {
      // Reset with default values for a new lead
      reset({
        status: 'new' as LeadStatus,
        prevStatus: null,
        truckIds: [],
        applicationIds: [],
        followUpNotes: [],
        statusHistory: [],
        tags: [],
        truckRequest: {
          types: [],
          notes: ''
        }
      });
    }
  }, [selectedLead, reset, users]);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, display: 'flex', alignItems: 'center' }}>
        {isSmallScreen ? (
          <>
            <Typography variant="body1" sx={{ flexGrow: 1, fontSize: '1rem'}}>
              {tabValue === 0 ? 'Basic Info' : 
               tabValue === 1 ? 'Truck Preferences' : 
               'Inventory & Applications'}
            </Typography>
            <IconButton 
              edge="end" 
              color="inherit" 
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={mobileMenuAnchor}
              open={Boolean(mobileMenuAnchor)}
              onClose={handleMobileMenuClose}
            >
              <MenuItem onClick={() => handleMobileMenuSelect(0)} selected={tabValue === 0} sx={{ fontSize: '0.8rem' }}>
                Basic Info
              </MenuItem>
              <MenuItem onClick={() => handleMobileMenuSelect(1)} selected={tabValue === 1} sx={{ fontSize: '0.8rem' }}>
                Truck Preferences
              </MenuItem>
              <MenuItem onClick={() => handleMobileMenuSelect(2)} selected={tabValue === 2} sx={{ fontSize: '0.8rem' }}>
                Inventory & Applications
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            variant={isMobile ? "fullWidth" : "standard"}
          >
            <Tab label="Basic Info" sx={{ fontSize: '1rem' }}/>
            <Tab label="Truck Preferences" sx={{ fontSize: '1rem' }}/>
            <Tab label="Inventory & Applications" sx={{ fontSize: '1rem' }}/>
          </Tabs>
        )}
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanel value={tabValue} index={0}>
          <Box ml={-2}>
            <Stack spacing={2}>
              {!hideClientSearch && !selectedUser && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Select Existing Client or Create New
                  </Typography>
                  <Box sx={{ position: 'relative' }}>
                    <UserSelector 
                      onUserSelect={handleUserSelect}
                      title="Select Client"
                      placeholder="Search clients..."
                      showCreateButton={true}
                    />
                  </Box>
                </Box>
              )}
              
              {selectedUser && !hideClientSearch && (
                <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1rem'} }}>
                      Selected Client: {selectedUser.first_name} {selectedUser.last_name}
                    </Typography>
                    <IconButton size="small" onClick={handleClearUser}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {selectedUser.email}
                  </Typography>
                  {selectedUser.company_name && (
                    <Typography variant="body2" color="text.secondary">
                      Company: {selectedUser.company_name}
                    </Typography>
                  )}
                  {userApplications.length > 0 && (
                    <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
                      {userApplications.length} application{userApplications.length !== 1 ? 's' : ''} found
                    </Typography>
                  )}
                </Box>
              )}
              
              <Divider sx={{ my: 1 }} />
              <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1.2rem'} }} gutterBottom>
                Client Information
              </Typography>
              
              <Box ml={-2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Customer Name'
                      {...register('customerName', { required: 'Customer name is required' })}
                      error={!!errors.customerName}
                      helperText={errors.customerName?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Company'
                      {...register('company', { required: 'Company is required' })}
                      error={!!errors.company}
                      helperText={errors.company?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Email'
                      type="email"
                      {...register('email', { 
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address"
                        }
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Phone'
                      {...register('phone', { required: 'Phone is required' })}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="location-label">Location</InputLabel>
                      <Controller
                        name="location"
                        control={control}
                        rules={{ required: 'Location is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="location-label"
                            label="Location"
                            error={!!errors.location}
                          >
                            {usStates.map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.location && (
                        <FormHelperText error>{errors.location?.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="assigned-rep-label">Assigned Representative</InputLabel>
                      <Controller
                        name="assignedRepId"
                        control={control}
                        rules={{ required: 'Representative is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="assigned-rep-label"
                            label="Assigned Representative"
                            error={!!errors.assignedRepId}
                          >
                            {salesReps.map((rep) => (
                              <MenuItem key={rep.id} value={rep.id}>
                                {rep.first_name} {rep.last_name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.assignedRepId && (
                        <FormHelperText error>{errors.assignedRepId.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="status-label">Lead Status</InputLabel>
                      <Controller
                        name="status"
                        control={control}
                        rules={{ required: 'Status is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="status-label"
                            label="Lead Status"
                            error={!!errors.status}
                          >
                            {leadStatuses.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status === 'new' ? 'New' : 
                                 status === 'inProgress' ? 'In Progress' : 
                                 status === 'waitingFinance' ? 'Waiting Finance' : 
                                 status === 'closed' ? 'Closed' : 'Lost'}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.status && (
                        <FormHelperText error>{errors.status.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="financing-status-label">Financing Status</InputLabel>
                      <Controller
                        name="financingStatus"
                        control={control}
                        rules={{ required: 'Financing status is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="financing-status-label"
                            label="Financing Status"
                            error={!!errors.financingStatus}
                          >
                            {financingStatuses.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.financingStatus && (
                        <FormHelperText error>{errors.financingStatus.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="time-frame-label">Time Frame</InputLabel>
                      <Controller
                        name="timeFrame"
                        control={control}
                        rules={{ required: 'Time frame is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="time-frame-label"
                            label="Time Frame"
                            error={!!errors.timeFrame}
                          >
                            <MenuItem value="7 days">7 days</MenuItem>
                            <MenuItem value="1 month">1 month</MenuItem>
                            <MenuItem value="2 months">2 months</MenuItem>
                            <MenuItem value="6 months">6 months</MenuItem>
                            <MenuItem value="1 year">1 year</MenuItem>
                          </Select>
                        )}
                      />
                      {errors.timeFrame && (
                        <FormHelperText error>{errors.timeFrame.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="priority"
                      control={control}
                      defaultValue="medium"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="priority-label">Priority</InputLabel>
                          <Select
                            {...field}
                            labelId="priority-label"
                            label="Priority"
                          >
                            <MenuItem value="low">Low</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="high">High</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="tags"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          freeSolo
                          options={[]}
                          renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Tags"
                              placeholder="Add tags"
                            />
                          )}
                          onChange={(_, data) => field.onChange(data)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <TruckRequestForm 
            register={register} 
            errors={errors.truckRequest as FieldErrors<TruckRequest>}
            control={control}
            setValue={setValue}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Stack spacing={3}>
            <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1.2rem'} }}>Link to Inventory</Typography>
            <Controller
              name="truckIds"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={activeVehicles || []}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      const vehicle = activeVehicles?.find(v => v.id === option);
                      return vehicle ? formatVehicleDisplay(vehicle) : option;
                    }
                    return formatVehicleDisplay(option);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Active Vehicles"
                      placeholder="Search vehicles"
                    />
                  )}
                  renderOption={(props, option) => {
                    // Ensure option is a Vehicle object, not a string
                    if (typeof option === 'string') {
                      const vehicleObj = activeVehicles?.find(v => v.id === option);
                      if (!vehicleObj) return null;
                      
                      return (
                        <li {...props}>
                          <Box>
                            <Typography variant="body1">
                              {formatVehicleDisplay(vehicleObj)}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              Status: {vehicleObj.status || 'N/A'} | Type: {vehicleObj.type || 'N/A'} | Condition: {vehicleObj.condition || 'N/A'}
                            </Typography>
                          </Box>
                        </li>
                      );
                    }
                    
                    return (
                      <li {...props}>
                        <Box>
                          <Typography variant="body1">
                            {formatVehicleDisplay(option)}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            Status: {option.status || 'N/A'} | Type: {option.type || 'N/A'} | Condition: {option.condition || 'N/A'}
                          </Typography>
                        </Box>
                      </li>
                    );
                  }}
                  onChange={(_, data) => {
                    const ids = data.map(item => {
                      return typeof item === 'string' ? item : item.id;
                    });
                    field.onChange(ids);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    const optionId = typeof option === 'string' ? option : option.id;
                    const valueId = typeof value === 'string' ? value : value.id;
                    return optionId === valueId;
                  }}
                  value={field.value.map((id: string) => {
                    return activeVehicles?.find(vehicle => vehicle.id === id) || id;
                  })}
                />
              )}
            />

            <Divider sx={{ my: 2 }} />

            <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1.2rem'} }}>Link to Applications</Typography>
            {userApplications.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Applications found for selected client:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {userApplications.map(appId => (
                    <Chip 
                      key={appId} 
                      label={`App ID: ${appId.substring(0, 8)}...`} 
                      color="primary" 
                      variant="outlined" 
                      size="small"
                    />
                  ))}
                </Box>
              </Box>
            )}
            <Controller
              name="applicationIds"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Application IDs (comma separated)"
                  placeholder="Enter application IDs"
                  onChange={(e) => {
                    const ids = e.target.value.split(',').map(id => id.trim()).filter(id => id)
                    field.onChange(ids)
                  }}
                  value={field.value.join(', ')}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Stack>
        </TabPanel>

        <Box mt={3} display="flex" justifyContent="space-between">
          {!hideCancel && (
            <Button 
              onClick={() => {
                onClearSelection();
              }}
            >
              <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1.2rem'}, color: 'error.main' }}>
                Cancel
              </Typography>
            </Button>
          )}
          <Button 
            type='submit' 
            variant='contained'
            sx={{ ml: hideCancel ? 'auto' : 0 }}
          >
            <Typography variant="body1" sx={{ fontSize: {xs: '0.8rem', md: '1.2rem'} }}>
              {selectedLead ? 'Update Lead' : 'Create Lead'}
            </Typography>
          </Button>
        </Box>
      </form>
    </Box>
  )
}
