import { Box, Button, Chip, IconButton, Switch, Theme, Tooltip } from '@mui/material'
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ICellEditorParams,
  CellValueChangedEvent,
  ValueParserParams,
  GridApi,
  CellClassParams,
} from 'ag-grid-enterprise'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import React from 'react'
// import truckIcon from 'assets/Icon-OpticTruckWorks-01.png'
import EditIcon from '@mui/icons-material/Edit'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import NoteIcon from '@mui/icons-material/Note'
import {
  boxTruck as boxTruckIcon,
  cargoVan as cargoVanIcon,
  cutaway as cutawayIcon,
  stepVan as stepVanIcon,
} from 'assets'
import { calculateProfit, sanitizeNumber, formatPhoneNumber } from 'core/utils/inventoryUtils'
import AddLinkDialog from '../Dialogs/AddLinkDialog'
import NotesDialog from '../Dialogs/NotesDialog'
import VehicleForm from '../Forms/VehicleForm'
import { useInventory } from 'hooks/inventory/useInventory'
import { Vehicle } from '@otw/models/inventory'
import { getDealQualityInfo } from 'hooks/graybook/useCalculateDeal'
import {
  vehicleTypeOptions,
  vehicleFuelOptions,
  getVehicleSizeOptions,
  makeOptions,
  getVehicleModelOptions,
} from 'core/constants/inventory'
import states from 'states-us'

const vehicleStatusOptions = [
  'Pending Available',
  'Available',
  'Pending Sale',
  'Sold',
  'Sold elsewhere',
  'Active Deal',
]

type VehicleColDef = ColDef<Vehicle & { vehicleIcon?: string }>

export const formatCurrency = (value: number | undefined | null): string => {
  if (value === undefined || value === null || isNaN(value)) return ''
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
}

const formatDate = (value: string | Date | undefined | null): string => {
  if (!value) return ''
  try {
    const date = typeof value === 'string' ? new Date(value) : value
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
  } catch (e) {
    return ''
  }
}
interface InventoryColumnsProps {
  isAdmin: boolean
  isOwner: boolean
  theme: Theme
  inventoryCollection: 'master_inventory' | 'shield_inventory'
}

export const getInventoryColumns = ({
  isAdmin,
  isOwner,
  inventoryCollection,
}: InventoryColumnsProps): VehicleColDef[] => {
  const columns: VehicleColDef[] = [
    {
      colId: 'vehicleIcon',
      field: 'vehicleIcon',
      headerName: 'Icon',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      editable: false,
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressSizeToFit: true,
      enableRowGroup: false,
      enablePivot: false,
      lockPosition: true,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px',
        overflow: 'hidden',
      },
      cellRenderer: (params: ICellRendererParams) => {
        const getDefaultIcon = (type: string | undefined) => {
          if (!type) return stepVanIcon // Return default icon if type is undefined

          const lowerType = type.toLowerCase()
          switch (lowerType) {
            case 'cutaway':
              return cutawayIcon
            case 'step van':
              return stepVanIcon
            case 'box truck':
              return boxTruckIcon
            case 'cargo van':
              return cargoVanIcon
            default:
              return stepVanIcon
          }
        }

        // Add null check for params.data
        if (!params.data) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                p: 0.25,
                overflow: 'hidden',
                borderRadius: 1,
              }}
            >
              <Box
                component='img'
                src={stepVanIcon}
                alt='Default Truck'
                sx={{
                  width: '48px',
                  height: '48px',
                  objectFit: 'contain',
                  borderRadius: 1,
                  cursor: 'pointer',
                  display: 'block',
                  p: 0.25,
                  mixBlendMode: 'multiply',
                }}
              />
            </Box>
          )
        }

        const icon = getDefaultIcon(params.data?.type)
        const [openVehicleForm, setOpenVehicleForm] = React.useState(false)
        const { updateDocument } = useInventory(inventoryCollection)

        // Get the latest data whenever the form opens
        const currentRowData = React.useMemo(() => {
          if (!openVehicleForm) return null
          return params.api.getRowNode(params.data.id)?.data || params.data
        }, [openVehicleForm, params.data, params.api])

        const handleSubmit = async (data: { [key: string]: string | number | boolean }) => {
          const sanitizedData = { ...data }
          sanitizedData.miles = sanitizeNumber(sanitizedData.miles)
          sanitizedData.optic_list_price = sanitizeNumber(sanitizedData.optic_list_price)
          sanitizedData.seller_asking_price = sanitizeNumber(sanitizedData.seller_asking_price)

          const newValues = Object.fromEntries(
            Object.entries(sanitizedData).filter(([, value]) => value !== undefined),
          )

          if (newValues.optic_list_price && newValues.seller_asking_price) {
            newValues.profit = calculateProfit(
              String(newValues.seller_asking_price),
              String(newValues.optic_list_price),
            )
          }

          try {
            // Update the grid row data
            const node = params.node
            if (node) {
              node.setData({ ...params.data, ...newValues })
            }
            updateDocument({
              id: params.data.id,
              data: newValues,
            })
            setOpenVehicleForm(false)
          } catch (error) {
            setOpenVehicleForm(false)
            console.error('Error updating vehicle:', error)
          }
          setOpenVehicleForm(false)
        }

        return (
          <>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                p: 0.25,
                overflow: 'hidden',
                borderRadius: 1,
              }}
            >
              <Box
                component='img'
                src={icon}
                alt='Default Truck'
                onDoubleClick={() => setOpenVehicleForm(true)}
                sx={{
                  width: '48px',
                  height: '48px',
                  objectFit: 'contain',
                  borderRadius: 1,
                  cursor: 'pointer',
                  display: 'block',
                  p: 0.25,
                  mixBlendMode: 'multiply',
                }}
              />
            </Box>

            {openVehicleForm && currentRowData && (
              <VehicleForm
                inventoryCollection={inventoryCollection}
                open={openVehicleForm}
                vehicle={currentRowData}
                onClose={() => setOpenVehicleForm(false)}
                onSubmit={handleSubmit}
                generateStockNumber={() => currentRowData.stock_number}
                isEdit={true}
              />
            )}
          </>
        )
      },
    } as VehicleColDef,
    {
      field: 'type',
      headerName: 'Type',
      filter: 'agMultiColumnFilter',
      cellDataType: false,
      width: 100,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [...vehicleTypeOptions, 'Unknown'],
      },
      onCellValueChanged: (params: CellValueChangedEvent) => {
        // Use the handler from context to handle dependent field resets
        if (params.context?.handleFieldChangeWithDependency) {
          params.context.handleFieldChangeWithDependency(params, 'Type', 'Size', getVehicleSizeOptions)
        }
      },
    } as VehicleColDef,
    {
      field: 'stock_number',
      headerName: 'Stock Number',
      filter: 'agTextColumnFilter',
      width: 140,
      editable: false,
      suppressSizeToFit: true,
      cellRenderer: 'agGroupCellRenderer',
      // cellRendererParams: {
      //   innerRenderer: (params: ICellRendererParams) => <Typography>{params.value}</Typography>,
      // },
    } as VehicleColDef,
    {
      field: 'who',
      headerName: 'Who',
      filter: 'agMultiColumnFilter',
      width: 120,
    } as VehicleColDef,
    {
      field: 'condition',
      headerName: 'Cond.',
      filter: 'agMultiColumnFilter',
      width: 120,
      minWidth: 70,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Used', 'New'],
      },
    } as VehicleColDef,
    {
      field: 'year',
      headerName: 'Year',
      filter: 'agNumberColumnFilter',
      minWidth: 70,
      valueGetter: (params: ValueGetterParams) => {
        const value = params.data?.year
        return value === undefined || value === null ? null : Number(value)
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined || params.value === null) return ''
        return `${params.value}`
      },
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
      },
    } as VehicleColDef,
    {
      field: 'make',
      headerName: 'Make',
      filter: 'agMultiColumnFilter',
      width: 120,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [...makeOptions, 'Unknown'],
      },
      onCellValueChanged: (params: CellValueChangedEvent) => {
        // Use the handler from context to handle dependent field resets
        if (params.context?.handleFieldChangeWithDependency) {
          params.context.handleFieldChangeWithDependency(params, 'Make', 'Model', getVehicleModelOptions)
        }
      },
    } as VehicleColDef,
    {
      field: 'model',
      headerName: 'Model',
      filter: 'agMultiColumnFilter',
      width: 120,
      minWidth: 90,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params: ICellEditorParams) => {
        const make = params.data?.make || ''
        return {
          values: [...getVehicleModelOptions(make), 'Unknown'],
        }
      },
    } as VehicleColDef,
    {
      field: 'size',
      headerName: 'Size',
      filter: 'agMultiColumnFilter',
      width: 100,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params: ICellEditorParams) => {
        const type = params.data?.type || ''
        return {
          values: [...getVehicleSizeOptions(type), 'Unknown'],
        }
      },
    } as VehicleColDef,
    {
      field: 'miles',
      headerName: 'Miles',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined || params.value === null || isNaN(params.value)) return ''
        return `${params.value.toLocaleString()} mi`
      },
      valueGetter: (params: ValueGetterParams) => {
        const value = params.data?.miles
        return value === undefined || value === null || isNaN(value) ? null : Number(value)
      },
      cellClass: 'ag-left-aligned-cell',
      aggFunc: 'avg',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,
    {
      field: 'dealQuality',
      minWidth: 160,
      headerName: 'Deal Quality',
      filter: 'agMultiColumnFilter',
      editable: false,
      cellRenderer: (params: ValueFormatterParams) => {
        const value = params.value
        if (!value) return ''
        const { label, color } = getDealQualityInfo(value)
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', // Added to center horizontally
              height: '100%',
            }}
          >
            <Chip
              label={label}
              color={color}
              size='small'
              variant='outlined'
              sx={{
                minWidth: 70,
                height: 20,
                '& .MuiChip-label': {
                  px: 0.5,
                  fontSize: '0.75rem',
                },
              }}
            />
          </Box>
        )
      },
    } as VehicleColDef,
    {
      field: 'seller_asking_price',
      headerName: 'Seller Asking Price',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      valueGetter: (params: ValueGetterParams) => {
        const value = parseFloat(params.data?.seller_asking_price || '0')
        return isNaN(value) ? null : value
      },
      cellClass: 'ag-left-aligned-cell',
      aggFunc: 'avg',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,
    {
      field: 'optic_list_price',
      headerName: 'List Price',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      valueGetter: (params: ValueGetterParams) => {
        const value = parseFloat(params.data?.optic_list_price || '0')
        return isNaN(value) ? null : value
      },
      cellClass: 'ag-left-aligned-cell',
      aggFunc: 'avg',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,
    {
      field: 'profit',
      headerName: 'Profit',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<Vehicle>) => {
        if (!params.data || !params.data.optic_list_price || !params.data.seller_asking_price) return null

        const profit = params.data.optic_list_price - params.data.seller_asking_price

        const extraCost = (params.data.repair_cost || 0) + (params.data.shipping_cost || 0)
        return profit - extraCost
      },
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      cellStyle: (params: ValueFormatterParams) => {
        const opticPrice = parseFloat(params.data?.optic_list_price || '0')
        const sellerPrice = parseFloat(params.data?.seller_asking_price || '0')

        if (params.value < 0 && opticPrice !== 0) return { color: 'red' }
        if (opticPrice === 0 && sellerPrice > 0) return { color: 'blue' }
        return null
      },
      cellClass: (params: ValueFormatterParams) => {
        const opticPrice = parseFloat(params.data?.optic_list_price || '0')
        if (opticPrice === 0) return 'profit-unknown'
        if (params.value > 0) return 'profit-positive'
        if (params.value < 0) return 'profit-negative'
        return ''
      },
      aggFunc: 'avg',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,
    {
      field: 'vin',
      headerName: 'VIN',
      filter: 'agMultiColumnFilter',
      flex: 1,
      minWidth: 200,
      editable: true,
    } as VehicleColDef,
    {
      field: 'link',
      headerName: 'Link',
      filter: 'agMultiColumnFilter',
      width: 120,
      cellRenderer: (params: ICellRendererParams) => {
        const [dialogOpen, setDialogOpen] = React.useState(false)

        const handleAddLink = (newLink: string) => {
          const node = params.node
          if (node) {
            node.setDataValue('link', newLink)
          }
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 0.5,
              width: '100%',
              height: '100%',
              padding: '2px',
            }}
          >
            <Button
              variant='text'
              size='small'
              sx={{
                minWidth: '40px',
                padding: '2px 6px',
                fontSize: '0.75rem',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  opacity: 0.9,
                },
              }}
              onClick={() => {
                if (params.value) {
                  window.open(params.value, '_blank')
                } else {
                  setDialogOpen(true)
                }
              }}
            >
              {params.value ? 'View' : 'Add'}
            </Button>

            {params.value && (
              <IconButton
                size='small'
                onClick={() => setDialogOpen(true)}
                sx={{
                  padding: 0,
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    opacity: 0.9,
                  },
                }}
              >
                <EditIcon sx={{ fontSize: 14 }} />
              </IconButton>
            )}

            <AddLinkDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSubmit={handleAddLink}
              currentLink={params.value}
            />
          </Box>
        )
      },
      editable: false,
    } as VehicleColDef,
    {
      field: 'photos',
      headerName: 'Photo',
      width: 50,
      maxWidth: 80,
      editable: false,
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressSizeToFit: true,
      cellRenderer: (params: ICellRendererParams) => {
        // Add null check for params.data
        if (!params.data) {
          return (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title='No data'>
                <NoPhotographyIcon
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'text.disabled',
                  }}
                />
              </Tooltip>
            </Box>
          )
        }

        const { data: photos } = usePhotosForInventoryObject('master_inventory', params.data.id, {
          onlyMainPhoto: true,
          enabled: params.data.photo_storage_path !== '',
        })

        return !photos || photos.length === 0 ? (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tooltip title='Photos needed!'>
              <NoPhotographyIcon
                sx={{
                  width: 20,
                  height: 20,
                  color: 'primary.main',
                }}
              />
            </Tooltip>
          </Box>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component='img'
              src={photos.find(p => p.position === 'main')?.url || photos[0]?.url}
              alt='Vehicle'
              sx={{
                width: 40,
                height: 40,
                objectFit: 'contain',
                borderRadius: 2,
              }}
            />
          </div>
        )
      },
    } as VehicleColDef,
    {
      field: 'id',
      headerName: 'ID',
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
        suppressAndOrCondition: true,
      },
      flex: 1,
      minWidth: 100,
      hide: true,
      editable: false,
    } as VehicleColDef,
    {
      field: 'date_added',
      headerName: 'Date Added',
      filter: 'agDateColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatDate(params.value),
      valueGetter: (params: ValueGetterParams) => {
        if (!params.data?.date_added) return null
        return new Date(params.data.date_added)
      },
      sortable: true,
      sort: 'desc',
      editable: false,
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
          if (!cellValue) return -1
          const cellDate = new Date(cellValue)
          if (cellDate < filterLocalDateAtMidnight) return -1
          if (cellDate > filterLocalDateAtMidnight) return 1
          return 0
        },
        applyButton: true,
        clearButton: true,
      },
    } as VehicleColDef,
    {
      field: 'fuel',
      headerName: 'Fuel',
      hide: true,
      minWidth: 90,
      filter: 'agMultiColumnFilter',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: vehicleFuelOptions,
      },
    } as VehicleColDef,
    {
      field: 'location',
      headerName: 'Location',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: states.map(state => state.abbreviation),
        cellRenderer: (params: any) => {
          const stateObj = states.find(state => state.abbreviation === params.value)
          return stateObj ? `${stateObj.name} (${stateObj.abbreviation})` : params.value
        },
        suppressMiniFilter: false, // Enable search in filter
        suppressSelectAll: false,
      },
      width: 100, // Reduced width since we're only showing abbreviation
      // Use a rich select editor for better search functionality
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: states.map(state => state.abbreviation),
        cellHeight: 40,
        searchDebounceDelay: 500,
        formatValue: (value: string) => {
          const stateObj = states.find(state => state.abbreviation === value)
          return stateObj ? `${stateObj.name} (${stateObj.abbreviation})` : value
        },
      },
      // Just show the abbreviation in the grid cell to save space
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value || ''
      },
    } as VehicleColDef,
    {
      field: 'sold_date',
      headerName: 'Sold Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatDate(params.value),
      valueGetter: (params: ValueGetterParams) => {
        if (!params.data?.sold_date) return null
        return new Date(params.data.sold_date)
      },
      editable: params => {
        // Only allow owners or admins to edit sold_date when vehicle is already sold
        const isVehicleSold = params.data?.status?.toLowerCase() === 'sold'
        return isOwner || isAdmin || !isVehicleSold
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
          if (!cellValue) return -1
          const cellDate = new Date(cellValue)
          if (cellDate < filterLocalDateAtMidnight) return -1
          if (cellDate > filterLocalDateAtMidnight) return 1
          return 0
        },
        applyButton: true,
        clearButton: true,
      },
    } as VehicleColDef,
    {
      field: 'sold_to_user.name',
      headerName: 'Sold To',
      filter: 'agMultiColumnFilter',
      width: 150,
      valueGetter: params => {
        return params.data?.sold_to_user?.name || ''
      },
    } as VehicleColDef,
    {
      field: 'sold_by_user.name',
      headerName: 'Sold By',
      filter: 'agMultiColumnFilter',
      width: 150,
      valueGetter: params => {
        return params.data?.sold_by_user?.name || ''
      },
    } as VehicleColDef,
    {
      field: 'truck_notes',
      headerName: 'Truck Notes',
      width: 100,
      editable: false,
      suppressKeyboardEvent: () => true,
      cellRenderer: (params: ICellRendererParams) => {
        const [dialogOpen, setDialogOpen] = React.useState(false)

        const handleSave = (newNotes: string) => {
          const node = params.node
          if (node) {
            node.setDataValue('truck_notes', newNotes)
          }
        }

        const handleClick = (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          setDialogOpen(true)
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Tooltip title={params.value ? 'Edit notes' : 'Add notes'}>
              <IconButton
                size='small'
                onClick={handleClick}
                sx={{
                  color: params.value ? 'primary.main' : 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <NoteIcon />
              </IconButton>
            </Tooltip>

            <NotesDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSave={handleSave}
              initialValue={params.value || ''}
              title='Truck Notes'
            />
          </Box>
        )
      },
      tooltipValueGetter: params => params.value || 'No notes',
    } as VehicleColDef,
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      filter: 'agMultiColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatPhoneNumber(params.value),
      width: 150,
      cellStyle: { textAlign: 'left' },
    } as VehicleColDef,
    {
      field: 'email',
      headerName: 'Email',
      filter: 'agMultiColumnFilter',
      width: 200,
    } as VehicleColDef,
    {
      field: 'follow_up_notes',
      headerName: 'Follow Up Notes',
      filter: 'agMultiColumnFilter',

      tooltipField: 'follow_up_notes',
      hide: true,
    } as VehicleColDef,
    {
      field: 'entered_by',
      headerName: 'Entered By',
      filter: 'agMultiColumnFilter',
      width: 150,
    } as VehicleColDef,
    {
      field: 'payoff_amount',
      headerName: 'Payoff Amount',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      valueGetter: (params: ValueGetterParams) => {
        const value = parseFloat(params.data?.payoff_amount || '0')
        return isNaN(value) ? null : value
      },
      cellClass: 'ag-left-aligned-cell',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,
    {
      field: 'repair_cost',
      headerName: 'Repair Cost',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      valueGetter: (params: ValueGetterParams) => {
        const value = parseFloat(params.data?.repair_cost || '0')
        return isNaN(value) ? null : value
      },
      cellClass: 'ag-left-aligned-cell',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,
    {
      field: 'shipping_cost',
      headerName: 'Shipping Cost',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      valueGetter: (params: ValueGetterParams) => {
        const value = parseFloat(params.data?.shipping_cost || '0')
        return isNaN(value) ? null : value
      },
      cellClass: 'ag-left-aligned-cell',
      filterParams: {
        applyButton: true,
        clearButton: true,
        debounceMs: 200,
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
        ],
      },
    } as VehicleColDef,

    {
      field: 'show_on_website',
      headerName: 'On Website',
      filter: 'agMultiColumnFilter',
      width: 120,
      minWidth: 120,
      suppressSizeToFit: true,
      editable: false,
      cellRenderer: (params: ICellRendererParams) => (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 8px',
          }}
        >
          <Switch
            checked={params.value}
            onChange={() => {
              const node = params.node
              if (node) {
                // If vehicle is sold, don't allow setting show_on_website to true
                if (params.data.status?.toLowerCase() === 'sold' && !params.value) {
                  return
                }
                node.setDataValue('show_on_website', !params.value)
              }
            }}
            color='primary'
            size='small'
          />
        </div>
      ),
      cellClass: (params: any) => (params.value ? 'show-on-website' : 'hide-on-website'),
    } as VehicleColDef,
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agMultiColumnFilter',

      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: vehicleStatusOptions,
      },
      cellClass: (params: any) => {
        if (params.value) {
          return `status-${params.value.toLowerCase().replace(' ', '-')}`
        }
        return 'status-unknown'
      },
      // filter: 'agSetColumnFilter',
      // filterParams: {
      //   values: vehicleStatusOptions,
      //   defaultToNothingSelected: false,
      //   excludeValues: ['Sold', 'Sold elsewhere']
      // },
      // defaultFilter: {
      //   type: 'notIn',
      //   values: ['Sold', 'Sold elsewhere']
      // }
    } as VehicleColDef,
    {
      field: 'cameras',
      headerName: 'Cameras',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['yes', 'no', 'unknown'],
      },
      width: 100,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['yes', 'no', 'unknown'],
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) return 'unknown'
        return params.value.toString()
      },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.value || 'unknown'
        let color = 'text.secondary'
        let icon = '?'

        if (value === 'yes') {
          color = 'success.main'
          icon = '✓'
        } else if (value === 'no') {
          color = 'error.main'
          icon = '✗'
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color,
            }}
          >
            {icon}
          </Box>
        )
      },
    } as VehicleColDef,
    {
      field: 'shelves',
      headerName: 'Shelves',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['yes', 'no', 'unknown'],
      },
      width: 100,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['yes', 'no', 'unknown'],
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) return 'unknown'
        return params.value.toString()
      },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.value || 'unknown'
        let color = 'text.secondary'
        let icon = '?'

        if (value === 'yes') {
          color = 'success.main'
          icon = '✓'
        } else if (value === 'no') {
          color = 'error.main'
          icon = '✗'
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color,
            }}
          >
            {icon}
          </Box>
        )
      },
    } as VehicleColDef,
    {
      field: 'favorite',
      headerName: 'Favorite',
      filter: 'agMultiColumnFilter',
      width: 100,
      editable: false,
      cellRenderer: (params: ICellRendererParams) => {
        const isFavorite = params.value === true;
        
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                const newValue = !isFavorite;
                const node = params.node;
                if (node) {
                  // Simply update the cell value in the grid
                  // The actual document update will be handled by AG Grid's event handlers
                  node.setDataValue('favorite', newValue);
                }
              }}
              size="small"
              sx={{ 
                color: isFavorite ? 'warning.main' : 'text.secondary',
                '&:hover': {
                  backgroundColor: 'rgba(255, 152, 0, 0.08)'
                }
              }}
            >
              {isFavorite ? '★' : '☆'}
            </IconButton>
          </Box>
        );
      },
    } as VehicleColDef,
  ]

  if (isOwner || isAdmin) {
    return columns
  }

  // Filter columns based on admin status if needed
  const restrictedColumns = [
    'who',
    'vin',
    'seller_asking_price',
    'profit',
    'phone_number',
    'show_on_website',
    'dealQuality',
  ]
  return columns.filter(col => !restrictedColumns.includes(col.field as string))
}
