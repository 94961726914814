import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { db } from 'config/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { BusinessSubmission } from '@otw/models';

export const useBusinessSubmissions = () => {
  const queryClient = useQueryClient();

  // Fetch business submissions
  const businessSubmissions = useQuery({
    queryKey: ['business_submissions'],
    queryFn: async (): Promise<BusinessSubmission[]> => {
      const submissionsRef = collection(db, 'business_submissions');
      const snapshot = await getDocs(submissionsRef);
      const submissions = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as BusinessSubmission[];
      return submissions;
    },
  });

  // Mutation to update the "show" field
  const toggleShowOnWebsite = useMutation({
    mutationFn: async ({ id, show }: { id: string, show: boolean }) => {
      const submissionRef = doc(db, 'business_submissions', id);
      await updateDoc(submissionRef, { show });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['business_submissions'] });
    },
  });

  // Example mutation to update a business submission
  const updateBusinessSubmission = useMutation({
    mutationFn: async (submission: BusinessSubmission) => {
      const submissionRef = doc(db, 'business_submissions', submission.id);
      const docToUpdate = {
        ...submission,
       // id: submission.id,
      }
      await updateDoc(submissionRef, docToUpdate);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['business_submissions'] });
    },
  });

  return {
    businessSubmissions: businessSubmissions.data,
    businessSubmissionsError: businessSubmissions.error,
    businessSubmissionsIsLoading: businessSubmissions.isLoading,
    updateBusinessSubmission,
    toggleShowOnWebsite,
  };
};
