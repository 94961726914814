import { useState } from 'react';
import { portalFunctions } from 'core/functions/portalFunctions';

interface GenerateApplicationForUserParams {
  userId: string;
  bankId: string;
}

export const useGenerateApplicationForUser = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [applicationUrl, setApplicationUrl] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const generateApplicationForUser = async ({ userId, bankId }: GenerateApplicationForUserParams) => {
    try {
      setIsGenerating(true);
      setError(null);
      setApplicationUrl(null);
      setIsSuccess(false);

      const response = await portalFunctions.admin.generateApplicationForUser(userId, bankId);
      
      if (response.url) {
        setApplicationUrl(response.url);
        setIsSuccess(true);
      } else {
        setError('Failed to generate application: No URL returned');
      }
    } catch (err) {
      console.error('Error generating application:', err);
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsGenerating(false);
    }
  };

  const reset = () => {
    setError(null);
    setApplicationUrl(null);
    setIsSuccess(false);
  };

  return {
    generateApplicationForUser,
    isGenerating,
    error,
    applicationUrl,
    isSuccess,
    reset
  };
}; 