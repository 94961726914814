import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseIcon from '@mui/icons-material/Close'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  alpha,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
  DialogActions,
  TextField,
  InputAdornment,
} from '@mui/material'
import { PrivateUserInfoType, Vehicle } from '@otw/models'
import { ColDef } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import { AgGridReact } from 'ag-grid-react'
import { formatDateTime, formatPrice } from 'core/utils/inventoryUtils'
import dayjs from 'dayjs'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useMemo, useState } from 'react'
import { VehicleTypeDistribution } from './VehicleTypeDistribution'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { MobileQuickFilter } from '../../../shared/MobileQuickFilter'
import { getDealQualityInfo } from 'hooks/graybook'
import { getFullNameFromPrefix } from '../../../../dashboardTools'

interface StockOverviewProps {
  stockVehicles: Vehicle[],
  dateRange?: {
    start: Date
    end: Date
  }
}

interface StockMetrics {
  prefix: string
  totalCount: number
  activeDealsCount: number
  availableCount: number
  potentialProfit: number
  averageProfit: number
  dealQuality: Record<string, number>
  activeDealProfit: number
  totalCost: number
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`stock-tabpanel-${index}`}
      aria-labelledby={`stock-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  )
}

export const StockOverview: React.FC<StockOverviewProps> = ({ stockVehicles, dateRange }) => {
  const [tabValue, setTabValue] = useState(0)
  const [selectedPrefix, setSelectedPrefix] = useState<string | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [quickFilterText, setQuickFilterText] = useState('')
  const [quickFilterDialogOpen, setQuickFilterDialogOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
  const theme = useTheme()
  const isMobile = useIsMobile()

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleViewVehicles = (prefix: string) => {
    setSelectedPrefix(prefix)
    setDialogOpen(true)
  }

  // Group vehicles by stock number prefix
  const stockMetrics = useMemo(() => {
    // Extract stock number prefixes by taking first 2 characters
    const prefixMap: Record<string, StockMetrics> = {}

    // Initialize with "All" category
    prefixMap['All'] = {
      prefix: 'All',
      totalCount: 0,
      activeDealsCount: 0,
      availableCount: 0,
      potentialProfit: 0,
      averageProfit: 0,
      dealQuality: {
        great: 0,
        good: 0,
        average: 0,
        belowAverage: 0,
        poor: 0,
      },
      activeDealProfit: 0,
      totalCost: 0,
    }

    stockVehicles.forEach(vehicle => {
      const stockNumber = vehicle.stock_number || ''
      const prefix = stockNumber.length >= 2 ? stockNumber.substring(0, 2).toUpperCase() : 'Other'

      // Initialize prefix if not exists
      if (!prefixMap[prefix]) {
        prefixMap[prefix] = {
          prefix,
          totalCount: 0,
          activeDealsCount: 0,
          availableCount: 0,
          potentialProfit: 0,
          averageProfit: 0,
          dealQuality: {
            great: 0,
            good: 0,
            average: 0,
            belowAverage: 0,
            poor: 0,
          },
          activeDealProfit: 0,
          totalCost: 0,
        }
      }

      // Calculate total costs - split into purchase price and additional costs
      const buyPrice = vehicle.seller_asking_price || 0
      const additionalCosts =
        (vehicle.repair_cost || 0) +
        (vehicle.shipping_cost || 0) +
        (vehicle.rental_cost || 0) +
        (vehicle.other_cost || 0)

      // Calculate profit for this vehicle (list price - buy price - additional costs)
      const profit = (vehicle.optic_list_price || 0) - buyPrice - additionalCosts

      // Update metrics for this prefix
      prefixMap[prefix].totalCount++
      prefixMap['All'].totalCount++

      // Add to costs regardless of status
      prefixMap[prefix].totalCost += buyPrice + additionalCosts
      prefixMap['All'].totalCost += buyPrice + additionalCosts

      // We only track deal quality for active deals now
      if (vehicle.status === 'Active Deal' ) {
        prefixMap[prefix].activeDealsCount++
        prefixMap['All'].activeDealsCount++

        // Add to active deal profit
        prefixMap[prefix].activeDealProfit += profit
        prefixMap['All'].activeDealProfit += profit

        // We only add to potential profit for active deals
        prefixMap[prefix].potentialProfit += profit
        prefixMap['All'].potentialProfit += profit

        // Use the vehicle's dealQuality directly
        const quality = vehicle.dealQuality || 'poor';
        prefixMap[prefix].dealQuality[quality] = (prefixMap[prefix].dealQuality[quality] || 0) + 1
        prefixMap['All'].dealQuality[quality] = (prefixMap['All'].dealQuality[quality] || 0) + 1
      } else if (vehicle.status === 'Available' || vehicle.status === 'Pending Available') {
        prefixMap[prefix].availableCount++
        prefixMap['All'].availableCount++
      }
    })

    // Calculate average profit - now based only on active deals
    Object.values(prefixMap).forEach(metrics => {
      metrics.averageProfit = metrics.activeDealsCount > 0 ? metrics.activeDealProfit / metrics.activeDealsCount : 0
    })

    return Object.values(prefixMap).sort((a, b) => {
      // Sort by total count, but keep "All" at the top
      if (a.prefix === 'All') return -1
      if (b.prefix === 'All') return 1
      return b.totalCount - a.totalCount
    })
  }, [])

  // Format currency
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)
  }

  // Get selected prefix data
  const selectedPrefixData = useMemo(() => {
    if (!selectedPrefix) return null
    return stockMetrics.find(m => m.prefix === selectedPrefix) || null
  }, [selectedPrefix, stockMetrics])

  // Get vehicles for selected prefix
  const prefixVehicles = useMemo(() => {
    if (!selectedPrefix) return []

    // If "All" is selected, return all vehicles
    if (selectedPrefix === 'All') return stockVehicles

    // Otherwise, filter vehicles by prefix
    const prefixRegex = new RegExp(`^${selectedPrefix}`, 'i')
    return stockVehicles.filter(v => v.stock_number && prefixRegex.test(v.stock_number))
  }, [selectedPrefix, stockVehicles])

  // Filter vehicles based on quick filter text and selected status
  const filteredVehicles = useMemo(() => {
    let filtered = prefixVehicles

    // Apply text filter
    if (quickFilterText) {
      const searchText = quickFilterText.toLowerCase()
      filtered = filtered.filter(vehicle => {
        return (
          vehicle.stock_number?.toLowerCase().includes(searchText) ||
          vehicle.make?.toLowerCase().includes(searchText) ||
          vehicle.model?.toLowerCase().includes(searchText) ||
          vehicle.status?.toLowerCase().includes(searchText) ||
          vehicle.type?.toLowerCase().includes(searchText) ||
          vehicle.size?.toLowerCase().includes(searchText)
        )
      })
    }

    // Apply status filter
    if (selectedStatus) {
      filtered = filtered.filter(vehicle => vehicle.status === selectedStatus)
    }

    return filtered
  }, [prefixVehicles, quickFilterText, selectedStatus])

  // AgGrid column definitions
  const columnDefs = useMemo<ColDef<Vehicle>[]>(() => {
    // Define all columns that will be shown on both mobile and desktop
    return [
      {
        field: 'stock_number',
        headerName: 'Stock #',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
      },
      {
        field: 'make',
        headerName: 'Make',
        sortable: true,
        filter: true,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'model',
        headerName: 'Model',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
        cellRenderer: (params: any) => {
          const status = params.value
          let color = 'default'

          if (status === 'Available') color = 'success'
          else if (status === 'Pending Sale' || status === 'Active Deal') color = 'warning'
          else if (status === 'Sold') color = 'primary'

          return status ? <Chip size='small' label={status} color={color as any} /> : ''
        },
      },
      {
        field: 'optic_list_price',
        headerName: 'List Price',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
        valueFormatter: (params: any) => formatPrice(params.value || 0),
      },
      {
        field: 'seller_asking_price',
        headerName: 'Buy Price',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
        valueFormatter: (params: any) => formatPrice(params.value || 0),
      },
      {
        headerName: 'Additional Costs',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
        valueGetter: (params: any) => {
          const repairCost = params.data.repair_cost || 0
          const shippingCost = params.data.shipping_cost || 0
          const rentalCost = params.data.rental_cost || 0
          const otherCost = params.data.other_cost || 0
          return repairCost + shippingCost + rentalCost + otherCost
        },
        valueFormatter: (params: any) => formatPrice(params.value || 0),
        tooltipValueGetter: (params: any) => {
          const repairCost = params.data.repair_cost || 0
          const shippingCost = params.data.shipping_cost || 0
          const rentalCost = params.data.rental_cost || 0
          const otherCost = params.data.other_cost || 0

          return `Repair: ${formatPrice(repairCost)}\nShipping: ${formatPrice(shippingCost)}\nRental: ${formatPrice(rentalCost)}\nOther: ${formatPrice(otherCost)}`
        },
      },
      {
        field: 'profit',
        headerName: 'Profit',
        sortable: true,
        filter: true,
        minWidth: 110,
        flex: 1,
        valueGetter: (params: any) => {
          const listPrice = params.data.optic_list_price || 0
          const buyPrice = params.data.seller_asking_price || 0
          const repairCost = params.data.repair_cost || 0
          const shippingCost = params.data.shipping_cost || 0
          const rentalCost = params.data.rental_cost || 0
          const otherCost = params.data.other_cost || 0
          const totalCosts = repairCost + shippingCost + rentalCost + otherCost

          return listPrice - buyPrice - totalCosts
        },
        valueFormatter: (params: any) => formatPrice(params.value || 0),
        cellStyle: (params: any) => {
          if (params.value > 0) return { color: 'green' }
          if (params.value < 0) return { color: 'red' }
          return null
        },
      },
    ]
  }, [])

  // Default column definitions
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      autoHeaderHeight: true, // Ensures header height adjusts to content
      wrapHeaderText: true, // Allows header text to wrap
      suppressSizeToFit: false, // Allows columns to be sized to fit
    }),
    [],
  )

  // Clear all filters in the AgGrid
  const clearAllFilters = () => {
    setQuickFilterText('')
  }

  // Handle quick filter change
  const handleQuickFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(event.target.value)
  }

  // Handle quick filter dialog
  const handleOpenQuickFilterDialog = () => {
    setQuickFilterDialogOpen(true)
  }

  const handleCloseQuickFilterDialog = () => {
    setQuickFilterDialogOpen(false)
  }

  const handleStatusFilter = (status: string | null) => {
    setSelectedStatus(status)
  }

  // Get all unique statuses from the vehicles
  const availableStatuses = useMemo(() => {
    const statuses = new Set<string>()
    prefixVehicles.forEach(vehicle => {
      if (vehicle.status) {
        statuses.add(vehicle.status)
      }
    })
    return Array.from(statuses).sort()
  }, [prefixVehicles])

  return (
    <Paper elevation={3} sx={{ 
      p: 2, 
      height: isMobile ? 'calc(100vh - 10px)' : '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', md: 'center' },
          mb: 1,
          gap: { xs: 1, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h6' sx={{ fontSize: '1.1rem', mr: 1 }}>
            Inventory & Stock Overview
          </Typography>
          <Tooltip
            title={`Showing data from ${dateRange?.start ? dayjs(dateRange.start).format('MMM D, YYYY') : ''} to ${dateRange?.end ? dayjs(dateRange.end).format('MMM D, YYYY') : ''}`}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, color: 'text.secondary', fontSize: '0.75rem' }}>
              <CalendarTodayIcon sx={{ fontSize: '0.875rem', mr: 0.5 }} />
              <span>
                {dateRange?.start ? dayjs(dateRange.start).format('MMM D') : ''} - {dateRange?.end ? dayjs(dateRange.end).format('MMM D, YYYY') : ''}
              </span>
            </Box>
          </Tooltip>
        </Box>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label='stock overview tabs'
            sx={{ minHeight: 36 }}
            variant={isMobile ? 'fullWidth' : 'standard'}
          >
            <Tab
              label='Stock Numbers'
              id='stock-tab-0'
              aria-controls='stock-tabpanel-0'
              sx={{ minHeight: 36, py: 0.5, px: 1 }}
            />
            <Tab
              label='Vehicle Types'
              id='stock-tab-1'
              aria-controls='stock-tabpanel-1'
              sx={{ minHeight: 36, py: 0.5, px: 1 }}
            />
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={tabValue} index={0}>
        {isMobile ? (
          <Box sx={{ 
            flex: 1,
            maxHeight: 'calc(100vh - 160px)', 
            overflowY: 'auto' 
          }}>
            <Stack spacing={1.5}>
              {stockMetrics.map(metrics => {
                const isAllRow = metrics.prefix === 'All'
                return (
                  <Paper
                    key={metrics.prefix}
                    elevation={isAllRow ? 2 : 1}
                    sx={{
                      p: 1.5,
                      cursor: 'pointer',
                      bgcolor: isAllRow ? alpha(theme.palette.primary.light, 0.1) : 'inherit',
                      '&:hover': { bgcolor: 'action.hover' },
                    }}
                    onClick={() => handleViewVehicles(metrics.prefix)}
                  >
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant={isAllRow ? 'subtitle1' : 'subtitle2'}
                        color='primary.main'
                        fontWeight={isAllRow ? 'bold' : 'normal'}
                        sx={{ textDecoration: 'underline' }}
                      >
                        {getFullNameFromPrefix(metrics.prefix)}
                      </Typography>
                    </Box>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant='caption' color='text.secondary'>
                          Total
                        </Typography>
                        <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                          {metrics.totalCount}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='caption' color='text.secondary'>
                          Available
                        </Typography>
                        <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                          {metrics.availableCount}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='caption' color='text.secondary'>
                          Active Deals
                        </Typography>
                        <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                          {metrics.activeDealsCount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='caption' color='text.secondary'>
                          Potential Profit
                        </Typography>
                        <Typography variant='body2' fontWeight={isAllRow ? 'bold' : 'normal'}>
                          {formatCurrency(metrics.potentialProfit)}
                        </Typography>
                      </Grid>
                      {Object.values(metrics.dealQuality).some(count => count > 0) ? (
                        <Grid item xs={12}>
                          <Typography variant='caption' color='text.secondary' display='block' sx={{ mb: 0.5 }}>
                            Deal Quality
                          </Typography>
                          <Stack direction='row' spacing={0.5}>
                            {Object.entries(metrics.dealQuality)
                              .filter(([_, count]) => count > 0)
                              .map(([quality, count]) => {
                                const qualityInfo = getDealQualityInfo(quality as Vehicle['dealQuality'])
                                return (
                                  <Chip
                                    key={quality}
                                    label={count}
                                    color={qualityInfo.color as any}
                                    size='small'
                                    variant='outlined'
                                  />
                                )
                              })}
                          </Stack>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Paper>
                )
              })}
            </Stack>
          </Box>
        ) : (
          <TableContainer sx={{ maxHeight: 400, width: '100%' }}>
            <Table size='small' stickyHeader sx={{ width: '100%', tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '15%', py: 1, whiteSpace: 'nowrap', overflow: 'visible' }}>
                    Stock Prefix
                  </TableCell>
                  <TableCell align='center' sx={{ width: '8%', py: 1, whiteSpace: 'nowrap', overflow: 'visible' }}>
                    Total
                  </TableCell>
                  <TableCell align='center' sx={{ width: '8%', py: 1, whiteSpace: 'nowrap', overflow: 'visible' }}>
                    Available
                  </TableCell>
                  <TableCell align='center' sx={{ width: '8%', py: 1, whiteSpace: 'nowrap', overflow: 'visible' }}>
                    Active Deals
                  </TableCell>
                  <TableCell align='center' sx={{ width: '8%', py: 1, whiteSpace: 'nowrap', overflow: 'visible' }}>
                    Potential Profit
                  </TableCell>
                  <TableCell align='center' sx={{ width: '17%', py: 1, whiteSpace: 'nowrap', overflow: 'visible' }}>
                    Deal Quality
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockMetrics.map(metrics => {
                  const isAllRow = metrics.prefix === 'All'
                  return (
                    <TableRow
                      key={metrics.prefix}
                      sx={{
                        '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' },
                        bgcolor: isAllRow ? alpha(theme.palette.primary.light, 0.1) : 'inherit',
                        fontWeight: isAllRow ? 'bold' : 'normal',
                      }}
                      onClick={() => handleViewVehicles(metrics.prefix)}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{
                          fontWeight: isAllRow ? 'bold' : 'normal',
                          fontSize: isAllRow ? '1rem' : 'inherit',
                          py: 1,
                          color: theme.palette.primary.main,
                          textDecoration: 'underline',
                        }}
                      >
                        {getFullNameFromPrefix(metrics.prefix)}
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: isAllRow ? 'bold' : 'normal', py: 1 }}>
                        {metrics.totalCount}
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: isAllRow ? 'bold' : 'normal', py: 1 }}>
                        {metrics.availableCount}
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: isAllRow ? 'bold' : 'normal', py: 1 }}>
                        {metrics.activeDealsCount}
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: isAllRow ? 'bold' : 'normal', py: 1 }}>
                        {formatCurrency(metrics.potentialProfit)}
                      </TableCell>
                      <TableCell align='center' sx={{ py: 1 }}>
                        <Tooltip
                          title={
                            <>
                              {Object.entries(metrics.dealQuality)
                                .filter(([_, count]) => count > 0)
                                .map(([quality, count]) => {
                                  const qualityInfo = getDealQualityInfo(quality as Vehicle['dealQuality'])
                                  return (
                                    <Typography key={quality} variant='caption' component='div'>
                                      {qualityInfo.label}: {count}
                                    </Typography>
                                  )
                                })}
                            </>
                          }
                        >
                          <Stack direction='row' spacing={0.5} justifyContent='center'>
                            {Object.entries(metrics.dealQuality)
                              .filter(([_, count]) => count > 0)
                              .map(([quality, count]) => {
                                const qualityInfo = getDealQualityInfo(quality as Vehicle['dealQuality'])
                                return (
                                  <Box
                                    key={quality}
                                    sx={{
                                      width: 12,
                                      height: 12,
                                      borderRadius: '50%',
                                      bgcolor: `${qualityInfo.color}.main`,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      color: 'white',
                                      fontSize: '0.6rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {count}
                                  </Box>
                                )
                              })}
                          </Stack>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <VehicleTypeDistribution vehicles={stockVehicles} />
      </TabPanel>

      {/* Vehicles Dialog with AgGrid */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='lg' fullWidth fullScreen={isMobile}>
        {selectedPrefixData && (
          <>
            <DialogTitle
              sx={{
                py: 1.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography variant='h6' sx={{ fontSize: '1.1rem' }}>
                  {getFullNameFromPrefix(selectedPrefixData.prefix)} Stock Vehicles ({filteredVehicles.length})
                </Typography>
                <Typography variant='caption' color='text.secondary'>
                  {dateRange?.start ? dayjs(dateRange.start).format('MMM D, YYYY') : ''} - {dateRange?.end ? dayjs(dateRange.end).format('MMM D, YYYY') : ''}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {isMobile ? (
                  <Button
                    variant='outlined'
                    startIcon={<SearchIcon />}
                    onClick={handleOpenQuickFilterDialog}
                    size='small'
                    sx={{
                      ml: 'auto',
                      borderRadius: 2,
                      height: 36,
                      textTransform: 'none',
                    }}
                  >
                    Quick Filter
                  </Button>
                ) : (
                  <TextField
                    placeholder='Quick filter...'
                    variant='outlined'
                    size='small'
                    value={quickFilterText}
                    onChange={handleQuickFilterChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon fontSize='small' />
                        </InputAdornment>
                      ),
                      endAdornment: quickFilterText ? (
                        <InputAdornment position='end'>
                          <IconButton size='small' onClick={() => setQuickFilterText('')} edge='end'>
                            <ClearIcon fontSize='small' />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                    sx={{
                      ml: 'auto',
                      width: { xs: '100%', sm: 200 },
                      borderRadius: 2,
                    }}
                  />
                )}
                <IconButton onClick={() => setDialogOpen(false)} size='small'>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} md={3}>
                        <Typography variant='body2' color='text.secondary'>
                          Total Vehicles:
                        </Typography>
                        <Typography variant='body1'>{selectedPrefixData.totalCount}</Typography>
                      </Grid>
                      <Grid item xs={4} md={3}>
                        <Typography variant='body2' color='text.secondary'>
                          Available:
                        </Typography>
                        <Typography variant='body1'>{selectedPrefixData.availableCount}</Typography>
                      </Grid>
                      <Grid item xs={4} md={3}>
                        <Typography variant='body2' color='text.secondary'>
                          Active Deals:
                        </Typography>
                        <Typography variant='body1'>{selectedPrefixData.activeDealsCount}</Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {isMobile && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
                        Filter by Status:
                      </Typography>
                      <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap sx={{ mb: 1 }}>
                        <Chip 
                          label="All" 
                          onClick={() => handleStatusFilter(null)} 
                          color={selectedStatus === null ? 'primary' : 'default'} 
                          sx={{ mb: 1 }}
                        />
                        {availableStatuses.map(status => (
                          <Chip 
                            key={status} 
                            label={status} 
                            onClick={() => handleStatusFilter(status)} 
                            color={selectedStatus === status ? 'primary' : 'default'} 
                            sx={{ mb: 1 }}
                          />
                        ))}
                      </Stack>
                      {selectedStatus && (
                        <Button 
                          size="small" 
                          startIcon={<FilterAltOffIcon />} 
                          onClick={() => handleStatusFilter(null)}
                          sx={{ mb: 1 }}
                        >
                          Clear Status Filter
                        </Button>
                      )}
                    </Box>
                  )}

                  {/* AgGrid for vehicle data on desktop, cards on mobile */}
                  {isMobile ? (
                    <Box sx={{ maxHeight: 'calc(100vh - 320px)', overflow: 'auto' }}>
                      <Stack spacing={1.5}>
                        {filteredVehicles.map(vehicle => (
                          <Paper key={vehicle.id} elevation={1} sx={{ p: 1.5 }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    mb: 0.5,
                                  }}
                                >
                                  <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                                    {vehicle.stock_number}
                                  </Typography>
                                  <Chip
                                    label={vehicle.status}
                                    size='small'
                                    color={
                                      vehicle.status === 'Available'
                                        ? 'success'
                                        : vehicle.status === 'Pending Sale' || vehicle.status === 'Active Deal'
                                          ? 'warning'
                                          : vehicle.status === 'Sold'
                                            ? 'primary'
                                            : 'default'
                                    }
                                  />
                                </Box>
                                <Typography variant='body2'>
                                  {vehicle.year} {vehicle.make} {vehicle.model}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider sx={{ my: 0.5 }} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='caption' color='text.secondary'>
                                  List Price
                                </Typography>
                                <Typography variant='body2'>{formatPrice(vehicle.optic_list_price || 0)}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='caption' color='text.secondary'>
                                  Buy Price
                                </Typography>
                                <Typography variant='body2'>
                                  {formatPrice(vehicle.seller_asking_price || 0)}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='caption' color='text.secondary'>
                                  Additional Costs
                                </Typography>
                                <Tooltip
                                  title={
                                    <div>
                                      <Typography variant='caption' display='block'>
                                        Repair: {formatPrice(vehicle.repair_cost || 0)}
                                      </Typography>
                                      <Typography variant='caption' display='block'>
                                        Shipping: {formatPrice(vehicle.shipping_cost || 0)}
                                      </Typography>
                                      <Typography variant='caption' display='block'>
                                        Rental: {formatPrice(vehicle.rental_cost || 0)}
                                      </Typography>
                                      <Typography variant='caption' display='block'>
                                        Other: {formatPrice(vehicle.other_cost || 0)}
                                      </Typography>
                                    </div>
                                  }
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {formatPrice(
                                      (vehicle.repair_cost || 0) +
                                        (vehicle.shipping_cost || 0) +
                                        (vehicle.rental_cost || 0) +
                                        (vehicle.other_cost || 0),
                                    )}
                                    <InfoOutlinedIcon
                                      sx={{ ml: 0.5, fontSize: '0.8rem', color: 'text.secondary' }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='caption' color='text.secondary'>
                                  Profit
                                </Typography>
                                <Typography
                                  variant='body2'
                                  sx={{
                                    color:
                                      (vehicle.optic_list_price || 0) -
                                        (vehicle.seller_asking_price || 0) -
                                        (vehicle.repair_cost || 0) -
                                        (vehicle.shipping_cost || 0) -
                                        (vehicle.rental_cost || 0) -
                                        (vehicle.other_cost || 0) >
                                      0
                                        ? 'success.main'
                                        : 'error.main',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {formatPrice(
                                    (vehicle.optic_list_price || 0) -
                                      (vehicle.seller_asking_price || 0) -
                                      (vehicle.repair_cost || 0) -
                                      (vehicle.shipping_cost || 0) -
                                      (vehicle.rental_cost || 0) -
                                      (vehicle.other_cost || 0),
                                  )}
                                </Typography>
                              </Grid>
                              {vehicle.status === 'Sold' && vehicle.sold_date && (
                                <Grid item xs={12}>
                                  <Typography variant='caption' color='text.secondary'>
                                    Sold Date
                                  </Typography>
                                  <Typography variant='body2'>{formatDateTime(vehicle.sold_date)}</Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        ))}
                        {filteredVehicles.length === 0 && (
                          <Paper sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant='body1'>No vehicles found matching the filter criteria</Typography>
                          </Paper>
                        )}
                      </Stack>
                    </Box>
                  ) : (
                    <div className='ag-theme-material' style={{ height: 500, width: '100%' }}>
                      <AgGridReact
                        rowData={filteredVehicles}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                        enableCellTextSelection={true}
                        onGridReady={params => {
                          params.api.sizeColumnsToFit()
                        }}
                        onGridSizeChanged={params => {
                          params.api.sizeColumnsToFit()
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
      {isMobile && (
        <MobileQuickFilter
          open={quickFilterDialogOpen}
          onClose={handleCloseQuickFilterDialog}
          value={quickFilterText}
          onChange={e => setQuickFilterText(e.target.value)}
          onClear={clearAllFilters}
        />
      )}
    </Paper>
  )
}
