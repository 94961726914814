import React from 'react'
import { Grid, Box } from '@mui/material'
import { Vehicle } from '@otw/models'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { 
  SalesAndDealsOverview, 
  SalesTrendChart, 
} from './views'
import { MobileViewSelector } from '../../shared'

// Import icons for the mobile view selector
import ReceiptIcon from '@mui/icons-material/Receipt'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

interface SalesTabProps {
  soldVehicles: Vehicle[]
  dateRange: {
    start: Date
    end: Date
  }
}

export const SalesTab: React.FC<SalesTabProps> = ({ 
  soldVehicles, 
  dateRange 
}) => {
  const isMobile = useIsMobile();
  
  // For mobile, we'll use the view selector with dropdown
  if (isMobile) {
    return (
      <Box sx={{ 
        maxWidth: '100%',
        overflowX: 'hidden'
      }}>
        <MobileViewSelector
          titles={["Sales Overview", "Sales Trends"]}
          icons={[
            <ReceiptIcon fontSize="small" />,
            <TrendingUpIcon fontSize="small" />
          ]}
        >
          <SalesAndDealsOverview 
            soldVehicles={soldVehicles} 
            dateRange={dateRange}
          />
          <SalesTrendChart 
            soldVehicles={soldVehicles} 
            dateRange={dateRange}
          />
        </MobileViewSelector>
      </Box>
    );
  }
  
  // Desktop view remains unchanged
  return (
    <Box sx={{ 
      maxWidth: '100%',
      overflowX: 'hidden'
    }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SalesAndDealsOverview 
            soldVehicles={soldVehicles} 
            dateRange={dateRange}
          />
        </Grid>
        <Grid item xs={12}>
          <SalesTrendChart 
            soldVehicles={soldVehicles} 
            dateRange={dateRange}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SalesTab 