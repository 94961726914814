import React, { useMemo } from 'react'
import { Paper, Typography, Box, Grid, Chip, Stack, Divider, LinearProgress, Tooltip } from '@mui/material'
import { Vehicle } from '@otw/models'
import { formatPrice } from 'core/utils/inventoryUtils'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import dayjs from 'dayjs'
import { useInventory } from 'hooks/inventory'
import { useIsMobile } from 'hooks/screen/useIsMobile'

interface MonthlyMetrics {
  soldCount: number
  revenue: number
  profit: number
  expenses: number
  avgProfit: number
}

export const MonthlyComparison: React.FC = () => {
  const { vehicles } = useInventory('master_inventory')
  const isMobile = useIsMobile()
  // Helper function to safely parse dates
  const parseDate = (dateValue: any): Date | null => {
    try {
      if (!dateValue) return null

      // Handle Firestore Timestamp
      if (dateValue?.seconds) {
        return new Date(dateValue.seconds * 1000)
      }

      // Handle string dates
      if (typeof dateValue === 'string') {
        const date = new Date(dateValue)
        return isNaN(date.getTime()) ? null : date
      }

      // Handle Date objects
      if (dateValue instanceof Date) {
        return isNaN(dateValue.getTime()) ? null : dateValue
      }

      return null
    } catch (error) {
      console.error('Error parsing date:', dateValue, error)
      return null
    }
  }

  // Calculate metrics for this month and last month
  const { thisMonth, lastMonth } = useMemo(() => {
    if (!vehicles)
      return {
        thisMonth: { soldCount: 0, revenue: 0, profit: 0, expenses: 0, avgProfit: 0 },
        lastMonth: { soldCount: 0, revenue: 0, profit: 0, expenses: 0, avgProfit: 0 },
      }

    const now = dayjs()
    const thisMonthStart = now.startOf('month').toDate()
    const lastMonthStart = now.subtract(1, 'month').startOf('month').toDate()
    const lastMonthEnd = now.subtract(1, 'month').endOf('month').toDate()

    // Filter vehicles sold this month
    const soldThisMonth = vehicles.filter(v => {
      if (v.status !== 'Sold') return false
      const soldDate = parseDate(v.sold_date)
      if (!soldDate) return false
      return soldDate >= thisMonthStart && soldDate <= now.toDate()
    })

    // Filter vehicles sold last month
    const soldLastMonth = vehicles.filter(v => {
      if (v.status !== 'Sold') return false
      const soldDate = parseDate(v.sold_date)
      if (!soldDate) return false
      return soldDate >= lastMonthStart && soldDate <= lastMonthEnd
    })

    // Calculate metrics for this month
    const thisMonthMetrics: MonthlyMetrics = {
      soldCount: soldThisMonth.length,
      revenue: soldThisMonth.reduce((sum, v) => sum + (v.optic_list_price || 0), 0),
      profit: soldThisMonth.reduce((sum, v) => sum + (v.profit || 0), 0),
      expenses: soldThisMonth.reduce((sum, v) => {
        return sum + (v.shipping_cost || 0) + (v.repair_cost || 0)
      }, 0),
      avgProfit: soldThisMonth.length
        ? soldThisMonth.reduce((sum, v) => sum + (v.profit || 0), 0) / soldThisMonth.length
        : 0,
    }

    // Calculate metrics for last month
    const lastMonthMetrics: MonthlyMetrics = {
      soldCount: soldLastMonth.length,
      revenue: soldLastMonth.reduce((sum, v) => sum + (v.optic_list_price || 0), 0),
      profit: soldLastMonth.reduce((sum, v) => sum + (v.profit || 0), 0),
      expenses: soldLastMonth.reduce((sum, v) => {
        return sum + (v.shipping_cost || 0) + (v.repair_cost || 0)
      }, 0),
      avgProfit: soldLastMonth.length
        ? soldLastMonth.reduce((sum, v) => sum + (v.profit || 0), 0) / soldLastMonth.length
        : 0,
    }

    return {
      thisMonth: thisMonthMetrics,
      lastMonth: lastMonthMetrics,
    }
  }, [vehicles])

  // Calculate percentage changes
  const changes = useMemo(() => {
    const calculateChange = (current: number, previous: number) => {
      if (previous === 0) return current > 0 ? 100 : 0
      return ((current - previous) / previous) * 100
    }

    return {
      soldCount: calculateChange(thisMonth.soldCount, lastMonth.soldCount),
      revenue: calculateChange(thisMonth.revenue, lastMonth.revenue),
      profit: calculateChange(thisMonth.profit, lastMonth.profit),
      expenses: calculateChange(thisMonth.expenses, lastMonth.expenses),
      avgProfit: calculateChange(thisMonth.avgProfit, lastMonth.avgProfit),
    }
  }, [thisMonth, lastMonth])

  // Helper to render trend icon
  const renderTrendIcon = (change: number) => {
    if (change > 5) return <TrendingUpIcon color='success' />
    if (change < -5) return <TrendingDownIcon color='error' />
    return <TrendingFlatIcon color='action' />
  }

  // Helper to get color based on change
  const getChangeColor = (change: number, inverse = false) => {
    if (inverse) {
      // For expenses, negative change is good
      if (change > 5) return 'error'
      if (change < -5) return 'success'
      return 'info'
    } else {
      if (change > 5) return 'success'
      if (change < -5) return 'error'
      return 'info'
    }
  }

  // Get formatted month names
  const thisMonthName = dayjs().format('MMMM YYYY')
  const lastMonthName = dayjs().subtract(1, 'month').format('MMMM YYYY')

  return (
    <Paper elevation={3} sx={{ 
      p: { xs: 1.5, sm: 2, md: 3 }, 
      height: '100%',
      overflow: 'hidden'
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start', 
        mb: 2,
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? 1 : 0
      }}>
        <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom={!isMobile}>
          Monthly Comparison
        </Typography>
        <Chip
          icon={<CalendarTodayIcon />}
          label={`${thisMonthName} vs ${lastMonthName}`}
          size='small'
          color='primary'
          variant='outlined'
        />
      </Box>

      <Grid container spacing={2}>
        {/* This Month Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography variant='subtitle2' color='text.secondary' gutterBottom>
              This Month ({thisMonthName})
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant='body2' noWrap>Vehicles Sold</Typography>
                  <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">{thisMonth.soldCount}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant='body2' noWrap>Revenue</Typography>
                  <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">{formatPrice(thisMonth.revenue)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant='body2' noWrap>Profit</Typography>
                  <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">{formatPrice(thisMonth.profit)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant='body2' noWrap>Expenses</Typography>
                  <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">{formatPrice(thisMonth.expenses)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant='body2' noWrap>Avg. Profit per Vehicle</Typography>
                  <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">{formatPrice(thisMonth.avgProfit)}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Last Month Comparison Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant='subtitle2' color='text.secondary'>
                vs. Last Month ({lastMonthName})
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='body2' noWrap>Vehicles Sold</Typography>
                    <Chip
                      icon={renderTrendIcon(changes.soldCount)}
                      label={`${changes.soldCount.toFixed(1)}%`}
                      size='small'
                      color={getChangeColor(changes.soldCount)}
                      sx={{ height: 20, '& .MuiChip-label': { px: 0.5, fontSize: '0.7rem' } }}
                    />
                  </Box>
                  <Tooltip title={`Last Month: ${lastMonth.soldCount}`}>
                    <LinearProgress
                      variant='determinate'
                      value={Math.min(Math.max(changes.soldCount + 100, 0), 200) / 2}
                      color={getChangeColor(changes.soldCount)}
                      sx={{ height: 6, borderRadius: 1, my: 0.5 }}
                    />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='body2' noWrap>Revenue</Typography>
                    <Chip
                      icon={renderTrendIcon(changes.revenue)}
                      label={`${changes.revenue.toFixed(1)}%`}
                      size='small'
                      color={getChangeColor(changes.revenue)}
                      sx={{ height: 20, '& .MuiChip-label': { px: 0.5, fontSize: '0.7rem' } }}
                    />
                  </Box>
                  <Tooltip title={`Last Month: ${formatPrice(lastMonth.revenue)}`}>
                    <LinearProgress
                      variant='determinate'
                      value={Math.min(Math.max(changes.revenue + 100, 0), 200) / 2}
                      color={getChangeColor(changes.revenue)}
                      sx={{ height: 6, borderRadius: 1, my: 0.5 }}
                    />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='body2' noWrap>Profit</Typography>
                    <Chip
                      icon={renderTrendIcon(changes.profit)}
                      label={`${changes.profit.toFixed(1)}%`}
                      size='small'
                      color={getChangeColor(changes.profit)}
                      sx={{ height: 20, '& .MuiChip-label': { px: 0.5, fontSize: '0.7rem' } }}
                    />
                  </Box>
                  <Tooltip title={`Last Month: ${formatPrice(lastMonth.profit)}`}>
                    <LinearProgress
                      variant='determinate'
                      value={Math.min(Math.max(changes.profit + 100, 0), 200) / 2}
                      color={getChangeColor(changes.profit)}
                      sx={{ height: 6, borderRadius: 1, my: 0.5 }}
                    />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='body2' noWrap>Expenses</Typography>
                    <Chip
                      icon={renderTrendIcon(changes.expenses)}
                      label={`${changes.expenses.toFixed(1)}%`}
                      size='small'
                      color={getChangeColor(changes.expenses, true)}
                      sx={{ height: 20, '& .MuiChip-label': { px: 0.5, fontSize: '0.7rem' } }}
                    />
                  </Box>
                  <Tooltip title={`Last Month: ${formatPrice(lastMonth.expenses)}`}>
                    <LinearProgress
                      variant='determinate'
                      value={Math.min(Math.max(changes.expenses + 100, 0), 200) / 2}
                      color={getChangeColor(changes.expenses, true)}
                      sx={{ height: 6, borderRadius: 1, my: 0.5 }}
                    />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='body2' noWrap>Avg. Profit</Typography>
                    <Chip
                      icon={renderTrendIcon(changes.avgProfit)}
                      label={`${changes.avgProfit.toFixed(1)}%`}
                      size='small'
                      color={getChangeColor(changes.avgProfit)}
                      sx={{ height: 20, '& .MuiChip-label': { px: 0.5, fontSize: '0.7rem' } }}
                    />
                  </Box>
                  <Tooltip title={`Last Month: ${formatPrice(lastMonth.avgProfit)}`}>
                    <LinearProgress
                      variant='determinate'
                      value={Math.min(Math.max(changes.avgProfit + 100, 0), 200) / 2}
                      color={getChangeColor(changes.avgProfit)}
                      sx={{ height: 6, borderRadius: 1, my: 0.5 }}
                    />
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='caption' color='text.secondary'>
          Comparing current month progress with last month's final results
        </Typography>
      </Box>
    </Paper>
  )
}
