import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { Vehicle } from "../inventory";

const vehicleConverter: FirestoreDataConverter<Vehicle> = {
  toFirestore(vehicle: WithFieldValue<Vehicle>) {
    const { id, ...data } = vehicle;
    return data;
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Vehicle {
    const data = snapshot.data(options);
    const safeNumber = (value: any): number | null => {
      if (!value) return null;
      const num = Number(value);
      return isNaN(num) ? null : num;
    };

    const safeDate = (value: any): string | null => {
      if (!value) return null;
      
      // If it's already a Date object
      if (value instanceof Date) {
        return value.toISOString();
      }
      
      // If it's a Firestore Timestamp
      if (value && typeof value.toDate === 'function') {
        return value.toDate().toISOString();
      }
      
      // If it's a string that can be parsed as a date
      if (typeof value === 'string') {
        try {
          const date = new Date(value);
          if (!isNaN(date.getTime())) {
            return date.toISOString();
          }
        } catch (e) {
          // Invalid date string
          return null;
        }
      }
      
      return null;
    };

    // if no data, return a new vehicle with all null values
    if (!data)
      return {
        id: snapshot.id,
        date_added: null,
        entered_by: "",
        vin: "",
        status: null,
        stock_number: "",
        year: null,
        make: null,
        model: null,
        size: null,
        type: null,
        miles: null,
        fuel: null,
        condition: null,
        optic_list_price: null,
        seller_asking_price: null,
        profit: null,
        payoff_amount: null,
        repair_cost: 0,
        shipping_cost: 0,
        rental_cost: 0,
        other_cost: 0,
        sold_date: null,
        who: null,
        sold_to_user: null,
        sold_by_user: null,
        dealQuality: null,
        location: null,
        phone_number: null,
        email: null,
        truck_notes: null,
        follow_up_notes: null,
        link: null,
        photo_storage_path: null,
        show_on_website: false,
        updated_by: null,
        updated_at: null,
        cameras: 'unknown',
        shelves: 'unknown',
        size_confirmed: false,
        favorite: false,
      };

    return {
      // Core identification fields
      id: snapshot.id,
      date_added: safeDate(data.date_added),
      entered_by: data.entered_by || "",
      vin: data.vin || "",
      stock_number: data.stock_number || "",

      // Basic vehicle information
      year: safeNumber(data.year),
      make: data.make || null,
      model: data.model || null,
      size: data.size || null,
      type: data.type || null,
      miles: safeNumber(data.miles),
      fuel: data.fuel || null,
      condition: data.condition || null,

      // Financial information
      optic_list_price: safeNumber(data.optic_list_price),
      seller_asking_price: safeNumber(data.seller_asking_price),
      profit: safeNumber(data.profit),
      payoff_amount: safeNumber(data.payoff_amount),
      
      // Vehicle costs
      repair_cost: safeNumber(data.repair_cost) || 0,
      shipping_cost: safeNumber(data.shipping_cost) || 0,
      rental_cost: safeNumber(data.rental_cost) || 0,
      other_cost: safeNumber(data.other_cost) || 0,

      // Sales information
      sold_date: safeDate(data.sold_date),
      who: data.who || null,
      sold_to_user: data.sold_to_user || 
        (data.sold_to ? { 
          id: data.who || 'unknown',
          name: data.sold_to
        } : null),
      sold_by_user: data.sold_by_user || 
        (data.sold_by ? { 
          id: data.sold_by_id || 'house',
          name: data.sold_by
        } : null),
      status: [
        "Pending Available",
        "Available",
        "Pending Sale",
        "Sold",
        "Sold elsewhere",
        "Active Deal",
      ].includes(data.status || "")
        ? (data.status as Vehicle["status"])
        : null,
      dealQuality: [
        "great",
        "good",
        "average",
        "belowAverage",
        "poor",
      ].includes(data.dealQuality || "")
        ? (data.dealQuality as Vehicle["dealQuality"])
        : null,

      // Contact and location details
      location: data.location || null,
      phone_number: data.phone_number || null,
      email: data.email || null,
      ...(data.assigned_user && { assigned_user: data.assigned_user }),

      // Notes and additional information
      truck_notes: data.truck_notes || null,
      follow_up_notes: data.follow_up_notes || null,
      link: data.link || null,

      // Photo and display properties
      show_on_website: Boolean(data.show_on_website),
      photo_storage_path: data.photo_storage_path || null,
      photos: {
        main: data.photos?.main || null,
        front: data.photos?.front || null,
        back: data.photos?.back || null,
        left: data.photos?.left || null,
        right: data.photos?.right || null,
        interior: data.photos?.interior || null,
        engine: data.photos?.engine || null,
      },

      // Equipment details
      cameras: data.cameras || 'unknown',
      shelves: data.shelves || 'unknown',
      size_confirmed: Boolean(data.size_confirmed),
      updated_at: safeDate(data.updated_at),
      updated_by: data.updated_by || null,
      favorite: data.favorite || false,
    };
  },
};

export { vehicleConverter };
